import '../../invoice.css';
import { NoDokumenPembayaran, getInaDate, numberFormatReverse, ex_terbilang, ucWords, isJson, getTtdKabag } from '../../api/Value';
import TtdKabag from './TtdKabag'
export default ({ myForm, invoiceSelected }) => {
    

    const infoInvoice = (typeof invoiceSelected.info_invoice === "string") ? isJson(invoiceSelected.info_invoice) : invoiceSelected.info_invoice
    
    return (
        <div>
            <div className="arial file bg-white  pr-[1cm] pl-[1cm] pb-[1cm] ">
        <div className="header-invoice">
            <div className="ppsdm-kop-invoice">PPSDM KEBTKE</div>
            <center>
                <div className="ppsdm-2nd">Kementerian Energi dan Sumber Daya Mineral</div>
            </center>
        </div>
        <div className="calibri ">
            <div className="second-header-invoice">
            <table width="100%" >
                <tbody>
                    <tr>
                        <td width="70%" style={{fontSize: "14px"}}>
                            Jalan Poncol Raya No. 39
                            <br/>
                            Ciracas, Jakarta Timur 
                            <br/>
                            13740
                        </td>
                        <td>
                            <center>
                                <div className="invoice-txt">
                                    INVOICE
                                </div>
                            </center>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
            <table width="100%"  className="table-tagih border-collapse">
                <tbody>
                    <tr>
                        <td valign="top" width="8%">Tagih ke</td>
                        <td valign="top">:</td>
                        <td valign="top" className="text-justify">{myForm.transaksi.info_pelanggan.nama}</td>

                        <td valign="top" width="8%">Telepon</td>
                        <td valign="top">:</td>
                        <td valign="top" className="text-justify">{myForm.transaksi.info_pelanggan.telp}</td>

                        <td valign="top" width="10%">No. Invoice</td>
                        <td valign="top">:</td>
                        <td valign="top" className="">{ NoDokumenPembayaran({ id_invoice: invoiceSelected.id_invoice, dt_invoice_created: invoiceSelected.dt_invoice_created })}</td>
                    </tr>
                    <tr>
                        <td rowSpan="2" valign="top">Alamat</td>
                        <td rowSpan="2" valign="top">:</td>
                        <td rowSpan="2" valign="top" className="pr-2">{myForm.transaksi.info_transaksi.alamat_tagihan}</td>

                        <td valign="top">Fax</td>
                        <td valign="top">:</td>
                        <td valign="top">-</td>

                        <td valign="top">Tanggal</td>
                        <td valign="top">:</td>
                        <td valign="top" className="text-justify pr-2">{getInaDate(invoiceSelected.dt_invoice_created)}</td>
                    </tr>
                    <tr>
                        <td valign="top">
                            Email
                        </td>
                        <td valign="top">:</td>
                        <td valign="top" className="text-justify pr-2">{myForm.transaksi.info_pelanggan.email}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table-invoice" width="100%">
                <thead>
                    <tr>
                        <th width="5%">No</th>
                        <th>Deskripsi</th>
                        <th width="8%">Jumlah</th>
                        <th>Harga Satuan</th>
                        <th>Diskon</th>
                        <th width="12%">Harga</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        myForm.keranjang.map((x, i) => {
                            return (
                                <tr key={i}>
                                  <td className="text-center">{ i+1 }</td>
                                    <td>{x.uraian_keranjang}</td>
                                    <td className="text-center">{x.kuantitas}</td>
                                    <td className="text-right" style={{ whiteSpace: 'nowrap'}}>Rp { numberFormatReverse(  `${x.tarif_keranjang}` ) }</td>
                                    <td className="text-center">-</td>
                                    <td className="text-right" style={{ whiteSpace: 'nowrap'}}>Rp { numberFormatReverse( `${x.jumlah_keranjang}` ) }</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="4" rowSpan="6" style={{border: "none"}} valign="bottom">
                            {
                                (infoInvoice.tipe_pembayaran === "Transfer") ?
                                <span>
                                    Pembayaran melalui transfer ke : <br /> Bank  {infoInvoice.nama_bank}  Rekening <strong> {infoInvoice.nama_rekening} </strong> <br /> No. Rekening
                                    <strong>  {infoInvoice.nomor_rekening}</strong> 

                                </span>
                                :
                                <span>    
                                    Pembayaran melalui transfer ke Virtual Account <br />
                                    <strong> BNI {invoiceSelected.no_va} </strong> <br /><br />
                                    Nb: Jumlah Pembayaran tersebut sudah termasuk biaya Transaksi Perbankan   
                                </span>
                            }
                        </td>
                        <td className="text-right">Subtotal Faktur</td>
                        <td className="text-right" style={{ whiteSpace: "nowrap" }}>Rp { numberFormatReverse( `${myForm.transaksi.jumlah_transaksi}` ) }</td>
                    </tr>
                
                    <tr>
                        
                      
                        <td className="text-right">Transaksi Perbankan</td>
                        <td  className="text-right" style={{ whiteSpace: "nowrap" }}>
                            {
                                
                                (infoInvoice.tipe_pembayaran === "Transfer") ?
                                <span>Rp. 0</span>
                                :
                                <span>
                                    Rp. { numberFormatReverse( `${myForm.invoice.biaya_bank}` ) }
                                </span>
                            }
                        </td>
                    </tr>
                    <tr>
                   
                        <td className="text-right">Deposit yang Diterima</td>
                        <td  className="text-right">-</td>
                    </tr>
                    <tr>
                        <td className="text-right">
                            TOTAL
                        </td>
                        <td  className="text-right" style={{ whiteSpace: "nowrap" }}>
                            {
                                (infoInvoice.tipe_pembayaran === "Transfer") ?
                                <span>
                                    Rp { numberFormatReverse( `${myForm.invoice.jumlah_bruto-myForm.invoice.biaya_bank}` ) }
                                </span>
                                :
                                <span>Rp { numberFormatReverse( `${myForm.invoice.jumlah_bruto}` ) }</span>
                            }
                        </td>
                    </tr>

                </tfoot>
            </table>
                            <table className="tbl-biaya" width="100%" style={{ border:'none' }}>
                                <tbody>
                                    <tr>
                                        <td width="50%" valign="bottom"  className="text-[13px]" style={{ border:'none' }}>
                                            Lampiran 1 : Pelanggan
                                            <br/>
                                            Lampiran 2 : Bagian Umum
                                            
                                        </td>
                                        <td width="50%" valign="bottom" style={{ border:'none' }}>
                                            <TtdKabag 
                                                tanggal = { invoiceSelected.dt_invoice_created } 
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                
        </div>
       
    </div>
        </div>
    )
}