import { Component, Fragment } from 'react';
import { SmallNavbar } from '../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort,faList, faPrint, faEye } from '@fortawesome/free-solid-svg-icons';
import { Card } from '../components/Card';
import Datatable from '../components/Datatable';
import { InputText } from '../components/Input';
import { getOneUser, updateUserPassword, updateUser } from '../api/User';
import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import { backEndUrl } from '../config/Config';
import Spinner    from '../components/Spinner';
import { uploadProfilePicture, updateUserInfo } from '../api/User';
import Auth from '../api/Auth.js';


export default  () => {
    return (
        <Auth.AuthConsumer>
            {
                ({ getOneUser }) => {
                    return (
                         <Penerimaan getOneUser={getOneUser} />
                    )
                }
            }
           
        </Auth.AuthConsumer>
    )
}

class Penerimaan extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: {

            },
            password: {
                password: "",
                verify_password: ""
            },
            color: localStorage.getItem("PROFIL_COLOR"),

            formState: {
                username: "",
                password: "",
                verify_password: "",
                nama: "",
                nip: ""
            },
            loader: false
        }
    }
    componentDidMount  = () => {
        const id_user = localStorage.getItem("PENERIMAAN_ID_USER");
        const self    = this;
        getOneUser({ id_user: id_user }).then((result) => {
            if (result.status === 500) {
                toast.custom((t) => {
                    return <ToastComponent param={t} message="User Gagal dimuat" bg="red"/>
                })
            }else{
                let data = result.message.data;
                data['user_info'] = JSON.parse(data['user_info']);
                data['roles']   =  data.roles.split(",")
                
               // console.log(data);
                self.setState({
                    data:data
                })
            }
        })
    }
    componentWillUnmount = () => {
      
    }
    openDeleteForm = () => {

    }
    submit = async ()=> {
        let isSuccess = true;
        let isPassword = false;
        let data     = this.state.data;
        let password = this.state.password;
        let formState =this.state.formState;
        if (data.username === "") {
            formState['username'] = "required";
            isSuccess = false
        }
        if (data.user_info.nama === "") {
            formState['nama']     = "required";
            isSuccess = false

        }
        if (data.user_info.nip === "") {
            formState["nip"]      = "required";
            isSuccess = false
        }
        if (password.password !== "") {
            if (password.password !== password.verify_password) {
                formState['verify_password'] = "password tidak sama"
                isPassword = false;
                isSuccess = false;
            }else if(password.password.length < 8){
                formState['verify_password'] = "password minimal 8 karakter"
                formState['password'] = "password minimal 8 karakter"
                isPassword = false;
                isSuccess = false;
            }else{
                isPassword = true;

            }
        }

        if (isSuccess) {      
            await updateUser(data).then((result) => {
           //     console.log(result)
                
                if (result.status === 200) {
                    isSuccess = true
                }else{
                
                    isSuccess = false
                }
            })   
        }
        if (isSuccess) {
            this.setState({
                loader: true
            })
        }
        if (isPassword && isSuccess) {   
            await updateUserPassword({ id_user: this.state.data.id_user, password: this.state.password.password }).then((result) => {
               //     console.log(result)
                
                if (result.status === 200) {
                    isSuccess = true
                }else{
                    isSuccess = false
                }
            })   
        }

       // console.log(isSuccess)
        if (isSuccess) {
            this.setState({
                loader: false
            }, () => {
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            })
            toast.custom((t) => {
                return <ToastComponent param={t} message="Berhasil mengupdate profil" bg="green"/>
            })

        }else{
            this.setState({
                formState: formState,
                loader: false
            }, () => {
                setTimeout(() => {
                    formState['username'] = "";
                    formState['password'] = "";
                    formState['verify_password'] = "";
                    formState['nama'] = "";
                    formState['nip'] = "";
                }, 2000);
            })

            toast.custom((t) => {
                return <ToastComponent param={t} message="Gagal mengupdate profil" bg="red"/>
            })
        }
    }
    handleChange = (e)   => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "nama" || name ==="nip") {
            let data = this.state.data;
            data["user_info"][name] = value
            this.setState({
                data: data
            })
        }else{
            let data = this.state.data;
            data[name] = value
            this.setState({
                data: data
            })
        }
         this.setState({
            loader: false
        })
    }
    handlePassword = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let password = this.state.password;
        password[name] = value;
        this.setState({
            password: password
        })
    }
    progressBar = (percent) => {
    //    console.log(percent)
    }
    uploadProfile = async (e) => {
        const file = e.target.files;
        const id_user = localStorage.getItem("PENERIMAAN_ID_USER");

        const self = this;
        if (file.length > 0) {
            await  uploadProfilePicture({ id_user: id_user ,file: file, onUpload: this.progressBar }).then(async (result) => {
               // console.log(result)
                if (result.status == 200) {
                    const myForm = self.state.data;
                    myForm['user_info']['profile_picture'] = result.data;
                    await updateUserInfo({ id_user: parseInt(id_user), user_info: myForm.user_info })
                    .then((res) => {
                        if (res.status === 200) {
                            const myProfil = document.getElementById("profile-picture");
                            myProfil.src = backEndUrl() + "/penerimaan/files/" + result.data
                            toast.custom((t) => {
                                return <ToastComponent param={t} message="Profile Berhasil diunggah" bg="green"/>
                            })       
                            self.props.getOneUser(id_user)
                            self.setState({
                                data: myForm
                            })
            
                        }else{
                            toast.custom((t) => {
                                return <ToastComponent param={t} message="Profile Gagal diunggah" bg="red"/>
                            })           
                        }
                    })
                }else{
                console.log(result)
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Profile Gagal diunggah" bg="red"/>
                    })   
                }
            })
        }
    }
    render(){
        const { openDeleteForm, handleChange, handlePassword, submit, uploadProfile } = this;
        const { data, password, loader, formState }           = this.state;
        let profil  =   require("../profile.png");
        if (data.user_info) {
            if (data.user_info.profile_picture !== undefined && data.user_info.profile_picture !== "") {
                profil = backEndUrl() + "/files/" + data.user_info.profile_picture;
            }
        }
        return (
            <div className="p-5 animate-fadein">
                <Toaster position="bottom-right"/>
                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2 " >
                                Profile Setting
                            </div>
                        </Fragment>}
                />
                <div className="md:w-1/2">
                
                <Card>
                    <div className="flex">
                        
                        <div className="flex-1 p-2 ml-3">
                                <center>
                                    <div style={{ background: this.state.color }} className="w-20 h-20 grid grid-cols-1 content-center text-slate-50 font-bold rounded-full shadow-xl text-4xl">
                                        <div>
                                            {
                                                data.user_info !== "" && data.user_info ?
                                                    data.user_info.nama.charAt(0).toUpperCase()
                                                :
                                                null
                                            }
                                        </div>
                                        
                                    </div>
                                </center>
                                <InputText other={{ onChange: handleChange, value: data.username }} Title="Username" Placeholder = "Username" Name="username" Status={formState.username} TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" "/>
                                <InputText other={{ onChange: handlePassword, value: password.password }} Title="Password" Placeholder = "Password" Name="password" Status={formState.password} Type="password" TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" "/>
                                <InputText other={{ onChange: handlePassword, value: password.verify_password }} Title="Ulangi Password" Placeholder = "Ulangi Password" Name="verify_password"  Type="password" Status={formState.verify_password} TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" "/>
                                <InputText other={{ onChange: handleChange, value: (data.user_info) ? data.user_info.nama: "" }} Title="Nama" Placeholder = "Nama" Name="nama" Status={formState.nama} TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" "/>
                                <InputText other={{ onChange: handleChange, value: (data.user_info) ? data.user_info.nip: "" }} Title="NIP" Placeholder = "NIP" Name="nip" Status={formState.nip} TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" "/>
                                {
                                    loader ? 
                                     <button className="opacity-70 cursor-not-allowed flex  justify-center bg-blue-500 text-white w-full rounded-md p-2 shadow-md hover:bg-blue-400 active:shadow-none active:bg-blue-700 ">
                                                               <div className=" ">
                                                    <Spinner 
                                                        color="rgb(255 255 255)"
                                                        width="30px"
                                                        height="30px"
                                                    />
                                                </div>
                                                
                                                <div className="pt-1 ml-2">
                                                    Mohon Tunggu
                                                </div>                            
                                     </button>
         
                                    : 
                                    <button onClick={submit} className="bg-blue-500 text-white w-full rounded-md p-2 shadow-md hover:bg-blue-400 active:shadow-none active:bg-blue-700 ">SIMPAN</button>
                                }
                                
                                
                        </div>
                    </div>
                </Card>
                </div>
            </div>
        )
    }
}

