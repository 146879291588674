import { Component }  from 'react';
import { Select }   from '../../components/Input';
import { updateUnitPenjual } from '../../api/Layanan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ModalFooter from '../../components/ModalFooter';
import toast, { Toaster } from 'react-hot-toast';
import { getParentLevel } from '../../api/Value';
import { Toast as ToastComponent } from '../../components/Toast';


export default class Home extends Component{
    constructor(props) {
        super(props)
        this.state = {
           
            loader: false,
            selectUnit:""
        }
    }
    
    handleUnit = (e) => {
        if (e.target.value != "") {
            let selectUnit = this.state.selectUnit;

            let val        = selectUnit.split(",")
            if (val.length == 0) {
                val = []
            }
            val.push(e.target.value)
                val =   val.join(",")
            this.setState({
                selectUnit: val
            })   
        }
    }
    deleteUnit = (index) => {
         let selectUnit = this.state.selectUnit.split(",");

        selectUnit.splice(index, 1);
        selectUnit = selectUnit.join(",")
        this.setState({
            selectUnit:selectUnit
        })
    }
    onSubmit = () => {
        // kode layanan selected
        const unit_penjual = this.state.selectUnit;
        const data = this.props.data
        const list_kode = getParentLevel(data.filter((x) => { 
                        return x.checked
                    }))
     
        if(list_kode.length == 0) {
            toast.custom((t) => {
                return <ToastComponent param={t} message="Mohon Pilih Layanan Terlebih Dahulu" bg="red"/>
            }) 
        // }else if (unit_penjual == "") {
        //       toast.custom((t) => {
        //             return <ToastComponent param={t} message="Mohon Pilih Unit Terlebih Dahulu" bg="red"/>
        //         }) 
            
        }else{
            this.setState({
                loader: true
            })
            let index = 0;
            const updateSmooth = setInterval(() => {
                if (index == list_kode.length) {
                    this.setState({
                        loader: false
                    }, () => {
                        
                this.props.closeModal({ isUpdate: true })        
                        console.log("Berhenti")
                        clearInterval(updateSmooth)
                    })
                }else{
                       this.loopUpdate(list_kode[index], unit_penjual)
                    //console.log(list_kode[index]['kode_layanan'])
                    index++;
                }
              
            }, 700);
        }
    }
    loopUpdate =  (kode_layanan, unit_penjual) => {
         
        const self = this;
        updateUnitPenjual({ kode_layanan: kode_layanan, unit_penjual: unit_penjual })
        .then(res => {
            if(res.status == 200){
                toast.custom((t) => {
                    return <ToastComponent param={t} message={"Unit penjual " + kode_layanan + " diterapkan"} bg="green"/>
                }) 
            }else{
                console.log(res)
                toast.custom((t) => {

                     return <ToastComponent param={t} message={(typeof res.messages.data === "string") ? res.messages.data : "Unknown Response From Server" } bg="red"/>
                }) 
                
            }
        })

    }
    render(){
        const { handleUnit, deleteUnit, onSubmit } = this;
        const { selectKategori, loader, selectUnit }  = this.state;
        const { unitList, closeModal }   = this.props;
        return(
            <div>
                <br />


                 <Select Other={{ onChange: handleUnit }} Title="Pilih Unit"  Name="Unit" Status={selectKategori == "" ? "Pilih Unit" : ""} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                                <option value="">Pilih Unit</option>
                                {   
                                    unitList.map((x) =>  {

                                        const find = selectUnit.indexOf(x)
                                        if (find == -1) {
                                         return <option key={x} className="" value={x}>{x}</option>   
                                            
                                        }else{
                                            return null
                                        }
                                    
                                    })
                                }
                </Select>
    <div className="-mt-2 mb-2">
      {
                    selectUnit.split(",").map((x, index) => {
                        if (x == "") {
                            return null
                        }else{

                            return (
                                <div className="inline-block bg-blue-500 pb-1 pt-1 mt-2 pl-2 pr-2  rounded-md text-white mr-2 " key={x}>
                                    {x} 
                                    <FontAwesomeIcon className="ml-1" onClick={() => deleteUnit(index)} icon={faTimes} />
                                </div>
                            )
                        }
                    })
                }
    </div>
              <br />
              <br />
                <ModalFooter 
                    loader={loader} 
                    onSubmit={onSubmit} 
                    closeModal={closeModal} />
            </div>
        )
    }
}


