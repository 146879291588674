import { Component, Fragment, useState } from 'react';
import { SmallNavbar } from '../../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort,faList, faCheck, faTrash, faEdit, faUser,faBuilding, faCoins, faChartLine, faTimes,faRecycle,faFile } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import Datatable from '../../components/Datatable';
import Modal    from '../../components/Modal';
import Spinner    from '../../components/Spinner';
import { getAllTransaksiSelesai, transaksiTerhapus, restoreTransaksi as restoreApi } from '../../api/Transaksi';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { Navigate  } from "react-router-dom";
import { InputText, Select } from "../../components/Input";
import TabApproval from '../../components/TabApproval';
import { ActiveDropdown } from '../../config/Config';
import FullLoader from '../../components/FullLoader';
import { NavLink }   from 'react-router-dom';
import { numberFormat } from '../../api/Value';
import { Tooltip }  from '../../components/Tooltip'
import ModalFooter from '../../components/ModalFooter';

export default class BelumDiajukan extends Component {
    constructor(props){
        super(props)
        const user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"))
        this.state = {
            data: [],
            dataFilter: [],
            ready: false,
            type_data: "semua",
            tmp_id_transaksi: "",
            modalRestore: false,
            statusRestore: user['aksi_transaksi_terhapus'].indexOf("restore") != -1
        }
    }
    componentDidMount  = () => {
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/transaksi/terhapus", title: "Transaksi Terhapus" }));

        const act = document.getElementById("Transaksi_nav");
        if(act){ 
            act.classList.add("border-b-4")
            act.classList.add(ActiveDropdown())
        }
        this.getAll();
    }
    getAll = () => {
        const self = this;
        transaksiTerhapus().then((result) => {
          //  console.log(result)
            if (result.status === 200) {

                self.setState({
                    data: result.data !== null ? result.data : [],
                    dataFilter: result.data !== null ? result.data : [],
                    ready: true
                })
            }else{
                self.setState({
                    ready: true
                })
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Gagal Mendapatkan Transaksi Terhapus" bg="orange"/>
                })
            }
     //       console.log(result)
        })
    }
    componentWillUnmount = () => {
        const act = document.getElementById("Transaksi_nav");
        if (act !== null) {

            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
    }
    openDeleteForm = () => {

    }
    handleType = (nameType) => {
        this.setState({
            type_data: nameType,
            dataFilter: (nameType === "semua") ? this.state.data : this.state.data.filter((r) => {
                            return r.tipe_transaksi === nameType
                        })
        })
    }
    restoreTransaksi = (id_transaksi) => {
        this.setState({
            tmp_id_transaksi: id_transaksi,
            modalRestore: true
        })
    }
    closeRestore = () => {
        this.setState({
            modalRestore: false
        })
    }
    restoreNow = () => {
        const self = this;
        this.setState({
            loader: true
        })
        restoreApi({ id_transaksi: this.state.tmp_id_transaksi })
        .then((res) => {
            if (res.status === 200) {
               toast.custom((t) => {
                   return <ToastComponent param={t} message="Transaksi Berhasil di restore" bg="green"/>
               })
               self.getAll();
               self.setState({
                loader: false,
                modalRestore: false
               })
           }else{
               toast.custom((t) => {
                   return <ToastComponent param={t} message="Transaksi Gagal di restore" bg="red"/>
               })
               self.setState({
                loader: false,
                modalRestore: false
               })
           }
          
       })
    }
    render(){
        const { openDeleteForm, handleType, restoreTransaksi, closeRestore, restoreNow } = this;
        const { dataFilter, ready, data, type_data, modalRestore, loader, statusRestore }          = this.state;
        if (ready === false) {
            return <FullLoader />
        }
        return (
            <div className="p-5 animate-fadein">
            <Toaster position="bottom-right"/>

                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Terhapus
                            </div>
                        </Fragment>}
                />
                <TabApproval type_data={type_data} handleType={handleType} />

                <div className="pt-3">
                    <Card>
                        <TableTransaksi statusRestore={ statusRestore } DataRender={dataFilter} openDeleteForm={openDeleteForm} restoreTransaksi={restoreTransaksi}/>
                    </Card>
                </div>

                <Modal
                    Title = {<span className="">Restore Transaksi</span>}
                    Body  = {<div className="mt-5 mb-5 pl-4">
                                Apakah Anda yakin akan me-restore/mengembalikan transaksi? 
                                <br />
                                <br />
                                Setelah di-restore, transaksi akan berada di menu Transaksi Berjalan
                                
                                dan akan terhapus secara otomatis kurang dari 5 menit jika transaksi tidak diproses lunas atau invoice tidak diterbitkan kembali.
                            </div>}
                    Footer = {<ModalFooter textSubmit="Yakin" onSubmit={restoreNow} loader={loader} closeModal = {closeRestore}/>}
                    closeModal = {closeRestore}
                    isOpen = {modalRestore}
                    
                    Container = "max-w-md bg-green-100"
                />
            </div>
        )
    }
}


const TableTransaksi = ({  DataRender,detailTransaksi, restoreTransaksi, statusRestore }) => {
 
 const TheadComponent = ({ sortData }) => {
   return (
     <thead>
       <tr>
           <td className=" border-2  border-gray-300 p-2 w-20 text-center" >ID</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Uraian/Bidang/Pembuat/Tipe</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Pelanggan/Jumlah/Progress/Penolakan</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Aksi</td>
       
          
       </tr>
     </thead>
     
   )
 }
 return (
   <div>
       <Datatable 
         Head={TheadComponent}
         Data={DataRender}
         Entries={[]}
         Body={(row, index) => {
           const mod = (index%2 == 0) ? " bg-gray-100 " : "";
           let user_info = {}
           let history = [];
           let user_pembuat = {}
          //\ console.log(history)
           try {
               user_pembuat = JSON.parse(row.user_pembuat)
               user_info    = JSON.parse(row.info_pelanggan)
               history      = JSON.parse(row.history)
           } catch (error) {
        //    console.log(error, row.history)   
           }
           return (
             <tr key={ index }>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod} width="5%"> 
                   { row.id_transaksi } 
               </td>
               <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                   <NavLink to={"/app/detail_transaksi/" + row.id_transaksi}>
                   <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={<span className="text-blue-500 underline">{row.uraian}</span>} />
                   </NavLink>
                       <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={row.bidang} />
                       <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={user_pembuat.nama} />
                       <ChipTable icon={<FontAwesomeIcon icon={faFile} />} text={row.tipe_transaksi} />

               </td>
               <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                       <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={<span>{user_info.nama}</span>} />

                   <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.jumlah_transaksi}`)} />
                   <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={row.progress_transaksi} />
                   <ChipTable icon={<FontAwesomeIcon icon={faTimes} />} text={

                       
                       (row.progress_transaksi.indexOf("ditolak") !== -1 && history.length > 0) ? 
                       <div className="text-red-600">
                           <span className="font-bold">{ 
                               history[history.length-1]["username"] } ({ history[history.length-1]["role"] }) : </span>
                          { history[history.length-1]["aksi"]}
                       
                       </div>

                       : 
                       null
                   } />

               </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod}>
                    <center>
                        {
                            statusRestore ?
                            <div className="w-20">
                            
                                <Tooltip message="Restore Transaksi">
                                    <button onClick={ () => restoreTransaksi(row.id_transaksi) } className="active:shadow-none bg-white active:bg-cyan-300 hover:bg-cyan-200 border-cyan-500 border-2 shadow-md text-cyan-500 p-1 rounded-full w-10">
                                        <FontAwesomeIcon icon={faRecycle}/>
                                    </button>
                                </Tooltip>
                            
                            </div>

                            :
                            null
                        }
                    </center>    
                </td>
              
             </tr>
           )
         }}
       />
   </div>
 )
}

