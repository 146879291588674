import { Component }  from 'react';
import { Select }   from '../../components/Input';
import { updateLayananKategori } from '../../api/Layanan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ModalFooter from '../../components/ModalFooter';
import toast, { Toaster } from 'react-hot-toast';
import { getParentLevel } from '../../api/Value';

import { Toast as ToastComponent } from '../../components/Toast';


export default class Home extends Component{
    constructor(props) {
        super(props)
        this.state = {
            //list_category: [],
            loader: false,
            selectKategori:""
        }
    }
    
    handleCategory = (e) => {
        // let category = this.state.list_category;
        // category.push(e.target.value);
        // this.setState({
        //     list_category:category
        // })
        this.setState({
            selectKategori: e.target.value
        })
    }
    // deleteCategory = (index) => {
    //     let category = this.state.list_category;
    //     category.splice(index, 1);
    //     this.setState({
    //         list_category:category
    //     })
    // }
    onSubmit = () => {
        // kode layanan selected
        const kategori = this.state.selectKategori;
        const data = this.props.data
        const list_kode = getParentLevel(data.filter((x) => { 
            return x.checked
        }))
        if(list_kode.length == 0) {
            toast.custom((t) => {
                return <ToastComponent param={t} message="Mohon Pilih Layanan Terlebih Dahulu" bg="red"/>
            }) 
        }else if (kategori == "") {
              toast.custom((t) => {
                    return <ToastComponent param={t} message="Mohon Pilih Pelaksana Terlebih Dahulu" bg="red"/>
                }) 
            
        }else{
            this.setState({
                loader: true
            })
            let index = 0;
            const updateSmooth = setInterval(() => {
                if (index == list_kode.length) {
                    this.setState({
                        loader: false
                    }, () => {
                        
                this.props.closeModal({ isUpdate: true })        
                        console.log("Berhenti")
                        clearInterval(updateSmooth)
                    })
                }else{
                       this.loopUpdate(list_kode[index], kategori)
                    //console.log(list_kode[index]['kode_layanan'])
                    index++;
                }
              
            }, 700);
            
        }
    }
    loopUpdate =  (kode_layanan, kategori) => {
         
        updateLayananKategori({ kode_layanan: kode_layanan, kategori: kategori })
            .then(res => {
                if(res.status == 200){
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={"Pelaksana " + kode_layanan + " berhasil diterapkan"} bg="green"/>
                    })      
                }else{
                    console.log(res)

                    toast.custom((t) => {

                        return <ToastComponent param={t} message={(typeof res.messages.data === "string") ? res.messages.data : "Unknown Response From Server" } bg="red"/>
                    }) 
                      
                }
            })

    }
    render(){
        const { handleCategory, deleteCategory, onSubmit } = this;
        const { selectKategori, loader }  = this.state;
        const { categoryList, closeModal }   = this.props;
        return(
            <div>
                <br />


                 <Select Other={{ onChange: handleCategory }} Title="Pilih Pelaksana"  Name="category" Status={selectKategori == "" ? "Pilih Pelaksana" : ""} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                                <option value="">Pilih Pelaksana</option>
                                {   
                                    categoryList.map((x) =>  {
                                        // const find = list_category.findIndex((s) => x == s)
                                        // if (find == -1) {
                                        return <option key={x} className="" value={x}>{x}</option>   
                                            
                                        // }else{
                                        //     return null
                                        // }
                                    
                                    })
                                }
                </Select>
    <div className="-mt-2 mb-2">
      {
                    // list_category.map((x, index) => {
                    //     return (
                    //         <div className="inline-block bg-blue-500 pb-1 pt-1 mt-2 pl-2 pr-2  rounded-md text-white mr-2 " key={x}>
                    //             {x} 
                    //             <FontAwesomeIcon className="ml-1" onClick={() => deleteCategory(index)} icon={faTimes} />
                    //         </div>
                    //     )
                    // })
                }
    </div>
              <br />
              <br />
                <ModalFooter 
                    loader={loader} 
                    onSubmit={onSubmit} 
                    closeModal={closeModal} />
            </div>
        )
    }
}


