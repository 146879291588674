import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true

export const getForm = async () => {
    const reqData = await axios.get( backEndUrl() + "/engine/global/form", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getKategori = async () => {
    
    const reqData = await axios.get( backEndUrl() + "/engine/global/kategori", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getUnit = async () => {
    const reqData = await axios.get( backEndUrl() + "/engine/global/unit", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getAllLayanan = async () => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/layanan", getCred())
                    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllProduct = async () => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/layanan/product", getCred())
                    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getAllProductUnit = async () => {
    
    
    return await axios.get( backEndUrl() + "/penerimaan/layanan/product/unit", getCred())
    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}
export const createLayanan = async ({ kode_layanan, nama_layanan, tarif, target, is_product, info_layanan, kategori, unit_penjual }) => {


    let values= { 
                    kode_layanan: kode_layanan,
                    nama_layanan: nama_layanan,
                    tarif: tarif,
                    target: target,
                    is_product: is_product,
                    info_layanan: info_layanan,
                    kategori: kategori,
                    unit_penjual: unit_penjual,
                    uuid: uuidv4()
                };
  
        return await axios.post( backEndUrl() + "/penerimaan/layanan", values, getCred())
                        .then((res) => {
                            return res.data
                        }).catch((err) => {
                            return {
                                status: 500,
                                messages: err.response 
                            }
                        });
        
   
}
export const updateLayanan = async ({ kode,kode_layanan, nama_layanan, tarif, target, is_product, info_layanan, kategori, unit_penjual }) => {
  
     let values= { 
                    kode_layanan: kode_layanan,
                    nama_layanan: nama_layanan,
                    tarif: tarif,
                    target: target,
                    is_product: is_product,
                    info_layanan: info_layanan,
                    kategori: kategori,
                    unit_penjual: unit_penjual,
                    uuid: uuidv4()
                };
   // // console.log(backEndUrl() + "/penerimaan/layanan/" + kode, values)
    return await axios.put( backEndUrl() + "/penerimaan/layanan/" + kode, values, getCred() )
                    .then((res) => {
                        return res.data
                    }).catch((err) => {
                        return {
                            status: 500,
                            messages: err.response 
                        }
                    });

}
export const updateLayananKategori = async ({ kode_layanan, kategori }) => {
    

       let values= { 
                    kategori: kategori
                };
 
    return await axios.put( backEndUrl() + "/penerimaan/layanan/kategori/" + kode_layanan, values, getCred())
                    .then((res) => {
                        return res.data
                    }).catch((err) => {
                        return {
                            status: 500,
                            messages: err.response 
                        }
                    });


}

export const updateUnitPenjual = async ({ kode_layanan,unit_penjual }) => {

       let values= { 
                    unit_penjual: unit_penjual
                };
    // console.log(values)
    return await axios.put( backEndUrl() + "/penerimaan/layanan/penjual/" + kode_layanan, values, getCred())
                    .then((res) => {
                        return res.data
                    }).catch((err) => {
                        return {
                            status: 500,
                            messages: err.response 
                        }
                    });

    
}
export const deleteLayanan = async ({ kode_layanan }) => {
  
        return await axios.delete( backEndUrl() + "/penerimaan/layanan/" + kode_layanan, getCred() )
          .then((res) => {
            return res.data
            }).catch((err) => {
                return {
                    status: 500,
                    messages: err.response 
                }
            });
   
}
