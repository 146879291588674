import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getBidang, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true;

export const getPdf = async (pdf_file) => {

    return await axios.get( backEndUrl() + "/penerimaan/files/" + pdf_file, getCred())  
    .then((res) => {
        return res.data
    })
    .catch((e) => {
        return {
            status: 500,
            message: e.response
        }
    }) 

}
export const approveKedinasan = async ({ id_transaksi, history }) => {
    let hs = history;
    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
    let user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"));

    let user_info = JSON.parse(user.user_info);
    hs.push({ waktu: Date.now(), aksi: "approve", role: role, username: user.username })
    return axios.post(backEndUrl() + "/penerimaan/transaksi/terimalunas", {
        id_transaksi: id_transaksi,
        history: JSON.stringify(history)
    }, getCred()) 
    .then((res) => {
                       return res.data
                   })
                   .catch((e) => {
                       return {
                           status: 500,
                           message: e.response
                       }
                   }) 
}
export const getStatusTarif = async () => {
    return await axios.get( backEndUrl() + "/engine/global/tarif_perorangan_changable", getCred())
    .then((res) => {
                       return res.data
                   })
                   .catch((e) => {
                       return {
                           status: 500,
                           message: e.response
                       }
                   }) 
}
export const getStatusTarifEdit = async () => {
    return await axios.get( backEndUrl() + "/engine/global/tarif_perorangan_editable", getCred())
    .then((res) => {
                       return res.data
                   })
                   .catch((e) => {
                       return {
                           status: 500,
                           message: e.response
                       }
                   }) 
}
export const getFilterVa =  async ({ va }) => {

    return await axios.get( backEndUrl() + "/penerimaan/transaksi/va/" + va, getCred())
    .then((res) => {
                       return res.data
                   })
                   .catch((e) => {
                       return {
                           status: 500,
                           message: e.response
                       }
                   }) 
}
export const getFilterInvoice = async ({ invoice }) => {

    return await axios.get( backEndUrl() + "/penerimaan/transaksi/invoice/" + invoice, getCred())
    .then((res) => {
                       return res.data
                   })
                   .catch((e) => {
                       return {
                           status: 500,
                           message: e.response
                       }
                   }) 
}
export const getAksesBidang= async () => {
    const reqData = await axios.get( backEndUrl() + "/engine/global/akses_bidang", getCred())
    .then((res) => {
                       return res.data
                   })
                   .catch((e) => {
                       return {
                           status: 500,
                           message: e.response
                       }
                   }) 
   return reqData
}

export const getRekening = async () => {
    const reqData = await axios.get( backEndUrl() + "/engine/global/rekening", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const transaksiTerhapus = async () => {
    
    const reqData = await axios.get(backEndUrl() + "/penerimaan/transaksi/terhapus", getCred())
    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getAllTransaksiPelaksana = async () => {
    
    return await axios.get(backEndUrl() + "/penerimaan/transaksi/pelaksana", getCred())
                    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}
export const getAllTransaksiBerjalan = async () => {
    
     const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/berjalan", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksi = async () => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiFilterBidang = async ({ bidang }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/bidang/" + bidang, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiFilterPelanggan = async ({ id_pelanggan }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/pelanggan/" + id_pelanggan, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiFilterProgress = async ({ progress }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/progress/" + progress, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiFilterBidangProgress = async ({ progress, bidang }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/bidang/" + bidang + "/progress/" + progress, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}


export const getOneTransaksi = async ({ id_transaksi }) => {
    
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/detail/" + id_transaksi, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiMenunggu = async () => {
    
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/menunggu", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
    // console.log(headersUser)

                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiSelesai = async () => {
    
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/selesai", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
    // console.log(headersUser)

                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData;
}
export const createTransaksi = async ({ id_pelanggan, uraian, history, user_pembuat, keranjang, info_transaksi, tipe_transaksi }) => {
    const attrKeranjang = ["kode_layanan", "uraian_keranjang", "kuantitas", "tarif_keranjang", "jumlah_keranjang", "info_keranjang", "tipe_transaksi"];
    let newKeranjang = [];
    let jumlah_transaksi = 0;
  
    for(let d of keranjang){
        let obj = {}
        for(let s of attrKeranjang){
            if(s === "kuantitas") {
                obj[s] = parseInt(d[s]);
            }else if (s === "tarif_keranjang" || s === "jumlah_keranjang") {
                obj[s] = parseFloat(d[s]);
            }else if (s === "info_keranjang") {
                
                obj[s] = JSON.stringify(d[s]);
            
            }else{
                obj[s] = d[s];
            }
        }
        jumlah_transaksi += parseFloat(d.jumlah_keranjang)
        obj['uuid'] = uuidv4()
        newKeranjang.push(obj)
    }
    let user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"));
    if (!user) {
        return {
            status: 500,
            message: "Role Penerimaan Is Not Defined"
        }
    }
    let user_info = JSON.parse(user.user_info);
    if(user_info.bidang === undefined || user_info.bidang === ""){
        return {
            status: 500,
            message: "Akun Anda Belum Mengatur Bidang"
        }
    }

    const values = {
        "id_pelanggan": id_pelanggan,
        "tipe_transaksi": tipe_transaksi,
        "bidang": user_info.bidang,
        "uraian": uraian,
        "jumlah_transaksi": tipe_transaksi === "kedinasan" ? 1 : jumlah_transaksi,
        "history": JSON.stringify([{ waktu: Date.now(), username: user_info.username, aksi: "buat", role:localStorage.getItem("PENERIMAAN_ACTIVE_ROLE")  }]),
        "user_pembuat": JSON.stringify(user_info),
        "uuid" : uuidv4(),
        "keranjang" : newKeranjang,
        "info_transaksi": JSON.stringify(info_transaksi),
        "deleted": 0
    }

    const reqData = await axios.post( backEndUrl() + "/penerimaan/transaksi", values, getCred())
     .then((res) => {
                    console.log(res)
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const updateTransaksi = async ({ id_transaksi,id_pelanggan, tipe_transaksi, bidang, uraian, keranjang, history, info_transaksi }) => {
  let jumlah_transaksi = 0;
   for(let d of keranjang){
        jumlah_transaksi += parseInt(d.jumlah_keranjang)
    }

     let values= { 
            "id_pelanggan": id_pelanggan,
            "tipe_transaksi": tipe_transaksi,
            "bidang": bidang,
            "uraian": uraian,
            "jumlah_transaksi": jumlah_transaksi,
            "info_transaksi": JSON.stringify(info_transaksi),
            "history": (typeof history === "object") ? JSON.stringify(history) : history
        };
        
    
   // // console.log(backEndUrl() + "/penerimaan/layanan/" + kode, values)
    return await axios.put( backEndUrl() + "/penerimaan/transaksi/" + id_transaksi, values, getCred())
                    .then((res) => {
                        return res.data
                    }).catch((err) => {
                        return {
                            status: 500,
                            messages: err.response 
                        }
                    });

}
export const deleteTransaksi = async ({ id_transaksi }) => {
  
        return await axios.delete( backEndUrl() + "/penerimaan/transaksi/" + id_transaksi, getCred() )
          .then((res) => {
            return res.data
            }).catch((err) => {
                return {
                    status: 500,
                    messages: err.response 
                }
            });
   
}
export const approveTolak  = async ({ id_transaksi, history, message, tujuan_penolakan })  => {
    let hs = history;
    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
    let user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"));

    let user_info = JSON.parse(user.user_info);
 
    hs.push({ waktu: Date.now(), username: user.username, alasan: message, role: role, aksi: "tolak" })
    
    if(hs.length >= 20){
        hs = hs.slice(1, hs.length);
    }

    let values=  {
        id_transaksi: id_transaksi,
        history: JSON.stringify(hs),
        tujuan_penolakan: tujuan_penolakan
    }
    
    const reqData = await axios.post( backEndUrl() + "/penerimaan/transaksi/tolak", values, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const approveTerima = async  ({ id_transaksi, history }) => {
    let hs = history;
    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
    let user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"));
    let message = ""
    let user_info = JSON.parse(user.user_info);
  

    hs.push({ waktu: Date.now(), aksi: "approve", role: role, username: user.username })
    
    if(hs.length >= 20){
        hs = hs.slice(1, hs.length);
    }

    let values=  {
        id_transaksi: id_transaksi,
        history: JSON.stringify(hs)
    }
    // // console.log(values)
    const reqData = await axios.post( backEndUrl() + "/penerimaan/transaksi/terima", values, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const terbitkan = async ({ id_transaksi, history }) => {
    
    let hs = history;
    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
    let user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"));
    
    let user_info = JSON.parse(user.user_info);

    hs.push({ waktu: Date.now(), aksi: "terbitkan", role: role, username: user.username })
    let values=  {
        id_transaksi: id_transaksi,
        history: JSON.stringify(hs)
    }
    // // console.log(values)
    const reqData = await axios.post( backEndUrl() + "/penerimaan/transaksi/terbitkan", values, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}



export const kirim = async ({ id_transaksi, history }) => {
    
    let hs = history;
    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
    let user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"));
    let message = ""
    let user_info = JSON.parse(user.user_info);

    user_info.username =  user.username;

    hs.push({ waktu: Date.now(), username: user_info.username, aksi: "approve", role: localStorage.getItem("PENERIMAAN_ACTIVE_ROLE")  })
    let values=  {
        id_transaksi: id_transaksi,
        history: JSON.stringify(hs)
    }
    // // console.log(values)
    const reqData = await axios.post( backEndUrl() + "/penerimaan/transaksi/kirim", values, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getNotifikasiTransaksi = async ({ id_transaksi }) => {
    
    return await axios.get(backEndUrl() + "/penerimaan/notif/" + id_transaksi, getCred())
        .then((result) => {
            return result.data;
        }).catch((err) => {
            return {
                status: 500,
                message: err.response
            }   
        });
}
export const getCurrentTime = async () => {
    
    return await fetch("https://worldtimeapi.org/api/timezone/Asia/Jakarta")
        .then((result) => {
            return result.json()
        }).catch((err) => {
            return {
                status: 500,
                message: err.response
            }   
        });
}
export const restoreTransaksi = async ({ id_transaksi }) => {
    
    return await axios.put(backEndUrl() + "/penerimaan/transaksi/restore/" + id_transaksi, null, getCred())
        .then((result) => {
            return result.data;
        }).catch((err) => {
            return {
                status: 500,
                message: err.response
            }   
        });
}