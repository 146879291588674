import { Component } from 'react';
import { faEye, faMapSigns, faPlus, faUser, faSort, faPhone, faMailBulk, faAddressCard, faBuilding, faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { InputText, TextArea, Select } from '../../components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllUser, createUser, deleteUser, updateUser, uploadProfilePicture ,updateUserPassword, getProfilePicture} from '../../api/User';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { Switch } from '@headlessui/react';
import Spinner    from '../../components/Spinner';
import { checkType } from '../../api/Value';
import { backEndUrl } from '../../config/Config';

export default class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            myForm: {
                username: "",
                password: "",
                roles: ["user"],
                user_info: {
                  nip: "",
                  nama: "",
                  bidang: "",
                  profile_picture: ""
                },
                status: "aktif",
                retype_password: ''
            },
            formState:{
                nip: "",
                nama: "",
                bidang: "",
                username: "",
                password: "",
                roles: ""
            },
            isActive: true,
            idDeleteUser: 0,
            submitLoader: false,
            nameFile: "",
            // update 
            id_user: "",
            // is profile
            isProfile: false 

        }
    }
    componentDidMount = () => {
        let dataForm  = this.props.detailForm;
     //   console.log(dataForm)
        let data        = {};
        let id_user     = ""
        let myForm = this.state.myForm
        let nameFile = "";
        let isActive    = true;
        for(let d in dataForm) {
            if (d == "status") {
                isActive    = (dataForm[d] == "aktif")
            }else if (d == "username") {
                myForm['username']    = dataForm[d];
            }else if (d == "roles") {
                myForm['roles']    = dataForm[d].split(',').map((x) => { 
                    return x.trim();
                });
            }else if (d == "profile_picture") {
                nameFile = dataForm[d];
            }else if (d == "id_user") {
                id_user = dataForm[d];
            }else if(d != "user_info"){
                data[d] = dataForm[d] 
            }
        }
        if (id_user != "") {
            
        }
        // console.log(data);
        myForm['user_info'] = data;
        this.setState({
            id_user: id_user,
            myForm: myForm,
            isActive: isActive,
            nameFile: nameFile
        })
        var s = document.querySelector("#cari");
        s.disabled = "disabled"
    }
    componentWillUnmount = () => {
          var s = document.querySelector("#cari").removeAttribute("disabled");
    }
     // simpan User
    saveUser = (isFromProfile = false) => {
        const myForm = this.state.myForm;
        let formState = this.state.formState;
        const id_user = this.state.id_user;
        let newForm  = myForm;
        const self   = this;
        let isValid  = true;
        // check user info 
        for(let namaObject of ["nip", "nama", "bidang"]) {
            const cek = (myForm['user_info'][namaObject] == "") ? "Required" : "";
            formState[namaObject] = cek;
            if (cek == "Required") {
                isValid = false;
            }
        }
        let namaObjectMyForm = ["username"];
        // cek parent of data
        if (id_user == "") {
            namaObjectMyForm.push("password")
        }
        // cek parent of data loop
        for(let nmForm of namaObjectMyForm) {
            const cek =  myForm[nmForm] == "" ? "Required" : "";
            formState[nmForm] = cek;
             if (cek == "Required") {
                isValid = false;
            }
        }
        
        if (myForm.password != "" && isFromProfile == false) {
            if (myForm.password != myForm.retype_password) {
                isValid = false;
                formState['password'] = "Password Tidak Sama";
            }
            if (myForm.password.length < 8) {
                isValid = false;
                formState['password'] = "Password Minimal 8 Karakter"
            }
        }
        if (myForm['roles'].length == 0) {
            isValid = false;
            formState['roles'] = "Required"
        }
        // set error
        this.setState({
            formState: formState
        }, () => {
            if (!isValid) {
                
                setTimeout(() => {
                    for(let s in formState){
                        formState[s] = ""
                    }
                    this.setState({
                        formState: formState
                    })
                }, 3000);   
            }
        })
      
        // is data required valid ?
        if (isValid) {
            // set loader
            this.setState({
                submitLoader: true
            })
            // cek update or create
            if (id_user == "") {
                createUser({ username: myForm.username, roles: myForm.roles, password: myForm.password, user_info: myForm.user_info, status: (this.state.isActive) ? "aktif" : "nonaktif" }).then((result) => {
               //     console.log(result)
                    if(result.status  == 200){
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="User berhasil ditambah " bg="green"/>
                        }) 
                        self.props.closeModalForm(true);
                    }else{
                        if (result.status == false) {
                            for(let d of result.param){
                                for(let s in d){
                                    formState[s] = d[s]
                                }
                            }
                        }else{
                          console.log(result.messages.data.message)
                            let message = (typeof result.messages.data.message == "string") ?  result.messages.data.message : "Gagal Update User"
                            toast.custom((t) => {
                                return <ToastComponent param={t} message={message} bg="red"/>
                            }) 
                        }
                    }
                    self.setState({
                        submitLoader: false
                    })
                })
            }else if (id_user != "") {
                let user_info = {};
                for(let d in this.state.formState){
                    user_info[d] = myForm['user_info'][d];
                }
                user_info['profile_picture'] = myForm['user_info']['profile_picture'];
                updateUser({ id_user: id_user, username: myForm.username, user_info: user_info, roles: myForm.roles, status: (this.state.isActive) ? "aktif" : "nonaktif" }).then((result) => {
                    //console.log(result)
                    if(result.status  == 200){
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="User berhasil diubah " bg="green"/>
                        }) 
                        self.props.closeModalForm(true);
                    }else{
                        if (result.status == false) {
                            for(let d of result.param){
                                for(let s in d){
                                    formState[s] = d[s]
                                }
                            }
                        }else{
                            let message = (typeof result.messages.data.message == "string") ?  result.messages.data.message : "Gagal Update User" 
                            toast.custom((t) => {
                                return <ToastComponent param={t} message={message} bg="red"/>
                            }) 
                        }
                    }
                    self.setState({
                        submitLoader: false
                    })
                })


            }
        }
      
        
    }
    // bind input form
    bindUserInfo = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        let myForm = this.state.myForm;
        myForm['user_info'][name] = val
        this.setState({
            myForm: myForm
        })
    }
    bindForm    = (e) => {
        const name = e.target.name;
        const val = e.target.value;
                //console.log(name)

        if(name == "roles") {
            let myForm = this.state.myForm;

            if (myForm['roles'].findIndex((x) => { return x == val }) == -1) {

                myForm['roles'].push(val);
                this.setState({
                    myForm: myForm
                })                
            }

        }else {
            let myForm = this.state.myForm;
            myForm[name] = val;
            this.setState({
                myForm: myForm
            })
        }
    }
    deleteRoles = (index) => {
        let form = this.state.myForm;
        form['roles'].splice(index, 1);
        this.setState({
            myForm: form
        })
    }
    // toggle user aktif || non_aktif
    setActive = () =>  {
        this.setState({
            isActive: !this.state.isActive
        })
    }
    setIsProfile = (bool) => {
       
        this.setState({
            isProfile: bool
        }, () => {
   
            
        })
    }
    progressBar = (percent) => {
        //    console.log(percent)
    }
    savePassword=  () => {
        const self= this;
        let isValid = true;
        // formState
        let formState = this.state.formState;
        let myForm    = this.state.myForm;

        if (myForm.password == "") {
            formState['password'] = "Mohon Masukan Password";        
            isValid = false;
        }

        if (myForm.retype_password == "") {
            formState['password'] = "Mohon Masukan Retype Password";        
            isValid = false;
            
        }

        if (myForm.password != myForm.retype_password) {
            isValid = false;
            
        }
        if(isValid){
            this.setState({
                submitLoader: true
            })
                updateUserPassword({ id_user: this.state.id_user, password: myForm.password }).then((result) => {
                    
                //    console.log(result);
                    if (result.status == 200) {
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Password berhasil diubah " bg="green"/>
                        }) 
                       self.setState({
                            submitLoader: false
                        })
                    }else{
          //              console.log(result)
                          toast.custom((t) => {
                            return <ToastComponent param={t} message="Gagal Update Password User" bg="red"/>
                        }) 
                        self.setState({
                            submitLoader: false
                        })
                    }
                })

        }else {
            this.setState({
                formState: formState
            }, () => {
                setTimeout(() => {
                    formState['password'] = "";
                    formState['retype_password'] = "";
                    this.setState({
                        formState: formState
                    })
                }, 2000);
            })
        }
    }
    uploadProfile = async (e) => {
        const file = e.target.files;
        const self = this;
        if (file.length > 0) {
            await  uploadProfilePicture({ id_user: this.state.id_user ,file: file, onUpload: this.progressBar }).then((result) => {
                if (result.status == 200) {
                    const myForm = self.state.myForm;
                    myForm['user_info']['profile_picture'] = result.data;
                    self.setState({
                        nameFile: result.data,
                        myForm: myForm
                    }, () => {
                        self.saveUser(true);
                    })
                  toast.custom((t) => {
                        return <ToastComponent param={t} message="Profile Berhasil diunggah" bg="green"/>
                    })       
                }else{
           //     console.log(result)
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Profile Gagal diunggah" bg="red"/>
                    })   
                }
            })
        }
    }
    render(){
        const { isProfile,myForm, isPerusahaan, isActive, idDeleteUser, submitLoader, formState, nameFile, loader, id_user } = this.state;
        const { setIsProfile,uploadProfile, saveUser, bindUserInfo, setPersonal, setActive, handleInstansi, bindForm, deleteRoles, savePassword } = this;
        const { closeModalForm, roles, bidang } = this.props;
        return (
            <div>
              
                <div className="pt-1 ">
                    {
                        (id_user != "") ?
                        <div className="flex border-b-2 pb-5 mt-2">
                            <button className={`hover:bg-blue-500 hover:text-white rounded-md p-2 w-20 ${!isProfile ? "bg-blue-500 text-white" : "bg-gray-100"}`} onClick={() => setIsProfile(false)}>Detail</button>
                            <button className={`hover:bg-blue-500 hover:text-white rounded-md p-2 w-20  ml-2 ${isProfile ? "bg-blue-500 text-white" : "bg-gray-100"}`} onClick={() => setIsProfile(true)}>Profile</button>
                        </div>
                        : 
                        null
                    }
                    
                    {
                        (isProfile && id_user != "") ?
                        <div className="mt-4">
                            
                                <div className="w-90">
                                    <center>
                                        <img src={(nameFile == "") ? require("../../profile.png") : backEndUrl() + "/penerimaan/files/" + nameFile } className="w-20 rounded-full border-2" alt="" />
                                        <br />
                                        <label htmlFor="btn-profile" className="bg-blue-500 text-white rounded-md pl-2 pr-2 pt-1 pb-1">Upload Profile</label>
                                        <input type="file" id="btn-profile" className="hidden" onChange={uploadProfile} />
                                    </center>
                                    <center>
                                    <div className="w-72 text-left mt-4 mb-8">
                                        <InputText other={{ onChange: bindForm, value: myForm.password, autoComplete: "off" }} Type="password" Title="Password" Placeholder="Password" Name="password" Status={formState.password} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                                        <InputText other={{ onChange: bindForm, defaultValue: myForm.retype_password }} Type="password" Title="Retype Password" Placeholder="Retype Password" Name="retype_password" Status={(myForm.retype_password == myForm.password) ? "" : "Password Tidak Sama"} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                                    </div>
                                    </center>
                                    
                                    <ModalFooter closeModal = {closeModalForm} onSubmit={savePassword} loader={submitLoader} position="justify-center" />
                                    
                                </div>
                               
                            
                        </div>
                        : 
                        <div>
                            <div className="p-3 grid md:grid-cols-2 ">    
                            <div>
                                <TextArea other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.nama }} Title="Nama" Placeholder="Nama" Name="nama" Status={formState.nama} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                                <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.nip }} Type="number" Title="NIP" Placeholder="NIP" Name="nip" Status={formState.nip} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                                <InputText other={{ onChange: bindForm, defaultValue: myForm.username }} Title="Username" Placeholder="Username" Name="username" Status={formState.username} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                                {
                                    (id_user == "") ?
                                    <div>
                                        <InputText other={{ onChange: bindForm, defaultValue: myForm.password }} Type="password" Title="Password" Placeholder="Password" Name="password" Status={formState.password} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                                        <InputText other={{ onChange: bindForm, defaultValue: myForm.retype_password }} Type="password" Title="Retype Password" Placeholder="Retype Password" Name="retype_password" Status={(myForm.retype_password == myForm.password) ? "" : "Password Tidak Sama"} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                                    
                                    </div>
                                    
                                    :
                                    null
                                }
                            </div>          
                            
                            <div className="pl-3">
                            <Select Other={{ onChange: bindUserInfo, value: myForm.user_info.bidang }} Title="Pilih Bidang"  Name="bidang" Status={formState.bidang} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                                    <option value="">Pilih Bidang</option>
                                    {   
                                        bidang.map((x) =>  {
                                        
                                            return <option key={x.bidang} className="text-[14px]" value={x.bidang}>{x.bidang}</option>   
                                        
                                        })
                                    }
                                </Select>
                                <Select Other={{ onChange: bindForm }} Title="Pilih Roles"  Name="roles" Status={formState.roles} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" ">
                                    <option value="">Pilih Role</option>
                                    {   
                                        roles.map((x) =>  {
                                            const checkIndex =  myForm.roles.findIndex((d) => { return x.role == d }) == -1;
                                            //console.log(checkIndex)
                                            if (checkIndex) {   
                                                return <option key={x.role} className="text-[14px]" value={x.role}>{x.role}</option>   
                                            }else {
                                                return null
                                            }
                                        })
                                    }
                                    
                                
                                </Select>
                                <br />
                                <div className="-mt-3">
                                {
                                    myForm.roles.map((x, index) => {
                                        return (
                                            <div className="inline-block bg-blue-500 pb-1 pt-1 mt-2 pl-2 pr-2 text-[14px] rounded-md text-white mr-2 " key={x}>
                                                {x} 
                                                {
                                                    (x !== "user") ? 
                                                        <FontAwesomeIcon className="ml-1" onClick={() => deleteRoles(index)} icon={faTimes} />
                                                    : 
                                                    null
                                                }
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                
                                
                                <div className="mt-2 ">Status aktif</div>
                                <Switch
                                    checked={isActive}
                                    onChange={setActive}
                                    className="ml-2 bg-gray-200 relative inline-flex flex-shrink-0 h-[30px] w-[62px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                    aria-hidden="true"
                                    className={`${isActive ? 'translate-x-8 bg-blue-500' : 'translate-x-0 bg-white'}
                                        pointer-events-none inline-block h-[26px] w-[26px] rounded-full  shadow-lg transform ring-0 transition ease-in-out duration-200`}
                                    />
                                </Switch>
                            </div>

                        </div>
                        <ModalFooter closeModal = {closeModalForm} onSubmit={() => saveUser()} loader={submitLoader} />

                            
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const ModalFooter = ({ closeModal, onSubmit, loader, position="justify-end" }) => {
   
    return(
        <div className={" flex " + position}>
            
            
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-600 p-2 shadow-md hover:bg-gray-500 active:bg-gray-700 active:shadow-none rounded-md w-32  text-white" onClick={() => closeModal(false)}>Batal</button>
                        <button onClick={onSubmit} className=" font-bold bg-green-600 ml-2 p-2 shadow-md hover:bg-green-500 active:bg-green-700 active:shadow-none rounded-md w-32 border-green-700 text-white">
                                Simpan
                        </button>
                    </div>
                : 
                <button className="	 cursor-not-allowed flex font-bold bg-green-600 ml-2 p-2 rounded-md w-52 border-green-600 text-white">
                  
                     <div className="w-1/4">
                         <Spinner 
                        color="rgb(255 255 255)"
                        width="30px"
                        height="30px"
                    />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}