import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Grafik Line',
    },
  },
};


export default ({ line_data }) => {
  
  const labels = line_data.label
  const data = {
    labels,
    datasets: [
      {
        label: 'Target',
        data: line_data.data.target,
        borderColor: 'rgba(233, 30, 99, 1)',
        backgroundColor: 'rgba(233, 30, 99, 1)',
      },
      {
        label: 'Pendapatan',
        data: line_data.data.pendapatan,
        borderColor: 'rgba(52, 152, 219,1.0)',
        backgroundColor: 'rgba(52, 152, 219,1.0)',
      },
      {
        label: 'Piutang',
        data: line_data.data.piutang,
        borderColor: 'rgba(241, 196, 15,1.0)',
        backgroundColor: 'rgba(241, 196, 15,1.0)',
      },
    ],
  };
    return (
        <div>
            <Line options={options} data={data} />
        </div>
    )
}