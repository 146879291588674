import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPaperPlane, faTimes,faRocket } from '@fortawesome/free-solid-svg-icons';
import { toDateTime } from '../../../api/Value'
const History = ({ myForm }) => {
    
    useEffect(() => {
  //          console.log(myForm);
        return () => {
            
        };
    }, []);
    const { transaksi } = myForm;
    const history2 = transaksi.history
    
    return (
        <div>
            <center>
            {
                history2.map((x, i) => {
                    return (
                        <List key={i} { ...x } />
                    )
                })
            }
            </center>
        </div>
    )
}

const List = ({ aksi, type, role, waktu, alasan }) => {
    const color = (aksi === "buat" || type ==="buat") ? "border-blue-500 text-blue-600 " : (aksi === "approve" || type === "approve") ? "border-green-500 text-green-600" : (aksi === "tolak" || type === "tolak") ? "border-red-500 text-red-600": (aksi=== "terbitkan") ? "border-purple-700 text-purple-800": "border-gray-400 text-gray-600";
    return (
        <div className={color + " rounded-md lg:w-1/2 w-full border-4 bg-white p-2  mb-2"}>
        
            <div className="flex">
                <div className="pr-2">
                    {
                        aksi === "buat" || type === "buat" ?
                            <FontAwesomeIcon icon={ faPlus } />
                        :
                        aksi === "approve" || type === "approve" ?
                            <FontAwesomeIcon icon={ faPaperPlane } />
                        :
                        aksi === "tolak" || type === "tolak" ? 
                            <FontAwesomeIcon icon={ faTimes } />
                        : 
                        aksi === "terbitkan" ? 
                            <FontAwesomeIcon icon={ faRocket } />
                        
                        :
                        null
                    }
                </div>
                <div>
                    {
                        aksi === "buat" || type === "buat" ?
                            <div>{role} membuat transaksi</div>
                        :
                        aksi === "approve" || type === "approve" ?
                            <div>{role} mengapprove transaksi</div>
                        :
                        aksi === "tolak" || type === "tolak" ?
                        <div>{role} menolak transaksi : {alasan}</div>
                        :
                        aksi === "terbitkan" || type === "terbitkan" ?
                        <div>{role} menerbitkan invoice</div>
                        :
                        ""
                    }
                    
                </div>
            </div>
            { (waktu === undefined) ?
                <div className="italic">Bad Data</div>
            :
            <div className="text-sm italic text-right">
                 {toDateTime(waktu) }
            </div>
            }
        </div>
    )
}
export default History;