import { Component, Fragment, useState } from 'react';
import { SmallNavbar } from '../../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort,faList, faCheck, faTrash, faEdit, faUser,faBuilding, faCoins, faChartLine, faTimes, faRocket, faPaperPlane, faFile } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import { numberFormat } from '../../api/Value';
import Datatable, { IconSort } from '../../components/Datatable';
import Modal    from '../../components/Modal';
import Spinner    from '../../components/Spinner';
import { getAllTransaksiMenunggu, deleteTransaksi, approveKedinasan } from '../../api/Transaksi';
import { getStatusSendWa, getGlobalWhatsapp } from '../../api/Laporan';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { Navigate  } from "react-router-dom";
import { InputText, Select } from "../../components/Input";
import { approveTerima, approveTolak, terbitkan,kirim } from '../../api/Transaksi';
import { getKeranjangTransaksi } from '../../api/Keranjang';
import { ActiveDropdown } from '../../config/Config';
import FullLoader from '../../components/FullLoader';
import { NavLink }   from 'react-router-dom';
import TabApproval from '../../components/TabApproval';
export default class BelumDiajukan extends Component {
    constructor(props){
        super(props)
        const user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"))
        this.state = {
            data: [],
            myDeleteForm: false,
            approveModal: false,
            tolakModal: false,
            data: [],
            dataFilter: [],
            id_transaksi: 0,
            loader: false,
            isEdit: false,
            tmp_history: [],
            alasan: "",

            user: user,
            tujuan_penolakan: user["tujuan_penolakan"].split(",")[0],
            detailModal: false,
            detail: {
                transaksi: {},
                keranjang: []
            },
            type: "",
            ready: false,
            type_data: "semua",
            type_action: "",
            wa: { no_hp: "", message: "" },
            isSendWA: false
        }
    }
    componentDidMount  = () => {
        const act = document.getElementById("Transaksi_nav");
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/transaksi/belumDiajukan", title: "Transaksi Belum Diajukan" }));
        if(act){ 
                act.classList.add("border-b-4")
                act.classList.add(ActiveDropdown())
        }
       this.getAll();
    }
    getAll = async () => {
        const self = this;
        const me   = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"))
        const userInfo = JSON.parse(me.user_info);
        let isSuccess  = false;
        let state = { data: [], dataFilter: [], ready: false, isSendWA: false };
        let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");

        await getAllTransaksiMenunggu().then((res) => {
            console.log(res)
            if (res.status === 200) {
                state['data']       = (res.data === null) ? [] : res.data.map((x, i) => ({ ...x, index: i }));
                state['dataFilter'] = (res.data === null) ? [] : res.data.map((x, i) => ({ ...x, index: i }));
                isSuccess = true;
            }else{
                isSuccess = false;
                toast.custom((t) => {
                    return <ToastComponent param={t} message="List Transaksi Gagal di muat" bg="red"/>
                })
            }
        })
        if (isSuccess === true && role === "admin_meterai") {
            await getStatusSendWa().then(res => {
                if (res.status === 200) {
                    state['isSendWA'] = res.data.nilai === "true"
                }else{
                    toast.custom(t => {
                        return <ToastComponent param={ t } message="Gagal Mendapatkan Status Send WA" />
                    })
                }
            })   
        }
        state['ready'] = true
        this.setState(state)
    }
    componentWillUnmount = () => {
        const act = document.getElementById("Transaksi_nav");
        if (act !== null) {
            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
      
    }
    openDeleteForm = (id_transaksi) => {
        this.setState({
            myDeleteForm: true,
            id_transaksi: id_transaksi
        })
    }
    closeDeleteForm = () => {
        this.setState({
            myDeleteForm: false
        })
    }
    openAproveModal = async (index, type) => {
        let transaksi = this.state.data[index];
        
        let wa = { no_hp: "", message: "" }
        if (transaksi.tipe_transaksi === "kerjasama" && this.state.isSendWA) {
            wa = await this.siapkanSendWa(transaksi['id_transaksi']);
        }
        this.setState({
            approveModal: true,
            id_transaksi: transaksi["id_transaksi"],
            tmp_history: JSON.parse(transaksi["history"]),
            type: (transaksi["tipe_transaksi"] === "kedinasan") ? "terimalunas" : type,
            type_action: transaksi["tipe_transaksi"]
        })
    }
    siapkanSendWa = async (id_transaksi) => {
        // whatsapp
       
    }
    openTolakForm= (index) => {
        let transaksi = this.state.data[index];

         this.setState({
            tolakModal: true,
            id_transaksi: transaksi["id_transaksi"],
            tmp_history: JSON.parse(transaksi["history"])
        })
    }
    closeTolakForm= () => {
         this.setState({
            tolakModal: false
        })
    }
    closeAproveModal = () => {
        this.setState({
            approveModal: false
        })
    }
    closeModalDetail  =() => {
        this.setState({
            detail: {
                keranjang: [],
                transaksi: {}
            },
            detailModal: false
        })
    }

    handleSubmitPerorangan = async (param) => {
       const terbit = await terbitkan(param);
       if (terbit.status === 500) {
            return {
                status: 500,
                message: (typeof terbit.message == 'string') ? terbit.message : "Gagal Menerbitkan Invoice"
            }       
       }else{
            const send   = await kirim(param);
            if (send.status === 200) {
                return {
                    status: 200,
                    message: "Berhasil Menerbitkan & Mengirimkan Invoice"
                }
            }else{
                return {
                    status: 500,
                    message: "Berhasil Menerbitkan Invoice & Gagal Mengirimkan Invoice"
                }
            }
       }
        
    }
    submit = (type) => {
        const self = this;
        if (type === "delete") {
            this.setState({
                loader: true
            })
            deleteTransaksi({ id_transaksi: this.state.id_transaksi }).then((res) => {
                 if (res.status === 200) {
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Transaksi Berhasil di hapus" bg="orange"/>
                    })
                    self.getAll();
                    
                }else{
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Transaksi Gagal di hapus" bg="red"/>
                    })
               //     console.log(res)
                }
                self.setState({
                        loader: false,
                        myDeleteForm: false
                    })
            })
        }else if (type === "approve") {
            this.setState({
                loader: true
            })

            const tipeApprove = this.state.type;
            const myPromise = new Promise((resolve, reject) => {
                if (tipeApprove === "kirim") {
                    resolve(kirim({id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                }else if (tipeApprove === "terbitkan_kerjasama") {
                    resolve(terbitkan({id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                
                }else if (tipeApprove === "terbitkan") {
                    
                    resolve(self.handleSubmitPerorangan({ id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                    
                }else if (tipeApprove === "terima") {
                    resolve(approveTerima({ id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                } else if (tipeApprove === "terimalunas"){
                    resolve(approveKedinasan({ id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                }else{
                    reject("Tipe Approve Tidak Diketahui")
                }
            });
            
            myPromise.then((res) => {
                let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
              //  let type = (role.indexOf("admin") === -1) ? "approve": "ajukan";
                const isTransaksi = (tipeApprove === "terbitkan") ? "Invoice" : "Transaksi" 
                if (res.status === 200) {
                    const msg= tipeApprove === "terimalunas" ? "buat" : " " + tipeApprove.replaceAll("_kerjasama", "");
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={ isTransaksi + " Berhasil di" + msg}bg="green"/>
                    })
                    self.getAll();
                 
                }else{
                    const result = (typeof res === "object") ? res.message.data.message : "Transaksi Gagal diproses";
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={result} bg="red"/>
                    })
            //        console.log(res)
                }
                setTimeout(() => {
                     self.setState({
                        loader: false,
                        approveModal: false
                    })
                }, 500);
               

            }).catch((error) =>{
         //       console.log(error)
                toast.custom((t) => {
                    return <ToastComponent param={t} message={"Transaksi Gagal di approve"} bg="red"/>
                })
                setTimeout(() => {
                    self.setState({
                       loader: false,
                       approveModal: false
                   })
               }, 500);
            })
        }else if (type === "tolak") {
            
            let alasan = this.state.alasan;
            if (alasan !== "") {
                
                this.setState({
                    loader: true
                })

                approveTolak({ id_transaksi: this.state.id_transaksi, history: this.state.tmp_history, message: alasan, tujuan_penolakan: this.state.tujuan_penolakan })
                .then((res) => {
                    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");

                    if (res.status === 200) {
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={"Transaksi Berhasil ditolak"} bg="orange"/>
                        })
                        self.getAll();
                    
                    }else{
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={"Transaksi Gagal ditolak"} bg="red"/>
                        })
                   //     console.log(res)

                    }
                    setTimeout(() => {
                        self.setState({
                            loader: false,
                            tolakModal: false
                        })
                    }, 500);
                
                })
            }

        }
    }
    editTransaksi= (id_transaksi) => {
        this.setState({
            id_transaksi: id_transaksi,
            isEdit: true
        })
    }
    
    bindAlasan = (e) => {
        this.setState({
            alasan: e.target.value
        })
    }
    bindTujuan = (e) => {
        this.setState({
            tujuan_penolakan: e.target.value
        })
    }
    
    detailTransaksi = (index) => {
        
        let data = this.state.data[index];
        const self = this;
        getKeranjangTransaksi({ id_transaksi: data.id_transaksi }).then((result) => {
            if (result.status === 200) {
                let detail = self.state.detail;
                detail["keranjang"] = result.data.map((x) => {
                    return { ...x,info_keranjang: JSON.parse(x.info_keranjang) }
                    
                });
                detail["transaksi"] = { ...data, info_pelanggan: JSON.parse(data.info_pelanggan), info_transaksi: JSON.parse(data.info_transaksi), user_pembuat: JSON.parse(data.user_pembuat), history: JSON.parse(data.history)  }
               self.setState({
                   detail: detail,
                   detailModal: true
               })
            }else{
             //   console.log(result)
            }
        })
    }
    handleType = (nameType) => {
        this.setState({
            type_data: nameType,
            dataFilter: (nameType === "semua") ? this.state.data : this.state.data.filter((r) => {
                            return r.tipe_transaksi === nameType
                        })
        })
    }
    render(){
        const { openDeleteForm, closeDeleteForm, openAproveModal, closeAproveModal,  submit, editTransaksi, bindAlasan,openTolakForm, closeTolakForm, bindTujuan, detailTransaksi, closeModalDetail, handleType  } = this;
        const { myDeleteForm, approveModal, dataFilter, loader, id_transaksi, isEdit, tolakModal, alasan, user, tujuan_penolakan, detail, detailModal, type, ready, type_data }    = this.state;
     //   console.log(user)
        const tujuan_tolak = user.tujuan_penolakan.split(",");

        if (ready === false) {
            return <FullLoader />
        }
        if (isEdit) {
            return  <Navigate to={"/app/transaksi/" + id_transaksi} />;

        }

        
        return (
            <div className="p-5 animate-fadein">
                        <Toaster position="bottom-right"/>

                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2 " >
                                Belum Diajukan
                            </div>
                        </Fragment>}
                />
                <TabApproval type_data={type_data} handleType={handleType} />
                
                <div className="pt-2">
                    <Card>
                     
                        
                        <TableTransaksi 
                        aksi_kerjasama={user.aksi_transaksi} 
                        aksi_perorangan={user.aksi_transaksi_perorangan}
                        aksi_kedinasan={user.aksi_transaksi_kedinasan}
                        openTolakForm={openTolakForm} 
                        openDeleteForm={openDeleteForm} 
                        openAproveModal={openAproveModal} 
                        DataRender={dataFilter} 
                        detailTransaksi = {detailTransaksi}
                        editTransaksi={editTransaksi} />
                    </Card>
                </div>

                 <Modal
                    Title = {<span className="">Tolak Transaksi</span>}
                    Body  = {<div className="mt-5 mb-5">
                                  <InputText 
                                        other={{ onChange: bindAlasan, value: alasan }} 
                                        Type="text" 
                                        Title="Alasan Tolak" 
                                        Placeholder="Alasan Tolak" 
                                        Name="target" 
                                        Status={(alasan === "") ? "required": ""} 
                                        TitleClass=" text-xs" 
                                        elementClass=" mb-2" 
                                        InputClass=" " 
                                    />
                                    {
                                        (tujuan_tolak.length === 1) ? null 
                                        :
                                        <Select Other={{ onChange: bindTujuan, value: tujuan_penolakan }} Title="Tujuan Penolakan"  Name="tujuan_penolakan" Status={""} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                                              
                                                {   
                                                    tujuan_tolak.map((x) =>  {
                                                        return <option key={x}  value={x}>{x}</option>   
                                                    })
                                                }
                                        </Select>
                                    }
                            </div>}
                    Footer = {<ModalDeleteFooter submitDelete={() => submit("tolak")} loader={loader} closeModal = {closeTolakForm}/>}
                    closeModal = {closeTolakForm}
                    isOpen = {tolakModal}
                    Container = "max-w-md bg-white"
                />
                {
                    /*
                        <Modal
                            Title = {<span className="text-black">Detail Transaksi</span>}
                            Body  = {<Detail myForm={ detail } closeModalForm={closeModalDetail} />}
                            Footer = ""
                            closeModal = {closeModalDetail}
                            isOpen = {detailModal}
                            Container = "max-w-5xl min-h-92 bg-white"
                        />
                     */
                }
                

                 <Modal
                    Title = {<span className="text-white">Hapus Transaksi</span>}
                    Body  = {<div className="text-white mt-5 mb-2">Apakah Anda Yakin?</div>}
                    Footer = {<ModalDeleteFooter submitDelete={submit} loader={loader} closeModal = {closeDeleteForm}/>}
                    closeModal = {closeDeleteForm}
                    isOpen = {myDeleteForm}
                    Container = "max-w-md bg-red-600"
                />

                <Modal
                    Title = {<span className="text-white">
                    {
                        type === "terima" ? "Terima Transaksi"
                        :
                        type === "kirim" ? "Kirim Invoice"
                        : 
                        type === "terbitkan_kerjasama" ? "Terbitkan Invoice"
                        :
                        type === "terbitkan" ? "Terbitkan Invoice"
                        :
                        type === "terimalunas" ? "Buat Transaksi"
                        :
                        null
                    }
                    </span>}
                    Body  = {<div className="text-white mt-5"></div>}
                    Footer = {<ModalApproveFooter closeModal = {closeAproveModal} submit={() => submit("approve")} loader={loader} />}
                    closeModal = {closeAproveModal}
                    isOpen = {approveModal}
                    Container = "max-w-md bg-green-600"
                />
            </div>
        )
    }
}

const TableTransaksi = ({ openDeleteForm, openAproveModal, DataRender, editTransaksi, openTolakForm, aksi_kerjasama,aksi_perorangan, detailTransaksi, aksi_kedinasan }) => {
 // console.log(aksi_kedinasan)
  const TheadComponent = ({ sortData, sort, order_by }) => {
    return (
      <thead>
        <tr>
            <td onClick={() => sort("id_transaksi")} className=" text-center border-2 border-gray-300 pl-2 pr-2" rowSpan="2">ID {IconSort(order_by, "id_transaksi")}</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Uraian/Bidang/Pembuat/Tipe</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Pelanggan/Jumlah/Progress/Penolakan</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Aksi</td>
           
        </tr>
      </thead>
      
    )
  }
  return (
    <div>
        <Datatable 
          Head={TheadComponent}
          Data={DataRender}
        
          Entries={[]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
            const user_info = JSON.parse(row.info_pelanggan)
            const history = JSON.parse(row.history)
            let user_pembuat = {}

            
           let info_transaksi= {}
           let tgl_awal = ""
           let tgl_akhir = ""
           //\ console.log(history)
            try {
                user_pembuat = JSON.parse(row.user_pembuat)

                info_transaksi = JSON.parse(row.info_transaksi)

                tgl_awal = info_transaksi.tgl_awal.split("-").reverse().join("/")
                tgl_akhir = info_transaksi.tgl_akhir.split("-").reverse().join("/")
            } catch (error) {
                
            }
            const aksi_transaksi = (row.tipe_transaksi === "kedinasan") ? aksi_kedinasan : (row.tipe_transaksi === "kerjasama") ? aksi_kerjasama : aksi_perorangan 
            return (
              <tr key={ index }>
                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                    { row.id_transaksi } 
                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="38%">
                    <NavLink to={"/app/detail_transaksi/" + row.id_transaksi}>
                     <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={<span className="cursor-pointer underline text-blue-500">{row.uraian}</span>} />
                    </NavLink>
                        <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={
                            
                         <span>
                         { row.bidang }

                         <span className="bg-blue-600 text-xs px-2 ml-2 rounded-md text-slate-50 "> { tgl_awal }</span>
                         <span className="bg-blue-600 text-xs px-2 ml-2 rounded-md text-slate-50 "> { tgl_akhir }</span>
                     </span>
                        } />
                        <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={user_pembuat.nama} />
                        <ChipTable icon={<FontAwesomeIcon icon={faFile} />} text={row.tipe_transaksi} />

                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="38%">
                    <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={<span>{user_info.nama}</span>} />
                    <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.jumlah_transaksi}`)} />
                    <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={row.progress_transaksi} />
                    <ChipTable icon={<FontAwesomeIcon icon={faTimes} />} text={

                         (row.progress_transaksi.indexOf("ditolak") !== -1 && history.length > 0) ? 
                        <div className="text-red-600">
                            <span className="font-bold">{ 
                                history[history.length-1]["username"] } ({ history[history.length-1]["role"] }) : </span>
                           { history[history.length-1]["alasan"]}
                        
                        </div>

                        : 
                        null
                    } />

                </td>
               
                
                <td className={"border-2  border-gray-300 p-2 align-top text-center " + mod} >
                    
                                <div className="flex gap-3 justify-center">
                                    {
                                        (aksi_transaksi.indexOf("terima") !== -1) ?
                                            
                                                <button onClick={() => openAproveModal(row.index, "terima")} className="active:shadow-none bg-white active:bg-green-300 hover:bg-green-200 border-green-500 border-2 shadow-md text-green-500 p-1 rounded-full w-10">
                                                    <FontAwesomeIcon icon={faCheck}/>
                                                </button>
                                            
                        
                                        : 
                                        null
                                    }
                               
                                    {
                                        (aksi_transaksi.indexOf("terbitkan") !== -1 &&  row.tipe_transaksi === "perorangan")  ?
                                        <button onClick={() => openAproveModal(row.index, "terbitkan")} className="active:shadow-none bg-white active:bg-green-300 hover:bg-green-200 border-green-500 border-2 shadow-md text-green-500 p-1  rounded-full w-10">
                                            <FontAwesomeIcon icon={faRocket}/>
                                        </button>
                                        : 
                                        null
                                    }

                                    {
                                        (aksi_transaksi.indexOf("terbitkan") !== -1 &&  row.tipe_transaksi === "kerjasama")  ?
                                        <button onClick={() => openAproveModal(row.index, "terbitkan_kerjasama")} className="active:shadow-none bg-white active:bg-green-300 hover:bg-green-200 border-green-500 border-2 shadow-md text-green-500 p-1 rounded-full w-10">
                                            <FontAwesomeIcon icon={faRocket}/>
                                        </button>
                                        : 
                                        null
                                    }
                                  
                                    {
                                        (aksi_transaksi.indexOf("kirim") !== -1 && row.tipe_transaksi === "kerjasama") ?
                                        <button onClick={() => openAproveModal(row.index, "kirim")} className="active:shadow-none bg-white active:bg-green-300 hover:bg-green-200 border-green-500 border-2 shadow-md text-green-500 p-1 rounded-full w-10">
                                            <FontAwesomeIcon icon={faPaperPlane}/>
                                        </button>
                                        : 
                                        null
                                    }
                               
                                    {
                                        (aksi_transaksi.indexOf("tolak") !== -1) ?
                                        
                                        <button onClick={() => openTolakForm(row.index)} className="active:shadow-none bg-white active:bg-red-300 hover:bg-red-200 border-red-500 border-2 shadow-md text-red-500 p-1 rounded-full w-10">
                                            <FontAwesomeIcon icon={faTimes}/>
                                        </button>
                                        : 
                                        null
                                    }
                                   
                                    {
                                    (aksi_transaksi.indexOf("edit") !== -1) ?
                                        
                                        <button onClick={() => editTransaksi(row.id_transaksi)}  className="active:shadow-none bg-white active:bg-orange-300 hover:bg-orange-200 border-orange-500 border-2 shadow-md text-orange-500 p-1 rounded-full w-10">
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </button>
                                        :
                                        null
                                    }
                             
                                
                                {
                                    (aksi_transaksi.indexOf("delete") !== -1) ?
                                            <button onClick={() => openDeleteForm(row.id_transaksi)} className="active:shadow-none bg-white active:bg-red-300 hover:bg-red-200 border-red-500 border-2 shadow-md text-red-500 p-1 rounded-full w-10">
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </button>
                                        : 
                                        null
                                }
                            </div>
                </td>
              </tr>
            )
          }}
        />
    </div>
  )
}


const ModalApproveFooter = ({ closeModal, loader, submit }) => {
   
    return(
        <div className="text-right flex justify-end">
            
            
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={submit} className=" font-bold bg-green-700 ml-2 p-2 shadow-md hover:bg-green-800 active:bg-green-900 active:shadow-none rounded-md w-32 text-white">
                                Ya
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-green-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}

const ModalDeleteFooter = ({ closeModal, submitDelete, loader }) => {
  

    return(
        <div className="text-right flex justify-end">
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={() => submitDelete("delete")} className=" font-bold bg-red-700 ml-2 p-2 shadow-md hover:bg-red-800 active:bg-red-900 active:shadow-none rounded-md w-32 text-white">
                                Yakin
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-red-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
        </div>
    )
}