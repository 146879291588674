import { useState, Fragment, Component } from 'react';
import { Card, ChipTable, Breadcrumb } from '../../components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faRocket,faCheck,faTimes, faNotesMedical, faList, faChevronUp, faClipboardList, faChalkboardTeacher, faChalkboard,faCoins, faChartLine,faBox,faUser,faShoppingCart,faFileInvoice, faBell,faFileInvoiceDollar,faHistory } from '@fortawesome/free-solid-svg-icons';
import { Disclosure } from '@headlessui/react'
import Datatable from '../../components/Datatable';
import FullLoader from '../../components/FullLoader';
import { getOneTransaksi, approveTerima, approveTolak, terbitkan,kirim, transaksiTerhapus } from '../../api/Transaksi';
import { getGlobalWhatsapp, getStatusSendWa } from '../../api/Laporan';
import { getInvoicesOfTransaksi } from '../../api/Invoice';
import { getKeranjangTransaksi } from '../../api/Keranjang';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { isJson } from '../../api/Value';
import Modal    from '../../components/Modal';
import Spinner    from '../../components/Spinner';
import { InputText, Select } from "../../components/Input";
import { useNavigate } from 'react-router-dom'
import { AppType, backEndUrl } from '../../config/Config';
import Invoice from './Detail/Invoice';
import Keranjang from './Detail/Keranjang';
import Notifikasi from './Detail/Notifikasi';
import Transaksi from './Detail/Transaksi';
import Pelanggan from './Detail/Pelanggan';
import Pembayaran from './Detail/Pembayaran';
import Catatan from './Detail/Catatan';
import History from './Detail/History';
export default () => {
         const navigate = useNavigate();
         const redirect = () => {
            navigate('/app/transaksi/belumDiajukan', { replace: true })
         }
    return(
        <div>
            <Detail redirect={redirect} navigation={navigate} />
        </div>
    )
}


 class Detail extends Component{
    constructor(props){
        super(props)
        let user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"))
       //  console.log(user)
            user['progress_berjalan'] = user['progress_berjalan'].replaceAll("'","");
        this.state = {
            myForm: {
                transaksi: {},
                keranjang: []
            },
            state: "transaksi",
            fullLoad: true,
            hovering: false,
            loader: false,
            id_transaksi: 0,
            approveModal: false,
            tolakModal: false,
            user: user,
            tujuan_penolakan: user["tujuan_penolakan"].split(",")[0],
            alasan: "",
            back: false,
            aksi_kerjasama: user['aksi_transaksi'],
            aksi_perorangan: user['aksi_transaksi_perorangan'],
            tmp_history: [],
            type: "",
            history: JSON.parse(localStorage.getItem("BACK")),
            wa: {
                no_hp: "",
                message: ""
            },
            isDeleted: false,
            dontBack: false
        }
    }
    componentDidMount = () => {
        this.reloadData();
    }
    reloadData = () => {
        const myUrl = new URL(window.location.href)
        const id_transaksi  = myUrl.pathname.split("/")[3]; 
        if (id_transaksi !== undefined) {
            this.getData(id_transaksi) 
        }  
    }
    openWhatsapp = () => { 
        let wa = this.state.wa;
        console.log("whatsapp://send?phone=" + wa.no_hp + "&text=" + window.encodeURI(wa.message))
        window.location.href = "whatsapp://send?phone=" + wa.no_hp + "&text=" + window.encodeURI(wa.message);
    }
     closeTolakForm= () => {
         this.setState({
            tolakModal: false
        })
    }
    openTolakForm= (index) => {
         this.setState({
            tolakModal: true
        })
    }
     bindAlasan = (e) => {
        this.setState({
            alasan: e.target.value
        })
    }
    bindTujuan = (e) => {
        this.setState({
            tujuan_penolakan: e.target.value
        })
    }

    getData = async (id_transaksi, fullLoad = false) => {
        
        const self = this;
        if (fullLoad === true) {
            this.setState({
                fullLoad: true
            })
        }
        // self.setState({
        //     loader: true
        // })
        let myForm = this.state.myForm;
        let tmp_history =[]
        let wa = { no_hp: "", message: "" }
        let isTransaksi = false;
        let isDeleted = false;

        await getOneTransaksi({ id_transaksi: id_transaksi }).then(async (result) => {
            console.log(result)
            if (result.status === 200) {
                let transaksi = result.data
                    transaksi.history = isJson(transaksi.history);
                     tmp_history = transaksi.history.reverse()
                    transaksi.info_pelanggan = isJson(transaksi.info_pelanggan);
                    transaksi.user_pembuat = isJson(transaksi.user_pembuat);
                    transaksi.info_transaksi =isJson(transaksi.info_transaksi)
                myForm['transaksi'] = transaksi
                isTransaksi = true;
                
            }else{
                await transaksiTerhapus().then(res => {
                    if (res.status === 200) {
                        isDeleted = true;
                        const data = res.data;
                        if (data) {
                            let cariTransaksi = data.filter((x) => {
                                return x.id_transaksi == id_transaksi
                            });
                        //    console.log(cariTransaksi)
                            if (cariTransaksi.length > 0) {
                                let transaksi = cariTransaksi[0]
                                transaksi.history = isJson(transaksi.history);
                                tmp_history = transaksi.history.reverse()
                                transaksi.info_pelanggan = isJson(transaksi.info_pelanggan);
                                transaksi.user_pembuat = isJson(transaksi.user_pembuat);
                                transaksi.info_transaksi =isJson(transaksi.info_transaksi)
                                myForm['transaksi'] = transaksi
                                isTransaksi = true;
                            
                            }else{
                                isDeleted = false;
                            }
                        }else{
                            isDeleted = false;
                        }
                    }else{
                        isDeleted = false;
                    }
                })
                if (isDeleted === false) {   
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="List Transaksi Gagal di muat" bg="red"/>
                    })   
                }
            }
        })
        await getKeranjangTransaksi({ id_transaksi: id_transaksi }).then((result) => {
            if (result.status === 200) {
                let keranjang = (result.data === null) ? []: result.data.map((x) => {
                    x.info_keranjang = JSON.parse(x.info_keranjang)
                    return x;
                })
                myForm["keranjang"] = keranjang
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="List Transaksi Gagal di muat" bg="red"/>
                })
            }
        })
        let statusSendWa = true;
        await getStatusSendWa().then(res => {
            if (res.status === 200) {
                statusSendWa = (res.data.nilai === "true")
            }else{
                toast.custom(t => {
                    return <ToastComponent param={t} message="Gagal Mendapatkan Status Send WA" />
                })
            }
            
        })
        // jika send wa true
        if (statusSendWa === true && isTransaksi) {
            let dataInvoice = {};
            await getInvoicesOfTransaksi({ id_transaksi: myForm['transaksi']['id_transaksi'] })
            .then((result) => {
                if (result.status === 200) {
                    if (result.data) {
                        
                        let data = result.data.filter((x) => {
                            return x.is_aktif
                        })
                        if (data.length > 0) {
                            dataInvoice = data[0]
                        }   
                    }
                }else{
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={"Gagal memuat invoice"} bg="red"/>
                    })
                }
                //console.log(result);
            })
            await getGlobalWhatsapp().then(res => {
                if (res.status === 200) {
                    const info_invoice = isJson(dataInvoice.info_invoice);
                    
                    let nilai = res.data.nilai;
                        nilai = nilai.replaceAll("{id_transaksi}", myForm['transaksi']['id_transaksi']);
                        nilai = nilai.replaceAll("{id_invoice}", dataInvoice['id_invoice']);
                        nilai = nilai.replaceAll("{link_invoice}", backEndUrl() + "/penerimaan/files/" + info_invoice.file_invoice);   
                        let no_hp =( myForm['transaksi']['info_transaksi']) ?  myForm['transaksi']['info_transaksi']['no_telp_tagihan']: "";
                        
                        const firstHp  = no_hp.charAt(0)
                        if (firstHp === "0") {
                            let dn = no_hp.split("");
                            dn[0] = "62"
                            no_hp = dn.join("");
                        }
                        wa['no_hp'] = no_hp;
                        wa['message'] = nilai;
                }else{
                    toast.custom((t) => {
                        return <ToastComponent param={ t } message="Gagal memuat template Whatsapp" />
                    })
                }
            })
        }
       //console.log(myForm)
        this.setState({
            myForm: myForm,
            fullLoad: false,
            id_transaksi: parseInt(id_transaksi),
            tmp_history: tmp_history,
            wa: wa,
            isDeleted: isDeleted
        }, () => {
           // console.log(this.state.wa)
           // this.openWhatsapp();
        })
    }
    setMenu =  (type) => {
        this.setState({
            state: type
        })
    }
    setHovering = (bool) => {
        this.setState({
            hovering: bool
        })
    }
    openApproveModal = (type, dontBack=false) => {
        this.setState({
            approveModal: true,
            type: type,
            dontBack:dontBack
        })
    }

    closeAproveModal = () => {
        this.setState({
            approveModal: false
        })
    }
    handleSubmitPerorangan = async (param) => {
        const terbit = await terbitkan(param);
        if (terbit.status === 500) {
             return {
                 status: 500,
                 message: "Gagal Menerbitkan Invoice"
             }       
        }else{
             const send   = await kirim(param);
             if (send.status === 200) {
                 return {
                     status: 200,
                     message: "Berhasil Menerbitkan & Mengirimkan Invoice"
                 }
             }else{
                 return {
                     status: 500,
                     message: "Berhasil Menerbitkan Invoice & Gagal Mengirimkan Invoice"
                 }
             }
        }
         
     }

    approve = () => {
            const self  = this;
            const dontBack = this.state.dontBack;
            if (dontBack === false) {   
                this.setState({
                    loader: true
                })   
            }else{
                this.setState({
                    fullLoad: true
                })
            }
            
            let myForm= this.state.myForm;
            const tipe_transaksi =myForm.transaksi.tipe_transaksi;
            const tipeApprove = this.state.type;
            const myPromise = new Promise((resolve, reject) => {
                if (tipeApprove === "kirim") {
                    resolve(kirim({id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                }else if (tipeApprove === "terbitkan" && tipe_transaksi === "kerjasama") {
                    resolve(terbitkan({id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                
                }else if (tipeApprove === "terbitkan") {
                    // resolve(terbitkan({ id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                    resolve(self.handleSubmitPerorangan({ id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                }else if (tipeApprove === "terima") {
                    resolve(approveTerima({ id_transaksi: this.state.id_transaksi, history: this.state.tmp_history }))
                    
                }else{
                    reject("Tipe Approve Tidak Diketahui")
                }
            });
            
            
            myPromise
            .then((res) => {
                let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
                let type = (role.indexOf("admin") === -1) ? "approve": "ajukan";

                if (res.status === 200) {
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={"Transaksi Berhasil di " + tipeApprove}bg="green"/>
                    })
                    let wa  = self.state.wa;
                    if (wa.no_hp !== "" && role === "admin_meterai") {
                        self.openWhatsapp()
                    }
                    if(dontBack === false){
                        self.props.redirect();
                    }

                    if (dontBack === true) {
                        self.reloadData();
                        self.setState({
                            approveModal: false,
                            fullLoad: false
                        })
                    }
                
                }else{
                    const result = (typeof res === "object") ? res.message.data.message : "Transaksi Gagal diproses";
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={result} bg="red"/>
                    })
             //       console.log(res)
                    self.setState({
                        loader: false,
                        approveModal: false
                    })
                   

                }
           
            })
    }
    tolak = () => {
            const self = this;
            let alasan = this.state.alasan;
            if (alasan !== "") {
                
                this.setState({
                    loader: true
                })

                approveTolak({ id_transaksi: this.state.id_transaksi, history: this.state.tmp_history, message: alasan, tujuan_penolakan: this.state.tujuan_penolakan })
                .then((res) => {
                    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");

                    if (res.status === 200) {
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={"Transaksi Berhasil ditolak"} bg="orange"/>
                        })
                      
                    
                    }else{
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={"Transaksi Gagal ditolak"} bg="red"/>
                        })
                //        console.log(res)

                    }
               self.props.redirect();

                    // setTimeout(() => {
                    //     self.setState({
                    //         loader: false,
                    //         back: true,
                    //         tolakModal: false
                    //     })
                    // }, 500);
                
                })
            }
    }
    render(){
        const { setMenu, setHovering, closeAproveModal, openApproveModal, approve,  bindAlasan,openTolakForm, closeTolakForm, bindTujuan, tolak, getData } = this;
        const { isDeleted, history, id_transaksi, myForm, state, fullLoad, hovering, loader,approveModal, tolakModal,user, tujuan_penolakan, alasan, back, aksi_kerjasama, aksi_perorangan, type } = this.state;
        if (fullLoad) {
            return <FullLoader />
        }
        const { navigation } = this.props;
        //console.log(navigation);
        const tujuan_tolak = user.tujuan_penolakan.split(",");
        const statusBtn    = user.progress_berjalan.split(",").findIndex((x) => myForm.transaksi.progress_transaksi === x) >= 0;
       // console.log(user.progress_berjalan, myForm.transaksi.progress_transaksi);
    //    console.log( user.progress_berjalan)
    //    console.log(myForm.transaksi.progress_transaksi)
       // console.log(myForm)
        let list = [
            
            {
                to: history.to,
                title: history.title
            },
            {
                title: myForm.transaksi.uraian
            }
        ]
        if (history.before) {
            list.unshift(history.before)
        }
        const aksi_transaksi = (myForm.transaksi.tipe_transaksi === "kerjasama") ? aksi_kerjasama : aksi_perorangan
      
        return (
            <div>
       
            <Breadcrumb icon={<FontAwesomeIcon icon={faList}/>} list={list} /> 
            <DetailSideBar type={ state } setMenu={setMenu} setHovering={setHovering} hovering={hovering} />
            {
                (isDeleted === true) ?
                null
                :
                statusBtn || myForm.transaksi.progress_transaksi === "lunas"  ?
                    
                    null
                : 
                <div className="fixed right-5 w-32 text-right bottom-5">
                    
                        {
                            (aksi_transaksi.split(",").findIndex((x) => { 
                                return x === "terima"
                            }) !== -1) ?        
                                <button onClick={() => openApproveModal("terima")} className="bg-green-500 hover:bg-green-400 z-40  shadow-lg text-white text-2xl rounded-full w-12 h-12 ">
                                    <FontAwesomeIcon icon={faCheck} />
                                </button>
                            :
                            null
                        }
                        &nbsp;
                        {
                            (aksi_transaksi.split(",").findIndex((x) => { 
                                return x === "kirim"
                            }) !== -1) ?        
                                <button onClick={() => openApproveModal("kirim")} className="bg-green-500 hover:bg-green-400 z-40 bottom-5 shadow-lg text-white text-2xl rounded-full w-12 h-12 ">
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            :
                            null
                        }
                        &nbsp;

                        {
                            (aksi_transaksi.split(",").findIndex((x) => { 
                                return x === "terbitkan"
                            }) !== -1) ?        
                                <button onClick={() => openApproveModal("terbitkan")} className="bg-green-500 hover:bg-green-400 z-40 bottom-5 shadow-lg text-white text-2xl rounded-full w-12 h-12 ">
                                    <FontAwesomeIcon icon={faRocket} />
                                </button>
                            :
                            null
                        }
                        &nbsp;

                        {
                            (aksi_transaksi.split(",").findIndex((x) => { 
                                return x === "tolak"
                            }) !== -1) ?        
                                <button  onClick={openTolakForm} className="bg-red-500 hover:bg-red-400 bottom-5 z-40 shadow-lg text-white text-2xl rounded-full w-12 h-12 ">
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            :
                            null
                        }
                    </div>
            }
            
            <div className={hovering ? "pl-3 ml-44" : "pl-3 ml-16"}>
                <Toaster position="bottom-right"/>
                    <div className="p-3">
                        {
                            (state === "transaksi") ? 
                                <Card>
                                    <Transaksi myForm={myForm}  />
                                </Card>
                            :
                            (state === "pelanggan") ? 
                                <Card>
                                    <Pelanggan myForm={myForm} />
                                </Card>
                            :
                            (state === "keranjang") ? 
                            
                                    <Keranjang myForm={myForm} />
                              
                            :
                            (state === "invoice") ? 
                                <Invoice terbitkan={ () => openApproveModal("terbitkan", true) } isDeleted={ isDeleted } aksi_transaksi={aksi_kerjasama} myForm = {myForm} id_transaksi = {id_transaksi} getData={ getData } />
                            :
                            (state === "notifikasi") ? 
                                <Notifikasi myForm={myForm}  />
                            :
                            (state === "pembayaran") ? 
                                <Pembayaran myForm={myForm} id_transaksi = {id_transaksi} isDeleted={ isDeleted }  />

                            :
                            (state === "history") ?
                                <History myForm={myForm} id_transaksi = {id_transaksi} />
                            :
                            (state === "catatan") ?
                                <Catatan myForm={myForm} id_transaksi = {id_transaksi} />
                            :
                            null
                        }
                    </div>
               
            </div>
              <Modal
                    Title = {<span className="text-white">
                    {
                        type === "terima" ? "Terima Transaksi"
                        :
                        type === "kirim" ? "Kirim Invoice"
                        : 
                        type === "terbitkan" ? "Terbitkan Invoice"
                        :
                        null
                    }
                    </span>}
                    Body  = {<div className="text-white mt-5"></div>}
                    Footer = {<ModalApproveFooter closeModal = {closeAproveModal} submit={approve} loader={loader} />}
                    closeModal = {closeAproveModal}
                    isOpen = {approveModal}
                    Container = "max-w-md bg-green-600"
                />
                <Modal
                    Title = {<span className="">Tolak Transaksi</span>}
                    Body  = {<div className="mt-5 mb-5">
                                  <InputText 
                                        other={{ onChange: bindAlasan, value: alasan }} 
                                        Type="text" 
                                        Title="Alasan Tolak" 
                                        Placeholder="Alasan Tolak" 
                                        Name="target" 
                                        Status={(alasan === "") ? "required": ""} 
                                        TitleClass=" text-xs" 
                                        elementClass=" mb-2" 
                                        InputClass=" " 
                                    />
                                    {
                                        (tujuan_tolak.length == 1) ? null 
                                        :
                                        <Select Other={{ onChange: bindTujuan, value: tujuan_penolakan }} Title="Tujuan Penolakan"  Name="tujuan_penolakan" Status={""} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                                              
                                                {   
                                                    tujuan_tolak.map((x) =>  {
                                                        return <option key={x} className="" value={x}>{x}</option>   
                                                    })
                                                }
                                        </Select>
                                    }
                                   
                            
                            </div>}
                    Footer = {<ModalDeleteFooter submitDelete={tolak} loader={loader} closeModal = {closeTolakForm}/>}
                    closeModal = {closeTolakForm}
                    isOpen = {tolakModal}
                    Container = "max-w-md bg-white"
                />
            </div>
        )
    }
}


const ChipSideBar = ({ Icon, hovering, title, onClick, active }) => {
    return (
        <div onClick={ onClick } className={`w-10 h-10 hover:bg-white hover:text-indigo-500 cursor-pointer flex ${hovering ? "rounded-md w-full  " : "rounded-full "}  pt-2 ${ active ? "bg-white text-indigo-500" : "text-white"} mb-2`}>
            <div>
                { Icon }
            </div>
            { hovering ? <div className="ml-2 text-center "> { title } </div> : "" }
        </div>
    )
}
const DetailSideBar = ({ type,setMenu, hovering, setHovering }) => {
    const gradient = (AppType() === "Migas") ? "bg-gradient-to-b from-blue-500 to-emerald-600": "bg-gradient-to-b from-violet-500 to-fuchsia-500";
    return (
        <div onMouseOver ={() => setHovering(true)} onMouseLeave={()=>setHovering(false)}  className={gradient + ' bg-white mt-3 fixed z-10 p-3  w-16 transition-all	 hover:w-44 rounded-r-lg  pt-6 drop-shadow-md'}>
            <ChipSideBar 
                Icon        = { <FontAwesomeIcon className="text-xl mt-[2px] pl-[10px]" icon={faList}/> }
                hovering    = { hovering }
                onClick     = { () => setMenu("transaksi") }
                title       = "Transaksi"
                active        = { type === "transaksi" }
            />

            <ChipSideBar 
                Icon        = {  <FontAwesomeIcon className="text-xl pl-[11px]" icon={faUser}/> }
                hovering    = { hovering }
                onClick     = { () => setMenu("pelanggan") }
                title       = "Pelanggan"
                active        = { type === "pelanggan" }
            />

            <ChipSideBar 
                Icon        = {  <FontAwesomeIcon className="text-xl pl-[8px]" icon={faShoppingCart}/> }
                hovering    = { hovering }
                onClick     = { () => setMenu("keranjang") }
                title       = "Keranjang"
                active        = { type === "keranjang" }
            />
            <ChipSideBar 
                Icon        = {  <FontAwesomeIcon className="text-xl pl-[13px]" icon={faFileInvoice}/> }
                hovering    = { hovering }
                onClick     = { () => setMenu("invoice") }
                title       = "Invoice"
                active        = { type === "invoice" }
            />

           <ChipSideBar 
                Icon        = {  <FontAwesomeIcon className="text-xl pl-[12px]" icon={faBell}/> }
                hovering    = { hovering }
                onClick     = { () => setMenu("notifikasi") }
                title       = "Notifikasi"
                active        = { type === "notifikasi" }
            />

           <ChipSideBar 
                Icon        = {  <FontAwesomeIcon className="text-xl pl-[13px]" icon={faFileInvoiceDollar}/> }
                hovering    = { hovering }
                onClick     = { () => setMenu("pembayaran") }
                title       = "Pembayaran"
                active        = { type === "pembayaran" }
            />

           <ChipSideBar 
                Icon        = {  <FontAwesomeIcon className="text-xl pl-[11px]" icon={faHistory}/> }
                hovering    = { hovering }
                onClick     = { () => setMenu("history") }
                title       = "History"
                active        = { type === "history" }
            />

            <ChipSideBar 
                Icon        = {  <FontAwesomeIcon className="text-xl pl-[13px]" icon={faClipboardList}/> }
                hovering    = { hovering }
                onClick     = { () => setMenu("catatan") }
                title       = "Catatan"
                active        = { type === "catatan" }
            />
           
        </div>
    )
}

const ModalApproveFooter = ({ closeModal, loader, submit }) => {
   
    return(
        <div className="text-right flex justify-end">
            
            
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={submit} className=" font-bold bg-green-700 ml-2 p-2 shadow-md hover:bg-green-800 active:bg-green-900 active:shadow-none rounded-md w-32 text-white">
                                Ya
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-green-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}

const ModalDeleteFooter = ({ closeModal, submitDelete, loader }) => {
  

    return(
        <div className="text-right flex justify-end">
            
            
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={() => submitDelete("delete")} className=" font-bold bg-red-700 ml-2 p-2 shadow-md hover:bg-red-800 active:bg-red-900 active:shadow-none rounded-md w-32 text-white">
                                Yakin
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-red-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}
