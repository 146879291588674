import React from 'react';
import Check  from '../api/Validator';
import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import { AppType } from '../config/Config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faEye, faEyeSlash, faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
class FormLogin extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            form: {
                username: "",
                password: ""
            },
            onError: ""
        }
    }
    handleForm     = (e) => {
        let form = this.state.form;
        form[e.target.name] = e.target.value;
        this.setState({
            form: form
        })
    }
    handleSubmit = async () => {
        const form = this.state.form;
  
            const checkLogin = await this.props.submit({ username: form.username, password: form.password })
      //      console.log(checkLogin)
            if (checkLogin.status == 500 && checkLogin.message) {
                this.setState({
                    onError: checkLogin["message"]
                }, () => {
                    const myInterval = setTimeout(() => {
                        this.setState({
                            onError: ""
                        })
                        clearInterval(myInterval);
                    }, 2000);
                });
            }else{
                
            }
     
    }
    handleKeyPress = (e) => {
        
        if (e.key == "Enter") {
            this.handleSubmit();
            
        }
    } 
    render(){
           const { handleForm, handleSubmit, handleKeyPress } = this;
           const { form, onError }       = this.state;
           const { btnClass }   = this.props;
        return (
            <div>   
                        <Toaster position="top-center"/>

              

                { /*
                    AppType() === "Migas" ? 
                    <div>
                    
                    <center>
                    {
                        (onError != "") ?
                        <div className="w-48 bg-pink-500 text-md p-2  rounded-md text-white ">{onError}</div>
    
                        : 
                        null
                    }
                        <div className="w-80 mt-3">
                            <input type="text" name="username" onChange={handleForm} className="border-2 p-2 pl-5 w-full rounded-full bg-gray-200" placeholder="Username" id="" />
                            <input type="password" name="password" onChange={handleForm} onKeyPress={(e) => handleKeyPress(e)} className="border-2 p-2 mt-5 pl-5 w-full rounded-full bg-gray-200" placeholder="Password" id="" />
                            <button onClick={handleSubmit} className="bg-teal-500 hover:bg-teal-600 w-full mt-5 p-2 rounded-full text-white">Masuk</button>
                        </div>
                    </center>
                   
                    </div>
                    
                    : */
                    <div>
                    {
                        (onError != "") ?
                        <div className="bg-pink-500 text-md p-2 rounded-md text-white ">{onError}</div>
    
                        : 
                        null
                    }
                        <div className="mt-2">
                            <label className="text-md">Username</label><br />
                            <input type="text" onChange={handleForm} name="username" className=" pl-2 border-b-2 w-full h-10 ac" />
                        </div>
                        <div className="mt-2">
                            <label className="text-md">Password</label><br />
                            <input type="password" onChange={handleForm} onKeyPress={(e) => handleKeyPress(e)} name="password" className="pl-2 border-b-2 w-full h-10" />
                        </div>
                        <button onClick={handleSubmit} className={btnClass}>MASUK</button>
                    </div>
                }
                
                

            </div>
        )
    }
}

export default FormLogin;