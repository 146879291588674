import { Component, Fragment, useState } from 'react';
import { SmallNavbar } from '../../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort,faList, faCheck, faTrash, faEdit, faUser,faBuilding, faCoins, faChartLine, faTimes,faFile } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import Datatable from '../../components/Datatable';
import Modal    from '../../components/Modal';
import Spinner    from '../../components/Spinner';
import { getAllTransaksiSelesai, deleteTransaksi } from '../../api/Transaksi';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { Navigate  } from "react-router-dom";
import { InputText, Select } from "../../components/Input";
import TabApproval from '../../components/TabApproval';


import { ActiveDropdown } from '../../config/Config';
import FullLoader from '../../components/FullLoader';
import { NavLink }   from 'react-router-dom';
import { numberFormat } from '../../api/Value';
export default class BelumDiajukan extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            dataFilter: [],
            ready: false,
            type_data: "semua",
            user: JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN")),
            myDeleteForm: false,
            id_transaksi: "",
            loader: false
        }
    }
    componentDidMount  = () => {
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/transaksi/selesai", title: "Transaksi Selesai" }));

        const act = document.getElementById("Transaksi_nav");
        if(act){ 
            act.classList.add("border-b-4")
            act.classList.add(ActiveDropdown())
        }
        this.getAll();
    }
    getAll  = () => {
        
        const self = this;
        getAllTransaksiSelesai().then((result) => {
            if (result.status === 200) {

                self.setState({
                    data: result.data !== null ? result.data : [],
                    dataFilter: result.data !== null ? result.data : [],
                    ready: true
                })
            }else{
                self.setState({
                    ready: true
                })
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Gagal Mendapatkan Transaksi Berjalan" bg="orange"/>
                })
            }
     //       console.log(result)
        })
    }
    componentWillUnmount = () => {
        const act = document.getElementById("Transaksi_nav");
        if (act !== null) {

            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
    }

    submitDelete = () => {
        const self= this;
        this.setState({
            loader: true
        })
        deleteTransaksi({ id_transaksi: this.state.id_transaksi }).then((res) => {
             if (res.status === 200) {
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Transaksi Berhasil di hapus" bg="orange"/>
                })
                self.getAll();
                
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Transaksi Gagal di hapus" bg="red"/>
                })
           //     console.log(res)
            }
            self.setState({
                    loader: false,
                    myDeleteForm: false
                })
        })
    }
    openDeleteForm = (id_transaksi) => {
        this.setState({
            myDeleteForm: true,
            id_transaksi: id_transaksi
        })
    }
    handleType = (nameType) => {
        this.setState({
            type_data: nameType,
            dataFilter: (nameType === "semua") ? this.state.data : this.state.data.filter((r) => {
                            return r.tipe_transaksi === nameType
                        })
        })
    }
    render(){
        const { openDeleteForm, handleType, submitDelete, closeDeleteForm } = this;
        const { dataFilter, ready, data, type_data, user, loader, myDeleteForm }          = this.state;
        const isDeleted = user.aksi_transaksi_selesai.indexOf("delete") !== -1;

        if (ready === false) {
            return <FullLoader />
        }
        return (
            <div className="p-5 animate-fadein">
            <Toaster position="bottom-right"/>

                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Selesai
                            </div>
                        </Fragment>}
                />
                <TabApproval type_data={type_data} handleType={handleType} />

                <div className="pt-3">
                    <Card>
                        <TableTransaksi DataRender={dataFilter} openDeleteForm={openDeleteForm} isDeleted={isDeleted} />
                    </Card>
                </div>
                <Modal
                    Title = {<span className="text-white">Hapus Transaksi</span>}
                    Body  = {<div className="text-white mt-5 mb-2">Apakah Anda Yakin?</div>}
                    Footer = {<ModalDeleteFooter submitDelete={submitDelete} loader={loader} closeModal = {closeDeleteForm}/>}
                    closeModal = {closeDeleteForm}
                    isOpen = {myDeleteForm}
                    Container = "max-w-md bg-red-600"
                />
            </div>
        )
    }
}


const TableTransaksi = ({  DataRender,detailTransaksi, isDeleted, openDeleteForm }) => {
 const [ sorting, setSorting ] = useState("id")
 const [ typeSort, setTypeSort ] = useState("asc")
 const TheadComponent = ({ sortData }) => {
   return (
     <thead>
       <tr>
           <td className=" border-2  border-gray-300 p-2 w-20 text-center" >ID</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Uraian/Bidang/Pembuat/Tipe</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Pelanggan/Jumlah/Progress/Penolakan</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Aksi</td>
       
          
       </tr>
     </thead>
     
   )
 }
 console.log(sorting)
 return (
   <div>
       <Datatable 
         Head={TheadComponent}
         Data={DataRender.sort((a, b) => {
            if(sorting === "id"){
                return (typeSort === "asc") ? parseInt(a.id_transaksi) - parseInt(b.id_transaksi) 
                        :
                        parseInt(b.id_transaksi) - parseInt(a.id_transaksi)
            }else{
                return (typeSort === "asc") ? new Date(a.dt_transaksi_lunas) - new Date(b.dt_transaksi_lunas) 
                        : 
                        new Date(b.dt_transaksi_lunas) - new Date(a.dt_transaksi_lunas)
            }
         })}
         Entries={[]}
         TopLeft = {
            <Fragment>
                <div className='pl-1'>
                    <div className="mb-2">Urutkan</div> 
                    <select value={ sorting } onChange={ (e) => setSorting(e.target.value) } className="w-full border-2 p-2 rounded-md md:w-48">
                        <option value="id">ID</option>
                        <option value="lunas">Waktu Lunas</option>
                    </select>
                            
                </div>
                <div className='pt-8 pl-1'>
                    
                    <select value={ typeSort } onChange={ (e) => setTypeSort(e.target.value) } className=" border-2 p-2 rounded-md w-18">
                        <option value="asc">ASC</option>
                        <option value="desc">DESC</option>
                    </select>
                            
                </div>
            </Fragment>
         }
         Body={(row, index) => {
           const mod = (index%2 == 0) ? " bg-gray-100 " : "";
           let user_info = {}
           let history = [];
           let user_pembuat = {}

           let info_transaksi= {}
           let tgl_awal = ""
           let tgl_akhir = ""
          //\ console.log(history)
           try {
               user_pembuat = JSON.parse(row.user_pembuat)
               user_info    = JSON.parse(row.info_pelanggan)
               history      = JSON.parse(row.history)

               info_transaksi = JSON.parse(row.info_transaksi)

               tgl_awal = info_transaksi.tgl_awal.split("-").reverse().join("/")
               tgl_akhir = info_transaksi.tgl_akhir.split("-").reverse().join("/")
           } catch (error) {
        //    console.log(error, row.history)   
           }
           return (
             <tr key={ index }>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod} width="5%"> 
                   { row.id_transaksi } 
               </td>
               <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                   <NavLink to={"/app/detail_transaksi/" + row.id_transaksi}>
                   <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={<span className="cursor-pointer underline text-blue-500">{row.uraian}</span>} />
                   </NavLink>
                       <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={
                         <span>
                                { row.bidang }

                                <span className="bg-blue-600 text-xs px-2 ml-2 rounded-md text-slate-50 "> { tgl_awal }</span>
                                <span className="bg-blue-600 text-xs px-2 ml-2 rounded-md text-slate-50 "> { tgl_akhir }</span>
                            </span>
                       } />
                       <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={user_pembuat.nama} />
                       <ChipTable icon={<FontAwesomeIcon icon={faFile} />} text={row.tipe_transaksi} />

               </td>
               <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                       <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={<span>{user_info.nama}</span>} />

                   <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.jumlah_transaksi}`)} />
                   <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={row.progress_transaksi} />
                   <ChipTable icon={<FontAwesomeIcon icon={faTimes} />} text={

                       
                       (row.progress_transaksi.indexOf("ditolak") !== -1 && history.length > 0) ? 
                       <div className="text-red-600">
                           <span className="font-bold">{ 
                               history[history.length-1]["username"] } ({ history[history.length-1]["role"] }) : </span>
                          { history[history.length-1]["aksi"]}
                       
                       </div>

                       : 
                       null
                   } />

               </td>
               <td className={"border-2  border-gray-300 p-3 align-top text-center " + mod}>
               {
                   isDeleted ?
                   <button onClick={() => openDeleteForm(row.id_transaksi)} className="active:shadow-none bg-white active:bg-red-300 hover:bg-red-200 border-red-500 border-2 shadow-md text-red-500 p-1 rounded-full w-10">
                       <FontAwesomeIcon icon={faTrash}/>
                   </button>
                   :
                   null
               }    
               
           </td>
      
              
             </tr>
           )
         }}
       />
   </div>
 )
}
const ModalDeleteFooter = ({ closeModal, submitDelete, loader }) => {
  

    return(
        <div className="text-right flex justify-end">
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={() => submitDelete("delete")} className=" font-bold bg-red-700 ml-2 p-2 shadow-md hover:bg-red-800 active:bg-red-900 active:shadow-none rounded-md w-32 text-white">
                                Yakin
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-red-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
        </div>
    )
}