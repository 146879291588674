import { useState, useEffect, Fragment, Component } from 'react';
import { Card, InfoCard, ChipTable } from '../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faMapSigns, faPlus, faUser,  faPhone, faMailBulk, faAddressCard, faBuilding, faEdit, faTrash, faList, faChalkboardTeacher, faChalkboard } from '@fortawesome/free-solid-svg-icons';
import Modal    from '../components/Modal';
import Spinner    from '../components/Spinner';

import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import { SmallNavbar } from '../components/Navbar';
import { NavLink }   from 'react-router-dom';
import FullLoader from '../components/FullLoader';
import { getKategori, getAllLayanan, deleteLayanan, getUnit, getForm } from '../api/Layanan';
import { urutkanLayanan } from '../api/Value';
import { Switch } from '@headlessui/react';
import { checkType } from '../api/Value';
import Form  from './Layanan/Form'
import XLSX from 'xlsx';
import Table from './Layanan/Table';
import { getAllRoles } from '../api/Roles';
import { Delete } from '../components/ModalFooter'
import DropdownCategory from './Layanan/DropdownCategory'
import DropdownUnit from './Layanan/DropdownUnit'
import axios from 'axios'
import FormDetail from './Layanan/FormDetail';
import { getGlobalBidang } from '../api/User';
export default class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            // modal state
            myModalForm: false,
            myDeleteForm: false,
            myKategoriForm: false,
            myUnitForm: false,
            myDetailForm: false,
            
            data: [],
            detailForm: {},
        
            kategori: [],
            unit:[],
            form: [],
            // kode layanan selected
            kode_layanan: "",
            // loader state
            loading: false,
            spin: false,
            disabledKode: false
        }
    }
    componentDidMount = () => {
       axios.defaults.headers.common['active_role'] = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
        const self = this;
        getGlobalBidang().then((result) => {
            if (result.status == 200) {
                const data = result.data
                self.setState({
                    kategori: data.nilai.split(",").map((x) => {
                        return x.trim();
                    })
                })
            }else{
                // console.log(result)
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Pelaksana gagal dimuat" bg="orange"/>
                })       
            }
        })
        getUnit().then((result) => {
            if (result.status == 200) {
                const data = result.data
                self.setState({
                    unit: data.nilai.split(",").map((x) => {
                        return x.trim();
                    })
                })
            }else{
                // console.log(result)
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Unit gagal dimuat" bg="orange"/>
                })       
            }
        })
        getForm().then((result) => {
            if (result.status == 200) {
                const data = result.data
                self.setState({
                    formGlobal: data.nilai.split(",").map((x) => {
                        return x.trim();
                    })
                })
            }else {
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Form gagal dimuat" bg="orange"/>
                })  
            }
        })
        this.getAll(false);
    }
    openModal = (type, param={}) => {
        if (type === "form_child") {
            this.setState({
                myModalForm: true,
                kode_layanan: "",
                disabledKode: false,
                detailForm:{ kode_layanan: this.state.data[param.index]['kode_layanan'] + ".", type: param.type, fixed: this.state.data[param.index]['kode_layanan'] + "."  }
            })
        }else if(type === "form"){
             this.setState({
                    detailForm: {},
                    disabledKode: false,
                    myModalForm: true
                })

        }else if (type === "update_form") {
            const data = this.state.data;
            const kode = data[param.index]['kode_layanan'];
            let isChild = data.filter((x) =>{
                return x.kode_layanan.indexOf(kode+".") !== -1
            }).length > 0
            console.log(isChild)
            this.setState({
                myModalForm: true,
                detailForm:  data[param.index],
                kode_layanan: kode,
                disabledKode: isChild
            })
        }else if (type === "delete") {
            this.setState({
                myDeleteForm: true,
                kode_layanan: this.state.data[param.index]['kode_layanan']
            })
        }else if (type === "detail") {
            this.setState({
                detailForm:  this.state.data[param.index],
                myDetailForm: true
            })
        }else if (type === "kategori") {
             let data = this.state.data.filter((x) => { return x.checked  });
            if (data.length > 0) {   
                this.setState({
                    myKategoriForm:  true,
                    kode_layanan: data[0]['kode_layanan']
                })   
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Mohon Pilih Layanan Terlebih Dahulu" bg="red"/>
                }) 
            }
        }else if (type === "unit") {
            let dataLayanan = this.state.data.filter((x) => { return x.checked  });
            if (dataLayanan.length > 0) {   
                this.setState({
                    myUnitForm:  true,
                    kode_layanan: dataLayanan[0]['kode_layanan']
                })   
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Mohon Pilih Layanan Terlebih Dahulu" bg="red"/>
                }) 
            }

        }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Tipe Modal Tidak Diketahui" bg="red"/>
                }) 
        }
        
    }
    closeModal = (type,param={}) => {
        //console.log(isUpdate)
        if (param.isUpdate) {
           this.getAll(param.kode_layanan);
        }
        switch (type) {
         
            case 'form':
                this.setState({
                    kode_layanan: "",
                    myModalForm: false,
                    detailForm:  {}

                })
                break;
            case 'update_form':
                this.setState({
                    myModalForm: false,
                    detailForm:  {}

                })
                break;
            case 'delete':
                this.setState({
                    myDeleteForm: false,
                    kode_layanan: param.kode_layanan,
                    detailForm:  {}

                })
                break;
            case 'detail':
                this.setState({
                    detailForm:  {},
                    myDetailForm: false
                })
                break;
            case 'kategori':
                this.setState({
                    myKategoriForm:  false,
                    kode_layanan: ""

                })
                break;
            case 'unit':
                this.setState({
                    myUnitForm:  false,
                    kode_layanan: ""

                })
                break;
            default:
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Tipe Modal Tidak Diketahui" bg="red"/>
                }) 
                break;
        }
    }
    submitDelete = () => {
        let kode_layanan = this.state.kode_layanan;
        const self = this;
        this.setState({
            spin: true
        })
        deleteLayanan({ kode_layanan: kode_layanan }).then(result => {
            if (result.status == 200) {
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Layanan Berhasil Dihapus" bg="orange"/>
                }) 
                let focus = kode_layanan.split(".");
                self.closeModal("delete", { isUpdate: true, kode_layanan: focus.splice(focus.length-1,1).join(".") })
            }else{
            //    console.log(result);
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Gagal Menghapus Layanan" bg="red"/>
                }) 
            }
             self.setState({
                spin: false
            })
        })
    }
    // get all pelanggan
    getAll = (focus=true) => {
       const self=  this;
       getAllLayanan().then((result) => {
           if (result.status == 200) {
               let fromDb = urutkanLayanan(result.data).map((x,i) => {
                x.index = i;   
                return x;
               });
               //console.log(fromDb)
               const fromState = self.state.data;
              
                if (focus) {
                    fromDb = fromDb.map((x) => {
                                if (x.kode_layanan == focus) {
                                     x.showing = true;
                                        x.expand  = true;
                                }else{
                                     let oldData = fromState.filter((s) => { return s.kode_layanan == x.kode_layanan })
                                    if (oldData.length > 0) {   
                                        x.showing = oldData[0]['showing'];
                                        x.expand  = oldData[0]['expand'];
                                    }
                                }
                               
                                return x;
                            })     
                }
               this.setState({
                   data: fromDb
               })
           }else{
            //   console.log(result)
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Layanan gagal dimuat" bg="red"/>
                }) 
           }
       })
       
    }
  
    handleExpand = (index) => {
        // from state
        let data = this.state.data;
        // boolean swap
        const expand = !data[index]['expand'];
        // self click update
        data[index]['expand'] = expand;
        const kode_layanan = data[index]['kode_layanan'];
        // kode yang diclick dan di split
        const selectedCode = kode_layanan.split(".");
        // maximum panjang benar jika dikatan benar
        const maxIsMyChild = selectedCode.length;
        // map data
        data = data.map((x) => {
            // row split kode layanan
            const myCode = x.kode_layanan.split(".");
            // cek benar bera[a]
            let benar = 0;
            // loop berdasarkan besaran maximum kode yang diklik
            for (let i = 0; i <= maxIsMyChild; i++) {
                // cek kode nya sama atau tidak di pattern yang diloop
                if ((myCode[i] == selectedCode[i]) === false) {
                    // hentikan loop
                    break;
                }else{
                    // increment benar
                    benar++;
                }
            }
            // jika benar lebih dari sama dengan kode yang diklik dan panjang row kode
            // kurang dari selected kode + 1 (ini tujuannya untuk expand)
            if (benar == maxIsMyChild && myCode.length <= selectedCode.length+1 && x.kode_layanan !== kode_layanan) {
                x.expand = false;
                x.showing = expand
            // ini tujuannya close expand
            }else if (benar >= maxIsMyChild && x.kode_layanan !== kode_layanan && expand === false) {
                x.expand = false;
                x.showing = expand;
            }
            return x;
        })
     //console.log(selectedCode)
       this.setState({
           data: data
       })
       /*
       const meExpand   = data[index]['expand'];
        const kd = data[index]['kode_layanan'];
        const kode_layanan = kd.split(".");
        const ruleIsValid  =kode_layanan.length-1;
        
        const maxLevel     = data[index]['level'] + 1;
            data = data.map((d) => {
                if (d.kode_layanan != kd && meExpand == false) {
                     const myIndexCode = d.kode_layanan.split(".").findLastIndex((x) => { 
                                    return x == kode_layanan[ruleIsValid]
                                })
                    console.log(myIndexCode)
                    d.showing = (myIndexCode == ruleIsValid) ? false :  d.showing   
                    d.expand  = (myIndexCode == ruleIsValid) ? false : d.expand
                }else if (d.kode_layanan != kd && maxLevel == d.level) {
                    const myIndexCode = d.kode_layanan.split(".").findLastIndex((x) => { 
                                    return x == kode_layanan[ruleIsValid]
                                })
                    d.showing = ((myIndexCode == ruleIsValid) && d.showing == true) ? false :  ((myIndexCode == ruleIsValid) && d.showing == false) ? true : d.showing
                }
                return d;
            })
            console.log(data)
        this.setState({
            data:data
        })
        */
    }
    handleChecked = (index, checked) => {

        let data = this.state.data;
        const kd = data[index]['kode_layanan'];
        const kode_layanan = kd.split(".");
        const ruleIsValid  =kode_layanan.length;
            data = data.map((d) => {
                if (d.kode_layanan === kd) {
                    d.checked = checked;
                    return d;
                }
                const myCode = d.kode_layanan.split(".") 
                let benar = 0;
                // loop berdasarkan besaran maximum kode yang diklik
                for (let i = 0; i <= ruleIsValid; i++) {
                    // cek kode nya sama atau tidak di pattern yang diloop
                    if ((myCode[i] == kode_layanan[i]) === false) {
                        // hentikan loop
                        break;
                    }else{
                        // increment benar
                        benar++;
                    }
                }
                    d.checked = (benar == ruleIsValid) ? checked :  d.checked
              
                return d;
            })
        this.setState({
            data:data
        })
    }
    exportAll = () => {
        var ws = XLSX.utils.json_to_sheet(this.state.data.map((x) => {
            
            return {
                kode_layanan: x.kode_layanan, 
                nama_layanan: x.nama_layanan, 
                tarif: x.tarif
            }
            
        }));
        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Layanan Penerimaan");
        let name     = "Layanan Penerimaan"
        /* generate an XLSX file */
        XLSX.writeFile(wb, name + ".xlsx");
    }
    render(){

        
        // get object class
        const { openModal, closeModal, deleteUser, editForm, handleExpand, handleChecked, submitDelete, exportAll } = this;
        // get value state
        const { myModalForm, myDeleteForm, myDetailForm, myKategoriForm, myUnitForm, loading, data, 
        submitLoader, detailForm, detailModal, kode_layanan, spin, kategori, unit, formGlobal, disabledKode } = this.state;
        // is loading full screen ? 
        if(loading){
            return <FullLoader/>
        }else{
            // default render
            return (
                 <div className=" p-5 animate-fadein">
                        <Toaster position="bottom-right"/>
                        <SmallNavbar left={
                        <Fragment>
                            <FontAwesomeIcon icon={faChalkboardTeacher}  className="mt-1" />
                            <div className="flex-initial ml-2 " >
                                Layanan            
                            </div>
                        </Fragment>
                        } 
                            right = {
                                <button onClick={ () => openModal("form") } className="bg-white h-10 rounded-full ml-2 p-1 text-blue-700 pl-4 pr-4 shadow-md hover:bg-gray-100 active:bg-gray-200 active:shadow-none ">
                                        <FontAwesomeIcon icon={faPlus} /> Tambah
                                    </button>
                            }
                        />
                        <div className="bg-white rounded-md p-5">
                            <div className="mb-2">
                                <button onClick={ () => openModal("kategori")} className="w-40 text-center bg-white h-10 rounded-full ml-2 p-1 text-blue-700 border-2 border-blue-700 pl-4 pr-4 shadow-md hover:bg-gray-100 active:bg-gray-200 active:shadow-none">
                                    <FontAwesomeIcon icon={faList} /> Pelaksana
                                </button>
                                <button onClick={ () => openModal("unit")} className="w-40 text-center bg-white h-10 rounded-full ml-2 p-1 text-green-700 border-2 border-green-700 pl-4 pr-4 shadow-md hover:bg-green-100 active:bg-green-200 active:shadow-none">
                                    <FontAwesomeIcon icon={faChalkboardTeacher} /> Unit
                                </button>
                               
                            </div>
                            
                            <Table 
                                openEditModal={(param) => openModal("update_form", param)} 
                                openModal={(param) => openModal("delete", param)} 
                                exportAll = { exportAll }

                                openModalForm = { (param) => openModal("form_child", param) } 
                                Data_Layanan = { data.filter((d) => { return (d.padding_rem == 1 || d.showing) }) }
                                handleExpand = {handleExpand}
                                handleChecked = {handleChecked}
                                openModalDetail = {(param) => openModal("detail", param)}
                            />            
                        </div>    
                        <Modal
                            Title = "Form Layanan"
                            Body  = {
                                        <Form 
                                            kategori={kategori} 
                                            closeModal={(param) => closeModal("form", param)} 
                                            detailForm={detailForm} 
                                            categoryList={kategori}
                                            unit ={ unit}
                                            kode_layanan={kode_layanan}
                                            data={data}
                                            formGlobal={formGlobal}
                                            disabledKode ={ disabledKode }
                                        />
                                    }
                            closeModal = {(param) => closeModal("form", param)}
                            isOpen = {myModalForm}
                            Container = "max-w-4xl bg-white"
                        />

                        <Modal
                            Title = "Apply Pelaksana"
                            Body  = {
                                <DropdownCategory  
                                    categoryList ={ kategori }
                                    closeModal = {(param) => closeModal("kategori", param)}
                                    data = {data}
                                />
                            }
                            closeModal = {(param) => closeModal("kategori", param)}
                            isOpen = {myKategoriForm}
                            Container = "max-w-md bg-white"
                        />
                         <Modal
                            Title = "Apply Unit Penjual"
                            Body  = {
                                <DropdownUnit  
                                    unitList ={ unit }
                                    closeModal = {(param) => closeModal("unit", param)}
                                    data = {data}

                                />
                            }
                            closeModal = {(param) => closeModal("unit", param)}
                            isOpen = {myUnitForm}
                            Container = "max-w-md bg-white"
                        />
                        <Modal
                            Title = "Detail Layanan"
                            Body  = {<FormDetail 
                                        closeModalForm={(param) =>  closeModal("detail", param) } 
                                        myForm={detailForm} 
                                        />}
                   
                            closeModal = {(param) =>  closeModal("detail", param) }
                            isOpen = {myDetailForm}
                            Container = "max-w-4xl bg-white"
                        />

                        <Modal
                            Title = {<span className="text-white">Hapus Layanan</span>}
                            Body  = {<div className="text-white mt-5">Apakah Anda Yakin?</div>}
                            Footer = {<Delete closeModal = {(param) => closeModal("delete", param)} onSubmit={submitDelete} loader={spin}/>}
                            closeModal = {(param) => closeModal("delete", param)}
                            isOpen = {myDeleteForm}
                            Container = "max-w-md bg-red-600"
                        />
                    </div>
            )
        }
    }
}

