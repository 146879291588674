import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getBidang, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';
export const getPembayaran = async () => {
        return await axios.get(backEndUrl() + "/penerimaan/pembayaran", getCred())
        .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    })
}

export const getAllPembayaranTrx = async ({ id_transaksi }) => {
    return await axios.get(backEndUrl() + "/penerimaan/pembayaran/" + id_transaksi, getCred())
        .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}

export const createPembayaran = async ({ id_transaksi, id_invoice, dt_bayar, jumlah, cara_bayar }) => {
    return await axios.post(backEndUrl() + "/penerimaan/pembayaran", { 
        id_transaksi: id_transaksi,
        id_invoice: id_invoice,
        dt_bayar: dt_bayar,
        jumlah: jumlah,
        cara_bayar: cara_bayar,
        uuid: uuidv4()
     }, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}
export const setPembayaran = async ({ id_pembayaran,  dt_bayar, jumlah, cara_bayar }) => {
    return await axios.put(backEndUrl() + "/penerimaan/pembayaran", { 
        id_pembayaran: id_pembayaran,
        dt_bayar: dt_bayar,
        jumlah: jumlah,
        cara_bayar: cara_bayar
     }, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}
export  const unsetPembayaran = async ({ id_pembayaran, id_transaksi }) => {
    return await axios.delete(backEndUrl() + "/penerimaan/pembayaran/delete/" + id_transaksi + "/" + id_pembayaran, 
    getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}
export const checkPembayaranManual = async ({ id_invoice }) => {
    return await axios.get(backEndUrl() + "/penerimaan/pembayaran/cek_bayar/" + id_invoice, getCred())

                    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}