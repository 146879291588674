import '../../invoice.css';
import { NoDokumenPembayaran, getInaDate, numberFormat, ex_terbilang, ucWords } from '../../api/Value';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';

export default ({ myForm, invoiceSelected }) => {
    return (
        <div className="arial file bg-white  p-[2cm]">
           <table width="100%" className="border-b-2 border-black">
                <tbody>
                    <tr>
                        <td valign="top" width="20%" className="p-2 text-center">
                            <center>
                            <img src={ require("../../esdm.png") } className="w-[1.32cm]" alt="" />
                            <div className="font-bold text-[12px] pt-2">PPSDM MIGAS</div>
                            </center>
                            
                        </td>
                        <td className="pl-2">
                            <div className="font-bold text-[16px]">
                                PUSAT PENGEMBANGAN SUMBER DAYA MANUSIA <br/>
                                MINYAK DAN GAS BUMI CEPU                        
                            </div>
                            <div className="text-[10px]">
                                Jalan Sorogo 1 Cepu,  Blora - Jawa Tengah <br/>
                                Telp.  : (0296) 421888 Email. : keuangan.ppsdmmigas@esdm.go.id Faks : (0296)421891  
                            
                            </div>
                        </td>
                    </tr>
                </tbody>
           </table>
           <div className="text-center underline font-bold text-[18px] mt-2 mb-2">
                INVOICE
           </div>
           <table width="100%">
            <tbody>
                <tr>
                    <td width="60%"></td>
                    <td>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td>
                                        No. Invoice
                                    </td>
                                    <td>
                                        :
                                    </td>
                                    <td>
                                        { invoiceSelected.id_invoice }/BPM/{new Date().getFullYear()}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tanggal</td>
                                    <td>
                                        :
                                    </td>
                                    <td>{ getInaDate(invoiceSelected.dt_invoice_created) }</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
           </table>
           <br />
           <table>
            <tbody>
                <tr>
                    <td>Yang Terhormat</td>
                    <td>:</td>
                    <td>{ myForm.transaksi.info_transaksi.nama_tagihan }</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>{ myForm.transaksi.info_transaksi.alamat_tagihan }</td>
                </tr>
            </tbody>
           </table>
           <br />
           <table className="border-collapse text-md" width="100%">
                <tbody>
                    <tr>
                        <th className="border-[1px] border-black text-center" width="5%">NO</th>
                        <th className="border-[1px] border-black text-center" width="29%">URAIAN</th>
                        <th className="border-[1px] border-black text-center" width="22%">KUANTITAS</th>
                        <th className="border-[1px] border-black text-center" width="22%">HARGA SATUAN <br /> (Rp)</th>
                        <th className="border-[1px] border-black text-center" width="22%">HARGA <br /> (Rp)</th>
                    </tr>
                 {
                    myForm.keranjang.map((x, i) => {
                        return (
                            <tr key={i}>
                              <td className="text-center border-[1px] border-black p-1">{ i+1 }</td>
                                <td className="border-[1px] border-black p-1">{x.uraian_keranjang}</td>
                                <td className="text-center border-[1px] border-black p-1">{x.kuantitas}</td>
                                <td className="text-right border-[1px] border-black p-1" style={{ whiteSpace: 'nowrap'}}>Rp { numberFormat(  `${x.tarif_keranjang}` ) }</td>
                                <td className="text-right border-[1px] border-black p-1" style={{ whiteSpace: 'nowrap'}}>Rp { numberFormat( `${x.jumlah_keranjang}` ) }</td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <th  className="border-[1px] border-black p-1 text-right" colSpan="4">SUBTOTAL</th>
                    <th className="border-[1px] border-black p-1 text-right">Rp { numberFormat( `${myForm.transaksi.jumlah_transaksi}` ) }</th>
                </tr>
                <tr>
                    <th  className="border-[1px] border-black p-1 text-right" colSpan="4">BIAYA BANK</th>
                    <th className="border-[1px] border-black p-1 text-right">Rp { numberFormat( `${myForm.invoice.biaya_bank}` ) }</th>
                </tr>
                <tr>
                    <th  className="border-[1px] border-black p-1 text-right" colSpan="4">TOTAL</th>
                    <th className="border-[1px] border-black p-1 text-right">Rp { numberFormat( `${myForm.invoice.jumlah_bruto}` ) }</th>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td  className="border-[1px] border-black p-1 text-right" colSpan="5">({ ex_terbilang(myForm.invoice.jumlah_bruto) })</td>
                       
                    </tr>
                </tfoot>
           </table>
           Keterangan : 
           <table width="100%" className="text-[10px] border-[1px] border-black">
                <tbody>
                    <tr>
                        <td className="p-1" width="1%" valign="top">-</td>
                        <td className="p-1" width="80%">PPSDM Migas merupakan Instansi Pemerintah Non PKP, bebas dari potongan PPN dan PPh sesuai surat dari Kantor Pelayanan Pajak Blora nomor : S-2388/WPJ.10/KP.11/2020 tanggal 21 Juli 2020 sebagaimana terlampir.</td>
            
                    </tr>
                    <tr>
                        <td className="p-1" valign="top">-</td>

                        <td className="p-1">Semua biaya transaksi perbankan dibebankan kepada pengguna jasa.</td>
               
                    </tr>
                </tbody>
           </table>
           <br />
           <table className="relative">
                <tbody>
                    <tr>
                        <td width="57%" valign="top" className="text-md">
                            Pembayaran melalui transfer ke  <br />
                            Virtual Account BNI <strong> {invoiceSelected.no_va} </strong> 
                            <br />
                            <div className="mb-4">
                                paling lambat <strong><Moment format="DD-MM-YYYY HH:mm:ss" tz="Asia/Jakarta">{invoiceSelected.dt_va_expired}</Moment> WIB </strong> 
                            </div>
                            
                            Nb: Jumlah pembayaran tersebut sudah termasuk biaya transaksi perbankan
                        </td>
                        <td valign="top">SubKoordinator Keuangan dan Aset</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <img src={require("../../cap_ppsdm.png")} width="150" alt="" className="absolute -ml-[50px] -mt-[120px]" />
                            <img src="/ttd_andhy_mahendra.svg" width="150" alt="" className="absolute -mt-[120px]" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Andhy Mahendra</td>

                    </tr>
                    <tr>
                        <td></td>
                        <td>NIP 198311162010121002</td>

                    </tr>
                </tbody>
           </table>
        </div>
    )
}