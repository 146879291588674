import Datatable from '../../components/Datatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye, faCode, faPlus, faTimes, faList, faDate, faChartLine, faFileInvoiceDollar, faCoins, faCalendar, faEdit, faTrash, faChalkboard  } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import { Component, useEffect } from 'react';
import { InputText, TextArea } from '../../components/Input';
import { Toast as ToastComponent } from '../../components/Toast';
import { getKeranjangTransaksi } from '../../api/Keranjang';
import toast, { Toaster } from 'react-hot-toast';
import Modal from '../../components/Modal';
import ModalFooter from '../../components/ModalFooter';
import { numberFormat } from '../../api/Value';
import FormDetail from './Form/FormDetail'
import { deleteKeranjangTransaksi } from '../../api/Keranjang';
export default class Keranjang extends Component{
    constructor(props){
        super(props);
        this.state = {
            listKeranjang: [],
            // form pelatihan 
            pelatihan: {
                "kode_layanan": "",
                "uraian_keranjang": "",
                "kuantitas" : 0,
                "tarif_keranjang" : 0,
                "jumlah_keranjang" : 0,
                "info_keranjang" : {
                    type_form: "",
                    tgl_awal: "",
                    tgl_akhir: "",
                    list_peserta: []
                }
            },
            penunjang: {
                "kode_layanan": "",
                "uraian_keranjang": "",
                "kuantitas": 0,
                "tarif_keranjang": 0,
                "jumlah_keranjang": 0,
                "info_keranjang": {
                    type_form: "",
                    tgl_awal: "",
                    tgl_akhir: "",
                    nomor_fasilitas: ""
                }
            },
            formStatePelatihan: {
                tgl_awal: "",
                tgl_akhir: "",
                uraian_keranjang: ""
            },
            formStatePenunjang: {
                tgl_awal: "",
                tgl_akhir: "",
                uraian_keranjang: "",
                nomor_fasilitas: "",
                kuantitas: "",
                jumlah_keranjang: "",
                uraian_keranjang: "",
            },
            form_peserta: {
                namaPeserta: "",
                biayaPeserta: ""
            },
            typeForm: "",
            loader: false,
            indexEdit: -1,
            detailType: "",
            detail: {},
            backup: null

        }
    }
    componentDidMount = () => {
         const self = this;
        const myUrl = new URL(window.location.href);
          if (myUrl.pathname.split("/")[3] !== undefined) {
            getKeranjangTransaksi({ id_transaksi: myUrl.pathname.split("/")[3] }).then((res) => {
                if (res.status === 200) {
                    let myForm = this.state.myForm;
                    const row = res.data;
                   // console.log(row)
                    let index = 0;
                    for(let r of row){
                        let keranjang = JSON.parse(r.info_keranjang);
                        
                        if (keranjang.type_form === "pelatihan/sertifikasi") {
                            let n = 0;
                            for(let k of keranjang.list_peserta){
                                k.biayaPeserta = `${k.biayaPeserta}`.replaceAll(",","");
                                k.index =n;
                                n++
                            }
                        }
                        r.info_keranjang = keranjang

                        r.index = index;
                        index++;
                    }
              //      console.log(row)
                    self.setState({
                        listKeranjang: row
                    })
                    self.props.updaterKeranjang(row);
                    // this.setState({
                    //     myForm: myForm
                    // }, () => {
                    //     self.onSelectKonsumen(cariPelanggan[0])
                    // })
                }
            })
        }

    }
    setTarif = () => {

            let pelatihan = this.state.pelatihan;
            let penunjang = this.state.penunjang;
            let form_peserta = this.state.form_peserta;
            let produk_selected = this.props.produk_selected;
            pelatihan['uraian_keranjang'] = produk_selected.nama_layanan;
            penunjang['uraian_keranjang'] = produk_selected.nama_layanan;
            pelatihan['tarif_keranjang'] = numberFormat(`${produk_selected['tarif']}`.replaceAll(",", ""));
            penunjang['tarif_keranjang'] = numberFormat(`${produk_selected['tarif']}`.replaceAll(",", ""));
            form_peserta['biayaPeserta'] = numberFormat(`${produk_selected['tarif']}`.replaceAll(",", ""));
            this.setState({
                pelatihan: pelatihan,
                penunjang: penunjang,
                form_peserta: form_peserta
            })
        
    }
    addKeranjang = () => {
        const { produk_selected } = this.props;
        // console.log(produk_selected)
        if (produk_selected.info_layanan && produk_selected.info_layanan !== "") {
            let typeForm = JSON.parse(produk_selected.info_layanan)["tipe_form"];
           
      //      console.log(typeForm)
            if (typeForm === "pelatihan/sertifikasi") {
                let isValid = true;
                let pelatihan = this.state.pelatihan;
              
                let formStatePelatihan = this.state.formStatePelatihan;
                for(let p of ["uraian_keranjang","tgl_awal", "tgl_akhir"]){
                    const target = (p === "tgl_awal" || p === "tgl_akhir") ? "_"+p : p;
                    if (pelatihan['info_keranjang'][p] === "") {
                        isValid = false;                        
                        formStatePelatihan[p] = "required";
                        const form = document.getElementById(target);
                        
                        if (form) {
                            form.focus();
                        }
                    }
                    if (pelatihan[p] === "") {
                        isValid = false;                        
                        formStatePelatihan[p] = "required";
                        const form = document.getElementById(target);
                        console.log(form)

                        if (form) {
                            form.focus();
                        }
                    
                    }
                }

                if (pelatihan.info_keranjang.list_peserta.length == 0) {
                    isValid = false;
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Mohon Tambah Peserta" bg="red"/>
                    })
                }

                if (isValid === true) {
                    let listKeranjang = this.state.listKeranjang;
                    pelatihan["kuantitas"] = pelatihan["info_keranjang"]["list_peserta"].length
                    pelatihan["kode_layanan"] = produk_selected.kode_layanan;
                    pelatihan['info_keranjang']['type_form'] = typeForm;
                    pelatihan["tarif_keranjang"] = `${pelatihan["tarif_keranjang"]}`.replaceAll(",","");
                    let indexEdit = this.state.indexEdit;
                    if (indexEdit === -1) {
                        listKeranjang.push(JSON.parse(JSON.stringify(pelatihan)))
                        
                    }else{
                        listKeranjang[indexEdit] = JSON.parse(JSON.stringify(pelatihan))
                    }
                    listKeranjang = listKeranjang.map((l, index) => {
                        l.index=  index
                        return l;
                    })
                    pelatihan["uraian_keranjang"] = "";
                    pelatihan['tgl_awal'] = "";
                    pelatihan["tgl_akhir"] = "";
                    pelatihan["jumlah_keranjang"] = 0;
                    pelatihan['info_keranjang']['list_peserta'] = [];
                    this.setState({
                        pelatihan: pelatihan,
                        list_keranjang: listKeranjang,
                        indexEdit: -1,
                         pelatihan: {
                            "kode_layanan": "",
                            "uraian_keranjang": "",
                            "kuantitas" : 0,
                            "tarif_keranjang" : 0,
                            "jumlah_keranjang" : 0,
                            "info_keranjang" : {
                                type_form: "",
                                tgl_awal: "",
                                tgl_akhir: "",
                                list_peserta: []
                            }
                        },
                        penunjang: {
                            "kode_layanan": "",
                            "uraian_keranjang": "",
                            "kuantitas": 0,
                            "tarif_keranjang": 0,
                            "jumlah_keranjang": 0,
                            "info_keranjang": {
                                type_form: "",
                                tgl_awal: "",
                                tgl_akhir: "",
                                nomor_fasilitas: ""
                            }
                        },
                    }, () => {
                        this.props.closeModal("form",  { keranjang: listKeranjang })
                        this.props.updateKeranjang(listKeranjang)
                    })
                }else{
                    this.setState({
                        formStatePelatihan: formStatePelatihan
                    }, () => {
                        setTimeout(() => {
                            for(let i in formStatePelatihan){
                                formStatePelatihan[i] = "";
                            }
                            this.setState({
                                formStatePelatihan: formStatePelatihan
                            })
                        }, 2000);
                    })

                }
             
            }else if (typeForm === "penunjang") {
                let isValid = true;
                let penunjang = this.state.penunjang;
                let formStatePenunjang = this.state.formStatePenunjang;
                
                for(let r in formStatePenunjang){
                    if (r == "tgl_awal" || r == "tgl_akhir") {
                        if (penunjang["info_keranjang"][r] === "") {
                            isValid = false;
                            formStatePenunjang[r] = "required";
                        }
                    }else if (penunjang[r] == "") {
                        isValid = false;
                        formStatePenunjang[r] = "required";
                    }
                }

                if (isValid === false) {
                    this.setState({
                        formStatePenunjang: formStatePenunjang
                    }, () => {
                        setTimeout(() => {
                            for(let d  in formStatePenunjang){
                                formStatePenunjang[d] = "";
                            }
                            this.setState({
                                formStatePenunjang: formStatePenunjang
                            })
                        }, 2000);
                    })
                }else if (isValid) {
                    let listKeranjang = this.state.listKeranjang;
                    let indexEdit     = this.state.indexEdit;
                    penunjang["kode_layanan"] = produk_selected.kode_layanan;
                    penunjang['info_keranjang']['type_form'] = typeForm
                    penunjang["tarif_keranjang"] = `${penunjang["tarif_keranjang"]}`.replaceAll(",","");
                    penunjang["jumlah_keranjang"] = `${penunjang["jumlah_keranjang"]}`.replaceAll(",","");

                    if (indexEdit === -1) {
                        listKeranjang.push(JSON.parse(JSON.stringify(penunjang)));
                        
                    }else{
                        listKeranjang[indexEdit] = JSON.parse(JSON.stringify(penunjang));
                    }
                    listKeranjang = listKeranjang.map((l, index) => {
                        l.index=  index
                        return l;
                    })
                    this.setState({
                        listKeranjang: listKeranjang.map((x,i) => {
                            x.index = i
                            return x
                        }),
                         pelatihan: {
                                "kode_layanan": "",
                                "uraian_keranjang": "",
                                "kuantitas" : 0,
                                "tarif_keranjang" : 0,
                                "jumlah_keranjang" : 0,
                                "info_keranjang" : {
                                    type_form: "",
                                    tgl_awal: "",
                                    tgl_akhir: "",
                                    list_peserta: []
                                }
                            },
                            penunjang: {
                                "kode_layanan": "",
                                "uraian_keranjang": "",
                                "kuantitas": 0,
                                "tarif_keranjang": 0,
                                "jumlah_keranjang": 0,
                                "info_keranjang": {
                                    type_form: "",
                                    tgl_awal: "",
                                    tgl_akhir: "",
                                    nomor_fasilitas: ""
                                }
                            },
                    },() => {
                        this.props.closeModal("form", { keranjang: listKeranjang })
                        this.props.updateKeranjang(listKeranjang)
                    })

                }
            }
        }
    }
    deleteKeranjang = (index) => {
        const self = this
        const i = index;
        let listKeranjang = this.state.listKeranjang;
        if (listKeranjang[index]["id_keranjang"] !== undefined) {
            deleteKeranjangTransaksi({ id_keranjang: listKeranjang[index]["id_keranjang"] })
            .then((res) => {
                if (res.status === 200) {
                  listKeranjang.splice(i, 1);
                    self.setState({
                        listKeranjang: listKeranjang.map((x,i) => {
                            x.index = i
                            return x
                        })
                    })
                      toast.custom((t) => {
                        return <ToastComponent param={t} message="Keranjang berhasil dihapus" bg="green"/>
                    })
                }else{
                      toast.custom((t) => {
                        return <ToastComponent param={t} message="Keranjang gagal dihapus" bg="red"/>
                    })
                }
            })
        }else{
            listKeranjang.splice(index, 1);
            this.setState({
                listKeranjang: listKeranjang.map((x,i ) => {
                    x.index = i
                    return x
                })
            }, () => {
                this.props.updateKeranjang(listKeranjang)
            })
        }
           
    }
    editKeranjang = (index) => {
        let listKeranjang = this.state.listKeranjang;
        let myList    = listKeranjang[index];
        let typeForm  = myList["info_keranjang"]["type_form"];
        if (typeForm == "pelatihan/sertifikasi") {
            this.setState({
                pelatihan: myList,
                indexEdit: index,
                backup: JSON.stringify(myList)
            }, () => {
                this.props.openModal("edit_form", { kode_layanan: myList['kode_layanan'] });
            })
        }else if (typeForm == "penunjang") {
            this.setState({
                penunjang: myList,
                indexEdit: index,
                backup: JSON.stringify(myList)
            }, () => {
                this.props.openModal("edit_form", { kode_layanan: myList["kode_layanan"] });
                
            })
        }
    }

    detailKeranjang = (index) => {
      //  console.log(this.state.listKeranjang[index])
        this.setState({
            detail: this.state.listKeranjang[index]
        }, () => {
            this.props.openModal("detailKeranjang")
        })
    }
    handleFormPelatihan = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let pelatihan = this.state.pelatihan;
        let form_peserta = this.state.form_peserta;
        if (name == "tgl_awal" || name == "tgl_akhir") {
            pelatihan["info_keranjang"][name] = value;
        }else if (name === "tarif_keranjang") {
            let val = `${value}`.split(".");
         //   console.log(val)
            pelatihan[name] = (val.length > 1) ? numberFormat(val[0].replaceAll(",", "")) + "." + val[1] : numberFormat(val[0].replaceAll(",", ""));
            form_peserta["biayaPeserta"] = pelatihan[name];
        }else{
            pelatihan[name] = value;
        }
        this.setState({
            pelatihan: pelatihan
        })
    }
    handleFormPenunjang = (e) => {
        const name  = e.target.name;
        const value = e.target.value;
        let penunjang = this.state.penunjang;
        if (name === "tgl_awal" || name === "tgl_akhir" || name === "nomor_fasilitas") {
            penunjang["info_keranjang"][name] = value;
        }else if (name === "tarif_keranjang") {
            let val = `${value}`.split(".");
           penunjang[name] = (val.length > 1) ? numberFormat(val[0].replaceAll(",", "")) + "." + val[1] : numberFormat(val[0].replaceAll(",", ""));
        }else if (name === "kuantitas") {
            penunjang[name] = isNaN(parseInt(value)) ? 0 : parseInt(value);    
            
        }else{
            penunjang[name] = value;
        }

        if (name === "tarif_keranjang" || name === "kuantitas") {
            let hitung = `${parseFloat(`${penunjang["tarif_keranjang"]}`.replaceAll(",","")) * parseInt(penunjang["kuantitas"])}`;
            penunjang["jumlah_keranjang"] = numberFormat(`${parseFloat(`${hitung}`).toFixed(2)}`, true);
            
        }
        this.setState({
            penunjang: penunjang
        })
    }
  
    pushPeserta = () => {
        let form_peserta = this.state.form_peserta;
        if(form_peserta.namaPeserta !== "" && form_peserta !== "") {
            let pelatihan = this.state.pelatihan;
            pelatihan['info_keranjang']['list_peserta'].push(JSON.parse(JSON.stringify(form_peserta)))
            let d   =  pelatihan['info_keranjang']['list_peserta'].map((x,i) => {
                return { ...x, index:i }
            });
            pelatihan['info_keranjang']['list_peserta'] = d;
            let jumlah_keranjang = 0
            for(let p of pelatihan.info_keranjang.list_peserta){
                jumlah_keranjang += parseInt(p.biayaPeserta.replaceAll(",", ""))
            }
         
            pelatihan["jumlah_keranjang"] = jumlah_keranjang
            this.setState({
                pelatihan: pelatihan,
            },() => {
                   form_peserta['namaPeserta'] = "";
               // form_peserta['biayaPeserta'] = "";
                this.setState({
                formPeserta: form_peserta

                },() => {
                    document.getElementById("nama_peserta").focus();
                })
            })
        }
    }
    deletePeserta = (index) => {
        let pelatihan = this.state.pelatihan;
        pelatihan['info_keranjang']['list_peserta'].splice(index, 1);
        let jumlah_keranjang = 0
        for(let p of pelatihan.info_keranjang.list_peserta){
            jumlah_keranjang += parseInt(p.biayaPeserta.replaceAll(",", ""))
        }
     
        pelatihan["jumlah_keranjang"] = jumlah_keranjang
        this.setState({
            pelatihan: pelatihan
        })
    }
   
    handleEditPeserta = (index, e) => {
     //   console.log(index)
         let pelatihan = this.state.pelatihan;
         let get_info  = pelatihan['info_keranjang']['list_peserta'][index];
         let val = e.target.value;
         if (e.target.name === "biayaPeserta") {
            get_info[e.target.name] = numberFormat(`${val}`.replaceAll(",", ""));
            //console.log(numberFormat(`${val}`.replaceAll(",", "")))
            pelatihan['info_keranjang']['list_peserta'][index] = get_info;
            let jumlah_keranjang = 0;
            for(let p of pelatihan.info_keranjang.list_peserta){
                jumlah_keranjang += `${p.biayaPeserta}`.replaceAll(",", "")
            }
            
            pelatihan['jumlah_keranjang'] = jumlah_keranjang;
         }else{
            get_info[e.target.name] = e.target.value;
        //    console.log(pelatihan['info_keranjang']['list_peserta'][index], index)
            pelatihan['info_keranjang']['list_peserta'][index] = get_info;

         }
       
         this.setState({
             pelatihan: pelatihan
         }, () =>{
        
          
         })
    }
    handleFormPeserta= (e) => {

        if (e.target.name === "biayaPeserta") {
            // let form_peserta = this.state.form_peserta;
            // const value     = e.target.value;
            // //console.log(typeof value)
            // let intVal      = (typeof value == "number") ?  value : value.replaceAll(",", "");
            // if (typeof parseInt(intVal) === "number") {
            //     form_peserta[e.target.name] = numberFormat(value);
            //     this.setState({
            //         form_peserta: form_peserta
            //     })
            // }
        }else{
              let form_peserta = this.state.form_peserta;

                form_peserta[e.target.name] = e.target.value;
            this.setState({
                formPeserta: form_peserta
            })
        }
  
    }
    onCancelModal = () => {
        let backup = this.state.backup;
        if (backup === null) {
            this.props.closeModal("form");
            this.setState({
                indexEdit: -1,
                backup: null,
                pelatihan: {
                    "kode_layanan": "",
                    "uraian_keranjang": "",
                    "kuantitas" : 0,
                    "tarif_keranjang" : 0,
                    "jumlah_keranjang" : 0,
                    "info_keranjang" : {
                        type_form: "",
                        tgl_awal: "",
                        tgl_akhir: "",
                        list_peserta: []
                    }
                },
                penunjang: {
                    "kode_layanan": "",
                    "uraian_keranjang": "",
                    "kuantitas": 0,
                    "tarif_keranjang": 0,
                    "jumlah_keranjang": 0,
                    "info_keranjang": {
                        type_form: "",
                        tgl_awal: "",
                        tgl_akhir: "",
                        nomor_fasilitas: ""
                    }
                },
            })
        }else{
            backup   = JSON.parse(this.state.backup);

            let listKeranjang = this.state.listKeranjang;
            listKeranjang[this.state.indexEdit] = backup;
            this.setState({
                listKeranjang: listKeranjang.map((x,i) => {
                    x.index = i
                    return x
                }),
                backup: null,
                indexEdit: -1,
                pelatihan: {
                    "kode_layanan": "",
                    "uraian_keranjang": "",
                    "kuantitas" : 0,
                    "tarif_keranjang" : 0,
                    "jumlah_keranjang" : 0,
                    "info_keranjang" : {
                        type_form: "",
                        tgl_awal: "",
                        tgl_akhir: "",
                        list_peserta: []
                    }
                },
                penunjang: {
                    "kode_layanan": "",
                    "uraian_keranjang": "",
                    "kuantitas": 0,
                    "tarif_keranjang": 0,
                    "jumlah_keranjang": 0,
                    "info_keranjang": {
                        type_form: "",
                        tgl_awal: "",
                        tgl_akhir: "",
                        nomor_fasilitas: ""
                    }
                },
            }, () => {
                this.props.closeModal("form");
            })

        }
       
      
    }
    refreshToInsert = ()=> {
        this.setState({
            indexEdit: -1
        }, () => {
            this.props.openModal("produk");
        })
    }
    render(){
        const { penunjang, pelatihan, loader, form_peserta, formStatePelatihan, listKeranjang, detailType, detail, formStatePenunjang } = this.state;
        const { myModalForm, produk_selected, closeModal, myDetailForm, statusNilai, statusTarif, tipe_transaksi } = this.props;
        let typeForm = "";

        if (produk_selected.info_layanan && produk_selected.info_layanan !== "") {
            typeForm = JSON.parse(produk_selected.info_layanan)["tipe_form"];
        }
        let disabledNilai = (statusNilai === true && (tipe_transaksi === "perorangan" || tipe_transaksi === "kedinasan"))
        let disabledTarif = !(statusTarif === true && tipe_transaksi === "perorangan" )
        const { onCancelModal,handleEditPeserta, handleFormPenunjang, detailKeranjang, addKeranjang, handleFormPelatihan, pushPeserta, deletePeserta, handleFormPeserta, deleteKeranjang, editKeranjang, setTarif } = this;
        return(
            <div>
            <div className="mb-2 text-right">
                <button onClick={ () =>  this.refreshToInsert()} className="bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white rounded-md p-2 shadow-md">
                    <FontAwesomeIcon icon={faPlus} /> Keranjang
                </button>
            </div>
                    <TableKeranjang deleteKeranjang={ deleteKeranjang } editKeranjang={ editKeranjang } detailKeranjang={ detailKeranjang } DataKeranjang={ listKeranjang } />
                   <Modal
                        Title = {"Form " + typeForm}
                        Body  = {
                            (typeForm == "pelatihan/sertifikasi") ? 
                                <Pelatihan 
                                    handleForm  = {handleFormPelatihan}
                                    formState   = {formStatePelatihan}
                                    DataPeserta = {pelatihan.info_keranjang.list_peserta}
                                    myForm      = {pelatihan}
                                    pushPeserta = {pushPeserta}
                                    deletePeserta = {deletePeserta}
                                    listPeserta = { pelatihan.info_keranjang.list_peserta }
                                    handleFormPeserta={handleFormPeserta}
                                    form_peserta={form_peserta} 
                                    editPeserta = { handleEditPeserta }
                                    setTarif ={ setTarif }
                                    statusNilai = {disabledNilai}
                                    statusTarif ={ disabledTarif }
                                /> 
                                : 
                            (typeForm == "penunjang") ? 
                                <Penunjang 
                                    handleForm={ handleFormPenunjang }
                                    myForm={ penunjang }
                                    formState={formStatePenunjang}
                                    setTarif = { setTarif }
                                    statusNilai = {disabledNilai}
                                    statusTarif ={ disabledTarif }
                                /> 
                                : null
                            }
                        Footer = {
                            <ModalFooter 
                                closeModal = { () => onCancelModal() } 
                                loader     = { loader } 
                                onSubmit   = { addKeranjang } 
                            />
                        }
                        closeModal = {() => onCancelModal()}
                        isOpen = {myModalForm}
                        Container = "max-w-5xl bg-white"
                    />

                    <Modal
                        Title = "Detail Keranjang"
                        Body  = { <FormDetail detailType={ detailType }  closeModalForm={() => closeModal("detailKeranjang")} detail={detail} />  }
                        Footer = {""}
                        closeModal = {() => closeModal("detailKeranjang")}
                        isOpen = {myDetailForm}
                        Container = "max-w-5xl bg-white"
                    />
            </div>
        )
    }
}

const TableKeranjang =  ({ deleteKeranjang, editKeranjang, detailKeranjang, DataKeranjang }) => {

  const Thead = () => {
    return (
      <thead>
        <tr>
            <td className=" border-2 border-gray-300 p-2 w-20 text-center">No </td>
            <td className=" border-2 border-gray-300 p-2 text-center">Kode/Uraian/Tipe</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Kuantitas/Tarif/Jumlah</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Tgl Awal/Tgl Akhir</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Aksi</td>
        </tr>
      </thead>
      
    )
  }
    //console.log(DataKeranjang)
  return (
    <div>
        <Datatable 
        
          Head={Thead}
          Data={DataKeranjang}
          Entries={[]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
            return (
              <tr key={ index }>
                <td className={"border-2 p-2 border-gray-300 align-top text-center" + mod}>
                    <div className="w-[50px] text-center">
                        {row.index+1}    
                    </div>
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top " + mod} width="40%">
                    <ChipTable icon={<FontAwesomeIcon icon={faCode} />} text={row.kode_layanan} />
                    <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={row.uraian_keranjang} />
                    <ChipTable icon={<FontAwesomeIcon icon={faChalkboard} />} text={row.info_keranjang.type_form} />
              
                </td>
                
                <td className={"border-2 p-2 border-gray-300 align-top" + mod} width="40%">
                    <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={row.kuantitas} />
                    <ChipTable icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />} text={row.tarif_keranjang} />
                    <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={row.jumlah_keranjang} />

                      
                </td>
                <td className={"border-2 p-2 border-gray-300 align-top" + mod} width="40%">
                    <ChipTable icon={<FontAwesomeIcon icon={faCalendar} />} text={row.info_keranjang.tgl_awal.split("-").reverse().join("-")} />
                    <ChipTable icon={<FontAwesomeIcon icon={faCalendar} />} text={row.info_keranjang.tgl_akhir.split("-").reverse().join("-")} />

                      
                </td>
             
                <td className={"border-2 border-gray-300 p-2 align-top text-center " + mod}>
                    <div className="w-[150px]">
                        <button onClick={() => detailKeranjang(row.index)} className="active:shadow-none bg-white ml-2  active:bg-gray-300 hover:bg-gray-200 border-gray-400 border-2 shadow-md text-gray-400 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faEye} />
                        </button> 

                        <button onClick={() => editKeranjang(row.index)} className="active:shadow-none bg-white ml-2  active:bg-orange-300 hover:bg-orange-200 border-orange-400 border-2 shadow-md text-orange-400 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faEdit} />
                        </button> 
                        <button onClick={() => deleteKeranjang(row.index)} className="active:shadow-none bg-white ml-2  active:bg-red-300 hover:bg-red-200 border-red-400 border-2 shadow-md text-red-400 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faTrash} />
                        </button> 
                    </div>
                    
                </td>
              </tr>
            )
          }}
        />
    </div>
  )
}




const Penunjang = ({ handleForm, myForm, formState, setTarif, statusNilai, statusTarif }) => {
    console.log(statusTarif)
    useEffect(() => {
        if (myForm.uraian_keranjang === "") {
            setTarif();
        }
        
        return () => {
            
        };
    }, []);
    return (
        <div className="p-3">
           <TextArea other={{ onChange: handleForm, value: myForm.uraian_keranjang }} Title="Uraian" Placeholder = "Uraian" Name="uraian_keranjang" Status={ formState.uraian_keranjang } />
            <br />
            <InputText other={{ onChange: handleForm, value: parseInt(myForm.kuantitas) }} Title="Jumlah Fasilitas"  Placeholder = "Jumlah Fasilitas" Name="kuantitas" Status={ formState.kuantitas } />
            <br />
            <InputText other={{ readOnly: statusTarif === false ? statusTarif : statusNilai, onChange: handleForm, value: myForm.tarif_keranjang }} Title="Tarif Fasilitas" Placeholder = "Tarif Fasilitas" Name="tarif_keranjang" Status={ formState.tarif_keranjang } />
            <br />
            <InputText InputClass="opacity-60" other={{ value: myForm.jumlah_keranjang, disabled: true }} Title="Biaya Fasilitas" Placeholder = "Biaya Fasilitas" Name="jumlah_keranjang" Status={ formState.jumlah_keranjang } />
            <br />

            <InputText other={{ onChange: handleForm, value: myForm.info_keranjang.nomor_fasilitas }} Title="Nomor Fasilitas" Placeholder = "Nomor Fasilitas" Name="nomor_fasilitas" Status={ formState.nomor_fasilitas } />
            <br />
            
            <br />
            <div className="grid grid-cols-2 gap-2">
                <div>
                    <InputText other={{ onChange: handleForm, value: myForm.info_keranjang.tgl_awal }} Type="date"  Title="Tanggal Awal" Placeholder = "Tanggal Awal" Name="tgl_awal" Status={formState.tgl_awal}/>
                
                </div>
                <div>
                    <InputText other={{ onChange: handleForm, value: myForm.info_keranjang.tgl_akhir }} Type="date" Title="Tanggal Akhir" Placeholder = "Tanggal Akhir" Name="tgl_akhir" Status={ formState.tgl_akhir } />
                </div>
            </div>
        </div>
    )
}

const Pelatihan = ({ form_peserta, pushPeserta, listPeserta, deletePeserta, DataPeserta, handleFormPeserta, myForm, formState, handleForm, editPeserta, setTarif, statusNilai }) => {
    useEffect(() => {
        if (myForm.uraian_keranjang === "") {
            setTarif();
        }
        return () => {
            
        };
    }, []);
    const handleKeyPress = (e)  => {
        if (e.key === "Enter") {
            pushPeserta()
        }
    }
   
    const { namaPeserta, biayaPeserta } = form_peserta;
    return (
        <div className="p-2">
        
        
         <InputText other={{ onChange: handleForm, value: myForm.uraian_keranjang }} Title="Uraian Keranjang" Placeholder = "Uraian Keranjang" Name="uraian_keranjang" Status={formState.uraian_keranjang} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
         <InputText other={{ disabled: statusNilai, onChange: handleForm, value: myForm.tarif_keranjang }} Title="Tarif Keranjang" Placeholder = "Uraian Keranjang" Name="tarif_keranjang" Status={formState.tarif_keranjang} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
 
            <div className="flex">
                <div className="w-1/2 flex justify-between">
                
                    <div>
                        <InputText Type="date" other={{ onChange: handleForm, value: myForm.info_keranjang.tgl_awal, id: "_tgl_awal" }} Title="Tanggal Awal" Name="tgl_awal"  Status={formState.tgl_awal} TitleClass=" " elementClass=" mb-2" InputClass=" text-md" />
                    </div>
                    <div className="text-center pt-6">
                        s.d
                    </div>
                    <div>
                        <InputText Type="date" other={{ onChange: handleForm, value: myForm.info_keranjang.tgl_akhir, id: "_tgl_akhir" }} Title="Tanggal Akhir"  Name="tgl_akhir"  Status={formState.tgl_akhir} TitleClass=" text-md" elementClass=" mb-2" InputClass=" text-md" />
                    </div>
                </div>

                <div className="w-1/2 flex">
                    <div className="w-[30%]">
                    </div>
                 <table className="text-[14px]" width="70%">
                        <thead>
                            <tr>
                                <td className="border-2 pt-2 pb-2 text-center bg-gray-100 ">Jumlah Peserta</td>
                                <td className="border-2 pt-2 pb-2 text-center bg-gray-100">Jumlah Biaya</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border-2 p-2 text-center">{DataPeserta.length}</td>
                                <td className="border-2 p-2">Rp. {numberFormat(`${myForm.jumlah_keranjang}`)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div className="grid grid-cols-4 gap-2">
                <div>
                    <InputText Title="Nama Peserta" Placeholder = "Nama Peserta" Name="namaPeserta" Status="" TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " other={ { id: "nama_peserta", value: namaPeserta, onKeyPress: handleKeyPress, onChange: handleFormPeserta} }/>
                </div>
                <div>
                    <InputText Title="Biaya Peserta" Placeholder = "Biaya Peserta" Name="biayaPeserta" Status="" TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " other={{ id: "biaya_peserta", value: biayaPeserta, onChange: handleFormPeserta, onKeyPress: handleKeyPress, disabled: true }} />
                </div>
                <div className="text-center w-5 pl-5 pt-5">
                    <button onClick={pushPeserta} className="rounded-full bg-blue-500 hover:bg-blue-400 active:bg-blue-600 shadow-md active:shadow-none w-10 h-10 text-md text-white">
                        <FontAwesomeIcon  icon={faPlus}/>
                    </button>
                </div>
              
            </div>
            <TablePeserta statusNilai={statusNilai} DataPeserta={DataPeserta} listPeserta = { listPeserta } deletePeserta = { deletePeserta } editPeserta={ editPeserta } />
        </div>
    )
}



const TablePeserta =  ({ openDeleteForm, DataPeserta, openDetailPelanggan, editForm, deletePeserta, editPeserta, statusNilai }) => {

  const theadPelanggan = ({ sort, order_by }) => {
    return (
      <thead>
        
        <tr>
            <td className=" border-2 border-gray-200 p-2 w-20 text-center">No </td>
            <td className=" border-2 border-gray-200 p-2 text-center">Nama Peserta </td>
            <td className=" border-2 border-gray-200 p-2 text-center">Biaya Peserta</td>
            <td className=" border-2 border-gray-200 p-2 text-center">Aksi</td>
        </tr>
       
      </thead>
      
    )
  }

  return (
    <div>
        <Datatable 
        
          Head={theadPelanggan}
          Data={DataPeserta}
          Entries={[]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
            return (
              <tr key={ index }>
                <td className={"border-2 p-2 border-gray-300 align-top text-center"}>
                    <div className="w-[50px] text-center">
                        {index+1}    
                    </div>
                </td>
                <td className={"border-2 p-2 border-gray-300 align-top "} width="40%">
                    <input type="text" className="pl-2 rounded-md pt-2 pb-2 bg-gray-200 w-full " name="namaPeserta" value={row.namaPeserta} onChange={(e) => editPeserta(row.index, e)}  />
      
                </td>
                
                <td className={"border-2 p-2 border-gray-300  align-top"} width="40%">
                    <input type="text" className="pl-2 rounded-md pt-2 pb-2 bg-gray-200 w-full " name="biayaPeserta" value={ numberFormat(`${row.biayaPeserta}`)} onChange={(e) => editPeserta(row.index, e)} disabled={statusNilai } />
                      
                </td>
             
                <td className={"border-2 p-2 border-gray-300 align-top text-center "}>
                    <div className="w-[150px]">
                        <FontAwesomeIcon className="text-red-500 text-xl mt-1" icon={faTimes} onClick={() => deletePeserta(row.index)} />
                    </div>
                    
                </td>
              </tr>
            )
          }}
        />
    </div>
  )
}