import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getBidang, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true


export const getAllTransaksi = async () => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiFilterBidang = async ({ bidang }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/bidang/" + bidang)
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiFilterPelanggan = async ({ id_pelanggan }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/pelanggan/" + id_pelanggan, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiFilterProgress = async ({ progress }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/progress/" + progress, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getAllTransaksiFilterBidangProgress = async ({ progress, bidang }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/bidang/" + bidang + "/progress/" + progress, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}


export const getOneTransaksi = async ({ id_transaksi }) => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/transaksi/detail/" + id_transaksi)
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const getKeranjangTransaksi = async ({ id_transaksi }) => {

  const reqData = await axios.get( backEndUrl() + "/penerimaan/keranjang/" + id_transaksi, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}

export const addKeranjang = async ({ id_transaksi, kode_layanan, uraian_keranjang, kuantitas, tarif_keranjang,jumlah_keranjang, info_keranjang }) => {
 
    const values = {
        "id_transaksi": id_transaksi,
        "kode_layanan": kode_layanan,
        "uraian_keranjang": uraian_keranjang,
        "kuantitas": parseInt(kuantitas),
        "tarif_keranjang": parseFloat(`${tarif_keranjang}`.replaceAll(",", "")),
        "jumlah_keranjang": parseFloat(`${jumlah_keranjang}`.replaceAll(",", "")),
        "info_keranjang": JSON.stringify(info_keranjang),
        "uuid": uuidv4()
    }
    //// console.log(values)
    const reqData = await axios.post( backEndUrl() + "/penerimaan/keranjang", values, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}


export const deleteKeranjangTransaksi = async ({ id_keranjang }) => {
  
        return await axios.delete( backEndUrl() + "/penerimaan/keranjang/delete/" + id_keranjang , getCred())
         .then((res) => {
            return res.data
         }).catch((err) => {
                return {
                    status: 500,
                    messages: err.response 
                }
            });
   
}
export const updateItemKeranjang = async (keranjang, id_keranjang) => {
    const attrKeranjang = ["kode_layanan", "uraian_keranjang", "kuantitas", "tarif_keranjang", "jumlah_keranjang", "info_keranjang"];
    
    let jumlah_transaksi = 0;
  
        let obj = {}
        let d   = keranjang;
        for(let s of attrKeranjang){
            if(s === "kuantitas") {
                obj[s] = parseInt(d[s]);
            }else if (s === "tarif_keranjang" || s === "jumlah_keranjang") {
                obj[s] = parseFloat(d[s]);
            }else if (s === "info_keranjang") {
                obj[s] = JSON.stringify(d[s]);
            }else{
                obj[s] = d[s];
            }
        }
        jumlah_transaksi += parseInt(d.jumlah_keranjang)
        obj["id_keranjang"] = id_keranjang;
        
    const req = await axios.put(backEndUrl() + "/penerimaan/keranjang", obj, getCred())
           .then((res) => {
            return res.data
         }).catch((err) => {
                return {
                    status: 500,
                    messages: err.response 
                }
            });
    return req;
}