import { Component, Fragment } from 'react';
import { SmallNavbar } from '../../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort,faList, faPrint, faChevronRight, faUser,faBuilding, faCoins, faChartLine, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Card, ChipTable } from '../../components/Card';
import { NavLink }   from 'react-router-dom';
import PieChart     from '../../components/Graph/PieChart';
import Datatable from '../../components/Datatable';
import { InputText } from '../../components/Input';
import { getOnePelanggan } from '../../api/Pelanggan';
import { getAllTransaksiFilterPelanggan } from '../../api/Transaksi';
import { Toast as ToastComponent } from '../../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import { isJson, numberFormat } from '../../api/Value';
import { groupSum, getGlobalBiayaBank } from '../../api/Laporan';
export default class Penerimaan extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            pelanggan: {},
            terbayar: 0,
            belum_dibayar: 0,
            frekuensi_va: 0,
            biaya_bank: 0,
            net: 0,
            total: 0
        }
    }
    componentDidMount  = () => {
        const url = new URL(window.location.href);
        const pathname = url.pathname.split("/");
        this.detailPelanggan(pathname[pathname.length-1]);
        localStorage.setItem("BACK", JSON.stringify({ to: url.pathname, title: "Detail Transaksi Pelanggan", from:"report_pelanggan", before: { to: "/app/laporan/pelanggan", title: "Pelanggan" } }));

    }
    detailPelanggan     = async  (id_pelanggan) => {
        let data = {
            pelanggan: {},
            data: [],
            terbayar: 0,
            belum_dibayar: 0,
            biaya_bank: 0,
            frekuensi_va: 0,
            net: 0,
            total: 0,
            biaya_va: 0
        }
        await getOnePelanggan({ id_pelanggan }).then((res) =>{
           // console.log(res)
            if (res.status === 200) {
                data['pelanggan'] = (res.message.data === null) ? [] : res.message.data;
                data['pelanggan'] = { ...data['pelanggan'], ...JSON.parse(data['pelanggan']['user_info']) };
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Get One Pelanggan Gagal" bg="red"/>
                })
            }
        })
        await getAllTransaksiFilterPelanggan({ id_pelanggan }).then((res) =>{
            if (res.status === 200) {
                
                data['data'] = (res.data === null) ? [] : res.data.map((x) => {
                    // if (x.dt_transaksi_lunas === "0001-01-01T00:00:00Z") {
                    //     data['jumlah_piutang'] += x.jumlah_transaksi
                    // } else{
                    //     data['jumlah_pembayaran'] += x.jumlah_transaksi

                    // }   
                  //  data['total'] += x.jumlah_transaksi;
                    
                    x.history = isJson(x.history);
                    x.info_pelanggan = isJson(x.info_pelanggan);
                    x.info_transaksi = isJson(x.info_transaksi);
                    x.user_pembuat = isJson(x.user_pembuat);
                    x.nama_pelanggan = x.info_pelanggan.nama;
                    x.pembuat = x.user_pembuat.nama;
                    return x
                });
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Get All Transaksi Pelanggan Gagal" bg="red"/>
                })
            }
        })

        await getGlobalBiayaBank().then(res => {
            if (res.status === 200) {
                // data['terbayar']= 0;
                data['biaya_va']      = parseInt(res.data.nilai)
                
             }else if (res.status === 500) {
                 console.log(res);
                 toast.custom(t => {
                     return <ToastComponent param={t} message="Laporan Statistik Pelanggan Gagal Diambil" bg="red" />
                 })
             }
        })
        await groupSum({ id_pelanggan }).then(res => {
            if (res.status === 200) {
                data['total']       =res.data.total_transaksi.Float64;
               data['terbayar']      = res.data.total_pembayaran.Float64
               data['belum_dibayar'] = res.data.total_transaksi.Float64 - data['terbayar'];
               data['biaya_bank']    = data['biaya_va'] * res.data.frekuensi_va.Int32;
               data['frekuensi_va']  = res.data.frekuensi_va.Int32
               data['net']           = data['terbayar'] - data['biaya_bank']
         
            }else if (res.status === 500) {
                console.log(res);
                toast.custom(t => {
                    return <ToastComponent param={t} message="Laporan Statistik Pelanggan Gagal Diambil" bg="red" />
                })
            }
        })
        this.setState(data);
    //    console.log(data)
    }
    componentWillUnmount = () => {
      
    }
    openDeleteForm = () => {

    }
    render(){
        const { openDeleteForm } = this;
        const { pelanggan, data, terbayar, belum_dibayar, biaya_bank, total, biaya_va, frekuensi_va, net }     = this.state;
        const pieData = {
            labels: ['Terbayar Netto', 'Belum Dibayar'],
            datasets: [
                    {
                    label: 'Dataset 1',
                    data: [net, belum_dibayar],
                    backgroundColor: [
                        "rgb(59, 130, 246)",
                        "rgb(249, 115, 22)"
                    ],
                    }
                ]
            };
        return (
            <div className="p-5 animate-fadein">
                <Toaster position="bottom-right"/>
                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2 flex" >
                                <div className=""> Laporan  </div>
                                <div className="pl-2 pt-[0.3px]"> <FontAwesomeIcon icon={faChevronRight} /> </div>
                                <NavLink to="/app/laporan/pelanggan">
                                <div className="pl-2 cursor-pointer"> 
                                    <span className="border-b-2 border-b-white hover:text-gray-100">
                                    Pelanggan 
                                    </span>
                                </div>
                                </NavLink>
                                <div className="pl-2 pt-[0.3px]"> <FontAwesomeIcon icon={faChevronRight} /> </div>
                                <div className="pl-2"> { pelanggan.nama }  </div>

                            </div>
                        </Fragment>}
                />
                <Card>
                    <div className="grid md:grid-cols-2">
                    
                        <div>
                            <div className="overflow-x-auto">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td valign="top" className="p-2  bg-gray-100">Nama Pelanggan</td>
                                        <td valign="top" className="p-2  bg-gray-100">:</td>
                                        <td valign="top" className="p-2 bg-gray-100">{pelanggan.nama}</td>
                                    </tr>
                             
                                    
                                    <tr>
                                        <td valign="top" className="p-2">CP Pelanggan </td>
                                        <td className="p-2">:</td>

                                        <td valign="top" className="p-2">{pelanggan.cp_konsumen}</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" className="p-2 bg-gray-100">CP Internal </td>
                                        <td className="p-2 bg-gray-100">:</td>

                                        <td valign="top" className="p-2 bg-gray-100">{pelanggan.telp}</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" className="p-2">Email </td>
                                        <td className="p-2">:</td>

                                        <td valign="top" className="p-2">{pelanggan.email}</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" className="p-2 bg-gray-100">Total Transaksi </td>
                                        <td className="p-2 bg-gray-100">:</td>
                                        <td valign="top" className="p-2 bg-gray-100">Rp. { numberFormat(`${total}`) }</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" className="p-2">Terbayar </td>
                                        <td className="p-2 ">:</td>
                                        <td valign="top" className="p-2 ">Rp. { numberFormat(`${terbayar}`) }</td>
                                    </tr>
                               
                                    <tr>
                                        <td valign="top" className="p-2 bg-gray-100">Biaya Bank ({biaya_va} x {frekuensi_va} Pembayaran) </td>
                                        <td className="p-2 bg-gray-100">:</td>
                                        <td valign="top" className="p-2 bg-gray-100">Rp. { numberFormat(`${biaya_bank}`) }</td>
                                    </tr>

                                    <tr>
                                        <td valign="top" className="p-2 ">Terbayar Netto </td>
                                        <td className="p-2">:</td>
                                        <td valign="top" className="p-2">Rp. { numberFormat(`${net}`) }</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" className="p-2 bg-gray-100">Belum Dibayar </td>
                                        <td className="p-2 bg-gray-100">:</td>

                                        <td valign="top" className="p-2 bg-gray-100">Rp. { numberFormat(`${belum_dibayar}`)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                          
                        
                        </div>
                        <div className="p-2 "> 
                            <center>
                                <div className="w-80">
                                    <PieChart data={pieData} />                       
                            
                                </div>
                            </center>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <TableTransaksi DataRender={data} />
                </Card>
            </div>
        )
    }
}
 
const Export  = ({ exportFile }) => {
    return (
        <div>
          <button onClick={() => exportFile("print", "Pelanggan",  "id_transaksi,uraian,nama_pelanggan,bidang,jumlah_transaksi,progress_transaksi,pembuat")} className="hover:bg-gray-400 active:bg-gray-600 active:shadow-none bg-gray-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Print</button>
          <button onClick={() => exportFile("excel", "Pelanggan", "id_transaksi,uraian,nama_pelanggan,bidang,jumlah_transaksi,progress_transaksi,pembuat")} className="hover:bg-teal-400 active:bg-teal-600 active:shadow-none border-teal-600 bg-teal-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Excel</button>
        </div>
    )
}
const TableTransaksi = ({  DataRender }) => {
 
 const TheadComponent = ({ sortData }) => {
   return (
     <thead>
       <tr>
           <td className=" border-2  border-gray-300 p-2 w-20 text-center" >ID</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Uraian/Bidang/Pembuat</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Pelanggan/Jumlah/Progress/Penolakan</td>
  
          
       </tr>
     </thead>
     
   )
 }
 return (
   <div>
       <Datatable 
         Head={TheadComponent}
         Data={DataRender}
         Entries={[]}
         Export = {Export}
         Body={(row, index) => {
           const mod = (index%2 == 0) ? " bg-gray-100 " : "";
           let user_info =row.user_pembuat ;
           let history = row.history;
           let user_pembuat = row.info_pelanggan;
    
           return (
             <tr key={ index }>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                   { row.id_transaksi } 
               </td>
               <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="45%">
                   <NavLink to={"/app/detail_transaksi/" + row.id_transaksi}>
                   <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={<span className="cursor-pointer underline text-blue-500">{row.uraian}</span>} />
                   </NavLink>
                       <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={row.bidang} />
                       <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={user_pembuat.nama} />

               </td>
               <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="45%">
                       <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={<span>{user_info.nama}</span>} />

                   <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.jumlah_transaksi}`)} />
                   <ChipTable customCard={(row.progress_transaksi === "lunas") ? "bg-green-300" : "bg-yellow-300"} icon={<FontAwesomeIcon icon={faChartLine} />} text={row.progress_transaksi} />
                   <ChipTable icon={<FontAwesomeIcon icon={faTimes} />} text={

                       (row.progress_transaksi.indexOf("ditolak") !== -1) ? 
                       <div className="text-red-600">
                            <span className="font-bold">{ 
                                history[history.length-1]["username"] } ({ history[history.length-1]["role"] }) : </span>
                            { history[history.length-1]["alasan"]}
                        
                        </div>

                       : 
                       null
                   } />

               </td>
           
              
             </tr>
           )
         }}
       />
   </div>
 )
}
