export default () => {
    return (
        <div className="p-5 text-center pt-32">
            <div className="text-6xl mb-3 font-bold">
                403
            </div>
            <div>
                Akses Ditolak     
            </div>
        </div>
    )
}