import { useState, Component, Fragment } from 'react';
import { ChipTable } from '../../../components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faChevronUp, faSms } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { Disclosure } from '@headlessui/react'
import Datatable from '../../../components/Datatable';
import { getNotifikasiTransaksi } from '../../../api/Transaksi';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../../components/Toast';
import { backEndUrl } from '../../../config/Config';
import Modal from '../../../components/Modal'
import { toDateTime } from '../../../api/Value';

library.add(fab)

 class Notif extends Component{
    constructor(props){
        super(props);
        this.state = {
            data: [],
            showModalPdf: false,
             attachment: ""
        }
    }
    componentDidMount = () => {
        const myForm = this.props.myForm;
        this.getData(myForm.transaksi.id_transaksi);
        
    } 
    getData = (id_transaksi) => {
        const self = this;
        getNotifikasiTransaksi({ id_transaksi: id_transaksi })
        .then((res) => {
            if (res.status === 200) {
                self.setState({
                    data: (res.data === null) ? [] : res.data.map((x) => {
                        let attachment = x.attachment.split("/")
                            attachment = attachment[attachment.length -1];
                        x.attachment = backEndUrl() + "/penerimaan/files/" + attachment
                        return x
                    })
                }, () => {
         //           console.log(res.data)
                })
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Gagal Mendapatkan Data Notifikasi" bg="red"/>
                })
            }
        })
    }
    handleModal = (attachment) => {
        
        this.setState({
            showModalPdf: true,
            attachment: attachment
        })
    }
    hideModalPdfPreview = () => {
        this.setState({
            showModalPdf: false
        })
    }
    render(){
        const { handleModal, hideModalPdfPreview } = this;
        const { data, showModalPdf, attachment } = this.state;
         return (
            <div>
                 <Toaster position="bottom-right"/>
                {
                    data.map((x, i) => {
                        if (x.via === "sms") {
                            return (
                                <div key={i}>
                                    <Sms { ...x }  />
                                </div>
                            )
                        }else if (x.via === "email") {
                            return (
                                <div key={i}>
                                    <Email { ...x } openInvoice={ handleModal } />
                                </div>
                            )
                        }else if (x.via === "whatsapp") {
                            return(
                                <div key={i}>
                                    <WhatsApp />
                                </div>
                            )    
                        }else{
                            return null
                        }
                    })
                }
                <Modal 
                    isOpen={showModalPdf}
                    Body={<div className="min-h-full	">
                            
                            <iframe width="800" className="min-h-screen	" src={attachment} frameBorder="0"></iframe>
                            
                        </div>}
                    closeModal = {hideModalPdfPreview}
                    Container="max-w-fit -mt-5"
                />
            </div>
         )
     }
 }
export default ({ myForm }) => {
    return (
        <div>
           <Notif myForm={myForm} />
         </div>
       
    )
}

const WhatsApp = () => {
    return (
        <div>
            <div className="flex mb-5">
                <div>
                    <div className="bg-green-500 w-[40px] text-xl text-center pt-1 h-[40px] rounded-full  text-white">
                        <FontAwesomeIcon icon={['fab', 'whatsapp']}/>
                    </div>
                </div>
                <div className="bg-green-100  ml-2 rounded-r-md rounded-b-md">
                    <div className="p-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione voluptatum molestiae deserunt, fugiat similique veniam sed possimus quia voluptates explicabo quas ducimus maiores repudiandae voluptatibus alias incidunt corporis amet et.
                    </div>

                 
                </div>

            </div>
        </div>
    )
}

const Sms = ({ konten,  sendfrom, sendto, dt_created }) => {
   
    return (
        <div className="flex mt-2 mb-2">
            <div>
                <div className="bg-purple-500 w-[40px] text-center pt-2 h-[40px] rounded-full shadow-md text-white">
                    <FontAwesomeIcon icon={faSms}/>
                </div>
            
            </div>
                <div className="bg-purple-50 border-1 border-purple-600  ml-2 rounded-r-md rounded-b-md w-full shadow-md" >
                    <div className="p-2 ">
                        <div  dangerouslySetInnerHTML={{ __html: konten }} />                        
                    </div>
                    <Disclosure>
                        {({ open }) => (
                            <Fragment>
                                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium text-left text-white bg-purple-500 rounded-b-md hover:bg-purple-600 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75">
                                    <span>Detail</span>
                                    <FontAwesomeIcon icon={faChevronUp}
                                    className={`${
                                        open ? 'transform rotate-180' : ''
                                    } w-5 h-5 text-white-500`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="">
                                    <div className="bg-white p-3">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pl-2 pr-2 bg-gray-100 rounded-l-md border-2 border-white">To</td>
                                                
                                                    <td className="pl-2 pr-2">{sendto}</td>
                                                </tr>
                                               
                                                <tr>
                                                    <td className="pl-2 pr-2 bg-gray-100 rounded-l-md border-2 border-white">From</td>
                                                
                                                    <td className="pl-2 pr-2">{sendfrom}</td>
                                                </tr>
                                                <tr>
                                                    <td className="pl-2 pr-2 bg-gray-100 rounded-l-md border-2 border-white
                                                    ">Time</td>
                                                
                                                    <td className="pl-2 pr-2">{ toDateTime(dt_created) }</td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>    
                                </Disclosure.Panel>
                            </Fragment>
                        )}
                    </Disclosure>           
                  
                    
            </div>
        </div>
    )
}

const Email = ({ konten, attachment, sendcc, sendfrom, sendto, dt_created, openInvoice }) => {
   
    return (
        <div className="flex mt-2 mb-2">
            <div>
                <div className="bg-orange-500 w-[40px] text-center pt-2 h-[40px] rounded-full shadow-md text-white">
                    <FontAwesomeIcon icon={faEnvelope}/>
                </div>
            
            </div>
                <div className="bg-orange-50 border-1 border-orange-600  ml-2 rounded-r-md rounded-b-md w-full shadow-md" >
                    <div className="p-2 ">
                        <div  dangerouslySetInnerHTML={{ __html: konten }} />                        
                    </div>
                    <Disclosure>
                        {({ open }) => (
                            <Fragment>
                                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium text-left text-white bg-orange-500 rounded-b-md hover:bg-orange-600 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75">
                                    <span>Detail</span>
                                    <FontAwesomeIcon icon={faChevronUp}
                                    className={`${
                                        open ? 'transform rotate-180' : ''
                                    } w-5 h-5 text-white-500`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="">
                                    <div className="bg-white p-3">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pl-2 pr-2 bg-gray-100 rounded-l-md border-2 border-white">To</td>
                                                
                                                    <td className="pl-2 pr-2">{sendto}</td>
                                                </tr>
                                                <tr>
                                                    <td className="pl-2 pr-2 bg-gray-100 rounded-l-md border-2 border-white">Cc</td>
                                                
                                                    <td className="pl-2 pr-2">{sendcc}</td>
                                                </tr>
                                                <tr>
                                                    <td className="pl-2 pr-2 bg-gray-100 rounded-l-md border-2 border-white">From</td>
                                                
                                                    <td className="pl-2 pr-2">{sendfrom}</td>
                                                </tr>
                                                <tr>
                                                    <td className="pl-2 pr-2 bg-gray-100 rounded-l-md border-2 border-white
                                                    ">Time</td>
                                                
                                                    <td className="pl-2 pr-2">{ toDateTime(dt_created) }</td>
                                                </tr>
                                                <tr>
                                                    <td className="pl-2 pr-2 bg-gray-100 rounded-l-md border-2 border-white
                                                    ">Attachment</td>
                                                
                                                    <td className="pl-2 pr-2">
                                                        <span className="text-blue-500 cursor-pointer" onClick={() => openInvoice(attachment)}>
                                                            {
                                                                (attachment) ?
                                                                 attachment.split("/")[attachment.split("/").length -1]
                                                                : 
                                                                
                                                                null
                                                            }
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>    
                                </Disclosure.Panel>
                            </Fragment>
                        )}
                    </Disclosure>           
                  
                    
            </div>
        </div>
    )
}