import Datatable from '../../components/Datatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faEye, faMapSigns, faPlus, faUser,  faPhone, faMailBulk, faAddressCard, faBuilding, faEdit, faTrash  } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';

export default ({ openDeleteForm, Data_pelanggan, openDetailPelanggan, editForm }) => {

  const theadPelanggan = ({ sort, order_by }) => {
   // console.log(order_by)
    return (
      <thead>
        
        <tr>
            <td className=" border-2 border-gray-300 p-2 w-20 text-center" onClick={() => sort("id_user")} >ID {(order_by.key == "id_user" && order_by.filtered == "asc") ? <FontAwesomeIcon icon={faSortUp}/> : (order_by.key == "-id_user") ? <FontAwesomeIcon icon={faSortDown}/> : <FontAwesomeIcon icon={faSort}/>}</td>
            <td className=" border-2 border-gray-300 p-2 text-center" onClick={() => sort("nama")}>Nama/Alamat/Status {(order_by.key == "nama" && order_by.filtered == "asc") ? <FontAwesomeIcon icon={faSortUp}/> : (order_by.key == "-nama") ? <FontAwesomeIcon icon={faSortDown}/> : <FontAwesomeIcon icon={faSort}/>}</td>
            <td className=" border-2 border-gray-300 p-2 text-center" onClick={() => sort("nik")}>NIK/NPWP/Perusahaan/Telp/Email {(order_by.key == "nik" && order_by.filtered == "asc") ? <FontAwesomeIcon icon={faSortUp}/> : (order_by.key == "-nik") ? <FontAwesomeIcon icon={faSortDown}/> : <FontAwesomeIcon icon={faSort}/>}</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Aksi</td>
        </tr>
       
      </thead>
      
    )
  }

  const Export  = ({ exportFile }) => {
      return (
          <div>
            <button onClick={() => exportFile("print", "Pelanggan")} className="hover:bg-gray-400 active:bg-gray-600 active:shadow-none bg-gray-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Print</button>
            <button onClick={() => exportFile("excel", "Pelanggan")} className="hover:bg-teal-400 active:bg-teal-600 active:shadow-none border-teal-600 bg-teal-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Excel</button>
          </div>
      )
  }
  return (
    <div>
        <Datatable 
          Export={ Export }
          Head={theadPelanggan}
          Data={Data_pelanggan}
          Entries={[]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
            return (
              <tr key={ index }>
                <td className={"border-2 p-2 border-gray-300 align-top text-center" + mod}>
                    <div className="w-[50px] text-center">
                        {row.id_user}    
                    </div>
                </td>
                <td className={"border-2 p-2 border-gray-300 align-top " + mod} width="40%">
                    
                    <ChipTable icon={<FontAwesomeIcon icon={faAddressCard} />} text={row.nama} />
                    <ChipTable icon={<FontAwesomeIcon icon={faMapSigns} />} text={row.alamat} />
                    <div className={`${(row.status == 'aktif') ? 'bg-green-600' : 'bg-orange-600'} text-white inline-block pl-2 pr-2 rounded-md`}>{ row.status }</div>    
                
                </td>
                
                <td className={"border-2 p-2  border-gray-300 align-top" + mod} width="40%">
                   
                        <ChipTable icon={<FontAwesomeIcon icon={faAddressCard} />} text={row.nik} />
                        <ChipTable icon={<FontAwesomeIcon icon={faAddressCard} />} text={row.npwp} />
                        <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={row.instansi} />
                        <ChipTable icon={<FontAwesomeIcon icon={faPhone} />} text={row.telp} />
                        <ChipTable icon={<FontAwesomeIcon icon={faMailBulk} />} text={row.email} />
                        
                   

                </td>
             
                <td className={"border-2 border-gray-300 p-2 align-top text-center " + mod}>
                    <div className="min-w-[120px]">
                        <button onClick={() => openDetailPelanggan(row.index)} className="active:shadow-none bg-white active:bg-gray-300 hover:bg-gray-200 border-blue-400 border-2 shadow-md text-blue-400 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faEye} />
                        </button> 
                        <button onClick={() => editForm(row.index)} className="active:shadow-none bg-white ml-2  active:bg-gray-300 hover:bg-gray-200 border-orange-400 border-2 shadow-md text-orange-400 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faEdit} />
                        </button> 
                    
                        <button onClick={() => openDeleteForm(row.id_user)} className="active:shadow-none bg-white ml-2 active:bg-gray-300 hover:bg-gray-200 border-red-500 border-2 shadow-md text-red-500 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    
                </td>
              </tr>
            )
          }}
        />
    </div>
  )
}