import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getBidang, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true

export const getAllPelanggan = async () => {

    const reqData = await axios.get( backEndUrl() + "/penerimaan/pelanggan", getCred())
                    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getOnePelanggan = async ({ id_pelanggan }) => {
 
    const attr    = { id_pelanggan: { required: true, checkv: { isNumber: true } } };
    const values  = { id_pelanggan: id_pelanggan };
 
    const valid   = checkType({ attr: attr, values: values });
    if (valid.status) {
        return await axios.get( backEndUrl() + "/penerimaan/pelanggan/" + id_pelanggan, getCred())
                    .then((res) => {
                        return {
                            status: 200,
                            message: res.data
                        }
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    })    
    }else{
        return valid;
    }
}
export const createPelanggan = async ({  user_info, status }) => {
    function getRandomInt() {
        return Math.floor(Math.random() * 99999);
    }
    const attr  = { 
                    username: { required: true, checkv: { isString: true } }, 
                    password: { required: true, checkv: { isString: true } }, 
                    user_info:  { required: true, checkv: { isString: true } }, 
                    status: { required: true, checkv: { isString: true } },
                    uuid: { required: true, checkv: { isString: true } },
                };
    const values= { 
                    username: user_info['nama'].split(" ").join("") + getRandomInt(),
                    password: "12345",
                    user_info: JSON.stringify(user_info),
                    status: status,
                    uuid: uuidv4()
                };
    // console.log(JSON.stringify(values))
    const valid = checkType({ attr: attr, values: values })
    if (valid.status) {
        return await axios.post( backEndUrl() + "/penerimaan/pelanggan", values, getCred())
                        .then((res) => {
                            return res.data
                        }).catch((err) => {
                            return {
                                status: 500,
                                messages: err.response 
                            }
                        });
        
    }else {
        return valid
    }
}
export const updatePelanggan = async ({ id_user, username, user_info, status }) => {
    const attr = {
        id_user: { required: true, checkv: { isNumber: true } },
        username: { required: true, checkv: { isString: true } },
        user_info: { required: true, checkv: { isString: true } },
        status: { required: true, checkv: { isString: true } }
    }
    const values = {
        id_user: id_user,
        username: username,
        user_info: user_info,
        status: status
    }
    const valid = checkType({ attr: attr, values: values })
    if (valid.status) {
        return await axios.put( backEndUrl() + "/penerimaan/pelanggan", values, getCred() )
                        .then((res) => {
                            return res.data
                        }).catch((err) => {
                            return {
                                status: 500,
                                messages: err.response 
                            }
                        });
    }else{
        return valid;
    }
}
export const updatePelangganPassword = async ({ id_user, password }) => {
    const attr = {
         id_user: { required: true, checkv: { isNumber: true } },
         password: { required: true, checkv: { isString: true } }
    }
    const values = {
        id_user: id_user,
        password: password
    }
    const valid = checkType({ attr: attr, values: values })
    if (valid.status) {
        return await axios.put( backEndUrl() + "/penerimaan/pelanggan", { data: values }, getCred() )
                        .then((res) => {
                            return res.data
                        }).catch((err) => {
                            return {
                                status: 500,
                                messages: err.response 
                            }
                        });
    }else{
        return valid;
    }
}
export const deleteUser = async ({ id_user }) => {
    const attr = { id_user:{required: true, checkv: { isNumber: true } } }
    const values = { id_user: id_user }
    const valid = checkType({ attr: attr, values: values })
    if (valid.status) {
        return await axios.delete( backEndUrl() + "/penerimaan/pelanggan/" + id_user, getCred() )
    }else{
        return valid
    }
}
export const uploadProfilePicture = async ({ file, onUpload, id_user }) => {
    if (file.length == 0) {
        return { status: false, messages: "Tidak Ada File Yang Dipilih" }
    }else{
        const valid =  checkType({ attr: { id_user: { required: true } }, values: { id_user: id_user } })
        if (valid.status) {
            let form  = new FormData();
            form.append("file", file[0]);
            const options = {
                onUploadProgress:(progressEvent) =>{
                    const {loaded,total} = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    onUpload(percent)
                }
            }
            return await axios.post( backEndUrl() + "/penerimaan/pelanggan/uploadprofile/3" , getCred())
        }else{
            return valid;
        }
    }
}