import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NavLink }   from 'react-router-dom';

export const Card = ({ children, className="" }) => {
    return (
        <div>
            <div className={`bg-white shadow-md rounded-md p-3 mb-5 ${className}`}>
                {children}
            </div>
           
        </div>
    )
}
export const InfoCard = ({ param="bg-white", children }) => {
    return (
        <div className={" shadow-md rounded-md p-5 " + param}>
            {children}
        </div>
    )
}

export const Breadcrumb = ({ icon, list }) => {
    return (
        <div className="width-full bg-white p-3 pl-8 z-0 flex">
            <div>
                { icon }
            </div>
            <div className="flex">
                { list.map((x, index) => {
                    let isGreater25 = (x.title.length > 25) ? "...": ""
                    return (
                        <div className=" ml-2 " key={index}>
                            {
                                x.to ?
                                <NavLink to={x.to}>
                                      <span className= "text-blue-600">
                                            { x.title.substring(0, 25) } 
                                        </span>
                                </NavLink>
                                :
                                x.title.substring(0, 25)
                            }
                            {
                                isGreater25
                            }
                            {
                                list.length-1 === index ? null 
                                :
                                <FontAwesomeIcon className="ml-2 " icon={faChevronRight} />

                            }
                        </div>
                    )
                }) }
            </div>
            
        </div>
    )
}
export const CustomChipTable = ({ icon, text, customCard, customIcon }) => {
    return (
       <div className={" rounded-xl bg-white mb-2 " + customCard}>
            <div className="flex  justify-between">
                <div className={"pl-2 pt-1 pb-1 flex-none text-black " + customIcon}>
                    { icon }
                </div>
                <div className="pl-2 pt-1 pb-1 flex-1 text-black ">
                    { text }
                </div>
            </div>
        </div>
    )
}
export const ChipTable = ({icon, text, customCard, customIcon}) => {
    return (
        <div className={" rounded-xl bg-white shadow-sm mb-2 " + customCard}>
            <div className="flex  justify-between">
                <div className={"pl-2 pt-1 pb-1 flex-none text-black " + customIcon}>
                    { icon }
                </div>
                <div className="pl-2 pt-1 pb-1 flex-1 text-black ">
                    { text }
                </div>
            </div>
        </div>
    )
}
export const ChipKeranjang = ({icon, text, customCard, customIcon}) => {
    return (
        <div className={"  mb-2 " + customCard}>
            <div className="flex  justify-between">
                <div className={"pl-2 pt-1 pb-1 flex-none text-black " + customIcon}>
                    { icon }
                </div>
                <div className="pl-2 pt-1 pb-1 flex-1 text-black ">
                    { text }
                </div>
            </div>
        </div>
    )
}