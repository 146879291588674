import { Fragment } from 'react'
import TableRow from './TableRow';
import { numberFormat } from '../../../api/Value';
export default ({myForm}) => {
    const tgl_awal = myForm.transaksi.info_transaksi.tgl_awal.split("-").reverse().join("/");
    const tgl_akhir = myForm.transaksi.info_transaksi.tgl_akhir.split("-").reverse().join("/");
    return (
         <div>
                <table className="text-md" width="100%">
                    <tbody>      
                        <TableRow title="Uraian " bg="bg-gray-200" value={myForm.transaksi.uraian} />  
                        <TableRow title="Bidang" bg="" value={myForm.transaksi.bidang} />  
                        <TableRow title="Nama Tagihan" bg="bg-gray-200" value={myForm.transaksi.info_transaksi.nama_tagihan} />  
                        <TableRow title="Alamat Tagihan" bg="" value={myForm.transaksi.info_transaksi.alamat_tagihan} />  
                        {
                            (tgl_awal === tgl_akhir) ?
                                <Fragment>
                                    <TableRow title="Tanggal Pelaksanaan" bg="bg-gray-200" value={tgl_awal} />  
                                    <TableRow title="No Kontrak" bg="" value={myForm.transaksi.info_transaksi.no_kontrak} />  
                                    <TableRow title="Jumlah Transaksi" bg="bg-gray-200" value={numberFormat(`${myForm.transaksi.jumlah_transaksi}`)} />  
                                    <TableRow title="Email Tagihan" bg="" value={myForm.transaksi.info_transaksi.email_tagihan} />  
                                    <TableRow title="No Telp Tagihan" bg="bg-gray-200" value={myForm.transaksi.info_transaksi.no_telp_tagihan} />  
                                    <TableRow title="Tipe Pembayaran" bg="" value={myForm.transaksi.info_transaksi.tipe_pembayaran} />  
                                    <TableRow title="Nama Bank" bg="bg-gray-200" value={myForm.transaksi.info_transaksi.nama_bank} />  
                                    <TableRow title="Nama Rekening" bg="" value={myForm.transaksi.info_transaksi.nama_rekening} />  
                                    <TableRow title="No Rekening" bg="bg-gray-200" value={myForm.transaksi.info_transaksi.nomor_rekening} />  
                                    <TableRow title="Progress Transaksi" bg="" value={
                                    (myForm.transaksi.progress_transaksi === "lunas") ?
                                        <span className="bg-green-600 pl-2 pr-2 text-white rounded-md">Lunas</span>
                                    :
                                    myForm.transaksi.progress_transaksi
                                } />  
                                <TableRow title="Tipe Transaksi" bg="bg-gray-200" value={myForm.transaksi.tipe_transaksi} />  

                                </Fragment>
                            :
                            <Fragment>
                                <TableRow title="Tgl Awal" bg="bg-gray-200" value={tgl_awal} />  
                                <TableRow title="Tgl Akhir" bg="" value={tgl_akhir} />  
                                <TableRow title="No Kontrak" bg="bg-gray-200" value={myForm.transaksi.info_transaksi.no_kontrak} />  
                                <TableRow title="Jumlah Transaksi" bg="" value={numberFormat(`${myForm.transaksi.jumlah_transaksi}`)} />  
                                <TableRow title="Email Tagihan" bg="bg-gray-200" value={myForm.transaksi.info_transaksi.email_tagihan} />  
                                <TableRow title="No Telp Tagihan" bg="" value={myForm.transaksi.info_transaksi.no_telp_tagihan} /> 
                                <TableRow title="Tipe Pembayaran" bg="bg-gray-200" value={myForm.transaksi.info_transaksi.tipe_pembayaran} />  

                                <TableRow title="Nama Bank" bg="" value={myForm.transaksi.info_transaksi.nama_bank} />  
                                <TableRow title="Nama Rekening" bg="bg-gray-200" value={myForm.transaksi.info_transaksi.nama_rekening} />  

                                <TableRow title="No Rekening" bg="" value={myForm.transaksi.info_transaksi.nomor_rekening} />  
                                <TableRow title="Progress Transaksi" bg="bg-gray-200" value={
                                    (myForm.transaksi.progress_transaksi === "lunas") ?
                                        <span className="bg-green-600 pl-2 pr-2 text-white rounded-md">Lunas</span>
                                    :
                                    myForm.transaksi.progress_transaksi
                                } />  
                                <TableRow title="Tipe Transaksi" bg="" value={myForm.transaksi.tipe_transaksi} />  

                            </Fragment>
                        }
                        
                       
                    </tbody>
                </table>
            </div>
    )
}