
export default  ({ myForm, closeModalForm }) => {
    return (
        <div>
            <br />
                <div >
                    <div>
                        <table width="100%">
                            <tbody>      
                                <TableRow title="Kode Layanan" bg="bg-gray-200" value={myForm.kode_layanan} />  
                                <TableRow title="Nama Layanan" bg="" value={myForm.nama_layanan} />  
                                <TableRow title="Pelaksana" bg="bg-gray-200" value={myForm.kategori} />  
                                <TableRow title="Unit Penjual" bg="" value={(myForm.unit_penjual != undefined)  ?  myForm.unit_penjual.split(",").map((d) => {
                                    if(d == "") {
                                        return null
                                    }
                                    return (
                                         <div className="inline-block bg-blue-500 pb-1 pt-1 mt-2 pl-2 pr-2  rounded-md text-white mr-2 " key={d}>
                                        {d} 
                                    </div>
                                    )
                                }) : null} />  
                                <TableRow title="Produk" bg="bg-gray-200" value={(myForm.is_product) ? <div className="bg-green-500 w-[23px] h-[23px] rounded-full"></div>  :  <div className="bg-red-500 w-[23px] h-[23px] rounded-full"></div>} />  
                                <TableRow title="Tarif" bg="" value={myForm.tarif} />  
                                <TableRow title="Target" bg="bg-gray-200" value={myForm.target} />  
                                <TableRow title="Realisasi" bg="" value={0} />  
                               
                            </tbody>
                        </table>
                    </div>
               
                </div>
            
            <br />
            <div className="text-right flex justify-end">
                <button className="font-bold bg-gray-600 p-2 shadow-md hover:bg-gray-500 active:bg-gray-700 active:shadow-none rounded-md w-32  text-white" onClick={closeModalForm}>TUTUP</button>
            </div>
        </div>
    )
}

const TableRow = ({ title, value, bg  }) => {
    return (
        <tr>
            <td className={"w-48 p-2 " + bg}>{ title }</td>
            <td className={"p-2 " + bg}>:</td>
            <td className={" p-2 " + bg}> { value }</td>
        </tr>
    )
}