import { Component, Fragment } from 'react';
import { SmallNavbar } from '../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Card } from '../components/Card';
import Datatable from '../components/Datatable';
import { InputText } from '../components/Input';
import { getAllApps, createApp, updateApp, deleteApp } from '../api/Integrasi';
import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import { backEndUrl } from '../config/Config';
import Spinner    from '../components/Spinner';
import { uploadProfilePicture, updateUserInfo } from '../api/User';
import Auth from '../api/Auth.js';
import Modal from '../components/Modal';
import ModalFooter from '../components/ModalFooter';
import { Switch } from '@headlessui/react';
export default  () => {
    return (
        <Auth.AuthConsumer>
            {
                ({ getOneUser }) => {
                    return (
                         <Penerimaan getOneUser={getOneUser} />
                    )
                }
            }
           
        </Auth.AuthConsumer>
    )
}

class Penerimaan extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            dataForm: {
                "nama_app": "",
                "url_app": "",
                "secret_token": "",
                "url_callback": "",
                "app_status": 1
            },
            validationForm: {
                "nama_app": "",
                "url_app": "",
                "secret_token": "",
                "url_callback": ""
            },
            modalForm: {
                isOpen: false,
                loader: false,
                id_app:  "",
                backup: ""
            },
            deleteForm: {
                isOpen: false,
                loader: false,
                id_app: ""
            }
        }
    }
    componentDidMount  = () => {
       this.getApps()
    }
    handleForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let dataForm = this.state.dataForm;
        dataForm[name] = value;
        this.setState({
            dataForm: dataForm
        })
    }
    getApps = () => {
        const self = this;
        getAllApps().then(res => {
            if (res.status === 200) {
                self.setState({
                    data: res.data
                })
            }else{
                toast.custom(t => {
                    return <ToastComponent param={ t } message="Gagal Mendapatkan Data Apps" />
                })
            }
        })
    } 
    submitForm = async () => {
        const modalForm = this.state.modalForm;
        const dataForm  = this.state.dataForm;
        let formIsValid = true;
        const self = this;
        // cek validation
        let validation = this.state.validationForm;
        // cek validation
        for(let d in validation){
            if(dataForm['app_status'] == 1 && (d == "secret_token" || d == "url_callback")){
                if (dataForm[d] == "") {
                    validation[d] = "required";
                    formIsValid = false;
                }
                continue;
            }else if (d == "secret_token" || d == "url_callback") {
                continue;
            }
            
            if (dataForm[d] == "") {
                validation[d] = "required";
                formIsValid = false;
            }
        }
        // for update
        if (modalForm.id_app != "" && formIsValid) {
            modalForm['loader']  = true;
            this.setState({
                modalForm: modalForm
            })
            // for insert
            await updateApp(dataForm).then((res) => {
                self.toastAlert(res, "update")
                if (res.status === 200) {
                    modalForm['isOpen'] = false;
                    modalForm['backup'] = "";
                    modalForm['id_app'] = "";
                }
                modalForm['loader'] = false;
                self.setState({
                    modalForm: modalForm
                })
            })
        }else if(formIsValid){
            modalForm['loader']  = true;
            this.setState({
                modalForm: modalForm
            })
            
            // for insert
            await createApp(dataForm).then((res) => {
                self.toastAlert(res, "insert")
                if (res.status === 200) {
                    modalForm['isOpen'] = false;
                }
                modalForm['loader'] = false;
                self.setState({
                    modalForm: modalForm
                })
            })
        }else{
            // for reset required message
            this.setState({
                validationForm: validation
            }, () => {
                setTimeout(() => {
                    for(let d in validation){
                        validation[d] = "";
                    }
                    this.setState({
                        validationForm: validation
                    })
                }, 3000);
            })
        }
    }
    closeModal = () => {
        let modalForm = this.state.modalForm
        modalForm['isOpen'] = false;
        if (modalForm.id_app != '') {
            let data = this.state.data.map((x) => {
                return (x.id_app === modalForm.id_app) ? JSON.parse(modalForm.backup) : x;
            });
            this.setState({
                data: data,
                modalForm: modalForm
            })
        }else{
            this.setState({
                modalForm: modalForm
            })
        }
    }
   
    openModal = (id_app="") => {
        if (id_app === "") {
            let dataForm = this.state.dataForm;
            for(let d in dataForm){
                if (d === "app_status") {
                    dataForm[d] = 1
                }else{
                    dataForm[d] = "";
                }
            }
            this.setState({
                dataForm: dataForm,
                modalForm: {
                    id_app: id_app,
                    isOpen: true,
                    loader: false,
                    backup: ""
                }
            }, () => {
                this.makeRandom();
            });   
        }else if (id_app != "") {
            const data = this.state.data;
            const cariApp = data.filter((x) => {
                return x.id_app === id_app
            })
            if (cariApp.length === 0) {
                toast.custom(t => {
                    return (
                        <ToastComponent param={ t } message="App Tidak Ditemukan" />
                    )
                })
            }else{
                const dataApp = cariApp[0];
                this.setState({
                    dataForm: dataApp,
                    modalForm: {
                        id_app: dataApp.id_app,
                        isOpen: true,
                        loader: false,
                        backup: JSON.stringify(dataApp)
                    }
                })
            }
        }
    }
    submitFormDelete = async () => {
        const self = this;
        let deleteForm = this.state.deleteForm;
        deleteForm['loader'] = true;
        await deleteApp({ id_app: deleteForm['id_app'] }).then(res => {
            if (typeof res === "string") {
                toast.custom(t => {
                    return (
                        <ToastComponent param={ t }  message="Respon Server Tidak Terbaca"/>
                    )
                })
                deleteForm['loader'] = false;
            }else if (res.status === 200) {
                toast.custom(t => {
                    return (
                        <ToastComponent bg="orange" param={ t }  message="Hapus Berhasil"/>
                    )
                })
                self.getApps()

                deleteForm['loader'] = false;
                deleteForm['isOpen'] = false;
            }else if (res.status === 500) {
                let message = res.message
                toast.custom(t => {
                    return (
                        <ToastComponent bg="orange" param={ t }  message={ typeof message === "string" ? message: "Gagal Menghubungi Server" }/>
                    )
                })
                deleteForm['loader'] = false;
            }
            self.setState({
                deleteForm: deleteForm
            })
        })
    }
    setEnabled = () => {
        let dataForm = this.state.dataForm
        dataForm['app_status'] = (dataForm['app_status'] === 1) ? 0 : 1;
        this.setState({
            dataForm: dataForm
        })
    }
    makeRandom = () => {
        let length           = 80;
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
             charactersLength));
       }
       let dataForm = this.state.dataForm
       dataForm['secret_token'] = result;
       this.setState({
           dataForm: dataForm
       }) 
    }
    toastAlert  = (res, type) => {
        const self= this;
        if (typeof res === "string") {
            toast.custom(t => {
                return <ToastComponent param={ t } message="Respon Server Tidak Terbaca" />
            })
        }else if(res.status === 200){
            let message = (type === "insert") ? "Data Berhasil Ditambah" : "Data Berhasil Diupdate"
            toast.custom(t => {
                return <ToastComponent bg="green" param={ t } message={ typeof message === "string" ? message: "Berhasil" } />
            })
            self.getApps()
                
        }else if(res.status === 500){
            let message = res.message.data

            toast.custom(t => {
                return <ToastComponent param={ t } message={ typeof message == "string" ? message: "Gagal Menghubungi Server" }  />
            })
        }
    }
    closeModalDelete = () => {
        let deleteForm = this.state.deleteForm;
        deleteForm["id_app"] = "";
        deleteForm['isOpen'] = false;
        deleteForm['loader'] = false;
        this.setState({
            deleteForm: deleteForm
        })
    }
    openModalDelete =(id_app) => {
        let deleteForm = this.state.deleteForm;
        deleteForm["id_app"] = id_app;
        deleteForm['isOpen'] = true;
        deleteForm['loader'] = false;
        this.setState({
            deleteForm: deleteForm
        })
    }
    copyToClip = (text) => {
       
        window.navigator.clipboard.writeText(text).then(res => {
            toast.custom(t => {
                return (
                    <ToastComponent param={ t } message="Copy to clipboard" bg="blue" />
                )
            })
        })
    }
    render(){
        const { data, dataForm, validationForm, modalForm, deleteForm } = this.state;
        const { copyToClip, handleForm, closeModal, submitForm, openModal, setEnabled, makeRandom, closeModalDelete, submitFormDelete, openModalDelete } = this;
        return (
            <div className="p-5 animate-fadein">
            <div className="border-green-800"></div>
            <div className="border-orange-800"></div>
                <Toaster position="bottom-right"/>
                <SmallNavbar 
                    left={
                        <Fragment>
                            <FontAwesomeIcon icon={faLocationArrow} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Integrasi Aplikasi External
                            </div>

                        </Fragment>
                    }
                    right={
                        <Fragment>
                            <button onClick={() => openModal() } className="shadow-md pt-1 hover:bg-gray-100 pb-1 rounded-md bg-white text-blue-500 pl-2 pr-2 font-bold">TAMBAH</button>
                        </Fragment>
                    }
                />
                <div className="w-full flex justify-center pt-3">
                    <div className="w-full md:w-1/2">
                        {
                            data.map((x, i) => {
                                return (
                                    <div key={i}>
                                        <CardIntegrasi copyToClip={ copyToClip } openHapus={ openModalDelete } openEdit = { openModal } { ...x}/>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                    
                </div>
                <Modal
                    Title = {<span className="text-white">Hapus App</span>}
                    Body  = {<div className="mb-3 pt-2">Apakah Anda Yakin ?</div>}
                    closeModal = {() => closeModalDelete()}
                    isOpen = { deleteForm.isOpen }
                    Container = "max-w-md bg-red-500 text-white "
                    Footer={
                        <ModalFooter 
                            closeModal={ closeModalDelete } 
                            onSubmit={ submitFormDelete }
                            loader={ deleteForm.loader }
                            isDelete={ true }
                        />
                    }
                />
                <Modal
                    Title = "Data Aplikasi"
                    Body  = {
                        <FormIntegrasi
                            handleForm     = { handleForm }
                            dataForm       = { dataForm }
                            validationForm = { validationForm }
                            setEnabled = { setEnabled }
                            makeRandom={ makeRandom }
                        />
                    }
                    closeModal = {() => closeModal()}
                    isOpen = { modalForm.isOpen }
                    Container = "max-w-2xl bg-white"
                    Footer={
                        <ModalFooter 
                            closeModal={ closeModal } 
                            onSubmit={ submitForm }
                            loader={ modalForm.loader }
                        />
                    }
                />
            </div>
        )
    }
}

const FormIntegrasi  = ({ handleForm, dataForm, validationForm, setEnabled, makeRandom }) => {
    const enabled = dataForm.app_status === 1
    return (
        <div className="p-3">
            <InputText  
                other={{ onChange: handleForm, value: dataForm.nama_app }}  
                Title={ <TitleRequired title="Nama App" /> }
                Type="text" 
                Placeholder = "Nama App" 
                Name="nama_app" 
                Status={ validationForm.nama_app } 
                TitleClass=" text-xs" 
                elementClass=" mb-3" 
                InputClass=" " />
            <InputText  
                other={{ onChange: handleForm, value: dataForm.url_app }}  
                Title={ <TitleRequired title="URL App" /> }
                Type="text" 
                Placeholder = "URL App" 
                Name="url_app" 
                Status={ validationForm.url_app } 
                TitleClass=" text-xs" 
                elementClass=" mb-3" 
                InputClass=" " />
            <div className="flex gap-3">
                <div className="w-5/6">
                    <InputText  
                        other={{ onChange: handleForm, value: dataForm.secret_token }}  
                        Title={(enabled) ? <TitleRequired title="Secret Token" />  : "Secret Token"} 
                        Type="text" 
                        Placeholder = "Secret Token" 
                        Name="secret_token" 
                        Status={ validationForm.secret_token }
                        TitleClass=" text-xs" 
                        elementClass=" mb-3" 
                        InputClass=" " />
                </div>
                
                <div className="pt-6">
                    <button onClick={ makeRandom } className="bg-indigo-600 p-2 text-white rounded-md">GENERATE</button>            
                </div>
            </div>
            
            <InputText  
                other={{ onChange: handleForm, value: dataForm.url_callback }}  
                Title={ (enabled) ? <TitleRequired title="URL Callback" />  : "URL Callback" }
                Type="text" 
                Placeholder = "URL Callback" 
                Name="url_callback" 
                Status={ validationForm.url_callback }
                TitleClass=" text-xs" 
                elementClass=" mb-3" 
                InputClass=" " />
            <div className=" text-sm mb-2">
             Nonaktif/Aktif
            </div>
            <Switch
                checked={enabled}
                onChange={setEnabled}
                className={`${enabled ? 'bg-blue-500' : 'bg-gray-300'}
                relative inline-flex flex-shrink-0 h-[30px] w-[66px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                <span className="sr-only">Use setting</span>
                <span
                aria-hidden="true"
                className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
                    pointer-events-none inline-block h-[26px] w-[26px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                />
            </Switch>
        </div>
    )
}
const CardIntegrasi = ({ secret_token, id_app, nama_app, url_app, app_status, openEdit, openHapus, copyToClip }) => {
    return (
        <div className="bg-white rounded-md shadow-md w-full relative mb-4">
            <div className="bg-blue-600 w-full h-10 rounded-t-md text-center pt-2 font-bold text-white">
                { nama_app }
            </div>
            <div className="absolute right-4 -mt-8">
                <FontAwesomeIcon className="text-white" onClick={() => openEdit(id_app) } icon={ faEdit } />
                &nbsp;
                &nbsp;
                <FontAwesomeIcon className="text-white" onClick={ () => openHapus(id_app) } icon={ faTrash } />
            </div>
            <div className="p-2 pb-5 pt-4">
               <div className="md:flex justify-between">
                <div className="pl-3 text-blue-600">{ url_app }</div>
                <div className="text-right pt-2 md:pt-0">
                    <span className="cursor-pointer hover:bg-fuchsia-700 rounded-md pl-2 pr-2 pt-1 pb-1 text-sm text-white shadow-md bg-fuchsia-600">ID App <span className="bg-white pl-1 pr-1 text-fuchsia-600 rounded-md">{ id_app }</span></span>
                    &nbsp;
                    <span onClick={() => copyToClip(secret_token) } className="cursor-pointer hover:bg-blue-600 bg-blue-500 rounded-md pl-2 pr-2 pt-1 pb-1 text-sm text-white shadow-md">Token</span>
                    &nbsp;
                    {
                        app_status === 1 ?
                        <span className="bg-green-500 rounded-md pl-2 pr-2 pt-1 pb-1 text-sm text-white rounded-full">Aktif</span>
                        
                        :
                        <span className="bg-red-500 rounded-md pl-2 pr-2 pt-1 pb-1 text-sm text-white rounded-full">Non Aktif</span>

                    }

                </div>
               </div>
        
            </div>
        </div>
    )
}
const TitleRequired = ({ title }) => {
    return (
        <span>
            { title } &nbsp;
            <span className="font-bold text-red-500">*</span>
        </span>
    )
}