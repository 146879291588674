import { Card } from '../components/Card'
import { Component, useEffect, useState } from 'react'; 
import { Switch,Transition } from '@headlessui/react';
import FormLogin from '../components/FormLogin';
import Auth from '../api/Auth'
import { AppType } from '../config/Config';

export default class Login extends Component{
    constructor(props){
        super(props)
        this.state = {
            enabled: false
        }
    }
    componentDidMount = () => {
       
    }
    setEnabled = () => {
        const enabled = !this.state.enabled;
        if (enabled === true && AppType() === "Migas") {
          window.location.href = "https://keuangan.migascepu.id/"
        }else{
                
            this.setState({
                enabled: enabled
            })
        }
    }
     render(){
        const { enabled }   = this.state;
        const { setEnabled }= this;
        const Theme         = (!enabled) ? "bg-gradient-to-r from-green-500 to-teal-500": "bg-gradient-to-r from-blue-700 to-orange-700";
        const bg            = (enabled) ? "bg-penerimaan" : "bg-pengeluaran"
        return( 
              <div className="w-full h-screen overflow-hidden" id="contain-login">
                    <div className="w-full  p-2 bg-white shadow-md ">
                        <center>
                            <div className=" w-80 flex">
                            <div className="pl-2 pr-2 pt-1 font-bold ">
                                PENERIMAAN
                            </div>
                                <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={`${enabled ? 'bg-gray-300' : 'bg-gray-300'}
                                    relative inline-flex flex-shrink-0 h-[30px] w-[66px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                    aria-hidden="true"
                                    className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
                                        pointer-events-none inline-block h-[26px] w-[26px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                                    />
                                </Switch>
                            <div className="pl-2 pr-2 pt-1 font-bold ">PENGELUARAN</div>

                            </div>
                        </center>
                        
                    </div>
                    <div className="bg-indigo-800">
                    <Transition
                            show={enabled}
                            enter="transition-all -translate-x-6 duration-500"
                            enterFrom="opacity-0 -translate-x-6"
                            enterTo="opacity-100 translate-x-0"
                            leave="transition-transform translate-x-0 duration-150"
                            leaveFrom="opacity-100 translate-x-6"
                            leaveTo="opacity-0 translate-x-0"
                        >
                        <Auth.AuthConsumer>
                           
                            {
                                ({ verifyLogin }) => {
                                    return(
                                         <LoginPengeluaran verifyLogin= {verifyLogin} />

                                    )
                                }
                            }
                        </Auth.AuthConsumer>
                        
                        </Transition>

                        <Transition
                            show={!enabled}
                           enter="transition-all translate-x-6 duration-500"
                            enterFrom="opacity-0 translate-x-6"
                            enterTo="opacity-100 translate-x-0"
                            leave="transition-transform translate-x-0 duration-150"
                            leaveFrom="opacity-100 translate-x-6"
                            leaveTo="opacity-0 translate-x-0"
                        >
                        <Auth.AuthConsumer>
                            {
                                ({ verifyLogin }) => {
                                    return(
                                         <LoginPenerimaan verifyLogin= {verifyLogin} />

                                    )
                                }
                            }
                        </Auth.AuthConsumer>
                            
                        </Transition>
                    
                </div>
              </div>
        )
     }
}
const LoginPengeluaran = ({ verifyLogin }) => {
   
    return (
        <center>
                    <div className="bg-pengeluaran p-10 h-screen">
                        <div className="md:w-1/4 rounded-md shadow-md bg-white">
                          
                            <div className="pt-10 pl-5 text-left pr-5">
                                <center>
                                    <img src={require("../esdm.png")} className="w-20 mb-1 mt-3" alt="" />
                                    <div className="text-center font-bold text-xl mb-1 mt-3">SIKK PENGELUARAN
                                    </div>
                                    <div className=" text-md">Mohon login terlebih dahulu</div>
                                </center>
                                <br />
                                
                                <FormLogin submit={verifyLogin} btnClass="hover:opacity-80 active:opacity-50 mt-5 w-full h-10 rounded-md text-white mb-10 bg-gradient-to-r from-green-500 to-teal-500 shadow-md hover:bg-white" />
                                
                            </div>
                            
                        </div>
                    </div>

                    </center>
    )
}
const LoginPenerimaan = ({ verifyLogin }) => {

   
    return (
        <center>
                    <div className="bg-penerimaan p-10 h-screen">
                        <div className="lg:w-1/4 rounded-md shadow-md bg-white">
                          
                            <div className="pt-10 pl-5 text-left pr-5">
                                <center>
                                    <img src={require("../esdm.png")} className="w-20 mb-1 mt-3" alt="" />
                                    <div className="text-center font-bold text-xl mb-1 mt-3">
                                        {
                                            AppType() === "Migas" ?
                                            "SIMPELKU PENERIMAAN"
                                            :
                                            "SIKK PENERIMAAN"
                                        }
                                        
                                    </div>
                                    <div className=" text-md">Mohon login terlebih dahulu</div>
                                </center>
                                <br />
                                <FormLogin submit={verifyLogin} btnClass="hover:opacity-80 active:opacity-50 mt-5 w-full h-10 rounded-md text-white mb-10 bg-gradient-to-r from-blue-500 to-orange-500 shadow-md hover:bg-white" />

                               

                            </div>
                            
                        </div>
                    </div>

                    </center>
    )
}