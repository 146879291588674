import '../../Surat_penagihan.css';
import { NoDokumenPembayaran, getInaDate, numberFormatReverse, ex_terbilang, ucWords, isJson , pembilang} from '../../api/Value';
import { Fragment } from 'react'

export default ({ myForm, invoiceSelected })=>{
    
   const active = `${invoiceSelected.id_invoice}`.split("-")
   const infoInvoice = (typeof invoiceSelected.info_invoice === "string") ? isJson(invoiceSelected.info_invoice) : invoiceSelected.info_invoice

   const strTglAwal = getInaDate(myForm.transaksi.info_transaksi.tgl_awal);
   const strTglAkhir = getInaDate(myForm.transaksi.info_transaksi.tgl_akhir);
   //console.log("Aktif", active)
    return (
         <div className=" file bg-white -mt-[0cm]" >
           <img src={require("../../after_kop_surat.jpg")} alt="" />

        <div className="text-lg arial">
        <div className="text-[16px] justify leading-5" style={{paddingLeft: "2.5cm", paddingRight: "1.5cm"}}>
             <table style={{marginTop: "10px", lineHeight: "20px"}} width="100%">
                <tbody>
                    <tr>
                        <td>Nomor</td>
                        <td>:</td>
                        <td className="courier">{ NoDokumenPembayaran({ id_invoice: invoiceSelected.id_invoice, dt_invoice_created: invoiceSelected.dt_invoice_created })}</td>
                        <td className="courier" align="right">{
                            // prevent dt created null cause
                            invoiceSelected.dt_created ?
                                getInaDate(invoiceSelected.dt_created)
                            :
                                getInaDate(invoiceSelected.dt_invoice_created)
                        }</td>
                    </tr>
                    <tr>
                        <td>Lampiran</td>
                        <td>:</td>
                        <td>Satu Berkas</td>
                    </tr>    
                        
                    <tr>
                        <td>Hal</td>
                        <td>:</td>
                        <td>
                            {
                                  active.length === 1 ? 
                                    "Penagihan Pembayaran"
                                 : 
                                    `Penagihan Piutang ${ ucWords(pembilang({ nilai: parseInt(active[active.length-1])}))}`
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="mt-3 mb-3">
                Yang Terhormat, <br/>
                {myForm.transaksi.info_transaksi.nama_tagihan} <br/>
                {myForm.transaksi.info_transaksi.alamat_tagihan}  <br/>
            
            </div>
            {
                active.length === 1 ?
                <Fragment>       
                    {/** sejajar dengan sehubungan, apabila atas */}
                    &nbsp;&nbsp;&nbsp;&nbsp;Sehubungan dengan telah dilaksanakan jasa layanan {myForm.transaksi.uraian} yang dilaksanakan pada 
                    tanggal&nbsp;
                    {
                        strTglAwal === strTglAkhir ? 
                        <span> {strTglAwal} </span> 
                        :
                        <span>
                            {getInaDate(myForm.transaksi.info_transaksi.tgl_awal)} s.d {getInaDate(myForm.transaksi.info_transaksi.tgl_akhir)} 
                        </span>
                    }
                    &nbsp;dari {myForm.transaksi.info_pelanggan.nama}   
                </Fragment>
                :
                <Fragment>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    Menunjuk surat kami nomor { (active[1]-1 === 0) ? active[0] : `${active[0]}-${active[1]-1}` } tanggal 
                    <span> {myForm.invoice.tgl_terbit_sebelumnya}</span> 
                    &nbsp;hal { 
                        parseInt(active[1])-1 === 0 ?
                            "Penagihan Pembayaran"
                        :
                            `Penagihan Piutang ${ucWords(pembilang({ nilai: parseInt(active[1])-1 }))}`
                    } (terlampir)                
                </Fragment>
            }, dengan ini kami harap Saudara segera melakukan pembayaran paling 
            lambat 1 (satu) bulan sejak tanggal surat penagihan (invoice terlampir), sebagai
            berikut:<br/>
            <ol className="list-[lower-alpha] ml-[15px] mt-3 mb-3">
                <li>Jumlah pembayaran sebesar Rp. { 
                    (infoInvoice.tipe_pembayaran === "Transfer") ?
                    numberFormatReverse(`${invoiceSelected.jumlah_bruto-myForm.invoice.biaya_bank}`) 
                    
                    :
                    numberFormatReverse(`${invoiceSelected.jumlah_bruto}`) 
            
                    },- (Terbilang : {  
                        (infoInvoice.tipe_pembayaran === "Transfer") ?
                        ucWords(ex_terbilang(invoiceSelected.jumlah_bruto-myForm.invoice.biaya_bank))
                        :
                        ucWords(ex_terbilang(invoiceSelected.jumlah_bruto))
                        

                    }) jumlah pembayaran di atas belum
                    termasuk biaya administrasi bank
                </li>
                <li>
                    Pembayaran dilakukan dengan melakukan transfer ke Bank&nbsp;
                    {
                        infoInvoice.tipe_pembayaran === "Transfer" ?
                        <span>
                                    
                            &nbsp; {infoInvoice.nama_bank} atas nama <strong> {infoInvoice.nama_rekening} </strong>dengan nomor rekening
                            <strong> {infoInvoice.nomor_rekening}</strong>
                        </span>
                        :
                        <span>
                            
                            BNI dengan nomor Virtual Account(VA) 
                            <strong> {invoiceSelected.no_va}</strong>   
                        </span>
                    }
                </li>
            </ol> 
            &nbsp;&nbsp;&nbsp;Apabila Saudara telah melakukan pembayaran, kami harap bantuan Saudara agar 
            mengirimkan bukti transfer melalui email: keuangan.ppsdmkebtke@esdm.go.id (contact person: Tien Kartini 08119951339).
            <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Atas perhatian dan kerja sama Saudara, kami ucapkan terima kasih.
            
            <table width="100%" className="mt-2">
                <tbody>
                    <tr>
                        <td width="50%"></td>
                        <td>
                        a.n &nbsp;Kepala <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kepala Bagian Umum 
                            <img src="/cap_ebt.svg"  className="absolute w-[4.6cm]  -rotate-12 -mt-[1.3cm] -ml-[1cm]" alt="" />
                            <img src="/ttd_kabag_ebt.svg" alt="" className="absolute w-[3cm] -mt-[1.3cm] ml-[1.5cm]"/>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rr. Rizki Amalia Nurhayati, S.H., M.H. 
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NIP. 19810126 200604 2 001
                        </td>
                    </tr>
                </tbody>
            </table>
   
             Tembusan : <br/>
            Kepala PPSDM KEBTKE
        </div>
    
        </div>
    </div>
    )
}