import { useState, useEffect, Fragment, Component } from 'react';
import { Card, InfoCard, ChipTable } from '../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faList, faCheck, faTrash, faEdit, faUser,faBuilding, faCoins, faChartLine, faTimes, faFile  } from '@fortawesome/free-solid-svg-icons';
import Modal    from '../components/Modal';
import Datatable, { IconSort } from '../components/Datatable';
import Spinner    from '../components/Spinner';
import { InputText, TextArea, Radio } from '../components/Input';
import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import { SmallNavbar } from '../components/Navbar';
import { NavLink }   from 'react-router-dom';
import FullLoader from '../components/FullLoader';
import { getFilterVa, getFilterInvoice } from '../api/Transaksi';
import { onlyNumber, numberFormat } from '../api/Value';
import { Switch } from '@headlessui/react';
import { checkType } from '../api/Value';
import Form  from './Pelanggan/Form'
import FormDetail from './Pelanggan/FormDetail'
import Table from './Pelanggan/Table';
import { ActiveDropdown } from '../config/Config';

/*     
toast.custom((t) => {
    return <ToastComponent param={t} message="Pelanggan Gagal das" bg="amber"/>
})
*/
export default class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            formCari: {
                str_cari: ""
            },
            data: []
        }
    }
    componentDidMount = () => {
     //   this.getAll()
        const act = document.getElementById("Transaksi_nav");
        if (act) {
            act.classList.add("border-b-4")
            act.classList.add(ActiveDropdown())
        }
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/transaksi/cariTransaksi", title: "Cari Transaksi" }));
    }
  componentWillUnmount = () => {
         const act = document.getElementById("Transaksi_nav");
        if (act) {
             act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
    }
    handleChange = (e) =>{
        this.setState({
            formCari: {
                str_cari: e.target.value
            }
        })
    }
    cari = () => {
        let formCari = this.state.formCari;
        if (formCari.str_cari == "") {
            toast.custom(t => {
                return <ToastComponent param={ t } message="Mohon Masukkan No. Invoice / No VA" />
            })
        }else{
            const self = this;
            self.setState({
                loading: true
            })
            if(formCari.str_cari.length < 11){
                getFilterInvoice({ invoice: formCari.str_cari }).then(res => {
                    if (res.status == 200) {
                        self.setState({
                            data: (res.data) ? res.data : [],
                            loading: false
                        })
                    }else{
                        toast.custom(t => {
                            return <ToastComponent param={ t } message="Gagal Menguhubungi Server" />
                        })
                        self.setState({
                            loading: false
                        })
                    }
                })
            }else{
                getFilterVa({ va: formCari.str_cari }).then(res => {
                    if (res.status == 200) {
                        self.setState({
                            data: (res.data) ? res.data : [],
                            loading: false
                        })
                    }else{
                        toast.custom(t => {
                            return <ToastComponent param={ t } message="Gagal Menghubungi Server" />
                        })
                        self.setState({
                            loading: false
                        })
                    }

                })
            
            }
        }
    }
    handleEnter = (e) => {
        if(e.key == "Enter"){
            this.cari();
        }
    }
    render(){
        // get object class
        const { handleChange, handleEnter, cari } = this;
        // get value state
        const { loading, data } = this.state;
        // is loading full screen ? 
  
            // default render
            return (
                 <div className=" p-5 animate-fadein">
                        <Toaster position="bottom-right"/>
                        <SmallNavbar left={
                        <Fragment>
                            <FontAwesomeIcon icon={faSearch}   />
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Cari Transaksi
                            </div>
                        </Fragment>
                        } 
                    
                        />
                        <div className="bg-white rounded-md p-5 shadow-lg">
                            <div className="flex justify-center">
                                <div className="xl:w-96">
                                    <InputText other={{ onKeyPress: handleEnter, onChange: handleChange, type: "search" }} Title="Cari No Invoice / No VA" Placeholder = "No. Invoice / No. VA" Name="cari" Status="" TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" "/>
                                </div>
                                <div className="pt-6 pl-2">
                                    {
                                        loading ? 
                                        <Spinner color="#22c55e" width="40px" height="40px" />
                                        :
                                        <button onClick={ cari } className="rounded-md shadow-md text-white bg-blue-500 pl-2 pr-2 pt-2 pb-2 w-20">Cari</button>
                                        
                                    }
                                </div>
                            </div>
                            <br/>
                            <TableTransaksi 
                                DataRender={ data }
                            />
                        </div>    
                    </div>
            )
        
    }
}


const TableTransaksi = ({ DataRender }) => {
 
    const TheadComponent = ({ sortData, sort, order_by }) => {
      return (
        <thead>
          <tr>
          <td onClick={() => sort("id_transaksi")} className=" text-center border-2 border-gray-200 pl-2 pr-2" rowSpan="2">ID {IconSort(order_by, "id_transaksi")}</td>
              <td className=" border-2 border-gray-300 p-2 text-center">Uraian/Bidang/Pembuat/Tipe</td>
              <td className=" border-2 border-gray-300 p-2 text-center">Pelanggan/Jumlah/Progress/Penolakan</td>
 
             
          </tr>
        </thead>
        
      )
    }
    return (
      <div>
          <Datatable 
            Head={TheadComponent}
            Data={DataRender}
            Entries={[]}
            Body={(row, index) => {
              let mod = (index%2 == 0) ? " bg-gray-100 " : "";
              let user_info = {}
              let history = [];
              let user_pembuat = {}
              
             //\ console.log(history)
              try {
                  user_pembuat = JSON.parse(row.user_pembuat)
                  user_info    = JSON.parse(row.info_pelanggan)
                  history      = JSON.parse(row.history)
                  
              } catch (error) {
              // console.log(error, row.history)   
              }
              
              return (
                <tr key={ index }>
                  <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod} width="5%"> 
                      { row.id_transaksi } 
                  </td>
                  <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                      <NavLink to={"/app/detail_transaksi/" + row.id_transaksi}>
                      <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={<span><span className="cursor-pointer underline text-blue-500">{row.uraian} 
                          
                          </span>
                          {row.isExpired =="EXPIRED" ?<span className="bg-red-600 ml-2 rounded-md text-sm pl-2 pr-2 decoration-transparent text-white font-bold">EXPIRED</span> : null}
                      </span>} />
                      </NavLink>
                          <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={row.bidang} />
                          <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={user_pembuat.nama} />
                          <ChipTable icon={<FontAwesomeIcon icon={faFile} />} text={row.tipe_transaksi} />
  
                  </td>
                  <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                          <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={<span>{user_info.nama}</span>} />
  
                      <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.jumlah_transaksi}`)} />
                      <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={row.progress_transaksi} />
                      <ChipTable icon={<FontAwesomeIcon icon={faTimes} />} text={
  
                          
                          (row.progress_transaksi.indexOf("ditolak") !== -1 && history.length > 0) ? 
                          <div className="text-red-600">
                              <span className="font-bold">{ 
                                  history[history.length-1]["username"] } ({ history[history.length-1]["role"] }) : </span>
                             { history[history.length-1]["aksi"]}
                          
                          </div>
  
                          : 
                          null
                      } />
  
                  </td>
            
                </tr>
              )
            }}
          />
      </div>
    )
  }