import { Component } from 'react';
import { ChipTable } from '../../../components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCalendarAlt, faPrint, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Disclosure } from '@headlessui/react'
import Datatable from '../../../components/Datatable';
import { getInvoicesOfTransaksi } from '../../../api/Invoice';
import { createSuratTagihan, getSuratTagihan, updateStatusUpload } from '../../../api/Laporan'
import { toDateTime, numberFormat, isJson, NoDokumen, getInaDate } from '../../../api/Value';
import SuratPenagihan from '../../File/Penagihan_pembayaran'
import InvoicePrint from '../../File/Invoice';
import InvoiceMigas from '../../File/InvoiceMigas';
import Lampiran from '../../File/Lampiran';
import Pajak from '../../File/Pajak';
import Pajak2Hal from '../../File/PajakHal2';
import Pajak3Hal from '../../File/PajakHal3';
import Modal from '../../../components/Modal'
import { printUrl } from '../../../config/Config';
import { uploadInvoiceBermeterai, updateInvoiceInfo } from '../../../api/Invoice';
import { AppType } from '../../../config/Config';
import Spinner  from '../../../components/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../../components/Toast';
import { backEndUrl } from '../../../config/Config'
import Auth from '../../../api/Auth'
import { Select } from '../../../components/Input'
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';

export default ({ id_transaksi, myForm, aksi_transaksi, getData, isDeleted, terbitkan })=>{
    return (
        <div>
            <Auth.AuthConsumer>
                {
                    ({ getOneUser }) => {
                        return (
                            <Invoice getOneUser={ getOneUser } terbitkan={ terbitkan } myForm={myForm} id_transaksi={id_transaksi} isDeleted={isDeleted} aksi_transaksi={aksi_transaksi} getData={ getData } />
                        )
                    }
                }
            </Auth.AuthConsumer>
            
        </div>
    )
}
class Invoice extends Component{
    constructor(props){
        super(props);
        const act = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE")
        
        this.state  = {
            role: act,
            dataRender:[],
            menu: "all", 
            showModalPrint: false,
            showModalPdf: false,
            invoiceSelected: {},
            openBST: false,
            printing: "",
            fileInvoice: "",
            card_num:{
                terbayar: "0",
                belum_terbayar: "0"
            },
            formTagihan: {
                id_invoice: "",

                err_id_invoice: ""
            }
        }
    }
    componentDidMount =  () => {
        
        this.getData();
    }

    getData  = async () => {
        const self = this;
        let invoiceTrans = []
        await getInvoicesOfTransaksi({ id_transaksi: this.props.id_transaksi })
        .then((result) => {
            if (result.status === 200) {
                const data = result.data === null ? [] : result.data;
                let card_num = { terbayar: 0, belum_terbayar: 0 }
                const state = this.props.myForm.transaksi.progress_transaksi === "lunas";
                let tmp_va = ""
                
                let tmp_date = ""
     
                const dataRender = data.map((x, i) => {
                                    
                                    if(i === 0){
                                    //    tmp_va = x.no_va
                                        tmp_date = x.dt_invoice_created
                                    }else{
                                    //    x["no_va"] = tmp_va
                                        x["tmp_date"] = tmp_date
                                        tmp_date  = x.dt_invoice_created
                                    }
                                    // console.log(x)
                                    x.info_piutang = i//(i > 0)? i : ""; 
                                    if(state == false){
                                        card_num['belum_terbayar'] = parseFloat(x.jumlah_bruto)
                                    }else if(state == true){
                                        card_num['terbayar'] = parseFloat(x.jumlah_bruto)
                                    }
                                    // jika dt created ada maka push
                                    // update tgl terbit sebelumnya
                                   
                                    return x;
                                })
                                .reverse().map((x,i) => {
                                    x.index = i;
                                    
                                    x.is_expand = false;
                                    return x
                                })
                invoiceTrans = dataRender
                card_num['belum_terbayar'] = numberFormat(`${card_num['belum_terbayar']}`);
                card_num['terbayar'] = numberFormat(`${card_num['terbayar']}`);
                self.setState({
                    dataRender: dataRender,
                    card_num: card_num
                })
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message={"Gagal memuat invoice"} bg="red"/>
                })
            }
        })

        await getSuratTagihan({ id_transaksi: this.props.id_transaksi }).then(res => {
            if(res.data){    
                let dataTagihan = []
                // let dt_created  = null 
                let tagihan    = res.data.map((x) => {
                    const f    = x.no_tagihan.split("-")[0]
                    let getInvoice = invoiceTrans.filter((x) => parseInt(x.id_invoice) === parseInt(f))
                    if(getInvoice.length > 0){
                   //     dt_created = x.dt_created
                        dataTagihan.push({ ...x, ...getInvoice[0], id_invoice: x.no_tagihan })
                    }
                    return x
                }) 

                // date terbit sebelumnya 
                let terbit_surat_sebelumnya = null
                // join invoice dan data tagian 
                let dtRender = [ ...invoiceTrans, ...dataTagihan,  ].map((x, i) => {
                    let time= `${x.id_invoice}`.indexOf("-")  
                    const checkTime = time >= 0 ? x.dt_created : x.dt_invoice_created
                    const m = moment(checkTime).format("DD/MM/YYYY HH:mm:ss")
                    x["tgl_terbit"] = m
                    x["tgl_terbit_sebelumnya"] = terbit_surat_sebelumnya
                    terbit_surat_sebelumnya = getInaDate(checkTime)
                    // x["dt_created"] = dt_created
                   return { ...x, index: i }
                })
                
                this.setState({
                    dataRender: dtRender.reverse().map((x,i ) => ({ ...x, index: i }))
                })
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message={"Surat Tagihan gagal dimuat"} bg="red"/>
                })
            }
        })
    }
    setMenu = (type) => {
        this.setState({
            menu: type
        })
    }
    handleExpand = (index) => {
        let dataRender = this.state.dataRender;
        dataRender[index]['is_expand'] = !dataRender[index]['is_expand'];
        this.setState({
            dataRender: dataRender
        })
    }
    openPdfPreview = (fileInvoice) => {
        let fileUrl  
        const getId = fileInvoice.split("_")
       // if(parseInt(getId[0]) <= 2300900){
         //   fileUrl = "https://sik.bpsdm.id/uploads/kebtke_penerimaan/2023/" + fileInvoice
         // }else{
             fileUrl = backEndUrl() + "/files/" + fileInvoice
         // }   
        this.setState({
            showModalPdf: true,
            fileInvoice: fileUrl
        })
    }
    hideModalPdfPreview = () => {
        this.setState({
            showModalPdf: false
        })
    }
    openPrintPreview = (index) => {
        this.setState({
            showModalPrint: true,
            invoiceSelected: this.state.dataRender[index]
        })
    }
    hideModalPrintPreview = () => {
        this.setState({
            showModalPrint: false
        })
    }
    printPage= () => {
     //   console.log(printUrl() + this.props.id_transaksi + "/"  + this.state.invoiceSelected['id_invoice'])
       this.setState({
           printing: ""
       }, () => {

           this.setState({
               printing: printUrl() + this.props.id_transaksi + "/"  + this.state.invoiceSelected['id_invoice']
           })
       })
    }

    handleUpload = (e, id_invoice, info) => {
        let file = e.target.files;
        if (file.length > 0) {
            const container = document.getElementById("container-progress-" + id_invoice);
            const txtprogress= document.getElementById("txt-progress-"  + id_invoice);
            container.classList.add("hidden");
            txtprogress.classList.remove("hidden");
            const self = this;
            const invoiceSelected = this.state.invoiceSelected
            txtprogress.innerHTML= "0%"
            const onUpload = (percent) => {
                txtprogress.innerHTML = percent + "%"
            }
            uploadInvoiceBermeterai({ id_invoice: id_invoice, file: file, onUpload: onUpload })
            .then(async (res) => {
                console.log(res);
                if (res.status === 200) {
                    const id  =  `${id_invoice}`.split("-")
                    if(id.length === 1){      
                        let info_invoice = isJson(info);
                        info_invoice["file_invoice"] = res.data;
                        await updateInvoiceInfo({ id_invoice: id_invoice, info_invoice: info_invoice})
                        .then((res2) => {
                            if (res2.status === 200) {
                                container.classList.remove("hidden");
                                txtprogress.classList.add("hidden");
                                txtprogress.innerHTML = "";
                                self.getData();
                                self.props.getData(self.props.myForm['transaksi']['id_transaksi'] , true);
                            }else{
                                toast.custom((t) => {
                                    return <ToastComponent param={t} message={"Gagal mengupload file invoice"} bg="red"/>
                                })
                            }
                        })
                    }else if(id.length > 1){
                        const res2 = await updateStatusUpload({ no_tagihan: id_invoice })
                            //console.log(res2)
                            if (res2.status === 200) {
                                container.classList.remove("hidden");
                                txtprogress.classList.add("hidden");
                                txtprogress.innerHTML = "";
                                self.getData();
                                self.props.getData(self.props.myForm['transaksi']['id_transaksi'] , true);
                             }else if(res2.status === 500){
                                container.classList.remove("hidden");
                                txtprogress.classList.add("hidden");
                                txtprogress.innerHTML = "";
                                toast.custom((t) => {
                                    return <ToastComponent param={t} message={"Gagal mengupload file invoice"} bg="red"/>
                                })
                            }
                        
                    }
                }else{
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={"Gagal mengupload file invoice"} bg="red"/>
                    })
                }
            })
        }
    }
    
    hideModalST = () => {
        this.setState({
            loader: false,
            openBST: false
        })
    }
    tambahSuratTagihan = async () => {
        // formulir tagihan
        const formTagihan =  this.state.formTagihan
        // jika id invoice kosong
        if(formTagihan.id_invoice === ""){
            formTagihan["err_id_invoice"]  = "required"
            this.setState({
                formTagihan: formTagihan
            }, () => {
                setTimeout(() => {
                    formTagihan["err_id_invoice"] = ""
                    this.setState({
                        formTagihan: formTagihan
                    })    
                }, 2000);
            })
        }else{
            this.setState({ loader: true })
            // data render state
            const dataRender =  this.state.dataRender
            // render length lebih dari 0
            if(dataRender.length > 0){
                const id_invoice = formTagihan["id_invoice"].split("-")[0]
                const { id_transaksi, no_va } = dataRender[0]
                const buatTagihan = await createSuratTagihan({ id_transaksi: id_transaksi, no_va: no_va, no_tagihan: `${id_invoice}-${dataRender.length}` })
                
                if(buatTagihan?.message?.name === "SequelizeUniqueConstraintError"){
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={`Tagihan sudah dibuat`} bg="red"/>
                    })
                }else if(buatTagihan){
                    if(buatTagihan.length > 0){
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={`Tagihan dibuat`} bg="green"/>
                        })  
                    }
                    this.getData()
                }
            }
            this.setState({ loader: false, openBST: false })

        }
    }
    openModalSuratTagihan = () => {
        this.setState({
            loader: false,
            openBST: true
        })
    }
    handleFormTagihan = ({ target }) => {
        let formTagihan = this.state.formTagihan
        formTagihan["id_invoice"] = target.value 
        this.setState({
            formTagihan: formTagihan
        })
    }
    render(){
        const { dataRender, menu, showModalPrint, activeRole, invoiceSelected, printing, showModalUpload, loaderUpload, fileInvoice, showModalPdf, card_num } = this.state;
        const { handleExpand, setMenu,openPrintPreview, hideModalPrintPreview, printPage, handleUpload, openModalUpload, closeModalUpload, hideModalPdfPreview, openPdfPreview, tambahSuratTagihan } = this;
        const { myForm,aksi_transaksi, isDeleted, terbitkan } = this.props;
      // console.log(this.props)
        let btnColor = {
            all: "bg-gray-200",
            aktif: "bg-gray-200",
            nonaktif: "bg-gray-200"
        }
        btnColor[menu] = "bg-gradient-to-r from-cyan-500 to-blue-500 text-white";
        const isUpload = aksi_transaksi.split(",").findIndex(x => { return x === "upload" })  !== -1;
    //    console.log(aksi_transaksi)
        const _9  = (isUpload) ? "9" : "8"
       // console.log(_9)
        return (
            <div>
                <h3>{activeRole}</h3>
                <div className="grid grid-cols-8">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="grid grid-cols-9">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="grid grid-cols-2">
                    <div>
                        <button onClick={() => setMenu("all")} className={btnColor["all"] + " hover:opacity-80 active:opacity-50  w-28 rounded-l-md pl-2 pr-2 pt-2 pb-2 shadow-md"}>
                            <span> All</span>
                        </button>
                        <button onClick={() => setMenu("aktif")} className={ btnColor["aktif"] + " hover:opacity-80 active:opacity-50  w-28 pl-2 pr-2 pt-2 pb-2 shadow-md"}>
                        
                            <span> Aktif</span>
                        </button>
                        
                        <button onClick={() => setMenu("nonaktif")} className={ btnColor["nonaktif"] + " hover:opacity-80 active:opacity-50 bg-gray-200 w-28 rounded-r-md pl-2 pr-2 pt-2 pb-2 shadow-md"}>
                            <span> Tidak Aktif</span>
                        </button>
                    </div>
                </div>
                <div className="mt-4">
                    <button onClick={ terbitkan } className="bg-indigo-600 active:bg-indigo-500 hover:bg-indigo-400 rounded-md p-2 font-bold text-white shadow-md">BUAT ULANG INVOICE</button>
                    &nbsp;
                    {
                        this.state.role === "adminkeu" ?
                            <button onClick={ this.openModalSuratTagihan } className="bg-green-600 active:bg-green-500 hover:bg-green-600 rounded-md p-2 font-bold text-white shadow-md">BUAT SURAT TAGIHAN</button>
                        :
                            null
                    }
                </div>
                
                <div className={"bg-blue-600 text-white shadow-md rounded-md p-3 mt-4 grid grid-cols-" + _9 +" font-semibold"}>
                    <div>ID Invoice</div>
                    <div className="-ml-8">Tgl Terbit</div>
                    <div>Jumlah</div>
                    <div>Total</div>
                    <div>Status</div>
                    <div>Cetak</div>
                    <div>SP</div>
                    <div className="-ml-10">Pembayaran</div>
                    {
                        (isUpload) ? 
                            <div>Upload</div>
                        :
                        null
                    }
                </div>
                {
                    dataRender.filter((x) => {
                        if (menu === "all") {
                            return true     
                        }else if (menu === "aktif") {
                            return x.is_aktif ;
                        } else if (menu === "nonaktif") {
                            return !x.is_aktif;
                        }else{
                            return true
                        }
                       
                        
                    }).map((x, i) =>{
                        const infoInvoice = isJson(x.info_invoice);
                        const expand      = (x.is_expand) ? "" : "hidden"
                        const is_gray     = (x.dt_invoice_lunas != "0001-01-01T00:00:00Z" && x.is_aktif === false) ? "bg-purple-500" :(x.is_aktif )  ? "bg-purple-500" : "bg-gray-400"
                        const sp          = (x.dt_invoice_lunas != "0001-01-01T00:00:00Z" && x.is_aktif === false) ? "bg-orange-500" : (x.is_aktif)  ? "bg-orange-500" : "bg-gray-400"
                        
                        return (
                            <div key={i} onClick={() => handleExpand(x.index)} className="border-2 border-gray-200 hover:bg-gray-200 bg-white rounded-md  mt-4 ">
                           
                    
                                <div className={"pr-3 pt-3 pl-3 pb-2 grid grid-cols-" + _9}>
                                    <div>
                                        <div className={ is_gray + " font-bold pl-3 pr-3 rounded-full inline-block text-white text-sm"}>
                                            {
                                                    x.id_invoice
                                            }
                                        </div>
                                    </div>
                                    <div className="-ml-8">
                                        {
                                            x.tgl_terbit
                                        }
                                    </div>
                                    <div>{numberFormat(`${x.jumlah}`, true)}</div>
                                    <div>{(infoInvoice.tipe_pembayaran === "Transfer") ? numberFormat(`${x.jumlah_bruto-x.biaya_bank}`): numberFormat(`${x.jumlah_bruto}`, true)}</div>
                                    <div>{
                                        (x.is_aktif) ? 
                                            <div className="bg-green-600 rounded-md text-white inline-block pr-2 pb-1 pt-1 pl-2">
                                                Aktif
                                            </div>
                                        :
                                            <div className="bg-gray-200 rounded-md text-gray-600 inline-block font-bold pr-2 pb-1 pt-1 pl-2">
                                                Tidak Aktif
                                            </div>
                                    }</div>
                                    <div>
                                        <FontAwesomeIcon className="text-blue-500 text-lg" onClick={() => openPrintPreview(x.index)} icon={faPrint} />
                                        &nbsp;
                                        &nbsp;
                                        {
                                            `${x.id_invoice}`.indexOf("-") >= 0 && x.status_upload === 1 ?
                                                <FontAwesomeIcon className="text-green-500 text-lg" onClick={() => openPdfPreview(`${x.id_invoice}_invoice.pdf`)} icon={faPrint} />
                                            :   
                                            infoInvoice.file_invoice ?
                                                <FontAwesomeIcon className="text-green-500 text-lg" onClick={() => openPdfPreview(infoInvoice.file_invoice)} icon={faPrint} />
                                            : 
                                            null
                                        }
                                    </div>
                                    <div>
                                        <div className={ sp + " font-bold text-center text-white rounded-full w-[22px] h-[22px]"}>
                                            { 
                                                `${x.id_invoice}`.indexOf("-") >= 0 ?
                                                    <span>{`${x.id_invoice}`.split("-")[1]}</span>
                                                :
                                                    0
                                             }
                                        </div>
                                    </div>
                                    <div className="-ml-10">
                                        {
                                            (x.index === 0 && x.dt_invoice_lunas === "0001-01-01T00:00:00Z") ?
                                                <div className="border-gray-600 border-4  rounded-md text-gray-600 inline-block font-bold pr-2 pl-2">
                                                    Belum Dibayar
                                                </div>
                                                :
                                            (x.dt_invoice_lunas === "0001-01-01T00:00:00Z" ) ?
                                                <div className="bg-gray-200  rounded-md text-gray-600 inline-block font-bold pr-2 pl-2">
                                                    Belum Dibayar
                                                </div>
                                            :
                                                <div className="bg-green-600 rounded-md text-white font-bold inline-block pr-2 pl-2">
                                                    Lunas
                                                </div>
                                        }
                                    </div>
                                    {
                                        (isUpload) ?
                                        <div>
                                            <div id={"container-progress-" + x.id_invoice}>
                                                {
                                                    isDeleted === true ?
                                                    null
                                                    :
                                                    `${x.id_invoice}`.indexOf("-") >= 0 ?
                                                        <div>
                                                            {
                                                                x.is_aktif === false ? 
                                                                    <div>        
                                                                        <label  htmlFor={"upload-" + x.id_invoice} className="bg-gray-200 pl-2 pr-2 pt-1 pb-1 hover:bg-gray-300 active:bg-gray-400 w-20 rounded-md text-gray-500 font-bold">
                                                                            {  x.status_upload === 1 ? "Sudah Upload" : "Belum Upload" }
                                                                        </label>
                                                                        <input type="file" onChange={(e) => handleUpload(e, x.id_invoice, x.info_invoice)} name="file" className="hidden" id={"upload-" + x.id_invoice} />
                                                                    </div>
                                                                    :
                                                                    
                                                                    x.status_upload === 1 ? 
                                                                    <div>        
                                                                        <label  htmlFor={"upload-" + x.id_invoice} className="bg-blue-500 pl-2 pr-2 pt-1 pb-1 hover:bg-blue-400 active:bg-blue-600 w-20 rounded-md text-white font-bold">
                                                                            Sudah Upload
                                                                        </label>
                                                                        <input type="file" onChange={(e) => handleUpload(e, x.id_invoice, x.info_invoice)} name="file" className="hidden" id={"upload-" + x.id_invoice} />
                                                                    </div>
                                                                    : 
                                                                    <div>        
                                                                        <label  htmlFor={"upload-" + x.id_invoice} className="bg-orange-500 pl-2 pr-2 pt-1 pb-1 hover:bg-orange-400 active:bg-orange-600 w-20 rounded-md text-white font-bold">
                                                                        Belum Upload
                                                                        </label>
                                                                        <input type="file" onChange={(e) => handleUpload(e, x.id_invoice, x.info_invoice)} name="file" className="hidden" id={"upload-" + x.id_invoice} />
                                                                    </div>            
                                                            }
                                                        </div>
                                                    :
                                                    x.is_aktif === false ? 
                                                    <div>        
                                                        <label  htmlFor={"upload-" + x.id_invoice} className="bg-gray-200 pl-2 pr-2 pt-1 pb-1 hover:bg-gray-300 active:bg-gray-400 w-20 rounded-md text-gray-500 font-bold">
                                                            {  infoInvoice.file_invoice ? "Sudah Upload" : "Belum Upload" }
                                                        </label>
                                                        <input type="file" onChange={(e) => handleUpload(e, x.id_invoice, x.info_invoice)} name="file" className="hidden" id={"upload-" + x.id_invoice} />
                                                    </div>
                                                    :
                                                    
                                                    infoInvoice.file_invoice ? 
                                                    <div>        
                                                        <label  htmlFor={"upload-" + x.id_invoice} className="bg-blue-500 pl-2 pr-2 pt-1 pb-1 hover:bg-blue-400 active:bg-blue-600 w-20 rounded-md text-white font-bold">
                                                            Sudah Upload
                                                        </label>
                                                        <input type="file" onChange={(e) => handleUpload(e, x.id_invoice, x.info_invoice)} name="file" className="hidden" id={"upload-" + x.id_invoice} />
                                                    </div>
                                                    : 
                                                    <div>        
                                                        <label  htmlFor={"upload-" + x.id_invoice} className="bg-orange-500 pl-2 pr-2 pt-1 pb-1 hover:bg-orange-400 active:bg-orange-600 w-20 rounded-md text-white font-bold">
                                                           Belum Upload
                                                        </label>
                                                        <input type="file" onChange={(e) => handleUpload(e, x.id_invoice, x.info_invoice)} name="file" className="hidden" id={"upload-" + x.id_invoice} />
                                                    </div>
                                                }
                                            </div>
                                            <div className="font-bold hidden text-black" id={"txt-progress-"  + x.id_invoice}>
                                                
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    
                                </div>
                                <div className={expand + " bg-gray-200 p-3 grid grid-cols-5 gap-5"}>
                                    <Detail title="No Dokumen" body={ <NoDokumen id_invoice={x.id_invoice} dt_invoice_created={x.dt_invoice_created} />}/>
                                    <Detail title="Biaya Bank" body={ (infoInvoice.tipe_pembayaran === "Transfer") ? "0": numberFormat(`${x.biaya_bank}`) }  customClass="ml-10"/>
                                    <Detail title="Bank" body={ (infoInvoice.tipe_pembayaran === "Transfer") ? <InfoBank info_invoice={infoInvoice}  /> : "BNI"}/>
                                    <Detail title="No VA" body={ x.no_va }/>
                                    <Detail title="VA Expired" body={ 
                                             // expired date 
                                             (x.dt_va_expired === "0001-01-01T00:00:00Z") ?
                                                null
                                                :
                                                <Moment format="DD/MM/YYYY HH:mm:ss" tz="Asia/Jakarta">{x.dt_va_expired}</Moment>
                                            }
                                         />
                                </div>
                                
                            </div>
                        )
                    })
                }
                <Modal 
                    isOpen={showModalPdf}
                    Body={<div className="min-h-full	">
                    <iframe width="800" className="min-h-screen	" src={fileInvoice} frameborder="0"></iframe>
                            
                        </div>}
                    closeModal = {hideModalPdfPreview}
                    Container="max-w-fit -mt-5"
                />

                <Modal 
                    isOpen={showModalPrint}
                    Body={<div >
                            <div className="text-right">
                                <button className="bg-white rounded-md w-32 mb-2 p-2 hover:bg-gray-100 active:bg-gray-200" onClick={printPage}>Save</button>

                            </div>
                            {
                                AppType() === "Migas" ?
                                <div id="areaPrint">
                                
                                    <div className="flex justify-center">
                                        <InvoiceMigas myForm={{...myForm, invoice: invoiceSelected}} invoiceSelected={ invoiceSelected } />
                                    </div>
                                   
                                    <div className="flex mt-10 justify-center">
                                        <Lampiran myForm={{...myForm, invoice: invoiceSelected}} invoiceSelected={ invoiceSelected } />
                                    </div>
                                    
                                </div>
                                :
                                <div id="areaPrint" className="bg-white">
                                    {
                                        invoiceSelected.id_invoice ?
                                        dataRender.map((x, i) => {
                                            const max = `${invoiceSelected.id_invoice}`.split("-")
                                            // jika dia length == 1 maka dia surat awal
                                            const running = `${x.id_invoice}`.split("-")

                                            if(max[0] != running[0]){
                                                return null 
                                            }
                                            if(running.length === 1){
                                                return (
                                                    <div key={ i } className="flex justify-center pt-[1cm] bg-white border-b-2 border-gray-500	 border-dashed	pb-[1cm]">
                                                        <SuratPenagihan myForm={{...myForm, invoice: x}} invoiceSelected={ x } />
                                                    </div>            
                                                )
                                            }
                                            if(max.length > 1){
                                                
                                                if(parseInt(running[1]) <= parseInt(max[1])){
                                                    return (
                                                        <div key={ i } className="flex justify-center pt-[1cm] bg-white border-b-2 border-gray-500	 border-dashed	pb-[1cm]">
                                                            <SuratPenagihan myForm={{...myForm, invoice: x}} invoiceSelected={ x } />
                                                        </div>            
                                                    )
                                                }
                                                
                                            }

                                        })
                                        : null
                                    }
                                    
                                    <div className="flex mt-10 justify-center border-b-2 border-gray-500	 border-dashed	pb-[1cm]">
                                        <InvoicePrint myForm={{...myForm, invoice: invoiceSelected}} invoiceSelected={ invoiceSelected } />
                                    </div>
                                    <div className="flex mt-10 justify-center border-b-2 border-gray-500	 border-dashed	pb-[1cm]">
                                        <Lampiran myForm={{...myForm, invoice: invoiceSelected}} preview={true} invoiceSelected={ invoiceSelected } />
                                    </div>
                                    <div className="flex mt-10 justify-center border-b-2 border-gray-500	 border-dashed	pb-[1cm]">
                                        <Pajak />
                                    </div>
                                    <div className="flex mt-10 justify-center border-b-2 border-gray-500	 border-dashed	pb-[1cm]">
                                        <Pajak2Hal />
                                    </div>
                                    <div className="flex mt-10 justify-center">
                                        <Pajak3Hal />
                                    </div>
                                </div>
                            }
                          
                            
                        </div>}
                    closeModal = {hideModalPrintPreview}
                    Container="max-w-fit"
                />

                <div className="hidden">
                    <iframe src={printing} frameBorder="0"></iframe>
                </div>
                <Modal
                    Title = {<span>
                            Buat Surat Tagihan?
                    </span>}
                    Body  = {<div className="mt-5 pb-5">
                                <Select
                                    Title="No Invoice"
                                    Name ="no_invoice"
                                    Other={{ onChange: this.handleFormTagihan }}
                                    Status={ this.state.formTagihan.err_id_invoice }
                                >
                                    <option value="">Pilih No Invoice</option>
                                    
                                    {
                                        dataRender.map((x) => {
                                            
                                            return <option key={ x.id_invoice }>{ x.id_invoice }</option>
                                        })
                                    }
                                </Select>
                            </div>
                        }
                    Footer = {<ModalApproveFooter closeModal = {this.hideModalST} submit={tambahSuratTagihan} loader={this.state.loader} />}
                    closeModal = {this.hideModalST}
                    isOpen = { this.state.openBST }
                    Container = "max-w-md bg-white"
                />
            </div>
        )
    }
}


const ModalApproveFooter = ({ closeModal, loader, submit }) => {
   
    return(
        <div className="text-right flex justify-end">            
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={submit} className=" font-bold bg-green-700 ml-2 p-2 shadow-md hover:bg-green-800 active:bg-green-900 active:shadow-none rounded-md w-32 text-white">
                                Ya
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-green-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}

const Detail = ({ title, body, customClass="" }) => {
    return (
        <div className={customClass}>
            <span className="font-semibold	" >{title}</span> 
            <div className=" rounded-md ">
                { body }
            </div>
        </div>
    )
}

const InfoBank = ({ info_invoice }) => {
    return (
        <div>
            { info_invoice.nama_bank } 
            <br />
            { info_invoice.nomor_rekening }
            <br />
            { info_invoice.nama_rekening }
        </div>
    )
}