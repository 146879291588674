import { useLottie } from "lottie-react";
import Money from "../money.json";

import { useEffect } from 'react';

const ContainMoney = () => {
  const options = {
    animationData: Money,
    loop: true,
    autoplay: true,
    style:{
        height: 300
    }
  };

  const { View } = useLottie(options);

  return View;
};

export default ({ title="Sedang Mengambil Data" }) => {
    useEffect(() => {
        let text    = "";
        const dotAnimate = setInterval(() => {
            if (text.length < 4) {
                text += ".";
            }else {
                text = ""
            }
            // console.log(text)
            document.getElementById("dotext").innerHTML = text;
        }, 500);
        return () => {
            clearInterval(dotAnimate)
        };
    }, []);
    return (
        <div className="flex bg-gray-100 fixed top-0 w-screen h-screen justify-center items-center">
            <div>
                <div>
                    <ContainMoney/>
                </div>
                
                <div className="text-center mt-2 text-md">{ title } <span id="dotext"></span></div>
            </div>
                
        </div>
    )
}