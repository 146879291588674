import { useState, useEffect, Fragment, Component } from 'react';
import { Card, InfoCard, ChipTable } from '../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faMapSigns, faPlus, faUser,  faPhone, faMailBulk, faAddressCard, faBuilding, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal    from '../components/Modal';
import Spinner    from '../components/Spinner';
import { InputText, TextArea } from '../components/Input';
import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import { SmallNavbar } from '../components/Navbar';
import { NavLink }   from 'react-router-dom';
import FullLoader from '../components/FullLoader';
import { getAllUser, createUser, getGlobalBidang, deleteUser } from '../api/User';
import { onlyNumber } from '../api/Value';
import { Switch } from '@headlessui/react';
import { checkType } from '../api/Value';
import Form  from './User/Form'
import FormDetail from './User/FormDetail'
import Table from './User/Table';
import { getAllRoles } from '../api/Roles';
import axios from 'axios'
import { ActiveDropdown } from '../config/Config';
/*     
toast.custom((t) => {
    return <ToastComponent param={t} message="User Gagal das" bg="amber"/>
})
*/
export default class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            myModalForm: false,
            myDeleteForm: false,
            loading: false,
            data: [],
            detailForm: {},
            detailModal: false,
            idDeleteUser: 0,

            roles: [],
            bidang: []
        }
    }
    componentDidMount = () => {
       axios.defaults.headers.common['active_role'] = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
        
        const self=  this;
          getGlobalBidang().then((result) => {
            if(result.status == 200) {
                const data = result.data
                // console.log("DATA", data)
                const bid = data.nilai.split(",").map((x) => {
                        return { bidang: x.trim() }
                    });
                //    console.log(bid)
                self.setState({
                    bidang: bid 
                })
            }else{
                //console.log(result)
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Bidang Gagal di muat" bg="red"/>
                })
            }
        })
        this.getAll()
             const act = document.getElementById("User_nav");
        if (act) {
            act.classList.add("border-b-4")
            act.classList.add(ActiveDropdown())
        }
    }

    componentWillUnmount = () => {
         const act = document.getElementById("User_nav");
         if (act !== null) {
            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
         }
      
    }
    closeModalForm = (isUpdate=false) => {
        //console.log(isUpdate)
        if (isUpdate) {
           this.getAll();
           
        }
        this.setState({
            myModalForm: false,
            detailForm: {},
        }, () => {
         
        })
    }
    openModalForm = () => {
        this.setState({
            myModalForm: true
        })
    }
    closeDeleteForm = () => {
        this.setState({
            myDeleteForm: false
        })
    }
    openDeleteForm = (idUser) => {
         this.setState({
            myDeleteForm: true,
            idDeleteUser: idUser
        })
    }
 
    // get all pelanggan
    getAll = () => {
        const self = this;
        getAllUser().then((result) => {
            if(result.status == 200) {
                const data = result.data.map((x, index) => {
                            const user_info  = JSON.parse(x.user_info)
                            for(let u in user_info) {
                                x[u] = user_info[u]
                            }
                            x.index =index
                            return x
                        })
                self.setState({
                    data: data
                })
            }else{
                //console.log(result)
                toast.custom((t) => {
                    return <ToastComponent param={t} message="User Gagal di muat" bg="red"/>
                })
            }
        })
        getAllRoles().then((result) => {
            if(result.status == 200) {
                const data = result.data
                self.setState({
                    roles: data
                })
            }else{
                //console.log(result)
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Roles Gagal di muat" bg="red"/>
                })
            }
        })
      
    }
  
    // delete User
    deleteUser = async () => {
        const self=  this;
        deleteUser({ id_user: this.state.idDeleteUser }).then((res) => {
       //     console.log(res);   
            self.closeDeleteForm();
            self.getAll();
             toast.custom((t) => {
                return <ToastComponent param={t} message={res.data.rows_affected + " User dihapus "} bg="orange"/>
            }) 
        })
    }

    editForm = (index) => {
         this.setState({
            detailForm: this.state.data[index],
        }, () => {
            this.openModalForm();
        })
    }
    render(){
        // get object class
        const { closeDeleteForm, openModalForm, closeModalForm, openDeleteForm, openDetailUser, closeModalDetail, deleteUser, editForm } = this;
        // get value state
        const { myModalForm, myDeleteForm, loading, data, submitLoader, detailForm, detailModal, roles, bidang } = this.state;
        // is loading full screen ? 
        if(loading){
            return <FullLoader/>
        }else{
            // default render
            return (
                 <div className=" p-5 animate-fadein">
                        <Toaster position="bottom-right"/>
                        <SmallNavbar left={
                        <Fragment>
                            <FontAwesomeIcon icon={faUser}  className="mt-1" />
                            <div className="flex-initial ml-2 " >
                                User            
                            </div>
                        </Fragment>
                        } 
                            right = {
                                <button onClick={ openModalForm } className="bg-white h-10 rounded-full ml-2 p-1 text-blue-700 pl-4 pr-4 shadow-md hover:bg-gray-100 active:bg-gray-200 active:shadow-none ">
                                        <FontAwesomeIcon icon={faPlus} /> Tambah
                                    </button>
                            }
                        />
                        <div className="bg-white rounded-md p-5">
                            <Table editForm={editForm} openDeleteForm={openDeleteForm} openDetailUser={openDetailUser} openModalForm = { openModalForm } Data_User = { data }/>            
                        </div>    
                        <Modal
                            Title = "Form User"
                            Body  = {<Form bidang={bidang} closeModalForm={closeModalForm} detailForm={detailForm} roles={roles} />}
                            closeModal = {closeModalForm}
                            isOpen = {myModalForm}
                            Container = "max-w-4xl bg-white"
                        />

                        <Modal
                            Title = "Detail User"
                            Body  = {<FormDetail 
                                        closeModalForm={ closeModalDetail } 
                                        myForm={detailForm} 
                                        />}
                            Footer = {""}
                            closeModal = {closeModalDetail}
                            isOpen = {detailModal}
                            Container = "max-w-4xl bg-white"
                        />

                        <Modal
                            Title = {<span className="text-white">Hapus User</span>}
                            Body  = {<div className="text-white mt-5">Apakah Anda Yakin?</div>}
                            Footer = {<ModalDeleteFooter closeModal = {closeDeleteForm} onSubmit={deleteUser}/>}
                            closeModal = {closeDeleteForm}
                            isOpen = {myDeleteForm}
                            Container = "max-w-md bg-red-600"
                        />
                    </div>
            )
        }
    }
}


const ModalDeleteFooter = ({ closeModal, onSubmit }) => {
    const [ loader, setLoader ] = useState(false)

    const submit = () => {
        onSubmit();
        setLoader(true)
    }
    return(
        <div className="text-right flex justify-end">
            
            
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={submit} className=" font-bold bg-red-700 ml-2 p-2 shadow-md hover:bg-red-800 active:bg-red-900 active:shadow-none rounded-md w-32 text-white">
                                Yakin
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-red-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}