import { Component, Fragment } from 'react'
import { Card } from './../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons';
import { SmallNavbar } from '../components/Navbar';

import { getAllInvoice } from './../api/Invoice';
export default () => {
    
    return (
         <div>
                <Invoice/>
            </div>
    )

}

class Invoice extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [],
            hovering: false,
            menu: "aktif"
        }
    }
    
    componentDidMount = () => {
        // getAllInvoice().then((result) => {
        //     console.log(result)
        // })
    }
    setHovering = (bool) => {
        this.setState({
            hovering: bool
        })
    }
    setMenu = (type) => {
        this.setState({
            menu: type
        })
    }
    render() {
        const { hovering, menu }  = this.state;
        const { setHovering,setMenu } = this;
        return (
            <div>
                 <div className="pl-4 pr-4 pt-4">
                    <SmallNavbar 
                     left={<Fragment>
                    
                        <FontAwesomeIcon icon={faFileInvoiceDollar} />  
                        <div className="flex-initial ml-2 -mt-1 mb-2" >
                            Invoice
                        </div>
                    </Fragment>}
                    />
                 </div>
                <DetailSideBar type={menu} setMenu={setMenu} hovering={hovering} setHovering={setHovering} />
                <div className={hovering ? "p-4 ml-44" : "p-4 ml-16"}>

                    <Card>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores sint, nulla dignissimos molestias voluptas adipisci maiores facere voluptates, dolor praesentium aliquid accusantium consequuntur unde neque, enim nemo molestiae? Nihil, numquam.
                    </Card>
                </div>
            </div>
            
        )
    }
}


const DetailSideBar = ({ type,setMenu, hovering, setHovering }) => {
    
    return (
        <div onMouseOver ={() => setHovering(true)} onMouseLeave={()=>setHovering(false)}  className='bg-white mt-3 fixed z-10 p-3  w-16 transition-all	 hover:w-44 rounded-r-lg bg-gradient-to-b from-violet-500 to-fuchsia-500 pt-6 drop-shadow-md'>
            <div onClick={() => setMenu("aktif")} className={`w-10 h-10 hover:bg-white hover:text-indigo-500 cursor-pointer flex ${hovering ? "rounded-md w-full  " : "rounded-full "}  pt-2 ${type === "aktif" ? "bg-white text-indigo-500" : "text-white"} mb-4`}>
                <div className="pl-[10px]">
                    <FontAwesomeIcon className="text-xl mt-[2px]" icon={faCheck}/>
                </div>
                { hovering ? <div className="ml-2 text-center "> Aktif </div> : "" }
            </div>
            <div onClick={() => setMenu("non_aktif")} className={`w-10 h-10 hover:bg-white hover:text-indigo-500 cursor-pointer  flex ${hovering ? "rounded-md w-full  " : "rounded-full "}  pt-2 ${type === "non_aktif" ? "bg-white text-indigo-500" : "text-white"} mb-4`}>
                <div className="pl-[13px]">
               
                 <FontAwesomeIcon className="text-xl" icon={faTimes}/>
                </div>
               
                 { hovering ? <div className="ml-2 text-center "> Non Aktif </div> : "" }
            </div>
         
        </div>
    )
}