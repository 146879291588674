import { Component, Fragment } from 'react';
import { SmallNavbar } from '../../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUser, faSort, faPhone, faMailBulk, faAddressCard, faBuilding, faEdit, faTrash, faList, faMapSigns } from '@fortawesome/free-solid-svg-icons';
import { Card, ChipTable } from '../../components/Card';
import Datatable from '../../components/Datatable';
import { NavLink }   from 'react-router-dom';
import { getAllPelanggan } from '../../api/Pelanggan';
import { ActiveDropdown } from '../../config/Config';
import { getLaporan } from '../../api/Laporan';

export default class Pelanggan extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: []
        }
    }
    componentDidMount  = () => {
        const act = document.getElementById("Laporan_nav");
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/laporan/pelanggan", title: "Laporan Pelanggan" }));
        if(act){ 
                act.classList.add("border-b-4")
                act.classList.add(ActiveDropdown())
        }
        this.getData();
    }
    getData = () => {
        const self = this;
        getLaporan({ type: "pelanggan" }).then((res) => {
            if (res.status === 200) {
                self.setState({
                    data: (res.data === null) ? [] : res.data.map((x) => { 
                        let user_info = JSON.parse(x.user_info);
                        return {...x, ...user_info}
                     })
                })
            }
        })
    }
    componentWillUnmount = () => {
        const act = document.getElementById("Laporan_nav");
        if (act !== null) {

            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
    }
    openDeleteForm = () => {

    }
    render(){
        const { openDeleteForm } = this;
        const { data }           = this.state;
        return (
            <div className="p-5 animate-fadein">
                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Pelanggan
                            </div>
                        </Fragment>}
                />

                <div className="pt-3">
                    <Card>
                        <TablePelanggan openDeleteForm={openDeleteForm} Data_pelanggan={data} />
                    </Card>
                </div>
            </div>
        )
    }
}


const TablePelanggan = ({ openDeleteForm, Data_pelanggan }) => {
 
    const theadPelanggan = ({ sort, order_by }) => {
    //console.log(order_by)
    return (
      <thead>
        
        <tr>
            <td className=" border-2 border-gray-300 p-2 w-20 text-center">ID </td>
            <td className=" border-2 border-gray-300 p-2 text-center">Nama/Alamat </td>
            <td className=" border-2 border-gray-300 p-2 text-center">Jumlah</td>
          
        </tr>
       
      </thead>
      
    )
  }

  
  const Export  = ({ exportFile }) => {
      return (
          <div>
            <button onClick={() => exportFile("print", "Pelanggan", "id_user,nama,alamat,jumlah")} className="hover:bg-gray-400 active:bg-gray-600 active:shadow-none bg-gray-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Print</button>
            <button onClick={() => exportFile("excel", "Pelanggan", "id_user,nama,alamat,jumlah")} className="hover:bg-teal-400 active:bg-teal-600 active:shadow-none border-teal-600 bg-teal-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Excel</button>
          </div>
      )
  }
  return (
    <div>
        <Datatable 
            Export={ Export }
          Head={theadPelanggan}
          Data={Data_pelanggan}
          Entries={[]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
            return (
                <tr key={ index }>
                <td className={"border-2 p-2 border-gray-300 align-top text-center" + mod}>
                    <div className="text-center">
                        {row.id_user}    
                    </div>
                </td>
                <td className={"border-2 p-2 border-gray-300 align-top " + mod} width="80%">
                    <NavLink to={"/app/laporan/pelanggan/" + row.id_user} >
                        <ChipTable icon={<FontAwesomeIcon icon={faAddressCard} />} text={<span className="text-blue-500 pointer">{row.nama}</span> } />
                    </NavLink>
                    <ChipTable icon={<FontAwesomeIcon icon={faMapSigns} />} text={row.alamat} />
                
                </td>
                
                <td className={"border-2 p-2 text-center border-gray-300 align-top" + mod} width="10%">
                    <div className={` inline-block pl-2 pr-2 rounded-md`}>{ row.jumlah }</div>    
                   
                </td>
             
              </tr>
            )
          }}
        />
    </div>
  )
}