import { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faList, faChalkboardTeacher, faChalkboard,faCoins, faChartLine,faBox } from '@fortawesome/free-solid-svg-icons';
import { Disclosure } from '@headlessui/react'
import Datatable from '../../../components/Datatable';
import { numberFormat, reverseDate } from '../../../api/Value'
export default ({ myForm }) => {
    console.log(myForm)
    return (    
        <div>
            {
                myForm.keranjang.map((x, i) => {
                   // console.log(x)
                    return (
                        <div key={i} className="rounded-lg shadow-md mb-4">
                        
                        <div className="bg-blue-600 p-3 text-white rounded-t-lg w-full p-">
                            { x.uraian_keranjang}
                        </div>
                        
                        <div className="bg-white rounded-b-lg p-2 ">
                           <div className="grid grid-cols-2">
                                <div>
                                    <table width="100%">
                                        <tbody>
                                            <ChipKeranjang title="Nama Layanan" customIcon="text-white bg-orange-500 rounded-full  " customCard="rounded-full p-1 "  icon={<FontAwesomeIcon icon={faChalkboardTeacher} className="-ml-[2px]" />} text={x.nama_layanan} />
                                            <ChipKeranjang title="ID Keranjang" customIcon="text-white bg-green-500 rounded-full  " customCard="rounded-full p-1 " icon={<FontAwesomeIcon icon={faList} />} text={x.id_keranjang} />
                                            <ChipKeranjang title="Uraian" customIcon="text-white bg-purple-500 rounded-full " customCard="rounded-full p-1 "  icon={<FontAwesomeIcon icon={faChalkboard} className="-ml-[2px]" />} text={x.uraian_keranjang} />
                                            <ChipKeranjang title="Tanggal" customIcon="text-white bg-red-500 rounded-full  " customCard="rounded-full p-1 "  icon={<FontAwesomeIcon icon={faChartLine} />} text={reverseDate(x.info_keranjang.tgl_awal) + " s.d " + reverseDate(x.info_keranjang.tgl_akhir)} />
                                
                                            <ChipKeranjang title="Kuantitas" customIcon="text-white bg-indigo-500 rounded-full " customCard="rounded-full p-1 "  icon={<FontAwesomeIcon icon={faBox} />} text={x.kuantitas} />
                                            <ChipKeranjang title="Tarif" customIcon="text-white bg-blue-500 rounded-full " customCard="rounded-full p-1 "  icon={<FontAwesomeIcon icon={faCoins} />} text={ "Rp. " + numberFormat(`${x.tarif_keranjang}`) } />
                                            <ChipKeranjang title="Jumlah" customIcon="text-white bg-red-500 rounded-full  " customCard="rounded-full p-1 "  icon={<FontAwesomeIcon icon={faChartLine} />} text={"Rp. " +  numberFormat(`${x.jumlah_keranjang}`)} />
                                
                                        </tbody>
                                    </table>
                                    
                                </div>
                                       
                                <div className="p-1">
                                     {
                                        (x.info_keranjang.list_peserta) ? 
                                            <Disclosure>
                                                    {({ open }) => (
                                                        <Fragment>
                                                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium text-left text-black bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                                <span>List Peserta</span>
                                                                <FontAwesomeIcon icon={faChevronUp}
                                                                className={`${
                                                                    open ? 'transform rotate-180' : ''
                                                                } w-5 h-5 text-purple-500`}
                                                                />
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel className="px-4 pt-4 pb-2">
                                                                <TablePeserta DataPeserta={x.info_keranjang.list_peserta}/>
                                                            </Disclosure.Panel>
                                                        </Fragment>
                                                    )}
                                                </Disclosure>                          
                                            : 
                                            null
                                        }
                                </div>
                            </div>
                             
                        </div>
                        </div>

                    )
                })
            }
        </div>
    )
}


const TablePeserta =  ({  DataPeserta}) => {

  const theadPelanggan = ({ sort, order_by }) => {
    return (
      <thead>
        
        <tr>
            <td className=" border-2 border-gray-200 p-2 w-20 text-center">No </td>
            <td className=" border-2 border-gray-200 p-2 text-center">Nama Peserta </td>
            <td className=" border-2 border-gray-200 p-2 text-center">Biaya Peserta </td>
            
            
        </tr>
       
      </thead>
      
    )
  }

  return (
    <div>
        <Datatable 
        
          Head={theadPelanggan}
          Data={DataPeserta}
          Entries={[]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
            return (
              <tr key={ index }>
                <td width="10%" className={"border-2 p-2 border-gray-300 align-top text-center" + mod}>
                    <div className="text-center">
                        {index+1}    
                    </div>
                </td>
                <td className={"border-2 p-2 border-gray-300 align-top " + mod} width="40%">
                    {row.namaPeserta}
                </td>
                  <td className={"border-2 p-2 border-gray-300 align-top " + mod} width="40%">
                    {row.biayaPeserta}
                </td>
                
                
             
              </tr>
            )
          }}
        />
    </div>
  )

}

const ChipKeranjang = ({ title, text, icon, customIcon }) => {
    return (
        <tr>
        
            <td width="100%">
                <div className="flex bg-white ">
                    <div className="bg-violet-500 rounded-l-md text-white p-2 w-52">
                        { title }
                    </div>
                    <div className="p-2 border-b-2 rounded-r-lg border-gray-300 w-full">
                        { text }
                    </div>
                </div>
                
            </td>
           
        </tr>
    )
}