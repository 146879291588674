import '../../pajak.css';

export default () => {
    return (
 
    <div className="arial bg-white file justify p-[1.5cm] pl-[2.8cm] pr-[2.8cm]">
        <table width="100%" style={{borderBottom: "#000000 solid 3px"}}>
            <tbody>
                <tr>
                    <td align="center" valign="top" width="20%">
                        <img src={require("./../../pjk.JPG")} width="80" alt=""/>
                    </td>
                    <td align="center" style={{lineHeight: "11px", paddingBottom: "7px"}}>
                        <div style={{fontSize: "16px", fontWeight: "bold"}}>
                            KEMENTERIAN KEUANGAN REPUBLIK INDONESIA <br/>
                        </div>
                        <div style={{lineHeight: "17px",paddingTop: "3px", fontSize: "13px", fontWeight: "bold"}}>
                            DIREKTORAT JENDERAL PAJAK <br/>
                            KANTOR WILAYAH DIREKTORAT JENDERAL PAJAK JAKARTA TIMUR <br/>
                            KANTOR PELAYANAN PAJAK PRATAMA JAKARTA PASAR REBO <br/>
                        </div>
                        <div style={{fontSize: "9px", lineHeight: "11px"}}>JL. RAYA BOGOR NO. 46 CIRACAS, JAKARTA TIMUR 13830 <br/>
                            TELEPON (021) 87799512; FAKSIMILE (021) 8400486; LAMAN www.pajak.go.id <br/>
                            LAYANAN INFORMASI DAN PENGADUAN KRING PAJAK (021) 1500200 <br/>
                            SUREL pengaduan@pajak.go.id; informasi@pajak.go.id
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style={{fontSize: "13px"}} className="pt-4 ">
            <div className="pb-4">
                <table width="100%"  style={{lineHeight: "14px"}}>
                    <tbody>
                        <tr>
                            <td>Nomor</td>
                            <td>:</td>
                            <td>S-1917/WPJ.20/KP.08/2021</td>
                            <td>18 Oktober 2021</td>
                        </tr>
                        <tr>
                            <td>Sifat</td>
                            <td>:</td>
                            <td>Segera</td>
                        </tr>
                        <tr>
                            <td>Hal</td>
                            <td>:</td>
                            <td>Penegasan Bahwa PPSDM KEBTKE Bukan Pengusaha Kena Pajak</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        <div style={{lineHeight: "16px"}} className="mb-4">
            Yth. Kepala PPSDM Ketenagalistrikan, Energi Baru, Terbarukan dan Konservasi Energi <br/>
            Jalan Poncol Raya No.39 <br/>
            Ciracas, Jakarta Timur 
        </div>
        
        &nbsp;&nbsp;&nbsp;&nbsp;Sehubungan dengan surat Saudara nomor B-455/KU.03/BPE/2021 tanggal 19 September 2021 hal Penegasan PPSDM KEBTKE Bukan Pengusaha Kena Pajak, dengan ini disampaikan
        hal-hal sebagai berikut:
        <ol className="list-decimal	ml-[0.4cm]">
            <li>Dalam surat tersebut dikemukakan bahwa berdasarkan Keputusan Menteri Keuangan nomor 965/KMK.05/2017 tentang Penetapan Pusat Pengembangan Sumber Daya Manusia 
                Ketenagalistrikan, Energi Baru, Terbarukan dan Konservasi Energi Pada Kementerian Energi dan Sumber Daya Mineral Sebagai Instansi Pemerintah Yang Menerapkan Pola 
                Pengelolaan Keuangan Badan Layanan Umum, menetapkan Pusat Pengembangan Sumber Daya Manusia Ketenagalistrikan, Energi Baru, Terbarukan dan Konservasi Energi Pada Kementerian Energi 
                dan Sumber Daya Mineral sebagai Instansi Pemerintah yang menerapkan PPK-BLU dengan status BLU Penuh;
            </li>
            <li>
                Berdasarkan Undang-Undang Nomor 7 Tahun 1983 tentang Pajak Penghasilan sebagaimana telah diubah 
                terakhir dengan Undang-Undang Nomor 36 Tahun 2008 mengatur antara lain: 
                <ol className="list-[lower-alpha] ml-[0.35cm]">
                    <li>Pasal 2 ayat (3) huruf b bahwa yang dikecualikan sebagai subjek pajak dalam negeri diantaranya adalah unit tertentu dari badan pemerintah yang memenuhi kriteria:
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td valign="top">1)</td>
                                    <td valign="top" className="pl-1">Pembentukannya berdasarkan ketentuan peraturan perundang-undangan;</td>
                                </tr>
                                <tr>
                                    <td valign="top">2)</td>
                                    <td valign="top" className="pl-1">Pembiayaannya bersumber dari Anggaran Pendapatan dan Belanja Negara atau Anggaran Pendapatan dan Belanja Daerah;</td>
                                </tr>
                                <tr>
                                    <td valign="top">3)</td>
                                    <td valign="top" className="pl-1">Penerimaannya dimasukkan dalam anggaran Pemerintah Pusat atau Pemerintah Daerah;</td>
                                </tr>
                                <tr>
                                    <td valign="top">4)</td>
                                    <td valign="top" className="pl-1">Pembukuannya diperiksa oleh aparat pengawasan Fungsional Negara.</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>Pasal 21 ayat (1) bahwa pemotongan pajak atas penghasilan sehubungan dengan pekerjaan, jasa, atau kegiatan dengan nama dan bentuk apapun yang ditermia atau diperolah
                        Wajib Pajak orang pribadi dalam negeri wajib dilakukan oleh:
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td valign="top">1)</td>
                                    <td valign="top" className="pl-1">pemberi kerja yang membayar gaji, upah, honorarium, tunjungan, dan pembayaran lain sebagai 
                                        imbalan sehubungan dengan pekerjaan yang dilakukan oleh pegawai atau bukan pegawai;
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </li>
                   
                </ol>
            </li>
          
        </ol>
        
       
        </div>
       
        </div>
    
    )
}