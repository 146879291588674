import '../../pajak.css';

export default () => {
    return (
        <div style={{fontSize: "13px"}}>
    <div className="arial bg-white file justify p-[1.5cm] pl-[2.8cm] pr-[2.8cm] pt-[0.5cm]">
    <table width="100%" className="ml-[23px]">
        <tbody>
            <tr>
                <td valign="top">2)</td>
                <td valign="top" className="pl-1">bendahara pemerintah yang membayar gaji, upah, honorarium, tunjungan, dan pembayaran lain sehubungan dengan pekerjaan, jasa, atau kegiatan</td>
            </tr>
            <tr>
                <td valign="top">3)</td>
                <td valign="top" className="pl-1">dana pensiun atau badan lain yang membayarkan uang pensiun dan pembayaran lain dengan nama apa pun dalam rangka pensiun;</td>
            </tr>
            <tr>
                <td valign="top">4)</td>
                <td valign="top" className="pl-1">badan yang membayar honorarium atau pembayaran lain sebagai imbalan sehubungan dengan jasa termasuk jasa tenaga ahli yang melakukan pekerjaan bebas; dan</td>
            </tr>
            <tr>
                <td valign="top">5)</td>
                <td valign="top" className="pl-1">penyelenggara kegiatan yang melakukan pembayaran sehubungan dengan pelaksanaan suatu kegiatan.</td>
            </tr>
        </tbody>
    </table>
        <ol className="list-decimal	ml-[0.4cm]" start="3">
         
            <li>
                Berdasarkan Undang-Undang Nomor 8 Tahun 1983 tentang Pajak Pertambahan Nilai Barang dan Jasa dan Pajak Penjualan Atas Barang Mewah sebagaimana telah diubah terakhir 
                dengan Undang-Undang Nomor 42 Tahun 2009 mengatur antara lain: 
                <ol className="list-[lower-alpha] ml-[0.4cm]">
                  
                        <li>Pasal 1, bahwa Pengusaha Kena Pajak adalah pengusaha yang melakukan penyerahan barang kena pajak dan/atau penyerahan jasa kena pajak yang dikenai pajak berdasarkan Undang-Undang PPN;</li>
                        <li>Pasal 4A ayat (3) huruf g, bahwa jasa pendidikan termasuk jenis jasa yang tidak dikenai Pajak Pertambahan Nilai;</li>
       
                </ol>
            </li>
            <li>
                Berdasarkan Pasal 2 Peraturan Menteri Keuangan Nomor 223/PMK.011/2014 tentang Kriteria Jasa Pendidikan Yang Tidak Dikenai Pajak Pertambahan Nilai, antara lain mengatur:
                <ol className="list-[lower-alpha] ml-[0.4cm]">
                    <li>Pasal 2 ayat (1), bahwa Jasa tertentu dalam kelompok Jasa Pendidikan termasuk dalam jenis jasa yang tidak dikenai Pajak Pertambahan Nilai; </li>
                    <li>Pasal 2 ayat (2), bahwa kelompok jasa pendidikan yang tidak dikenai Pajak Pertambahan Nilai sebagaimana dimaksud pada pasal 2 ayat (1) meliputi: 
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td valign="top">1)</td>
                                    <td valign="top" className="pl-1">Jasa penyelenggaraan jasa Penyelenggaraan Pendidikan sekolah, seperti jasa penyelenggaraan pendidikan umum, pendidikan
                                        kejuruan, pendidikan luar biasa, pendidikan kedinasan pendidikan keagamaan pendidikan akademik dan pendidikan professional;
                                        dan
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">2)</td>
                                    <td valign="top" className="pl-1">Jasa Penyelenggaraan Pendidikan luar sekolah;</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                </ol>
            </li>
     
        </ol>
        

        </div>
       
        </div>
    
    )
}