export default  ({ myForm, closeModalForm }) => {
    return (
        <div>
            <br />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <table width="100%">
                            <tbody>      
                                <TableRow title="Username" bg="bg-gray-200" value={<div className="w-[250px] overflow-x-auto">{myForm.username}</div>} />  
                                <TableRow title="NIK" bg="" value={myForm.nik} />  
                                <TableRow title="Nama" bg="bg-gray-200" value={myForm.nama} />  
                                <TableRow title="Alamat" bg="" value={myForm.alamat} />  
                                <TableRow title="Telp" bg="bg-gray-200" value={myForm.telp} />  
                                <TableRow title="Email" bg="" value={myForm.email} />  
                                <TableRow title="NPWP" bg="bg-gray-200" value={myForm.npwp} />  
                                <TableRow title="Instansi" bg="" value={myForm.instansi} />  
                                                      <TableRow title="Nama Tujuan 1" bg="bg-gray-200" value={myForm.nama_tujuan_1} />  
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table width="100%">
                            <tbody>      
                                <TableRow title="Alamat Tujuan 1" bg="bg-gray-200" value={myForm.alamat_tujuan_1} />  
                                <TableRow title="Nama Tujuan 2" bg="" value={myForm.nama_tujuan_2} />  
                                <TableRow title="Alamat Tujuan 2" bg="bg-gray-200" value={myForm.alamat_tujuan_2} />  
                                <TableRow title="Nama CP Konsumen" bg="" value={myForm.nama_cp_konsumen} />  
                                <TableRow title="HP CP Konsumen" bg="bg-gray-200" value={myForm.hp_cp_konsumen} />  
                                <TableRow title="Email CP Konsumen" bg="" value={myForm.email_cp_konsumen} />  
                                <TableRow title="Nama CP Internal" bg="bg-gray-200" value={myForm.nama_cp_internal} />  
                                <TableRow title="HP CP Internal" bg="" value={myForm.hp_cp_internal} />  
                                <TableRow title="Email CP Internal" bg="bg-gray-200" value={myForm.email_cp_internal} />  
                            </tbody>
                        </table>
                    
                    </div>
                </div>
            
            <br />
            <div className="text-right flex justify-end">
                <button className="font-bold bg-gray-600 p-2 shadow-md hover:bg-gray-500 active:bg-gray-700 active:shadow-none rounded-md w-32  text-white" onClick={closeModalForm}>TUTUP</button>
            </div>
        </div>
    )
}

const TableRow = ({ title, value, bg  }) => {
    return (
        <tr>
            <td valign="top" className={"w-48 p-2 " + bg}>{ title }</td>
            <td valign="top" className={"p-2 " + bg}>:</td>
            <td valign="top" className={" p-2 " + bg}> { value }</td>
        </tr>
    )
}