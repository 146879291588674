import { Component,useState, Fragment }  from 'react';
import { Card, InfoCard, ChipTable } from '../components/Card';
import { InputText, TextArea, Radio, Select, AutoComplete } from '../components/Input';
import Modal from '../components/Modal';
import { getAllProductUnit, getKategori } from '../api/Layanan';
import { getAllPelanggan } from '../api/Pelanggan';
import Spinner    from '../components/Spinner';
import Datatable from '../components/Datatable';
import { faPlus, faSort,faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SmallNavbar } from '../components/Navbar';
import ModalFooter from '../components/ModalFooter';
import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import Keranjang from './Transaksi/Keranjang';
import Produk from './Transaksi/Produk';
import { bankVa, pembayaranBidang } from '../api/Laporan.js';
import { Navigate, useParams   } from "react-router-dom";
import { createTransaksi, getOneTransaksi, updateTransaksi, getRekening, getAksesBidang, getStatusTarif, getStatusTarifEdit } from '../api/Transaksi'
import { addKeranjang, updateItemKeranjang } from '../api/Keranjang'
import { ActiveDropdown } from '../config/Config';
import FullLoader from '../components/FullLoader';
import { getBidang } from '../api/Value';
class Transaksi extends Component{
    constructor(props){ 
        super(props);
        this.state = {
            isShowingModal: false,
            typeForm: "",
            pelanggan: [],
            layanan: [],
            myModalForm: false,
            myProductForm: false,
            myDetailForm: false,
            myForm: {
                id_pelanggan: 0, 
                uraian: "",
                info_transaksi: { 
                    id_app: 1,
                    no_kontrak: "", 
                    tgl_awal: "",  
                    tgl_akhir: "",
                    nama_tagihan: "", 
                    alamat_tagihan: "", 
                    email_tagihan: "", 
                    no_telp_tagihan: "",
                    tipe_pembayaran: "",
                    nama_bank: "",
                    nomor_rekening: "",
                    nama_rekening: ""
                },
                tipe_transaksi: ""
            },
            formState: {
                uraian: "",
                tgl_awal: "",
                tgl_akhir: "",
                tujuan: "",
                nama_konsumen: "",
                no_kontrak: "",
                alamat: "",
                email:"",
                no_telp: "",
                rekening: "",
                tipe_transaksi: ""
            },
            produk_selected: {},
            pelanggan_selected: {},
            listTujuan: [],
            keranjang: "",
            loader: false,
            navigateToPending: false,
            ready: false,
            id_transaksi: null,
            list_hp: [],
            list_email: [],
            list_rekening: [],
            list_kategori: [],
            list_tipe_transaksi: [],
            statusNilai: false,
            statusTarif: false,
            list_bank_va: [],
            list_tipe_pembayaran: []
        }
    }
    componentDidMount  = () => {
        this.reqServer();
    }
    reqServer = async () => {
        const act = document.getElementById("Transaksi_nav");
        if(act){ 
                act.classList.add("border-b-4")
                act.classList.add(ActiveDropdown())
        }

        const self = this;
        let pelanggan = []
        await getAllProductUnit().then((x) => {
           //  // console.log(x)
             if (x.status == 200) {
                self.setState({
                    layanan: (x.data) ? x.data : []
                })   
             }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Produk Gagal di muat" bg="red"/>
                })
             }
            
        })
        await getAllPelanggan().then((x) => {
             //// console.log(x)
             if (x.status == 200) {
                let dataP= (x.data) ? x.data.map((d) => {
                    const user_info = JSON.parse(d.user_info);
                    return {id: d.id_user ,title: user_info.nama, ...d}
                }).reverse() : [];
                pelanggan = dataP
                self.setState({
                    pelanggan: dataP
                }, () => {
                    self.myEdit()
                })   
             }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Konsumen Gagal di muat" bg="red"/>
                })
             }
        })
        await getRekening().then((x) => {
            if (x.status == 200) {
                const { data } = x;
                
                self.setState({
                    list_rekening: (data.nilai) ? JSON.parse(data.nilai).map((c,h) => {
                        c.index = h;
                        return c;
                    }) : []
                })   
             }else{
                 let str = ""
                 if (x.message) {
                     if (x.message.data) {
                         if (x.message.data.messages) {
                             str =x.message.data.messages
                         }
                     }
                 }
            //     console.log("Rekening ", x)
                toast.custom((t) => {
                    return <ToastComponent param={t} message={str} bg="red"/>
                })
             }
        })
        await getKategori().then(x => {
            if (x.status == 200) {
                const { data } = x;
                
                self.setState({
                    list_kategori: (data.nilai) ? data.nilai.split(",") : []
                })   
             }else{
                 let str = ""
                 if (x.message) {
                     if (x.message.data) {
                         if (x.message.data.messages) {
                             str =x.message.data.messages
                         }
                     }
                 }
            //     console.log("Rekening ", x)
                toast.custom((t) => {
                    return <ToastComponent param={t} message={str} bg="red"/>
                })
             }
        })
        // pembayaran_bidang
        await pembayaranBidang().then(x => {
            if (x.status == 200) {
                const { data } = x;
                const myBidang = getBidang();
                const obj_bidang = JSON.parse(data.nilai);
                if (obj_bidang[myBidang]) {
                    self.setState({
                        list_tipe_pembayaran: obj_bidang[myBidang]
                    })
                    
                }else{
                    toast.custom(t => {
                        return (
                            <ToastComponent param={ t } message={`Tipe Pembayaran ${myBidang} Tidak Ditemukan di Global Param`} bg="red"/>
                        )
                    })
                }
             }else{
                 let str = ""
                 if (x.message) {
                     if (x.message.data) {
                         if (x.message.data.messages) {
                             str =x.message.data.messages
                         }
                     }
                 }
            //     console.log("Rekening ", x)
                toast.custom((t) => {
                    return <ToastComponent param={t} message={str} bg="red"/>
                })
             }
        })
        await getAksesBidang().then(x => {
            if (x.status == 200) {
                const { data } = x;
                const myBidang = getBidang();
                const obj_bidang = JSON.parse(data.nilai);
                if (obj_bidang[myBidang]) {
                    self.setState({
                        list_tipe_transaksi: obj_bidang[myBidang]
                    })
                }else{
                    toast.custom(t => {
                        return (
                            <ToastComponent param={ t } message={`Tipe Transaksi ${myBidang} Tidak Ditemukan di Global Param`} bg="red"/>
                        )
                    })
                }
             }else{
                 let str = ""
                 if (x.message) {
                     if (x.message.data) {
                         if (x.message.data.messages) {
                             str =x.message.data.messages
                         }
                     }
                 }
            //     console.log("Rekening ", x)
                toast.custom((t) => {
                    return <ToastComponent param={t} message={str} bg="red"/>
                })
             }
        })
        await getStatusTarif().then(x => {
            if (x.status == 200) {
                const { data } = x;
                
                if (data['nilai']) {
                    self.setState({
                        statusNilai: (data['nilai'] === "false")
                    })
                }else{
                    toast.custom(t => {
                        return (
                            <ToastComponent param={t} message={`Status Tarif Tidak Ditemukan di Global Param`} bg="red"/>
                        )
                    })
                }
             }else{
                 let str = ""
                if (x.message) {
                    if (x.message.data) {
                        if (x.message.data.messages) {
                            str =x.message.data.messages
                        }
                    }
                }
                toast.custom((t) => {
                    return <ToastComponent param={t} message={str} bg="red"/>
                })
             }
        })

        await bankVa().then(res => {
            if (res.status == 200) {
                const { nilai } = res.data;
                self.setState({
                    list_bank_va: (nilai) ? JSON.parse(nilai) : []
                })
            }else{
                toast.custom(t => {
                    return <ToastComponent param={ t } message="Gagal Mendapatkan List Bank VA" />
                })
            }
        })

        await getStatusTarifEdit().then(res => {
            if (res.status == 200) {
             const dataJson = JSON.parse(res.data.nilai);   
             const myBidang = getBidang();
                self.setState({
                    statusTarif: dataJson[myBidang]
                })
            }else{
                toast.custom(t => {
                    return <ToastComponent param={ t } message="Gagal Mendapatkan Status Tarif Editable" />
                })
            }
        })
        this.setState({
            ready: true
        })
    }
    myEdit = () => {
        const self = this;
        const myUrl = new URL(window.location.href);

        if (myUrl.pathname.split("/")[3] !== undefined) {
            
            getOneTransaksi({ id_transaksi: myUrl.pathname.split("/")[3] }).then((res) => {
                if (res.status === 200) {
                    let myForm = this.state.myForm;
                    const row = res.data;
                    const cariPelanggan = self.state.pelanggan.filter((x) => x.id_user === row.id_pelanggan);

                    myForm.id_pelanggan = row.id_pelanggan;
                    myForm.uraian  = row.uraian;
                    myForm.info_transaksi =    JSON.parse(row.info_transaksi)
                    myForm.bidang = row.bidang;
                    myForm.history = row.history;
                    myForm.tipe_transaksi = row.tipe_transaksi
                    // console.log(myForm)
                    this.setState({
                        myForm: myForm,
                        id_transaksi: row.id_transaksi
                    }, () => {
                        self.onSelectKonsumen(cariPelanggan[0])
                    })
                }
            })
        }
    }
    componentWillUnmount = () => {
         const act = document.getElementById("Transaksi_nav");
        if (act) {
             act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
       
    }
    onSelectKonsumen = (d) => {
        const x = d;
        let myForm = this.state.myForm;
        const user_info = JSON.parse(x.user_info);
        this.setState({
            listTujuan: []
        }, () => {

        let listTujuan  = [];
        myForm['id_pelanggan'] = x.id_user

        listTujuan.push({ nama: user_info.nama, alamat: user_info.alamat })
        let isFill = false;
        if (user_info.nama_tujuan_1 !== undefined && user_info.nama_tujuan_1 !== "") {
            listTujuan.push({ nama: user_info.nama_tujuan_1, alamat: user_info.alamat_tujuan_1 })
            myForm['info_transaksi']['nama_tagihan'] = user_info.nama_tujuan_1;
            myForm['info_transaksi']['alamat_tagihan'] = user_info.alamat_tujuan_1;
            isFill = true;
        }
        
        if (user_info.nama_tujuan_2 !== undefined && user_info.nama_tujuan_2 !== "") {
            listTujuan.push({ nama: user_info.nama_tujuan_2, alamat: user_info.alamat_tujuan_2 })
        }
        
        if (!isFill) {
            myForm['info_transaksi']['nama_tagihan'] = user_info.nama;
            myForm['info_transaksi']['alamat_tagihan'] = user_info.alamat;
        }

        let list_email  = []
        
        if (user_info.email !== undefined && user_info.email !== "") {
            list_email.push(user_info.email)
            myForm['info_transaksi']['email_tagihan'] = user_info.email;

        }
        
        if (user_info.email_cp_konsumen !== undefined && user_info.email_cp_konsumen !== "") {
            list_email.push(user_info.email_cp_konsumen)
        }
       // console.log(list_email)
        let list_hp     = [];
        if (user_info.hp_cp_konsumen !== undefined && user_info.hp_cp_konsumen !== "") {
            list_hp.push(user_info.hp_cp_konsumen)
        }
        if (user_info.telp !== undefined && user_info.telp !== "") {
            list_hp.push(user_info.telp)
            myForm['info_transaksi']['no_telp_tagihan'] = user_info.telp;

        }
        this.setState({
            pelanggan_selected: user_info,
            listTujuan: listTujuan,
            myForm:myForm,
            list_hp: list_hp,
            list_email: list_email
        }, () => {
            setTimeout(() => {
                 const ac = document.getElementById("ac-form");
                //console.log(ac)
                if (ac) {
                    ac.value = user_info.nama
                    
                }    
            }, 800);
            
        })
    })

    }
    cariKode = (kode) => {
        const layanan = this.state.layanan;
        return layanan.filter((x) => x.kode_layanan == kode)[0]
    }
    openModal = (type, param) => {
        switch (type) {
            case "produk":                  
                    const myForm = this.state.myForm;
                    if (myForm.tipe_transaksi === "") {
                        toast.custom(t => {
                            return (
                                <ToastComponent param={t} message="Mohon Pilih Tipe Transaksi Terlebih Dahulu" bg="red" />
                            )
                        })
                    }else{
                            
                        this.setState({
                            myProductForm: true
                        })  
                    }     
                break;
            case "form": 
                    const typeLimit = this.state.myForm.tipe_transaksi;
                    if (typeLimit === "kedinasan") {
                      const str = JSON.stringify(param.row);
                      let data = JSON.parse(str);
                      data['tarif'] = 0
                      this.setState({
                            produk_selected: data,
                            myProductForm: false,
                            myModalForm: true
                        })
                    }else{
                            
                        this.setState({
                            produk_selected: param.row,
                            myProductForm: false,
                            myModalForm: true
                        })
                    }
                break;
            case "edit_form":
                    this.setState({
                        produk_selected: this.cariKode(param.kode_layanan),
                        myModalForm: true,
                        myProductForm: false,
                       myDetailForm: false
                    }, () => {
                        // console.log(this.state)
                    })
                    break;
            case "detailKeranjang": 
                 this.setState({
                       myDetailForm: true
                    })
                break;
            default:
                break;
        }
    }
    closeModal = (type, param) => {
        switch (type) {
            case "produk":                  
                    this.setState({
                        myProductForm: false
                    }, () => {
                        setTimeout(() => {
                            document.getElementsByTagName("html")[0].removeAttribute("style")
                            
                        }, 1000);

                    })       
                break;
            case "form": 
                 this.setState({
                        produk_selected: {},
                        myProductForm: false,
                        myModalForm: false,
                        keranjang: (param !== undefined) ? JSON.stringify(param.keranjang) : this.state.keranjang
                    }, () => {
                        setTimeout(() => {
                            document.getElementsByTagName("html")[0].removeAttribute("style")
                            
                        }, 1000);
                    })
                break;
            case "detailKeranjang": 
                 this.setState({
                       myDetailForm: false
                    })
                break;
            default:
                break;
        }
    }
    onChangeLayanan = (e) => {
        this.setState({
            typeForm: e.target.value
        }, () => {
            this.openModal();
        })
    }
    bindForm = (e) => {
        const value = e.target.value;
        const name  = e.target.name;
        let myForm = this.state.myForm;
        if (name === "tgl_awal" || name === "tgl_akhir" || name === "no_kontrak") {
            myForm["info_transaksi"][name] = value;
        }else{
            myForm[name] = value;
        }
        this.setState({
            myForm: myForm
        })
    }
    updateKeranjang = (newData) => {
        this.setState({
            keranjang: JSON.stringify(newData)
        })
    }
    handleKonsumen = (e) => {
        
        let myForm     = this.state.myForm;
        myForm["info_transaksi"]["nama_tagihan"] = e.target.value
        this.setState({
            myForm: myForm
        })
    }
    handleAlamat = (e) => {

        let myForm     = this.state.myForm;
        myForm["info_transaksi"]["alamat_tagihan"] = e.target.value
        this.setState({
            myForm: myForm
        })
    }
    simpanTransaksi = async () => {
        let myForm    = this.state.myForm;
        let jumlah_keranjang = 0;

        let keranjang = this.state.keranjang
        let formState = this.state.formState;
        let isValid   = true;
        if (keranjang === "[]" || keranjang === "") {
            toast.custom((t) => {
                return <ToastComponent param={t} message="Anda Belum Mengisi Keranjang" bg="red"/>
            })
            isValid = false
        }else{
            
            JSON.parse(keranjang).map((x) => {
                jumlah_keranjang += parseInt(x.jumlah_keranjang)
                return x;
            });
        }
        
        if (myForm["uraian"] === "") {
            formState["uraian"] = "required";
            isValid = false;
        }
        if (myForm['tipe_transaksi'] === "") {
            formState['tipe_transaksi'] = "required";
            isValid = false;
        }
        if (myForm["id_pelanggan"] === 0) {
            formState["nama_konsumen"] = "required";
            isValid = false;
        }
        if (myForm["info_transaksi"]["tgl_awal"] === "") {
            formState["tgl_awal"] = "required";
            isValid = false;
            
        }
         if (myForm["info_transaksi"]["tgl_akhir"] === "") {
            formState["tgl_akhir"] = "required";
            isValid = false;
        }
        if (myForm["info_transaksi"]["nama_tagihan"] === "") {
            formState["tujuan"] = "required";
            isValid = false;

        }
          if (myForm["info_transaksi"]["alamat_tagihan"] === "") {
            formState["alamat"] = "required";
            isValid = false;

        }
        if (myForm["info_transaksi"]["email_tagihan"] === "") {
            formState["email"] = "required";
            isValid = false;

        }
    
        if (myForm["info_transaksi"]["no_telp_tagihan"] === "") {
            formState["no_telp"] = "required";
            isValid = false;
        }
        // jika kedinasan tipe pembayaran tidak required
        if (myForm["info_transaksi"]["tipe_pembayaran"] === "" && myForm['tipe_transaksi'] !== "kedinasan") {
            formState["tipe_pembayaran"] = "required";
            isValid = false;
        }else if(myForm['tipe_transaksi'] !== "kedinasan"){
            if (myForm["info_transaksi"]["tipe_pembayaran"] === "Transfer" && myForm["info_transaksi"]["nama_bank"] === "") {
                formState["rekening"] = "required";
            }
        }
        const typeTransaksi = myForm['tipe_transaksi'];
        if (typeTransaksi === "perorangan" || typeTransaksi === "kerjasama") {
            // jumlah transaksi tidak boleh 0
            if (jumlah_keranjang === 0 || jumlah_keranjang < 0) {      
                isValid = false;
                toast.custom(t => {
                    return (
                        <ToastComponent param={ t } message="Jumlah Transaksi Tidak Boleh Kosong atau Kurang dari 0" bg="red" />
                    )
                })   
            }
        }else if (typeTransaksi === "kedinasan") {
            // tidak boleh lebih dari 0
            if (jumlah_keranjang > 0) {
                isValid = false;
                toast.custom(t => { 
                    return (
                        <ToastComponent param={t} message="Jumlah Transaksi Harus Sama Dengan 0" bg="red" />
                    )
                })
            }
            // jika keranjang kosong
            if (keranjang.length === 0) {
                isValid = false;
                toast.custom( t => {
                    return (
                        <ToastComponent param={ t } message="Jumlah Keranjang Tidak Boleh Kosong" bg="red" />
                    )
                })
            }
        }
        if (isValid) {
            this.setState({
                loader: true
            })
            const self = this;

            if (this.state.id_transaksi === null) {
               await createTransaksi({ ...myForm, keranjang: JSON.parse(keranjang)}).then((result) => {
                
                    if (result.status === 500) {
                     //  console.log(result)
                        
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={typeof result.data === "string" ? result.data : "Gagal Menambah Transaksi"} bg="red"/>
                        })
                        self.setState({
                            loader: false
                        })
                    }else{
                        self.setState({
                            loader: false
                        })
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Transaksi dibuat" bg="green"/>
                        })
                   
                                self.setState({
                                    navigateToPending: true
                                })
               
                    }
                    

                })
            }else if (this.state.id_transaksi !== null) {
                const keranjang = JSON.parse(this.state.keranjang);
                let berhasil = true
                
               await updateTransaksi({ id_transaksi: this.state.id_transaksi, ...myForm, keranjang:  keranjang}).then((result) => {
                
                    if (result.status === 500) {
                        // console.log(result)
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Gagal Mengupdate Transaksi" bg="red"/>
                        })
                        berhasil =false
                    }else{
                     
                    
                    }
                })

                

     // keranjang update 
                let index = 0;
                const loopUpdate = setInterval(async () => {
                    if (keranjang[index]["id_keranjang"] !== undefined) {
                       await  updateItemKeranjang(keranjang[index], keranjang[index]["id_keranjang"]).then(result => {
                          let nomor = keranjang[index]["index"] + 1;
                          // console.log(keranjang[index])
                            if (result.status === 500) {
                                // console.log(result)
                                toast.custom((t) => {
                                    return <ToastComponent param={t} message={"Gagal Mengupdate Keranjang Nomor " + nomor} bg="red"/>
                                })
                                berhasil = false

                            }else{
                                // toast.custom((t) => {
                                //     return <ToastComponent param={t} message={"Keranjang Nomor " + nomor + " diupdate"} bg="green"/>
                                // })
                            }
                      })
                    }else{
                        await addKeranjang({ ...keranjang[index], id_transaksi: self.state.id_transaksi }).then((result) => {
                            let nomor = keranjang[index]["index"] + 1;
                       
                            if (result.status === 500) {
                                // console.log(result)
                                toast.custom((t) => {
                                    return <ToastComponent param={t} message={"Gagal Menambah Keranjang Nomor " + nomor} bg="red"/>
                                })
                                berhasil = false

                            }else{
                                // toast.custom((t) => {
                                //     return <ToastComponent param={t} message={"Keranjang Nomor " + nomor + " ditambah"} bg="green"/>
                                // })
                            }
                        })
                    }
                    
                    index++; 
                    if (index === keranjang.length) {
                        clearInterval(loopUpdate)
                        if (berhasil) {
                            toast.custom((t) => {
                                return <ToastComponent param={t} message="Update Sukses" bg="green"/>
                            })

                            self.setState({
                                loader: false,
                                navigateToPending: true

                            })
                           
                        }else{
                            self.setState({
                                loader: false
                            })
                        }
                          
                    }   
                }, 300);
                // console.log(keranjang)
              
            }

            
        }else{
             this.setState({
                formState: formState
            }, () => {
                setTimeout(() => {
                    for(let f in formState){
                        formState[f] = "";
                    }
                    this.setState({
                        formState: formState
                    })
                }, 2000);
            })
        }
    }
    updaterKeranjang = (keranjang) => {
        this.setState({
            keranjang: JSON.stringify(keranjang)
        })
    }
    handleCp = (e) => {
        let myForm = this.state.myForm;
        if (e.target.name === "rekening") {
              const val = e.target.value;
                const list_req = this.state.list_rekening.filter((x) => {
                    return x.nomor_rekening == val;
                });
                const list_rekening = (list_req.length > 0) ? list_req[0] : {};
              //console.log(value);
            myForm['info_transaksi']["nomor_rekening"] = list_rekening["nomor_rekening"];
            myForm['info_transaksi']["nama_bank"] = list_rekening["nama_bank"];
            myForm['info_transaksi']["nama_rekening"] = list_rekening["nama_rekening"];
            // console.log(myForm["info_transaksi"])
            this.setState({
                myForm: myForm
            })  
        }else{
                
            myForm['info_transaksi'][e.target.name] = e.target.value;
            if (e.target.name === "tipe_pembayaran") {
                myForm['info_transaksi']["nomor_rekening"] = "";
                myForm['info_transaksi']["nama_bank"] = "";
                myForm['info_transaksi']["nama_rekening"] = "";

            }
            this.setState({
                myForm: myForm
            })
        }
    }
    render() {
        const { myForm, list_email,list_hp, isShowingModal, typeForm, myModalForm, pelanggan, myProductForm, layanan, produk_selected, myDetailForm, listTujuan, formState, loader, navigateToPending, list_rekening, ready, list_kategori, list_tipe_transaksi, statusNilai, list_bank_va, list_tipe_pembayaran, statusTarif } = this.state;
        const { onSelectKonsumen, handleCp, openModal, closeModal, onChangeLayanan, bindForm, handleKonsumen, updateKeranjang,simpanTransaksi, handleAlamat, updaterKeranjang } = this;
        if (ready === false) {
            return <FullLoader />
        }
        if (navigateToPending) {
            return <Navigate to="/app/transaksi/belumDiajukan" />;
        }
         return (
             <div className="p-5 animate-fadein">
                        <Toaster position="bottom-right"/>

               <SmallNavbar left={
                        <Fragment>
                            <FontAwesomeIcon icon={faList} />
                            <div className="flex-initial ml-2 -mt-1 " >
                               Buat Transaksi            
                            </div>
                        </Fragment>
                    } 
            
                />
            <div className="pt-3">
                <Card>
                    <div className="grid grid-cols-3 gap-4">
                        <div>
                            <TextArea other={{ onChange: bindForm, value: myForm.uraian }} Title="Uraian" Placeholder = "Uraian" Name="uraian" Status={ formState.uraian } TitleClass=" text-xs" elementClass=" mb-3" InputClass=" " />
                   
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div>
                                    <InputText  other={{ onChange: bindForm, value: myForm.info_transaksi.tgl_awal }}  Title="Tanggal Awal" Type="date" Placeholder = "" Name="tgl_awal" Status={ formState.tgl_awal } TitleClass=" text-xs" elementClass=" mb-3" InputClass=" " />
                                </div>
                                <div>
                                    <InputText  other={{ onChange: bindForm, value: myForm.info_transaksi.tgl_akhir }}  Title="Tanggal Akhir" Type="date" Placeholder = "" Name="tgl_akhir" Status={ formState.tgl_akhir } TitleClass=" text-xs" elementClass=" mb-3" InputClass=" " />
                                </div>
                            </div>
                            <InputText  other={{ onChange: bindForm, value: myForm.info_transaksi.no_kontrak }}  Title="No Kontrak"  Placeholder = "No Kontrak" Name="no_kontrak" Status={ formState.no_kontrak } TitleClass=" text-xs" elementClass=" mb-3" InputClass=" " />
                      
                            <AutoComplete classDropdown="-mt-60" Data={pelanggan} onSelect={onSelectKonsumen} Title="Konsumen" Placeholder = "Cari Konsumen" Name="nama_konsumen" Status={formState.nama_konsumen } />
                            <br />
                            
                        </div>
                        <div>
                            <div className="mb-3">    
                                <Select Other={{ onChange:  handleKonsumen, value: myForm.info_transaksi.nama_tagihan }} Title="Pilih Nama Tagihan"  Name="tujuan_tagihan" Status={formState.tujuan} TitleClass=" text-xs" elementClass=" mb-4" InputClass=" ">
                                        <option value="">Pilih Tagihan</option>
                                        {
                                            listTujuan.map((x, index) => {
                                                return (
                                                    <option value={ x.nama } key={x.nama}>{ x.nama }</option>
                                                )
                                            })
                                        }
                                </Select>
                            </div>
                            <div className="mb-3">
                                <Select Other={{ onChange:  handleAlamat, value: myForm.info_transaksi.alamat_tagihan }} Title="Pilih Alamat Tagihan"  Name="alamat_tagihan" Status={formState.alamat} TitleClass=" text-xs" elementClass=" mb-4" InputClass=" ">
                                        <option value="">Pilih Alamat Tagihan</option>
                                        {
                                            listTujuan.map((x, index) => {
                                                return (
                                                    <option value={ x.alamat } key={x.alamat}>{ x.alamat }</option>
                                                )
                                            })
                                        }
                                </Select>
                            </div>
                            <div className="mb-3">
                                <Select Other={{ onChange:  handleCp, value: myForm.info_transaksi.email_tagihan }} Title="Pilih Email Tagihan"  Name="email_tagihan" Status={formState.email} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                                        <option value="">Pilih Email Tagihan</option>
                                        {
                                            list_email.map((x, index) => {
                                                return (
                                                    <option value={ x } key={index}>{ x }</option>
                                                )
                                            })
                                        }
                                </Select>
                            </div>
                            <div className="mb-3">
                                <Select Other={{ onChange:  handleCp, value: myForm.info_transaksi.no_telp_tagihan }} Title="Pilih Telp Tagihan"  Name="no_telp_tagihan" Status={formState.email} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                                        <option value="">Pilih No Telp Tagihan</option>
                                        {
                                            list_hp.map((x, index) => {
                                                return (
                                                    <option value={ x } key={index}>{ x }</option>
                                                )
                                            })
                                        }
                                </Select>
                            </div>
                        </div>
                        <div>
                            
                        <Select Other={{ onChange:  bindForm, value: myForm.tipe_transaksi }} Title="Pilih Tipe Transaksi"  Name="tipe_transaksi" Status={formState.tipe_transaksi}>
                                <option value="">Pilih Tipe Transaksi</option>
                                {
                                    list_tipe_transaksi.map((x) => {
                                        return (
                                            <option value={x} key={x}>{x}</option>
                                        )
                                    })
                                }
                        </Select> 
                    
                            <Select Other={{ onChange:  handleCp, value: myForm.info_transaksi.tipe_pembayaran }} Title="Pilih Tipe Pembayaran"  Name="tipe_pembayaran" Status={formState.tipe_pembayaran}>
                                    <option value="">Pilih Tipe Pembayaran</option>
                                    {
                                        list_tipe_pembayaran.map((x) => {
                                            return (
                                                <option value={x}>{x}</option>
                                            )
                                        })
                                    }
                            </Select> 
                            

                            {
                                myForm.info_transaksi.tipe_pembayaran === "Transfer" ?
                                <Select Other={{ onChange:  handleCp, value: myForm.info_transaksi.nomor_rekening }} Title="Pilih Rekening"  Name="rekening" Status={formState.rekening}>
                                        <option value="">Pilih Rekening</option>
                                        {
                                            list_rekening.map((x, index) => {
                                                return (
                                                    <option key={index} value={x.nomor_rekening}>{x.nama_rekening} - {x.nama_bank}</option>
                                                )
                                            })
                                        }
                                </Select> 
                                : 
                                myForm.info_transaksi.tipe_pembayaran === "VA" ?
                                <Select Other={{ onChange:  handleCp, value: myForm.info_transaksi.nama_bank }} Title="Pilih Bank"  Name="nama_bank" Status={formState.rekening}>
                                        <option value="">Pilih Bank</option>
                                        {
                                            list_bank_va.map((x) => {
                                                return (
                                                    <option value={x}>{x}</option>
                                                )
                                            })
                                        }
                                </Select> 
                                :
                                null
                            }
                              
                        </div>
                    </div>
                    
                    <div className="text-right">
                        {
                            loader ?
                                <button className="w-48 opacity-70 cursor-not-allowed  font-bold bg-green-500 text-center p-2 rounded-md w-full text-white">
                                <div className="flex justify-center">
                                    <div className=" ">
                                        <Spinner 
                                            color="rgb(255 255 255)"
                                            width="30px"
                                            height="30px"
                                        />
                                    </div>
                                    <div className="pt-1 ml-2">
                                        Mohon Tunggu
                                    </div>
                                </div>
                                
                                </button>
                                :
                            <button onClick={simpanTransaksi} className="w-48 font-bold bg-green-500 p-2 text-white rounded-md shadow-md transition ease-in duration-100 hover:bg-green-600 active:bg-green-700">SIMPAN TRANSAKSI</button>
                        }
                    </div>

                </Card>
                <div className="col-span-2">
                    <Card>
                     
                        
                        <Keranjang 
                            updaterKeranjang={updaterKeranjang} 
                            updateKeranjang={updateKeranjang} 
                            myDetailForm={ myDetailForm } 
                            openModal={ openModal } 
                            myModalForm ={myModalForm} 
                            produk_selected={produk_selected} 
                            closeModal={closeModal} 
                            statusNilai = { statusNilai }
                            tipe_transaksi={myForm.tipe_transaksi}
                            statusTarif={ statusTarif }
                        />
                    </Card>
                </div>
            </div>
            <Modal
                Title = "Produk"
                Body  = {
                    <Produk 
                        list_kategori={list_kategori} 
                        opeModal={openModal} 
                        DataProduk={layanan} 
                        closeModal={(param) => closeModal("produk", param)} 
                        openForm={(param) => openModal("form", param)}
                    />
                }
                closeModal = {(param) => closeModal("produk", param)}
                isOpen = {myProductForm}
                Container = "max-w-4xl bg-white"
            />
        </div>

         )
    }
}



export default Transaksi;

