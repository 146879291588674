import { useState, useEffect, Fragment } from 'react';
import { Card, InfoCard } from '../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faSort } from '@fortawesome/free-solid-svg-icons';
import LineDashboard from '../components/Graph/LineDashboard';
import BarDashboard from '../components/Graph/BarDashboard';
import Datatable, { IconSort } from '../components/Datatable';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../components/Toast';
import Check from '../api/Validator';
import { SmallNavbar } from '../components/Navbar';
import axios from 'axios'
import { AppType } from '../config/Config';
import { getDashboard, getTahun, getDashboardExpress } from '../api/Laporan';
import { numberFormat, listBulan, getLabelsMonth } from '../api/Value'
import { SelectNav } from '../components/Input'
export default () => {
    const [ headerCard, setHeaderCard ] = useState({ semua: 0, progress: 0, selesai: 0, ditolak: 0, pendapatan: 0, penerimaan: 0, piutang: 0, line_data: { label: [], data: { target: [], piutang: [], pendapatan: [] } }, bar_chart: { labels:[], data:{target: [], pendapatan: []} } })
    const [ grafikTable, setGrafikTable ]= useState([]);
    const [ tabelData, setTabelData ] = useState([])
    const [ listTahun, setTahun ]   = useState([])
    const [ tahunSelected, setTahunSelect ] = useState(new Date().getFullYear())
    const load = (y=tahunSelected) => {
    
      getDashboardExpress({ tahun: y }).then(data => {
      
        if(data.status !== 500){
    
        let labels = []
        let obj    = { target: [], pendapatan: [] }
        
        const tablePendapatan  = data.grafikbar_pendapatan === null ? [] : data.grafikbar_pendapatan.map((x,i) => {
          
            x.no = i+1;
            let nilai 	= parseInt(x.jumlah_keranjang) / parseInt(x.target)
                nilai   = Number.isFinite(nilai) ? nilai.toFixed(2) : 0
            x.nilai = nilai.toFixed(2)
            let persentase = nilai * 100;
            x.persentase = persentase.toFixed(2)
          return x
        })
        setGrafikTable(tablePendapatan);
        const tbl = data.tabel_pendapatan.map((x, i) => {
          x["no"] = i+1
          
          const penerimaan = data.tabel_penerimaan.filter((a) => a.kode_layanan === x.kode_layanan)
          if(penerimaan.length > 0){
            x["penerimaan"] = penerimaan[0]["pendapatan"]
            x["piutang"]    = x["pendapatan"] - penerimaan[0]["pendapatan"] 
            let nilai 	= parseInt(x.pendapatan) / parseInt(x.target)
                nilai   = Number.isFinite(nilai) ? nilai.toFixed(2) : 0
              x.nilai = nilai.toFixed(2)
              let persentase = nilai * 100;
              x.persentase = persentase.toFixed(2)
          }else{
            x["penerimaan"] = 0
            x["piutang"]    = 0
            x["nilai"]  = 0
            x["persentase"] = 0
          }

          labels.push(x.nama_layanan.slice(0, 10) + "...");
          obj.target.push(x.target);
          obj.pendapatan.push(x.pendapatan);
          return x
      })
        const grap_bar = {
          labels: labels,
          data: obj
        }
        
        let n =  (data.grafik_pendapatan && data.grafik_penerimaan && data.grafik_piutang) ? 
            handleLineData(data.grafik_target, data.grafik_pendapatan, data.grafik_penerimaan, data.grafik_piutang) 
          :
          {
              label: [],
              data: {
                pendapatan: [],
                target: [],
                piutang: []
              }
            }
        // KUMULATIF
        let tmpHit = 0
        n["data"]["pendapatan"] = n["data"]["pendapatan"].map((x) => {
          tmpHit += x
          return tmpHit
        })
        tmpHit = 0 
        n["data"]["target"] = n["data"]["target"].map((x) => {
          tmpHit += x
          return tmpHit
        })
        tmpHit = 0 

        n["data"]["piutang"] = n["data"]["piutang"].map((x) => {
          tmpHit += x
          return tmpHit
        })
        
        
        const preventUndefine = (dt, key) => dt[key] ? dt[key][0]["result"] : 0
        const st  = { 
          pendapatan: preventUndefine(data, "total_pendapatan"), 
          penerimaan: preventUndefine(data, "total_penerimaan"), 
          piutang: preventUndefine(data, "total_piutang"), 
          semua: preventUndefine(data, "card_semua"), 
          progress: preventUndefine(data, "card_progress"), 
          selesai: preventUndefine(data, "card_selesai"), 
          ditolak: 0, 
          line_data: n,
          bar_chart: grap_bar
        }
          
          setTabelData(tbl)
          setHeaderCard(prevState => (st))
        }else{
          toast.custom(t => {
            return <ToastComponent param={t} message={(typeof data.message === "string") ? data.message : "Gagal Mengambil Data Statistik Dashboard" } bg="red"/>
          })
        }
      })
    }
    const handleTahun = ({target }) => {
        const { name, value } = target 
        setTahunSelect(value)
        load(value)
    }
    useEffect(() => {
      axios.defaults.headers.common['active_role'] = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
      load()
    
      getTahun().then((res => {
        if(res.status !== 500){
          setTahun(res)
          
        }
      }))
      return () => {
        
      };
    }, []);
    return (
        <div>
        <div className="bg-red-600 border-red-800"></div>
        <Toaster position="bottom-right"/>

        <div className=" p-4 pl-8 pr-8 animate-fadein">
          
          <SmallNavbar 
            left={<Fragment>
                    
                    <FontAwesomeIcon icon={faChartPie} className="mt-[3px]" />  
                      <div className="flex-initial ml-2 mb-2 " >
                          Dashboard
                      </div>
                  </Fragment>}
            right ={
              <Fragment>
                  <SelectNav
                    value = { tahunSelected }
                    Name = "tahun"
                    onChange = { handleTahun }
                  >
                      {
                        listTahun.map((x) => {
                          return <option value={ x.tahun } key={x.tahun}>{ x.tahun }</option>
                        })
                      }
                  </SelectNav>
              </Fragment>
            }
          />
          <div className="grid  gap-4 sm: grid-cols-1 md:grid-cols-3">
            <div className=" pr-4">
              <InfoCard param=" bg-white flex">  
                <div className="flex-initial w-4/5">
                  <span>Semua</span>
                  <div className="text-2xl ">{ headerCard.semua }</div>
                </div>
                <div className="flex-initial">
                   <img src={require('../apps.svg').default} alt="" className="icon-dashboard" />            
                </div>
              </InfoCard>
            </div>
            <div className=" pr-4">
              <InfoCard param=" bg-white flex">  
                <div className="flex-initial w-4/5">
                  <span>On Progress</span>
                  <div className="text-2xl ">{ headerCard.progress }</div>
                </div>
                <div className="flex-initial">
                   <img src={require('../assignment.svg').default} alt="" className="icon-dashboard" />            
                </div>
              </InfoCard>
            </div>
            <div className=" pr-4">
              <InfoCard param="bg-white flex">  
                <div className="flex-initial w-4/5">
                  <span>Selesai</span>
                  <div className="text-2xl ">{ headerCard.selesai }</div>
                </div>
                <div className="flex-initial">
                   <img src={require('../check_circle.svg').default} alt="" className="icon-dashboard" />            
                </div>
              </InfoCard>
            </div>
           

            
          </div>
          <div className="grid  gap-3 sm: grid-cols-1 md:grid-cols-3 mt-4">
          
            <div className="flex-1 pr-4 ">
              <div className="rounded-md flex bg-sky-500">
                <div className="flex-initial bg-sky-600 rounded-tl-md rounded-bl-md p-3">
                   <img src={require('../trending_up.svg').default} alt="" className="w-16" />            
                </div>
                <div className="flex-initial ">
                    <div className="text-md text-white pt-4 pl-4">
                      Total Pendapatan
                      
                    </div>
                    <div className="text-md md:text-2xl pt-1 pl-4 text-white ">
                      Rp. { numberFormat(`${headerCard.pendapatan}`) } 
                    </div>
                </div>
              </div>
            </div>
            
             <div className="flex-1 pr-4">
              <div className="rounded-md flex bg-green-500">
                <div className="flex-initial bg-green-600 rounded-tl-md rounded-bl-md p-3">
                   <img src={require('../white.png')} alt="" className="w-16" />            
                </div>
                <div className="flex-initial ">
                    <div className="text-md text-white pt-4 pl-4">
                      Total Penerimaan
                      
                    </div>
                    <div className="text-md md:text-2xl pt-1 pl-4 text-white ">
                      Rp. { numberFormat(`${headerCard.penerimaan}`) } 
                    </div>
                </div>
              </div>
            </div>

             <div className="flex-1 pr-4">
              <div className="rounded-md flex bg-amber-500">
                <div className="flex-initial bg-amber-600 rounded-tl-md rounded-bl-md p-3">
                   <img src={require('../class.svg').default} alt="" className="w-16" />            
                </div>
                <div className="flex-initial ">
                    <div className="text-md text-white pt-4 pl-4">
                      Total Piutang
                      
                    </div>
                    <div className="text-md md:text-2xl pt-1 pl-4 text-white ">
                      Rp. { numberFormat(`${headerCard.piutang}`) } 
                    </div>
                </div>
              </div>
            </div>
            
          </div>
          {
            (AppType() === "EBT") ? 
            <div>
          
            <div className="mt-4 mb-4">
              <Card>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                      <LineDashboard line_data = { headerCard.line_data } />
                    </div>
                    <div>
                      <BarDashboard bar_data={ headerCard.bar_chart } />
                    </div>
                </div>
              </Card>
            </div>
            <div>
              <Card>
                <TableLayanan Data={tabelData} />
              </Card>
            </div>

          </div>
           
            :
            <div className="mt-4 mb-4">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <Card>

                  <div>
                      <LineDashboard line_data = { headerCard.line_data } />
                    </div>
                    </Card>
                    
                </div>
            </div>
          }
          
        </div>
        
     </div>
    )
}

const TableLayanan = ({ Data }) => {
  
  const tdeadLaporan = ({ sort, order_by }) => {


    return (
      <thead>
        <tr>
            <td onClick={() => sort("no")} className=" border-2 border-gray-200 pl-2 pr-2" rowSpan="2">No {IconSort(order_by, "no")}</td>
            <td onClick={() => sort("nama_layanan")} className=" border-2 border-gray-200 pl-2 pr-2" rowSpan="2">Nama Layanan {IconSort(order_by, "nama_layanan")}</td>
            <td onClick={() => sort("target")} className=" border-2 border-gray-200 pl-2 pr-2" rowSpan="2">Target {IconSort(order_by, "target")}</td>
            <td onClick={() => sort("pendapatan")} className=" border-2 border-gray-200 pl-2 pr-2" rowSpan="2">Pendapatan {IconSort(order_by, "pendapatan")}</td>
            <td className=" text-center border-2 border-gray-200 pl-2 pr-2" colSpan="2">Deviasi </td>
            <td onClick={() => sort("penerimaan")} className=" border-2 border-gray-200 pl-2 pr-2" rowSpan="2">Penerimaan {IconSort(order_by, "no")}</td>
            <td onClick={() => sort("piutang")} className=" border-2 border-gray-200 pl-2 pr-2" rowSpan="2">Piutang {IconSort(order_by, "no")}</td>
        </tr>
        <tr>
            <td onClick={() => sort("nilai")} className="border-2 border-gray-300 pl-2 pr-2">Nilai {IconSort(order_by, "no")}</td>
            <td onClick={() => sort("percent")}  className="border-2 border-gray-300 pl-2 pr-2">% {IconSort(order_by, "no")}</td>
        </tr>
      </thead>
      
    )
  }
  const Export  = ({ exportFile }) => {
      return (
          <div>
            <button onClick={() => exportFile("print", "Penerimaan")} className="hover:bg-gray-400 active:bg-gray-600 active:shadow-none bg-gray-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Print</button>
            <button onClick={() => exportFile("excel", "Penerimaan")} className="hover:bg-green-400 active:bg-green-600 active:shadow-none bg-green-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Excel</button>

          </div>
      )
  }
  return (
    <div>
        <Datatable 
          Export = { Export }
          Head={tdeadLaporan}
          Data={Data}
          Entries={["nama_layanan", "target", "pendapatan"]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
            return (
              <tr key={ index }>
                <td className={"border-2 border-gray-300 p-2 text-center" + mod}>{row.no}</td>
                <td className={"border-2 border-gray-300 p-2 " + mod}>{row.nama_layanan}</td>
                <td className={"border-2 border-gray-300 p-2 text-right " + mod}>{numberFormat(`${row.target}`)}</td>
                <td className={"border-2 border-gray-300 p-2 text-right " + mod}>{numberFormat(`${row.pendapatan}`)}</td>
                <td className={"border-2 border-gray-300 p-2 text-right " + mod}>{row.nilai}</td>
                <td className={"border-2 border-gray-300 p-2 text-right " + mod}>{row.persentase}</td>
                <td className={"border-2 border-gray-300 p-2 text-right " + mod}>{numberFormat(`${row.penerimaan}`)}</td>
                <td className={"border-2 border-gray-300 p-2 text-right " + mod}>{numberFormat(`${row.piutang}`)}</td>
              </tr>
            )
          }}
        />
    </div>
  )
}

const handleLineData = (target, pendapatan, penerimaan, piutang) => {
  
  let maxTanggal = null;
  let minTanggal = null;
  if (pendapatan) {
    // cari min max
    pendapatan.map((x) => {
      const tgl = new Date(x.dt_invoice_created);
      if (minTanggal > tgl || minTanggal === null) {
        minTanggal = tgl;
      }
      if (maxTanggal < tgl || maxTanggal === null) {
        maxTanggal = tgl;
      }
      return x;
    })
  }
  
  if (piutang) {
    piutang.map((x) => {
      const tgl = new Date(x.dt_invoice_created);
      if (minTanggal > tgl || minTanggal === null) {
        minTanggal = tgl;
      }
      if (maxTanggal < tgl || maxTanggal === null) {
        maxTanggal = tgl;
      }
      return x;
    })
  }
  
  if (maxTanggal && minTanggal) {
    let strTgl1 = `${minTanggal.getFullYear()}-${minTanggal.getMonth() + 1}`;
  let strTgl2 = `${maxTanggal.getFullYear()}-${maxTanggal.getMonth() + 1}`;
  let groupingDate = getLabelsMonth(strTgl1, strTgl2);
  let obj = {
    pendapatan: [],
    target: [],
    piutang: []
  };

  let data    = groupingDate.map((x) => {
    const getBulan  = x.split(" ");
    const findBulan = listBulan.findIndex((x) => { return x === getBulan[0] })
    let isPush      = false;
    let kumulatif_pendapatan = 0
    pendapatan.map(p => {
      const inv = new Date(p.dt_invoice_created);
      if (`${inv.getFullYear()}-${inv.getMonth()}` === `${getBulan[1]}-${findBulan}`) {
        kumulatif_pendapatan += p.jumlah_transaksi 
        obj['pendapatan'].push(p.jumlah_transaksi );
        isPush = true;
      }
      return p;
    })
    
    if (isPush === false) {
      obj['pendapatan'].push(0);
    }
    isPush = false;


    obj['target'].push(target)
  let kumulatif_piutang = 0
   piutang.map(p => {
    const inv = new Date(p.dt_invoice_created);
    if (`${inv.getFullYear()}-${inv.getMonth()}` === `${getBulan[1]}-${findBulan}`) {
      kumulatif_piutang += p.jumlah_transaksi 
      obj['piutang'].push(p.jumlah_transaksi);
      isPush = true;
    }
    return p;
   })
    if (isPush === false) {
      obj['piutang'].push(0);
    }
    
    return x
  })

  return {
    label: groupingDate,
    data: obj
  }
  }else{
    return {
      label: [],
      data: {
        pendapatan: [],
        target: [],
        piutang: []
      }
    }
  }
  
}