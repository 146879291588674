import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit, faPrint, faCheck } from '@fortawesome/free-solid-svg-icons';
import Modal    from '../../../components/Modal';
import { getAllPembayaranTrx, createPembayaran, setPembayaran, unsetPembayaran,checkPembayaranManual } from '../../../api/Pembayaran';
import { getInvoicesOfTransaksi } from '../../../api/Invoice';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../../components/Toast';
import { toDateTime, numberFormat,getInaDate, convertToUTC, convertToLocal } from '../../../api/Value';
import ModalFooter from '../../../components/ModalFooter';
import { Select, InputText } from '../../../components/Input';
import { kuitansiUrl } from '../../../config/Config'
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import Spinner from '../../../components/Spinner'

const Pembayaran = ({ id_transaksi, myForm, isDeleted }) => {
    const [user,setUser] = useState(JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN")))
    const [state, setState] = useState({
        dataPembayaran: [],
        listInvoice: []
    });
    const [filterPembayaran, setFilterPembayaran] = useState("");
    const [loader, setLoader]  = useState(false);
    const [isOpenModalForm, setIsOpenModalForm] = useState(false);
    const [form, setForm] = useState({
        id_invoice: "",
        dt_bayar: "", 
        jumlah: "",
        cara_bayar: ""
    })
    const [isUpdate, setIsUpdate] = useState("");
    const [required, setRequired] = useState({
        id_invoice: "",
        dt_bayar: "", 
        jumlah: "",
        cara_bayar: ""
    })
    const [frame, setFrame] = useState("")
    const [isOpenHapus, setIsOpenHapus] = useState("");
    const [isOpenCheck, setIsOpenCheck] = useState(false);
    const getInvoice = () =>{
        getInvoicesOfTransaksi({ id_transaksi: id_transaksi }).then((x) => {
            if (x.status === 200) {
               // console.log(x)
                setState(prevState => ({
                    ...prevState,
                    listInvoice: (x.data) ? x.data : []
                }))
                let data = x.data;
                if (data) {
                    const aktif = data.filter((x) => { 
                        return x.is_aktif
                    })
                    
                    if (aktif.length > 0) {
                        setForm(prevState => ({ ...prevState, id_invoice: aktif[0]['id_invoice'] }))
                    }
                }
                if (x.data === null) {
                    // toast.custom((t) => {
                    //     return <ToastComponent param={t} message="List Pembayaran Kosong" bg="orange"/>
                    // })
                }
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="List Pembayaran Gagal di muat" bg="red"/>
                })
            }
        })
    }
    const getAllPembayaran = () => {
        getAllPembayaranTrx({ id_transaksi: id_transaksi  }).then((x) => {
            if (x.status === 200) {
                setState(prevState => ({
                    ...prevState,
                    dataPembayaran: (x.data) ? x.data : []
                }))
                if (x.data === null) {
                    // toast.custom((t) => {
                    //     return <ToastComponent param={t} message="List Pembayaran Kosong" bg="orange"/>
                    // })
                }
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="List Pembayaran Gagal di muat" bg="red"/>
                })
            }
        })

    }
    const vaSubmitBayar = ({ jumlah_dibayar, waktu }) => {
        const waktuReverse = waktu.split(" ")[0].split("/").reverse().join("-");
        toggleModal("checkPembayaran")
        setForm(prevState => ({ ...prevState, jumlah: jumlah_dibayar, cara_bayar: "VA", dt_bayar: waktuReverse }))
        toggleModal("addPembayaran")
    }
    const toggleModal = (type, param) => {
        if (type === "hapusPembayaran") {
            setIsOpenHapus(isOpenHapus === "" ? param.id_pembayaran : "");
       //     setIdTransaksi(param.id_transaksi);
        } else if (type === "checkPembayaran") {
            setIsOpenCheck(!isOpenCheck)
        
        }else if(type === "addPembayaran"){
            setIsUpdate("");
            setIsOpenModalForm(!isOpenModalForm);
        }else if (type === "setPembayaran") {
            setIsUpdate(param.id_pembayaran);
            let newForm  = form;
            for(let d in newForm){
                if (param[d]) {
                    if (d === "dt_bayar") {
                        const tgl = toDateTime(param[d]).split(" ") ;
                      //  console.log(tgl)
                        newForm[d] = tgl[0].split("/").reverse().join("-");
                    }else{
                        newForm[d] = param[d];

                    }
                }
            }
            setForm(newForm);
            setIsOpenModalForm(!isOpenModalForm);

        }
        
    }
    const bindForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
       
        let newForm  = form;
        if (name === "jumlah") {
           // console.log(value)
            newForm[name] = numberFormat(value, true);
        }else{
            newForm[name] = value;
        }
        setForm(prevState => ({...prevState, ...form }))
    }
    const savePembayaran = () => {
        let isValid = true;
        let myForm = form;
        let req    = required;
        if (isOpenHapus !== "") {
            
        }else{
                
            for(let m in myForm){
                if (myForm[m] === "") {
                    req[m] = "required" 
                    isValid = false;
                }
            } 
        }
      
        
        if (isValid) {
            setLoader(true)
           // console.log(isOpenHapus)
            if (isOpenHapus !== "") {
                unsetPembayaran({ id_pembayaran: isOpenHapus, id_transaksi: id_transaksi })
                .then((x) => {
                    if (x.status === 200) {
                        toggleModal("hapusPembayaran")
                        toast.custom((t) => {

                            return <ToastComponent param={t} message="Berhasil Menghapus Pembayaran" bg="orange"/>
                        })
                        getAllPembayaran();
                    }else if (x.status === 500) {
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Gagal Menghubungi Server" bg="red"/>
                        })
                   //     console.log(x);   
                    }
                    setLoader(false)

                })
            }else if (isUpdate) {
                setPembayaran({ id_pembayaran: parseInt(isUpdate),jumlah: parseFloat(`${myForm.jumlah}`.replaceAll(",", "")), dt_bayar: convertToUTC(myForm.dt_bayar + "T" + "08:00"), cara_bayar: myForm.cara_bayar  })
                .then(x => {
                    if (x.status === 200) {
                        for(let i in myForm){
                            myForm[i] = ""
                        }
                        setForm(myForm)
                        toggleModal("addPembayaran");
                        toast.custom((t) => {

                            return <ToastComponent param={t} message="Berhasil Mengupdate Pembayaran" bg="green"/>
                        })
                        getAllPembayaran();

                    }else if (x.status === 500) {
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Gagal Menghubungi Server" bg="red"/>
                        })
                //        console.log(x);   

                    }
                    setLoader(false)

                })
            }else{
  
                createPembayaran({ id_transaksi: parseInt(id_transaksi), jumlah: parseFloat(myForm.jumlah.replaceAll(",", "")), id_invoice: parseInt(`${myForm.id_invoice}`), dt_bayar: convertToUTC(myForm.dt_bayar + "T" + "08:00"), cara_bayar: myForm.cara_bayar  })
                .then((x) => {
                    if (x.status === 200) {
                        for(let i in myForm){
                            myForm[i] = ""
                        }
                        setForm(myForm)
                        toggleModal("addPembayaran");
                        toast.custom((t) => {

                            return <ToastComponent param={t} message="Berhasil Menambah Pembayaran" bg="green"/>
                        })
                        getAllPembayaran();

                    }else if (x.status === 500) {
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Gagal Menghubungi Server" bg="red"/>
                        })
                //        console.log(x);   
                    }
                    setLoader(false)

                })
            }
            
        }else{
            setRequired(prevState => ({ ...prevState, ...req }));
            setTimeout(() => {
                for(let i in req){
                    req[i] = ""
                }
            setRequired(prevState => ({ ...prevState, ...req }));

            }, 2000);
        }
    }
    const printKuitansi = (id_transaksi, id_pembayaran) => {
        setFrame(prevState => "")
        setTimeout(() => {
            setFrame(prevState => kuitansiUrl(id_transaksi, id_pembayaran))
        }, 200);
    }
    useEffect(() => {
        getAllPembayaran();
        getInvoice()

        return () => {
            
        };
    }, []);
    let listBayar = state.dataPembayaran;
    if (filterPembayaran !== "") {
        listBayar =  listBayar.filter((x) => {
            return JSON.stringify(x).toLowerCase().indexOf(filterPembayaran.toLowerCase()) !== -1
        })
    }
   
    let jumlah_terbayar = 0;

    listBayar.forEach((x) => {
        jumlah_terbayar += x.jumlah
    })
    
    let jumlah_belum_terbayar = myForm.transaksi.jumlah_transaksi - jumlah_terbayar;
    // prevent biaya bank to minus belum dibayar
    if(jumlah_belum_terbayar < 0){
        jumlah_belum_terbayar = 0
    }
    const aksi_pembayaran = (user.aksi_pembayaran) ? user.aksi_pembayaran :""
    const edited          = aksi_pembayaran.indexOf("edit") !== -1 && isDeleted === false;
    const deleted          = aksi_pembayaran.indexOf("delete") !== -1 && isDeleted === false;
    const created            = aksi_pembayaran.indexOf("create") !== -1 && isDeleted === false;
    const checkBayar        = aksi_pembayaran.indexOf("cek_bayar") !== -1 && isDeleted === false;
    return (
        <div>
            <div class="xl:w-1/2">
                <div className="grid grid-cols-2 gap-3 mb-3">
                                    
                    <div className="bg-rose-500 p-2 pl-4 shadow-md text-white rounded-md">
                        Belum Terbayar 
                        <div className="font-bold">Rp. { numberFormat(`${jumlah_belum_terbayar}`)},-</div>
                    </div>
                    <div className="bg-teal-500 p-2 pl-4 shadow-md text-white rounded-md">
                        Terbayar 
                        <div className="font-bold">Rp. { numberFormat(`${jumlah_terbayar}`) },-</div>
                    </div>
                </div>
            </div>
            
            { /* Header Button dan input cari */ }
            <HeaderCari 
                toggleModal         = {() => toggleModal("addPembayaran") } 
                setFilterPembayaran = {(e) =>  setFilterPembayaran(e.target.value)}
                openCheck           = { () => toggleModal("checkPembayaran")}
                progress               = { myForm['transaksi']['progress_transaksi'] }
                created  = { created }
                checkBayar = { checkBayar }
            />
            { /* Header Navbar List Pembayaran */}
            <HeaderNav />
            {
                listBayar.length === 0 ?
                <div className="text-center mt-3">Tidak Ada Data</div>
                :
                listBayar.map((x, i) => ListPembayaran({ x:x, i: i, toggleModal: toggleModal, edited: edited, deleted: deleted, cetakKuitansi:()=> printKuitansi(x.id_transaksi, x.id_pembayaran)  }))
            }
            <FormPembayaran 
                toggleModal= { () => toggleModal("addPembayaran")}
                submit = {savePembayaran}
                loader = { loader }
                isOpen = { isOpenModalForm }
                listInvoice ={ state.listInvoice }
                form    = { form }
                bindForm = { bindForm }
                required={ required }
                isUpdate ={isUpdate}
            />
            <DeletePembayaran 
                submit = {savePembayaran}
                loader = {loader}
                toggleModal={() => toggleModal("hapusPembayaran")}
                isOpen={isOpenHapus === "" ? false : true}
            />
            { /** Check Pembayaran Modal */}
            {
                isOpenCheck ?
                <CheckPembayaran 
                    toggleModal={() => toggleModal("checkPembayaran")}
                    isOpen={ isOpenCheck }
                    id_invoice={ form.id_invoice }
                    vaSubmitBayar = { vaSubmitBayar }
                />

                :
                null
            }
            <iframe src={ frame } className="hidden" frameBorder="0"></iframe>
        </div>
    )
}

const DeletePembayaran = ({ submit, loader, toggleModal,isOpen }) => {
    return (
        <Modal
            Title ={<span className="text-white">Hapus Pembayaran</span>}
            Body  = {
                        <div className="mt-5 text-white">
                            Apakah Anda Yakin ?
                        </div>
                    }
            Footer = {<ModalFooter closeModal = { toggleModal } onSubmit={submit} loader={loader} isDelete={true} />}
            closeModal = { toggleModal }
            isOpen = { isOpen }
            Container = "max-w-xl bg-red-600"
        />
    )
}
const CheckPembayaran = ({ toggleModal, isOpen, id_invoice, vaSubmitBayar }) => {
    const [ detailPembayaran, setDetailPembayaran ] = useState({
        jumlah_dibayar: "",
        waktu: "",
        loader: false,
        ready: false
    })
    const submit = () => {
        vaSubmitBayar({ jumlah_dibayar: detailPembayaran.jumlah_dibayar, waktu: detailPembayaran.waktu })
    }
    // get detail pembayaran
    const getData = () => {
        
        checkPembayaranManual({ id_invoice: id_invoice }).then(res => {
            if(res.status === 200){
                if(res.data){
                    const bni_server = res.data.bni_server;
                    if(bni_server.status == 500){
                        toast.custom(t => {
                            return <ToastComponent param={ t } message="Pembayaran dengan ID Invoice tersebut Tidak Ditemukan" />
                        })
                        toggleModal()
                        
                    }else{
                        const datetime_payment = bni_server.data.datetime_payment; 
                        const payment_amount   = bni_server.data.payment_amount;

                        if(datetime_payment){
                            let dateObj = datetime_payment.split(" ");
                                setDetailPembayaran({
                                    jumlah_dibayar: payment_amount,
                                    waktu: dateObj[0].split("-").reverse().join("/") + " " + dateObj[1],
                                    loader: false, 
                                    ready: true
                                })
                        }else{
                            toast.custom(t => {
                                return <ToastComponent param={ t } message="Belum Ada Pembayaran" />
                            })  
                            toggleModal()

                        }
                    }
                }else{
                    toast.custom(t => {
                        return <ToastComponent param={t} message="Pembayaran Tidak Ditemukan" />
                    })
                    toggleModal()

                }
            }else{
                toast.custom(t => {
                    return <ToastComponent param={t} message="Gagal Mendapatkan Data Pembayaran" />
                })
                toggleModal()
            }
        })
    }
    useEffect(() => {
        getData()
        return () => {
            
        };
    }, []);
    return (
        <Modal
            Title ={<span>Cek Pembayaran</span>}
            Body  = {
                        <div className="mt-5 ">
                            {
                                !detailPembayaran.ready ?
                                <center>
                                    <Spinner color="#22c55e" width="80px" height="80px" />
                                </center>
                                :
                                <div className="pl-2 mb-2">
                                    <div className="mb-2">Informasi Pembayaran Dari Bank</div> 
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td className="p-2 border-2 border-gray-300" width="30%">Jumlah dibayar</td>
                                                <td className="p-2 border-2 border-gray-300">{ detailPembayaran.jumlah_dibayar }</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2 border-2 border-gray-300" width="30%">Waktu</td>
                                                <td className="p-2 border-2 border-gray-300">{ detailPembayaran.waktu }</td>
                                            </tr>
                                        </tbody>
                                    </table>    
                                </div>
                            }
                            
                        </div>
                    }
            Footer = {(detailPembayaran.ready) ?<ModalFooter closeModal = { toggleModal } textSubmit="Tambahkan Sebagai Pembayaran" onSubmit={submit} loader={detailPembayaran.loader} isDelete={false} /> : 
                         <div className="text-right">
                             <button className="font-bold bg-gray-100 text-black p-2 shadow-md hover:bg-gray-200 active:bg-gray-400 active:shadow-none rounded-md w-32" onClick={ toggleModal }>Batal</button>                     
                         </div>
                        }
            closeModal = { toggleModal }
            isOpen = { isOpen }
            Container = "max-w-xl bg-white"
        />
    )
}
const FormPembayaran = ({ toggleModal, submit, loader, isOpen, listInvoice, form, bindForm, required, isUpdate }) => {
    return (
        <Modal
                Title ="Form Pembayaran"
                Body  = {<div className="mt-5">
                    <div className="mb-2">
                    {
                        (isUpdate === "") ?  
                        <Select 
                        Title="Invoice"
                        Name ="id_invoice"
                        Other={
                            { value: form.id_invoice, onChange: bindForm }
                        }
                        Status={required.id_invoice }
                    >
                        <option value="">Pilih Pembayaran</option>
                        {
                            listInvoice.map((x, i) => {
                                return (
                                    <option value={x.id_invoice} key={i}>{x.id_invoice}</option>
                                )
                            })
                        }
                    </Select>
                        :
                        null
                    }
                  
                    </div>
                    <div className="mb-2">
                    
                    <InputText 
                            other={{ value: form.dt_bayar, onChange: bindForm }} 
                            Type="date" 
                            Title="Waktu bayar" 
                            Placeholder="" 
                            Name="dt_bayar" 
                            Status={required.dt_bayar} 
                            
                        />
                        </div>
                        <div className="mb-2">
                    <InputText 
                        other={{ value: form.jumlah, onChange: bindForm }} 
                        Type="text" 
                        Title="Jumlah Bayar" 
                        Placeholder="" 
                        Name="jumlah" 
                        Status={required.jumlah} 
                    />
                    </div>
                    <div className="mb-2">
                    <Select 
                        Title="Cara Bayar"
                        Name ="cara_bayar"
                        Status={required.cara_bayar }
                        Other={
                            { value: form.cara_bayar, onChange: bindForm }
                        }
                    >
                        <option value="">Pilih Cara Bayar</option>
                        <option value="Transfer">Transfer</option>
                        <option value="VA">VA</option>
                </Select>
                </div>
                </div>}
                Footer = {<ModalFooter closeModal = { toggleModal } onSubmit={submit} loader={loader} />}
                closeModal = { toggleModal }
                isOpen = { isOpen }
                Container = "max-w-xl bg-white"
            />
    )
}

const ListPembayaran = ({x, i, toggleModal, edited, deleted,cetakKuitansi}) => {
   
    return (
        <div key={i} className={`bg-white grid grid-cols-7 gap-5 shadow-md rounded-md mt-3 p-3`}>
            <div className="pt-2">
                { x.id_pembayaran }
            </div>
            <div className="pt-2">
                { x.id_transaksi }
            </div>
            <div className="pt-2">
                { x.id_invoice }
            </div>
            <div className="pt-2">
                { x.cara_bayar }
            </div>
            <div className="pt-2">
                { numberFormat(`${x.jumlah}`, true) }
            </div>
            <div className="pt-2">
                <Moment format="DD/MM/YYYY HH:mm:ss" tz="Asia/Jakarta">{x.dt_bayar}</Moment>
                
            </div>
            <div className="text-center flex justify-center">
               {
                   (edited) ?
                   <button onClick={ () => toggleModal("setPembayaran", x) } className="active:bg-orange-200 hover:bg-red-100 border-2 w-[40px] h-[40px] p-2 pt-2 border-red-500 rounded-full shadow-md">
                        <FontAwesomeIcon className="text-orange-500 text-xl" icon={ faEdit } />
                    </button>
                   :
                    null
               }
                {
                    (deleted) ?
                    <button onClick={ () => toggleModal("hapusPembayaran", x) } className="ml-2 active:bg-red-200 hover:bg-red-100 border-2 w-[40px] h-[40px] p-2 border-red-500 rounded-full shadow-md">
                        <FontAwesomeIcon className="text-red-500 text-xl" icon={ faTrash } />
                    </button> 
                    :
                    null
                }
                <button onClick={ cetakKuitansi } className="ml-2 active:shadow-none bg-white active:bg-gray-300 hover:bg-gray-200 border-gray-500 border-2 shadow-md text-gray-500 p-1 rounded-full w-10">
                    <FontAwesomeIcon icon={faPrint} />
                </button>
            </div>
        </div>
    )
}
const HeaderNav = () => {
    return (
        <div className="p-2 shadow-md rounded-md grid grid-cols-7 gap-5 bg-violet-500 text-white mt-3 pt-3 pb-3 pl-3">
            <div>
                ID Pembayaran
            </div>
            <div>
                ID Transaksi
            </div>
            <div>
                No Invoice
            </div>
            <div>
                Cara Bayar
            </div>
            <div>
                Jumlah
            </div>
            <div>
                Waktu Bayar
            </div>
            <div className="text-center">
                Aksi
            </div>
        </div>
    )
}

const HeaderCari = ({ toggleModal, setFilterPembayaran, openCheck, progress, created, checkBayar }) => {
    return (
        <div className="grid grid-cols-2">
            <div>
                {
                    progress === "lunas" ?
                    null 
                    :
                    (created) ?
                    
                        <button onClick={ toggleModal } className="bg-blue-500 active:bg-blue-600 hover:bg-blue-400 rounded-md text-white p-2 shadow-md mr-2">
                            <FontAwesomeIcon icon={ faPlus } />
                            <span className="ml-2 font-bold"> TAMBAH PEMBAYARAN</span>
                        </button> 
                        
                    :
                    null
                }
                {
                    progress === "lunas" ?
                    null
                    :
                    checkBayar ? 
                        <button onClick={ openCheck } className="bg-green-500 active:bg-green-600 hover:bg-green-400 rounded-md text-white p-2 shadow-md">
                            <FontAwesomeIcon icon={ faCheck } />
                            <span className="ml-2 font-bold"> CEK PEMBAYARAN</span>
                        </button> 
                    :
                    null
                }
            </div>
            <div className="text-right ">
                <input placeholder="Cari" className="p-2 rounded-md shadow-md" onChange={ setFilterPembayaran } type="text"  name="cari" />
            </div>
        </div>
    )
}
export default Pembayaran