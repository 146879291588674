import Datatable from '../../components/Datatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList,faChartLine, faCoins, faBullseye, faChalkboard  } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import { useState } from 'react';
import { Select } from '../../components/Input';
import { numberFormat } from '../../api/Value';
export default ({ DataProduk, openForm, closeModal, list_kategori }) => {
  const [ kategori, setKategori ] = useState("")
  const theadProduk = ({ sort, order_by }) => {
    //console.log(order_by)
    return (
      <thead>
        
        <tr>
           
            <td className=" border-2 border-gray-300 p-2 text-center bg-gray-100">Kode</td>
            <td className=" border-2 border-gray-300 p-2 text-center bg-gray-100">Nama</td>
            <td className=" border-2 border-gray-300 p-2 text-center bg-gray-100">Pelaksana/Form</td>
            
            <td className=" border-2 border-gray-300 p-2 text-center bg-gray-100">Tarif/Target/Realisasi</td>
        </tr>
       
      </thead>
      
    )
  }

 
  return (
    <div>
        <Datatable 
          
          Head={theadProduk}
          Data={(kategori === "") ? DataProduk : DataProduk.filter((x) => {
            return x.kategori === kategori
          })}
          Export = {() => {
            return (
              <div className="-mt-8  pl-2">
                <div className="mb-2"> Pelaksana</div>
                <select onChange={(e) => { setKategori(prevState => (e.target.value)) } } value={kategori} className="w-full border-2 p-2 rounded-md md:w-48">
                        <option value="">Semua</option>
                        {list_kategori.map(x => {
                          return (
                            <option key={x} value={x}>{x}</option>
                          )
                        }) }
                </select>
              </div>
            )
          }}
          Entries={[]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
                  const isProduct = (row.is_product) ? "green" : "red";
            return (
              <tr key={ index }>
                  
                <td onClick={() => openForm({ row: row })} className={"border-2 border-gray-300 p-2 align-top text-blue-500 cursor-pointer hover:text-blue-300 " + mod} style={{paddingLeft: row.padding_rem + "rem"}} width="25%">
                   {row.kode_layanan}
                    
                </td>
                
                <td onClick={() => openForm({ row: row })} className={"border-2 p-2 border-gray-300 align-top text-blue-500 cursor-pointer hover:text-blue-300 " + mod} width="20%" valign="top">
                   { row.nama_layanan }
                       
                </td>
                <td className={"border-2 p-2 border-gray-300 align-top " + mod} width="20%" valign="top">
                    <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={row.kategori} />
                       
                    <ChipTable icon={<FontAwesomeIcon icon={faChalkboard} />} text={ (row.info_layanan !== "" && row.info_layanan !== undefined && row.info_layanan !== null ) ? JSON.parse(row.info_layanan)['tipe_form']: null } />
                  
                
                 
                </td>
                  <td className={"border-2 p-2 border-gray-300 align-top " + mod} width="20%">
                    <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.tarif}`)} />
                    <ChipTable icon={<FontAwesomeIcon icon={faBullseye} />} text={numberFormat(`${row.target}`)} />
                    <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={numberFormat(`${row.realisasi}`)} />
                   
                       
                </td>
              
        
              </tr>
            )
          }}
        />
        <div className="text-right">
            <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
        </div>
    </div>
  )
}