import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true


export const getGlobalBidang = async () => {
    const reqData = await axios.get( backEndUrl() + "/engine/global/bidang", getCred() ) 
                .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getGlobalFilterLaporan = async () => {
    
    const reqData = await axios.get( backEndUrl() + "/engine/global/filter_laporan", getCred() ) 
                .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getAllUser = async () => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/user", getCred())
                    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getOneUser = async ({ id_user }) => {
   
        return await axios.get( backEndUrl() + "/penerimaan/user/" + id_user, getCred())
                    .then((res) => {
                        return {
                            status: 200,
                            message: res.data
                        }
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    })    
 
}
export const createUser = async ({  username, roles, password, user_info, status }) => {


    let values= { 
                    username: username,
                    password: password,
                    user_info: JSON.stringify(user_info),
                    status: status,
                    roles: roles.join(","),
                    uuid: uuidv4()
                };
  
        return await axios.post( backEndUrl() + "/penerimaan/user", values, getCred())
                        .then((res) => {
                            return res.data
                        }).catch((err) => {
                            return {
                                status: 500,
                                messages: err.response 
                            }
                        });
        
   
}
export const updateUser = async ({ id_user, username, roles, user_info, status }) => {
  
    const values = {
        id_user: id_user,
        username: username,
        user_info: JSON.stringify(user_info),
        status: status,
        roles: roles.join(",")
    }
 
    return await axios.put( backEndUrl() + "/penerimaan/user", values, getCred())
                    .then((res) => {
                        return res.data
                    }).catch((err) => {
                        return {
                            status: 500,
                            messages: err.response 
                        }
                    });

}
export const updateUserPassword = async ({ id_user, password }) => {
 
    const values = {
        id_user: id_user,
        password: password
    }

        return await axios.put( backEndUrl() + "/penerimaan/user/password", values, getCred() )
                        .then((res) => {
                            return res.data
                        }).catch((err) => {
                            return {
                                status: 500,
                                messages: err.response 
                            }
                        });

}
export const deleteUser = async ({ id_user }) => {
  
        return await axios.delete( backEndUrl() + "/penerimaan/user/" + id_user, getCred() )
          .then((res) => {
            return res.data
            }).catch((err) => {
                return {
                    status: 500,
                    messages: err.response 
                }
            });
   
}
export const uploadProfilePicture = async ({ file, onUpload, id_user }) => {

            let form  = new FormData();
            form.append("file", file[0]);
            const options = {
                onUploadProgress:(progressEvent) =>{
                    const {loaded,total} = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    onUpload(percent)
                },
                ...getCred()
            }
            return await axios.post( backEndUrl() + "/penerimaan/user/uploadprofile/" + id_user, form, getCred())
               .then((res) => {
                    return res.data
                }).catch((err) => {
                    return {
                        status: 500,
                        messages: err.response 
                    }
                });
  
}

export const updateUserInfo = async ({ id_user, user_info }) => {
    const values= {
        id_user: id_user,
        user_info: JSON.stringify(user_info)
    }
    // /penerimaan/user/info
    return await axios.put( backEndUrl() + "/penerimaan/user/info", values, getCred())
                    .then((res) => {
                        return res.data
                    }).catch((err) => {
                        return {
                            status: 500,
                            messages: err.response 
                        }
                    });
}