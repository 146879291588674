import '../../kuitansi.css';
import { NoDokumenPembayaran, getInaDate, numberFormat, ex_terbilang, ucWords } from '../../api/Value';
import { Component } from 'react'
import { getOneTransaksi } from '../../api/Transaksi';
import { getAllPembayaranTrx } from '../../api/Pembayaran';
import { isJson } from '../../api/Value';
import SuratPenagihan from './Penagihan_pembayaran'
import InvoicePrint from './Invoice';
import Lampiran from './Lampiran';
import Pajak from './Pajak';
import Pajak2Hal from './PajakHal2';
import Pajak3Hal from './PajakHal3';
import InvoiceMigas from './InvoiceMigas';
import { AppType } from '../../config/Config';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
export default class IndexPrint extends Component{
    state ={
        status: "Mohon Tunggu !",
        myForm: {transaksi: {}, pembayaran: {}},
        segment: 0
    }
    componentDidMount = () => {
        const myNavbar = document.getElementById("myNavbar");
        if (myNavbar) {
            myNavbar.remove();  
        }
        const isLogin = this.props.isLogin;
        document.body.style.backgroundColor = "white";

        const param = new URL(window.location.href);
        const pathname = param.pathname;
        const segment = param.pathname.split("/");
        
        this.getData(segment[3], segment[4])
        //window.print();
    }
    getData = async (id_transaksi, id_pembayaran) => {
        let transaksi = {};
        let pembayarantmp= {};
        let isValid = true;
        let status  = "";
        await getOneTransaksi({ id_transaksi: id_transaksi }).then(res => {
            if (res.status === 200) {
                const data = res.data;
                if (data) {
                 //   console.log(data)
                    transaksi = data;
                }else{
                    isValid = false;
                    status = "Transaksi Tidak Ditemukan !";
                }
            }else{
                isValid     = false;
                status      = "Gagal Mendapatkan Transaksi !";
            }
        })
        if (isValid === true) {      
            await getAllPembayaranTrx({ id_transaksi: id_transaksi }).then(res => {
                if (res.status === 200) {
                    const data = res.data;
                   
                    if (data) {
                        const pembayaran = data.filter((x) => {
                            return x.id_pembayaran === parseInt(id_pembayaran)
                        })
                        console.log(pembayaran)
                        if (pembayaran.length === 0) {
                            isValid = false;
                            status  = "Pembayaran tidak ditemukan!"
                        }else{
                            pembayarantmp = pembayaran[0];
                        }
                    }else{
                        isValid = false;
                        status  = "Gagal Mendapatkan Pembayaran";
                    }
                }else{
                    isValid     = false; 
                    status      = "Gagal Mendapatkan Pembayaran!";
                }
            })   
        }
        
        if (isValid === true) {
            transaksi['info_pelanggan'] = JSON.parse(transaksi['info_pelanggan']);
            //console.log(pembayarantmp)
            this.setState({
                myForm: { transaksi: transaksi, pembayaran: pembayarantmp },
                status: false
            },() => {
                   this.cetak()
            })
        }else if (isValid === false) {
            this.setState({
                status: status
            }, () => {
                window.print();

            })
        }
    }
    cetak= (id_invoice="Gagal") => {
        const isLogin = this.props.isLogin;
        const interval = setInterval(() => {
            let finish = true;
            for(let i of document.images){
                if (i.complete) {
                    
                }else{
                    finish = false;
                    break;
                }
            }    
            if (finish) {
                clearInterval(interval)
                const dc = document.title
               
                // if (isLogin === true) {
                   window.print();
                    
                
                document.title = dc;
            }
        }, 500);
        
    }
    render(){
        const { status, myForm, segment }  = this.state;
        
        if (status) {
            return(
                <div>
                    <br />
                    <br />
                    <br />
                    <center>
                        <h2>    
                        {
                           status
                        }
                        </h2>
                    </center>
                </div>
            )
        }
        return(
            <div className="print bg-white calibri w-[210mm] ">
                <Kuitansi myForm={ myForm } />
            </div>
        )
    }
}
const Kuitansi = ({ myForm }) => {
    const nameApp = AppType()
    return (
        <div className=" flex -ml-[100px] mt-[30px]">
            <table className="-rotate-90 mt-[135px]">
                <tbody>
                    <tr>
                        <td className="text-center font-bold ">
                            <div className={`h-[auto] pl-[30px] pr-[30px] ${nameApp === "EBT" ? "w-[404px]" : "w-[371px]" }  pt-[5px] pb-[5px] border-2 border-black text-[14px]`}>
                                PUSAT PENGEMBANGAN SUMBER DAYA MANUSIA 
                                <br />
                                {
                                    nameApp === "EBT" ?
                                    <span>
                                        KETENAGALISTRIKAN, ENERGI BARU, TERBARUKAN, DAN KONSERVASI ENERGI
                                        <br />
                                        Jalan Poncol Raya No. 39 Ciracas Jakarta Timur 13740
                                        <br />
                                        Telp : (021) 8729101 Fax : (021) 8729109
                                        
                                    </span>
                                    
                                    :
                                    <span>
                                      MINYAK DAN GAS BUMI
                                        <br />
                                        Jalan Sorogo No. 1 Cepu, Blora - Jawa Tengah 
                                        <br />
                                        Telp : (0296) 421888 Fax : (0296) 421891
                                    </span>
                                }
                             
                            </div>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className=" w-[650px] -ml-[120px] pt-[10px] h-[355px] relative">
                <h3 className="text-center underline font-bold text-xl">K U I T A N S I</h3>
                <div className="pt-[10px] text-[16px]">
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td className="pb-4">Terima dari</td>
                                <td className="pb-4">:</td>
                                <td className="font-bold pb-4">{ myForm.transaksi.info_pelanggan.nama.toUpperCase() }</td>
                            </tr>
                            <tr>
                                <td className="pb-4">Uang Sebanyak</td>
                                <td className="pb-4">:</td>
                                <td className="pb-4">{ex_terbilang(myForm.pembayaran.jumlah).toUpperCase()}</td>
                                {/*<td className="pb-4">{ ex_terbilang(myForm.pembayaran.jumlah).toUpperCase() </td>*/}
                            </tr>
                            <tr>
                                <td className="pb-4">Guna membayar</td>
                                <td className="pb-4">:</td>
                                <td className="pb-4">Pembayaran Atas Nomor Invoice { myForm.pembayaran.id_invoice }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="grid grid-cols-2 absolute bottom-0">
                    <div className="border-t-2 border-b-2 border-black pt-5 pb-5 w-[300px] flex justify-between">
                        <div>
                            Jumlah Rp. 
                        </div>
                        <div className="border-black border-2 pl-[3px] w-[150px] pr-[3px] text-center font-bold">
                            { numberFormat(`${myForm.pembayaran.jumlah}`.split(".")[0]).replaceAll(",", ".")}
                            {
                                (`${myForm.pembayaran.jumlah}`.split(".").length > 1) ?
                                 "," + `${myForm.pembayaran.jumlah}`.split(".")[1]
                                : 
                                null
                            }
                            
                        </div>
                    </div>
                   
                </div>
                <div className={`w-[300px]  bottom-0 right-0 absolute ${nameApp === "EBT" ? "-mr-[10px]" : "-mr-[90px]" }`}>
                    {
                        (nameApp === "EBT") ?
                        <span></span>
                        :
                        <span>
                            <img src={require("../../cap_ppsdm.png")} id="logo" width="150" alt="" className="absolute -ml-[80px] mt-[20px]" />
                            <img src="/ttd_siti_utami.svg" width="150" id="ttd" alt="" className="absolute mt-[10px]" />
                        </span>
                    }
                    
                    { nameApp === "EBT" ? "Ciracas" : "Cepu"}, <Moment format="DD/MM/YYYY" tz="Asia/Jakarta">{myForm.pembayaran.dt_bayar}</Moment> <br />
                    Analisis Pengelolaan Keuangan <br />
                    APBN Ahli Muda, 
                    <br />
                    <br />
                    <br />
                    <br />
                    {
                        (nameApp === "EBT") ? 
                        "Tien Kartini"
                        :
                        "Siti Utami"
                    }
                </div>
            </div>
        </div>
    )
}