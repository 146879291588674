import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

 const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Grafik Layanan',
    },
  }
};


export default ({ bar_data }) => {
  const labels = bar_data.labels
  const data = {
    labels,
    datasets: [
      {
        label: 'Target',
        data: bar_data.data.target,
        backgroundColor: 'rgba(233, 30, 99, 1)',
      },
      {
        label: 'Pendapatan',
        data: bar_data.data.pendapatan,
        backgroundColor: 'rgba(52, 152, 219,1.0)',
      },
    ],
  };
    return (
        <div>
            <Bar options={ options } data={data} />
        </div>
    )
    
}