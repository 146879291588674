import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChartPie, faChartLine, faList, faBell, faChalkboardTeacher, faFileInvoiceDollar, faUsers, faLocationArrow } from '@fortawesome/free-solid-svg-icons'

export const ActiveDropdown = () => {
    return "bg-blue-900"
}
export const AppType = () => {
    const url = new URL(window.location.href);
    // DEV Mode
    if (url.host == "localhost:5000") {
        return "EBT"
    }
    // Migas || EBT
    return (url.host == "k2.kebtke.id") ? "EBT" : "Migas";
}

export const backEndUrl = () => {
   // return "https://k2.kebtke.id/api";
    
    const url = new URL(window.location.href);
    if (url.host == "localhost:5000") {
          return "http://localhost/api/k2.php?url=";
         // return "http://localhost:4000/api";
    }
     return (url.host == "k2.kebtke.id") ? "https://k2.kebtke.id/api" : "https://penerimaan2.migascepu.id/api";
}

export const printUrl = () => {
    //return "https://k2.kebtke.id/app/printInvoice/"

    const url = new URL(window.location.href);
    if (url.host == "localhost:5000") {
        return `http://localhost:5000/app/printInvoice/2/`;
    }
    return (url.host == "k2.kebtke.id") ? `https://k2.kebtke.id/app/printInvoice/2/` : `https://penerimaan2.migascepu.id/app/printInvoice/1/`;

}
export const kuitansiUrl = (id_transaksi, id_pembayaran) => {
    const url = new URL(window.location.href);
    if (url.host == "localhost:5000") {
        return `http://localhost:5000/app/kuitansi/${id_transaksi}/${id_pembayaran}`;
    }
    return (url.host == "k2.kebtke.id") ? `https://k2.kebtke.id/app/kuitansi/${id_transaksi}/${id_pembayaran}` : `https://penerimaan2.migascepu.id/app/kuitansi/${id_transaksi}/${id_pembayaran}`
}
export const getNavigationMenu = () => {
        return [
            // dashboard
            {
                id: "dashboard",
                title: "Dashboard",
                to: "/app/dashboard",
                icon: <FontAwesomeIcon  icon={faChartPie} className="mt-0.5"/>
            },
            {
                id: "layanan",
                title: "Layanan",
                to: "/app/layanan",
                icon: <FontAwesomeIcon  icon={faChalkboardTeacher} className="mt-0.5"/>
            },
            {
                id: "pelaksana",
                title: "Pelaksana",
                to: "/app/pelaksana",
                icon: <FontAwesomeIcon  icon={faUsers} className="mt-0.5"/>
            },
            // pelanggan
            {
                id: "user",
                title: "User",
                icon: <FontAwesomeIcon  icon={faUser} className="mt-0.5"/>,
                addClass: "  ",
                child: "pelanggan"

            },
            // transaksi
            {
                id: "transaksi",
                title: "Transaksi",
                to: "/app/transaksi",
                icon: <FontAwesomeIcon  icon={faList} className="mt-0.5"/>,
                addClass: "  ",
                child: "transaksi"
            },
            {
                id: "invoice",
                title: "Invoice",
                to: "/app/invoice",
                addClass: "-mt-2",
                icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />
            },
            // Laporan
            {
                id: "laporan",
                title: "Laporan",
                to: "/app/laporan",
                icon: <FontAwesomeIcon  icon={faChartLine} className="mt-0.5"/>,
                addClass: " -ml-5 ",
                child: "laporan"
            },
            // Integrasi
            {
                id: "integrasi",
                title: "Integrasi",
                to: "/app/integrasi",
                icon: <FontAwesomeIcon  icon={faLocationArrow} className="mt-0.5"/>,
                addClass: " -ml-5 ",
                child: ""
            }
        ]
    
}
export const getNavigationTransaksi = () => {
    return {
        transaksi:
    [
        {
            id: "buat_transaksi",
            title: "Buat Transaksi",
            to: "/app/transaksi",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "transaksi_belum_diajukan",
            title: "Belum Diajukan",
            to: "/app/transaksi/belumDiajukan",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "transaksi_berjalan",
            title: "Berjalan",
            to: "/app/transaksi/berjalan",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "transaksi_selesai",
            title: "Selesai",
            to: "/app/transaksi/selesai",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "transaksi_terhapus",
            title: "Terhapus",
            to: "/app/transaksi/terhapus",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "cari_transaksi",
            title: "Cari Transaksi",
            to: "/app/transaksi/cariTransaksi",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
    ],
    pelanggan: [
          {
                id: "user_internal",
                title: "Internal",
                to: "/app/user",
                icon: <FontAwesomeIcon  icon={faUser} className="mt-0.5"/>,
       

            },
            {
                id: "user_pelanggan",
                title: "Pelanggan",
                to: "/app/pelanggan",
                icon: <FontAwesomeIcon  icon={faUser} className="mt-0.5"/>,
                addClass: "  "
            },
    ],
    laporan:
    [
        {
            id: "laporan_pendapatan",
            title: "Pendapatan",
            to: "/app/laporan/pendapatan",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "laporan_penerimaan",
            title: "Penerimaan",
            to: "/app/laporan/penerimaan",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "laporan_piutang",
            title: "Piutang",
            to: "/app/laporan/piutang",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "laporan_pelanggan",
            title: "Pelanggan",
            to: "/app/laporan/pelanggan",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
        {
            id: "laporan_pendapatan_lengkap",
            title: "Pendapatan Lengkap",
            to: "/app/laporan/pendapatan_lengkap",
            icon: <FontAwesomeIcon  icon={faChartPie} />
        },
    ]
    }

}
