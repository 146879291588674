import { Component } from 'react'
import { getOneTransaksi } from '../../api/Transaksi';
import { getInvoicesOfTransaksi, invoicePublik } from '../../api/Invoice';
import { getSuratTagihan } from '../../api/Laporan';
import { getKeranjangTransaksi } from '../../api/Keranjang';
import { isJson, getInaDate } from '../../api/Value';
import SuratPenagihan from './Penagihan_pembayaran'
import InvoicePrint from './Invoice';
import Lampiran from './Lampiran';
import Pajak from './Pajak';
import Pajak2Hal from './PajakHal2';
import Pajak3Hal from './PajakHal3';
import InvoiceMigas from './InvoiceMigas';
import { AppType } from '../../config/Config';

import moment from 'moment';
export default class IndexPrint extends Component{
    state ={
        status: "Mohon Tunggu !",
        myForm: {transaksi: {}, keranjang: [], invoice: []},
        segment: 0
    }
    componentDidMount = () => {
        const myNavbar = document.getElementById("myNavbar");
        if (myNavbar) {
            myNavbar.remove();  
        }

        
        const isLogin = this.props.isLogin;
        document.body.style.backgroundColor = "white";
        
        const param = new URL(window.location.href); 
        const pathname = param.pathname;
        const segment = param.pathname.split("/");
        
          //  console.log(param.searchParams.get("token"))
        this.setState({
            segment: segment[3]
        }, () => {
            if (isLogin === false) {

                if (param.searchParams.get("token") === "1EB5547DB43B99EBBB8E85FA628CB") {
                    this.getData(segment[4], segment[5], true)
                }else{
                    this.setState({
                        status: "Access Ditolak!"
                    });
                }
            }else if (isLogin === true) {

           //     console.log(segment[4], segment[5])
                this.getDataOld(segment[4],segment[5], false)
                
            }
        })
      
        //window.print();
    }
    getData = async (id_transaksi,id_invoice ,is_login) => {
        const self  = this;
        invoicePublik({ id_transaksi: id_transaksi })
        .then(result => {
            let message = [];
            let myForm = {transaksi: {}, keranjang: [], invoice: []};

            if (result.status === 200) {
                const { data_invoice, data_transaksi, data_keranjang } = result.data;
                // status invoice is exist
                if (data_transaksi.data) {
                    let transaksi = data_transaksi.data;
                    transaksi.history = JSON.parse(transaksi.history);
                    transaksi.info_pelanggan = JSON.parse(transaksi.info_pelanggan);
                    transaksi.user_pembuat = JSON.parse(transaksi.user_pembuat);
                    transaksi.info_transaksi =JSON.parse(transaksi.info_transaksi)
                    myForm['transaksi'] = transaksi;
                    

                    if (data_invoice.data) {
                        let invoice = data_invoice.data;
                        let cari= invoice.filter((x) => { 
                               
                               return x.id_invoice == parseInt(id_invoice)
                           });
                        if (cari.length === 0) {
                          message.push("Invoice Tidak Ditemukan")
                        }else{
                            cari[0]['info_invoice'] = isJson(cari[0]['info_invoice']);
                            myForm['invoice'] = cari[0];
                        }
                    }else{
                        message.push("Invoice Belum Ada");
                    }

                    if (data_keranjang.data) {
                        let keranjang = data_keranjang.data;
                        myForm['keranjang'] = keranjang.map((x) => {
                            x.info_keranjang = JSON.parse(x.info_keranjang)
                            return x
                        });
                    }else{
                        message.push("Keranjang Belum Ada")
                    }
                }else{
                    message.push("Transaksi tidak ditemukan")
                }
            }else{
             //   console.log(result);
                message.push("Unknown Server Response")
            }
            // is data ready ??
            if (message.length === 0) {
                self.setState({
                    myForm: myForm,
                    status: false
                }, () => {
                    if (is_login === true) {
                        self.cetak()
                    }
                })
            }else{
                self.setState({
                    status: message.join(", ")
                }, () => {
                    if (is_login === true) {
                        self.cetak()
                    }
                })
            }
        })
    }
    getDataOld = async (id_transaksi, id_invoice) => {
        //console.log(id_invoice)
        const self = this;

        await getOneTransaksi({ id_transaksi: id_transaksi }).then(async(result) => {

            if (result.status === 200) {
                 let transaksi = result.data
                 let myForm = {transaksi: {}, keranjang: [], invoice: []};
                 if (transaksi === null) {
                     self.setState({
                         status: "Transaksi Tidak Ditemukan"
                     }, () => {
                        self.cetak()
                    })
                 }
                    transaksi.history = JSON.parse(transaksi.history);
                    transaksi.info_pelanggan = JSON.parse(transaksi.info_pelanggan);
                    transaksi.user_pembuat = JSON.parse(transaksi.user_pembuat);
                    transaksi.info_transaksi =JSON.parse(transaksi.info_transaksi)
                    myForm['transaksi'] = transaksi

                    await getKeranjangTransaksi({ id_transaksi: id_transaksi })
                        .then((hasil) => {
                            if (hasil.status === 200) {
                                let keranjang = hasil.data;
                                if (keranjang === null) {
                                    self.setState({
                                        status: "Keranjang Tidak Ditemukan"
                                    }, () => {
                                        self.cetak()
                                    })
                                }else{
                                    myForm['keranjang'] = keranjang.map((x) => {
                                        x.info_keranjang = JSON.parse(x.info_keranjang)
                                        return x
                                    });
                                }        
                            }else{
                                self.setState({
                                    status: "Gagal Mendapatkan Keranjang"
                                }, () => {
                                    self.cetak()
                                })
                            }
                        })
                        let invoiceTrans  = []
                        let statusTransaksi = false
                      //  console.log(id_transaksi)
                    await getInvoicesOfTransaksi({ id_transaksi: id_transaksi }).then((hasil) => {
                        //console.log(hasil)
                         if (hasil.status === 200) {
                                let invoice = hasil.data;
                               // console.log(hasil)
                                if (invoice === null) {
                                    self.setState({
                                        status: "Invoice Tidak Ditemukan"
                                    }, () => {
                                        self.cetak()
                                    })
                                }else{
                                    let cari= invoice.filter((x) => { 
                                 //      console.log(x.id_invoice, id_invoice)
                                        return x.id_invoice == parseInt(id_invoice)
                                    });
                                    if (cari.length === 0) {
                                        self.setState({
                                            status: "Invoice Tidak Ditemukan"
                                        }, () => {
                                            self.cetak()
                                        })
                                    }else{
                                        cari[0]['info_invoice'] = isJson(cari[0]['info_invoice']);
                                        myForm['invoice'] = cari[0];
                                     //   console.log(myForm)
                                     statusTransaksi = true
                                        // self.setState({
                                        //     myForm: myForm,
                                        //     status: false
                                        // }, () => {
                                           
                                        // })
                                    }
                                }  
                                
                                invoiceTrans = invoice
                            }else{
                                self.setState({
                                    status: "Gagal Mendapatkan Invoice"
                                }, () => {
                                    self.cetak()
                                })
                            }
                    })

                    
                    
                    if(statusTransaksi === false){
                        return false
                    }
                    await getSuratTagihan({ id_transaksi: id_transaksi }).then(res => {

                        if(res.data){    
                            let dataTagihan = []
                            // let dt_created  = null 
                            let tagihan    = res.data.map((x) => {
                                const f    = x.no_tagihan.split("-")[0]
                                let getInvoice = invoiceTrans.filter((x) => parseInt(x.id_invoice) === parseInt(f))
                                if(getInvoice.length > 0){
                               //     dt_created = x.dt_created
                                    dataTagihan.push({ ...x, ...getInvoice[0], id_invoice: x.no_tagihan })
                                }
                                return x
                            }) 
                            //console.log(tagihan)
                            // date terbit sebelumnya 
                            let terbit_surat_sebelumnya = null
                            let invoice = myForm["invoice"]
                            // join invoice dan data tagian 
                            let dtRender = [ ...invoiceTrans, ...dataTagihan,  ].map((x, i) => {
                                let time= `${x.id_invoice}`.indexOf("-")  
                                const checkTime = time >= 0 ? x.dt_created : x.dt_invoice_created
                                const m = moment(checkTime).format("DD/MM/YYYY HH:mm:ss")
                                if(x.no_tagihan === id_invoice){
                                    invoice = x
                                }
                                x["tgl_terbit"] = m
                                x["tgl_terbit_sebelumnya"] = terbit_surat_sebelumnya
                                terbit_surat_sebelumnya = getInaDate(checkTime)
                                // x["dt_created"] = dt_created
                               return { ...x, index: i }
                            })
                            myForm["invoice"] = invoice
                            self.setState({
                                myForm: myForm,
                                status: false
                            }, () => {
                                self.cetak(id_invoice)
                            })
                            // this.setState({
                            //     dataRender: dtRender.reverse().map((x,i ) => ({ ...x, index: i }))
                            // })
                        }else{
                            // toast.custom((t) => {
                            //     return <ToastComponent param={t} message={"Surat Tagihan gagal dimuat"} bg="red"/>
                            // })
                        }
                    })
                        
            }else{
                self.setState({
                    status: "Gagal Mendapatkan Transaksi"
                }, () => {
                    self.cetak()
                })
            }
        })
        
    }
    cetak= (id_invoice="Gagal") => {
        const isLogin = this.props.isLogin;
        const interval = setInterval(() => {
            let finish = true;
            for(let i of document.images){
                if (i.complete) {
                    
                }else{
                    finish = false;
                    break;
                }
            }    
            if (finish) {
                clearInterval(interval)
                const dc = document.title
                document.title = "Invoice " + id_invoice
                if (isLogin === true) {
                    window.print();
                    
                }
                document.title = dc;
            }
        }, 500);
        
    }
    render(){
        const { status, myForm, segment }  = this.state;
        
        if (status) {
            return(
                <div>
                    <br />
                    <br />
                    <br />
                    <center>
                        <h2>    
                        {
                           status
                        }
                        </h2>
                    </center>
                </div>
            )
        }
        return(
            <div className="bg-white">
                        {
                            segment === "1" ?
                            <div>
                                <InvoiceMigas myForm={myForm} invoiceSelected={ myForm.invoice } /> 
                                <footer></footer>
                                <Lampiran myForm={myForm} invoiceSelected={ myForm.invoice } />
                            </div>
                            :
                            segment === "2" ?
                            <div>
                                
                                    <SuratPenagihan myForm={myForm} invoiceSelected={ myForm.invoice } />
                                
                                <footer></footer>
                                <InvoicePrint myForm={myForm} invoiceSelected={ myForm.invoice } />
                                <footer></footer>
                                <Lampiran myForm={myForm} invoiceSelected={ myForm.invoice } />
                                <footer></footer>
                                <Pajak />
                                <footer></footer>
                                <Pajak2Hal />
                                <footer></footer>
                                <Pajak3Hal />
                            </div>
                            :
                            <div>
                                Segment Undefined
                            </div>
                        }
                            
                               
                        
            </div>
        )
    }
}