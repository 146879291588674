import { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { NavLink }   from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faAlignJustify, faSortDown, faList, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { getNavigationMenu, getNavigationTransaksi } from '../config/Config';
import { Navigate, useNavigate } from 'react-router-dom'
import { backEndUrl, AppType } from '../config/Config';
import Auth from '../api/Auth';
import { getBidang } from '../api/Value';
const MyListNavbar = () => {
    return (
        <div className="flex gap-0 justify-items-center w-full ">
            {/* APP NAME  */}
             <div className="flex p-4 ">
              <img  src={require('../esdm.png')} className="w-7 h-7 flex-initial -mt-1" />
              <h2 className="text-white font-bold text-center pl-2">
              {
                (AppType() === "EBT") ? "SIKK PENERIMAAN" : "SIMPELKU PENERIMAAN"
              }
              </h2>
            </div>
            <div className="flex  justify-between -mr-5">
              <Auth.AuthConsumer>
              
            {
              ({ state }) => {
           //      console.log(JSON.stringify(state.access))
                return (
                  // Rendering Menu Navbar
                  getNavigationMenu().map((menu, index) => {

                    if (menu.child) {
                      const isChildAccess = state.access.join(",").indexOf(menu.id)
                      if (isChildAccess === -1) {
                        return null
                      }
                      return (
                        <Menu as="div" id={menu.title + "_nav"} className="relative text-white h-[56px] pt-[13px]  mr-2 " key={index}>
                            <Menu.Button>
                              <a  className= "p-4 pb-3 pl-5 pr-5 xl:text-[14px] 2xl:text-md "> 
                                    { menu.icon }
                                    <span className="ml-1  xl:text-[14px] 2xl:text-md ">
                                        {menu.title}
                                    </span>
                                    <FontAwesomeIcon icon={faSortDown} className="ml-2 -mt-3" />
                              </a>
                            </Menu.Button>
                            <Transition
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                            <Menu.Items className="absolute z-50 shadow-lg radius-2 rounded-md text-black bg-white ">
                              {
                                getNavigationTransaksi()[menu.child].map((menu2, index2) => {
                                  const isChildAccess = state.access.findIndex((x) => x === menu2.id)
                                  if (isChildAccess === -1) {
                                    return null
                                  }
                                  return (
                                    <Menu.Item key={index2}>
                                    {({ active }) =>  {
                                        
                                        return (
                                            <NavLink
                                              className={` rounded-md xl:text-[14px] 2xl:text-md p-3 flex w-48 ${active && 'bg-blue-900 text-white'}`}
                                              to={menu2.to}
                                            >
                                              
                                              {menu2.title}
                                            </NavLink>
                                        )
                                      }
                                    }
                                  </Menu.Item>
                                  )
                                })
                              }
                              
                            
                            </Menu.Items>
                            </Transition>
                          </Menu>
                    
                      )
                    }

                      const isOneAccess = state.access.findIndex((x) => x === menu.id)
                      if (isOneAccess === -1) {
                        return null
                      }
                      return (
                  
                          <NavLink to={menu.to} key={index} as="div" className={({isActive}) => `xl:text-[14px] 2xl:text-md p-4 pb-3 pl-5 pr-5 text-white pt-4 h-[56px] ${menu.addClass}` + (isActive ? " border-b-4 bg-blue-900" : " ") }> 
                                { menu.icon }
                                <span className="ml-1 ">
                                    {menu.title}
                                </span>
                          </NavLink>
                      
                      )
                    })
                 )
              }
            }
              </Auth.AuthConsumer>

            </div>
        </div>
    )
}


const ProfileDropDown = () => {

  return (
    <Menu as="div" className="relative">
      <Menu.Button>
        <div className="pt-2 w-[40px]">
            <Auth.AuthConsumer>
              {
                ({ state }) => {
               //   console.log("STATE", state)
                  const s = (state.profile) ? JSON.parse(state.profile) : {};
                  const bg = localStorage.getItem("PROFIL_COLOR").toString()
                  return (
                    <div className="w-[40px] h-[40px] font-bold grid grid-cols-1 content-center rounded-full border-2 border-slate-50 text-slate-50" style={{ background: bg }}>
                      {
                        s.nama ?
                          s.nama.charAt(0).toUpperCase()
                        : 
                          null
                      }
                    </div>
                  )
                }
              }
            </Auth.AuthConsumer>
        </div>
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >

      
      <Menu.Items className="absolute z-50 shadow-lg radius-2 rounded-md text-black -ml-32 bg-white ">
        <Menu.Item >
          {({ active }) => (
            <NavLink
              className={` rounded-md p-3 flex w-40 ${active && 'bg-blue-800 text-white'}`}
              to="app/profile"
            >
              Profile
            </NavLink>
          )}
        </Menu.Item>
         <Menu.Item >
         
          {({ active }) => (
              <Auth.AuthConsumer>
              {
                ({ logout }) => (
                    <a
                      className={` rounded-md p-3 flex ${active && 'bg-blue-800 text-white'}`}
                      href="#"
                      onClick={logout}
                    >
                      Logout
                    </a>
                )
              }
           
            </Auth.AuthConsumer>
          )}
        </Menu.Item>
      </Menu.Items>
      </Transition>
    </Menu>
  )
}
const MobileMenu = () => {
  return (
    
       <Menu as="div" className="relative ">
      <Menu.Button>
        <div className="pt-5 pb-4 pr-2 pl-2 ml-2 text-white text-2xl">
              <FontAwesomeIcon icon={faAlignJustify} />
          </div>
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform -translate-x-6 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform -translate-x-6  opacity-0"
      > 
      <Auth.AuthConsumer>
      {  
        ({ state })  => {
          
          return (
                <Menu.Items className="z-50  absolute shadow-lg radius-2 rounded-r-md text-black bg-white w-64 min-h-screen -ml-4">
          
                  
                {
                    // Rendering Menu Navbar
                    getNavigationMenu().map((menu, index) => {
                      
                      const isChildAccess = state.access.join(",").indexOf(menu.id)
                      if (isChildAccess === -1) {
                        return null
                      }

                      if (menu.child) {
                        return <ChildMobile index={index} menu={menu} key={index} />
                      }
                      return (
                          <Menu.Item key={index}>
                          {({ active }) => (


                            <NavLink to={menu.to} as="div" className={({isActive}) => "rounded-md p-3 flex p-4 pb-3 pl-5 pr-5" + (isActive ? "  text-white  rounded-l-none bg-blue-800" : " ") }> 
                                    { menu.icon }
                                    <span className="ml-2 text-base ">
                                        {menu.title}
                                    </span>
                              </NavLink>
                        
                          )}
                        </Menu.Item>
                      )
                    })
                  }
            </Menu.Items>
          )
        }
    
      }
        
      </Auth.AuthConsumer>
      </Transition>
    </Menu>
  )
}
const ChildMobile = ({ index, menu }) => {
  const [showTransaksi, setShowTransaksi] = useState(false)

  return (
    
                <div key={index}>
                  <div onClick={() => setShowTransaksi(!showTransaksi)} className="rounded-md p-3 grid grid-cols-2 justify-between p-4 pb-3 pl-5 pr-5 ">
                      <div className="flex">
                        <FontAwesomeIcon  icon={faList} className="mt-1"/> 
                        <div className="pl-2">
                          { menu.title }
                        </div>
                      </div>
                      <div className="text-right pt-1">
                        <FontAwesomeIcon  icon={faChevronDown} className={"mt-1 transform transition  " + (showTransaksi ? "" : "-rotate-90")}/> 

                      </div>
                  </div>
                  <Transition
                    show={showTransaksi}
                    enter="transform transition -translate-y-6 align-top duration-300"
                    enterFrom="opacity-0  -translate-y-6 "
                    enterTo="opacity-100  translate-y-0 "
                    leave="transform transition duration-100"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-6"
                  >
                  <Auth.AuthConsumer>
                  {
                    ({ state }) => {
                      return (
                        <div className="pl-10 z-10">
                          {
                            getNavigationTransaksi()[menu.child].map((menu2, index2) => {
                              const isChildAccess = state.access.findIndex((x) => x === menu2.id)
                              if (isChildAccess === -1) {
                                return null
                              }
                              return (
                                <Menu.Item key={index2}>
                                    <NavLink to={menu2.to} as="div" className={({isActive}) => "rounded-r-md p-3 flex p-4 pb-3 pl-5 pr-5" + (isActive ? "  border-l-4 border-blue-800 text-white  bg-blue-900" : " ") }> 
                                          <span className="ml-1 text-base ">
                                              {menu2.title}
                                          </span>
                                    </NavLink>
                                </Menu.Item>
                              )
                            })
                          }
                        </div>
                      )
                    }
                  }
                 
                   </Auth.AuthConsumer>
                  </Transition>
                </div>
                
  )
}
const RightNav = () => {

  // const [state, setRedirect] = useState(false)
  // if (state) {
  //   return <Navigate to="/app/dashboard" />
  // }
  const navigate = useNavigate();

  return (
    <div className="flex flex-initial mr-5 text-white pr-1 gap-0">
                       
                        <div className="pt-4 mr-2">
                          <Auth.AuthConsumer>
                            {
                              ({ state, getOneUser }) => {
                                const bid       = getBidang().toUpperCase();
                                const activeRole = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
                                const gantiRole = (val) => {
                                  localStorage.setItem("PENERIMAAN_ACTIVE_ROLE", val.target.value);
                                  const idUser  = localStorage.getItem("PENERIMAAN_ID_USER");
                                  getOneUser(idUser, true)
                                  // setRedirect(true);
                                  // setRedirect(false);
                                  navigate('/app/dashboard', { replace: true })
                                }
                                return (
                                 <select className="bg-transparent text-md text-right mr-2" onChange={gantiRole} defaultValue={activeRole}>
                                  { state.roles.map((c) => {
                                    let bidang_showed = ["admin_bidang", "koordinator", "subkoordinator"].some((z) => {
                                      return z === c
                                    }) ? bid : ""
                                    return (
                                      <option key={c} className="text-black text-right" value={c}>{c} {bidang_showed}</option>
                                    )
                                  }) }
                                </select>
                                )
                              }
                            }
                          </Auth.AuthConsumer>

                        </div>
                         <div className="pt-4 mr-4 hidden">
                          <select name="" id="" className="bg-transparent text-md">
                            <option value="2022" className="text-black">2022</option>
                            <option value="2021" className="text-black">2021</option>
                          </select>
                        </div>
                        <div className="pt-4 mr-4" >
                            <center>
                              <FontAwesomeIcon icon={faBell} className="text-lg" />
                            </center>
                        </div>
                        <div className="w-[40px] ">
                          <ProfileDropDown />
                        
                        </div>
                    </div>
  )
}
export const Navbar = ({ children, logout }) => {
    const [expand, setExpand] = useState(false);
    let isHide  = (!expand) ? "hidden" : "";
  const navigate = useNavigate();
    const color = (AppType() === "EBT") ? "bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500" : "bg-gradient-to-r from-blue-600 to-teal-800"
    return (
        <nav className="w-full" id="myNavbar">
            <div className={"z-50  pl-4 shadow-lg xl:h-[56px] " + color}>
                { /* Ketika navbar di mobile */ }
                <div className="flex xl:hidden  relative">
                    <MobileMenu />
                    
                    <div >
                  

                      <div className="absolute flex right-0">
                      <div className="pt-4 mr-2">
                      <Auth.AuthConsumer>
                        {
                          ({ state, getOneUser }) => {
                            const activeRole = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
                            const gantiRole = (val) => {
                              localStorage.setItem("PENERIMAAN_ACTIVE_ROLE", val.target.value);
                              const idUser  = localStorage.getItem("PENERIMAAN_ID_USER");
                              getOneUser(idUser, true)
                              // setRedirect(true);
                              // setRedirect(false);
                              navigate('/app/dashboard', { replace: true })
                            }
                            return (
                              <div className="pt-2">
                                <select className="bg-transparent text-md text-right text-white " onChange={gantiRole} defaultValue={activeRole}>
                                { state.roles.map((c) => {
                                  return (
                                    <option key={c} className="text-black text-right" value={c}>{c}</option>
                                  )
                                }) }
                              </select>  
                              </div>
                            
                            )
                          }
                        }
                      </Auth.AuthConsumer>
                    </div>
                      <div className="pt-6 ">
                        <select name="" id="" className="bg-transparent text-md text-white">
                          <option value="2022" className="text-black">2022</option>
                          <option value="2021" className="text-black">2021</option>
                        </select>
                      </div>
                        <div className="pt-5 text-white  p-2 text-2xl ">
                      
                              <FontAwesomeIcon icon={faBell} className="-mr-3"/>
                          
                        </div>
                        <div className="pt-2 w-20 pl-5">
                          <ProfileDropDown />                                       
                        </div>
                      </div>
                    </div>
                </div>
                <div className= "hidden  xl:flex justify-between">
                        
                        { /*  Ketika navbar di desktop */ }
                        <MyListNavbar/>
                      
                          <RightNav />
                       
                </div>
            </div>
            <div className="w-full bg-white xl:hidden">
              <h2 className="font-bold p-2 text-center text-blue-600">
                  {
                    (AppType() === "EBT") ? "SIKK PENERIMAAN" : "SIMPELKU PENERIMAAN"
                  }
              </h2>
            </div>
        </nav>
    )
}

export const SmallNavbar = ({ left, right }) => {
  return (
    
                <div className="grid grid-cols-2 justify-between bg-blue-500 mb-2 p-2 rounded-md shadow-lg">
                    <div className="flex text-md mt-2 ml-2 text-white">
                        { left }
                    </div>
                 
                    <div className="text-right">
                        { right }
                    
                    </div>
                </div>
  )
}