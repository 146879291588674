import '../../lampiran.css'
import { NoDokumenPembayaran, getInaDate, numberFormatReverse, ex_terbilang, ucWords } from '../../api/Value';
import { useEffect, useState } from 'react';

export default ({ myForm, invoiceSelected, preview=false }) => {
  /* const maxPanjang = 20;
    const maxIndexPanjang = 19;
    let jumlahPeserta = 0;
    let initJumlahPesertaPerPage = 0;
    let patternRender = []
    if (myForm.keranjang) {
        myForm.keranjang.map((x, i) => {
            
            if(x.info_keranjang.list_peserta){
                let panjangObject         = x.info_keranjang.list_peserta.length;
                jumlahPeserta            += panjangObject;
                // jika lebih besar dari max panjang
                if (panjangObject >= maxPanjang) {
                    // panjang peserta dibagi maxPanjang di uppercomma
                    for (let pp = 0; pp < Math.ceil(panjangObject / maxPanjang); pp++) {
                        let infoKeranjang = x.info_keranjang;
                        let fstIndex      = pp*maxIndexPanjang;
                        let panjangAkhir  = (panjangObject-1 <= (fstIndex+maxIndexPanjang)) ? panjangObject: (fstIndex+maxIndexPanjang);
                        let listPeserta   = infoKeranjang.list_peserta.slice(fstIndex, panjangAkhir)
                        patternRender.push([{ ...x, info_keranjang: { ...infoKeranjang, list_peserta: listPeserta } }])
                    }
                    
                    initJumlahPesertaPerPage = 0;
                }else if (panjangObject < maxPanjang) {
                    if (initJumlahPesertaPerPage === 0) {
                        patternRender.push([x])
                    }else if(initJumlahPesertaPerPage <  maxPanjang){
                       
                        patternRender[patternRender.length-1].push(x);
                    }
                    initJumlahPesertaPerPage += panjangObject

                }else if (initJumlahPesertaPerPage > maxPanjang) {
                    patternRender.push([x])
                }
                
            }else{
                patternRender.push([x])
            }

            return x;
        })
    }
    if (jumlahPeserta.length <= 20) {
        return (
            <HeaderLampiran invoiceSelected={invoiceSelected}>
                <RenderTable keranjang={ myForm.keranjang } invoiceSelected={invoiceSelected} />
            </HeaderLampiran>
        )
    }
    let renderFinal = patternRender.map((x, i) => {
        if (i === 0) {
            return (<HeaderLampiran  invoiceSelected={invoiceSelected}>
                    <RenderTable keranjang={x} nomor={i} />
                    <footer></footer>
                </HeaderLampiran>)
        }else{
            return (
                <div className={`${preview ? "mt-10" : ""} file bg-white  calibri p-[2.1cm] `}>
                        <RenderTable keranjang={x} nomor={i} />
                        {
                            (patternRender.length-1 === i) ?
                            <footer></footer>
                            :
                            null
                        }
                    </div>
                    )

        }
    })
    
    return (<div>{renderFinal}</div>);
    */
   return (
       <div>
    
                <HeaderLampiran  invoiceSelected={invoiceSelected}>
                    <RenderTable myForm={myForm} />   
                </HeaderLampiran>
         
    </div>
    )
 
}

const HeaderLampiran = ({ children, invoiceSelected }) => {
    return (
        <div className="bg-white file calibri p-[2.1cm] pt-[1.1cm] pb-[0cm]">
             <center>
                <div style={{fontSize:"18px",fontWeight: "bold"}}>LAMPIRAN INVOICE</div>
            </center>
            <br />
            
        <table style={{fontSize: "16px"}}>
            <tbody>
                <tr>
                    <td>Nomor &nbsp;&nbsp;</td>
                    <td>:</td>
                    <td> &nbsp;&nbsp;{NoDokumenPembayaran({ id_invoice: invoiceSelected.id_invoice, dt_invoice_created: invoiceSelected.dt_invoice_created })}</td>
                </tr>
                <tr>
                    <td>Tanggal &nbsp;&nbsp;</td>
                    <td>:</td>
                    <td> &nbsp;&nbsp;{getInaDate(invoiceSelected.dt_invoice_created)}</td>
                </tr>
            </tbody>
        </table>
        <br />
        { children }
        </div>
    )
}
const RenderTable = ({ myForm, nomor }) => {
    
    return (

        <div style={{ fontSize: "16px"}} className="list-decimal">
        {
            myForm.keranjang.map((x, i) => {
                let nextPeserta     = -1;
                let jumlahPeserta   = (x.info_keranjang.list_peserta) ? x.info_keranjang.list_peserta.length : 0;
                const tanggal_akhir = (x.info_keranjang.tgl_akhir) ? x.info_keranjang.tgl_akhir : x.info_keranjang.tgl_selesai 
                if (myForm.keranjang[i+1]) {
                    if (myForm.keranjang[i+1]['info_keranjang'].list_peserta) {
                        nextPeserta = myForm.keranjang[i+1]['info_keranjang'].list_peserta.length;
                    }
                }
                return (
                    <div key={i}> 
                    
                    {
                        x.info_keranjang.list_peserta ?
                        <table width="100%" className="tbl-biaya mt-4 mb-4" style={{ border: 'none' }}>
                        <tbody style={{ border: 'none' }}>
                            <tr>
                                <td style={{ border: 'none' }} valign="top" className="pt-[1cm]">
                                {i+1}.
                                </td>
                                <td style={{ border: 'none' }} colSpan="3" className="pt-[1cm]">
                                    { x.uraian_keranjang }
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th style={{ border: '1px solid black' }}>No</th>
                                <th style={{ border: '1px solid black' }}>Nama</th>
                                <th style={{ border: '1px solid black' }}>Tanggal Pelaksanaan</th>
                                <th style={{ border: '1px solid black' }}>Biaya</th>
                            </tr>
                        </tbody>
                        <tbody>
                        {

                            
                            x.info_keranjang.list_peserta.map((d, n) =>{
                                
                                return (
                                    <tr key={n}>
                                        <td>{n+1}</td>
                                        <td>{d.namaPeserta}</td>
                                        <td className="text-center" style={{whiteSpace: 'nowrap'}}>{ x.info_keranjang.tgl_awal.split("-").reverse().join("/") } s.d {tanggal_akhir.split("-").reverse().join("/") }</td>
                                        <td className="text-right">{ numberFormatReverse(`${d.biayaPeserta}`) }</td>
                                    </tr>
                                )
                            })
                        }
                            <tr>
                                <td colSpan="3" className="font-bold" align="righst">Total</td>
                                <td className="text-right">{numberFormatReverse(`${x.jumlah_keranjang}`)}</td>
                            </tr>
                        </tbody>
                           
                             
                         
                        </table>
                        : 
                        <table className="tbl-biaya mt-4 mb-4" width="100%">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nomor</th>
                                    <th>Tanggal Pelaksanaan</th>
                                    <th>Kuantitas</th>
                                    <th>Tarif</th>
                                    <th>Biaya</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">1</td>
                                    <td className="text-center">{x.info_keranjang.nomor_fasilitas}</td>
                                    <td className="text-center"  style={{whiteSpace: 'nowrap'}}>{ x.info_keranjang.tgl_awal.split("-").reverse().join("/") } s.d {tanggal_akhir.split("-").reverse().join("/") }</td>
                                    <td className="text-center">{ x.kuantitas }</td>

                                    <td className="text-center">{ numberFormatReverse(`${x.tarif_keranjang}`) }</td>
                                    <td className="text-center">{ numberFormatReverse(`${x.jumlah_keranjang}`) }</td>
                                </tr>
                            </tbody>
                        </table>
                    }
            
                       
                    {
                        // nextPeserta > 0 && nextPeserta <= 5 ?
                        // null
                        // : 
                        jumlahPeserta >= 14 && i < myForm.keranjang.length-1 ?
                        <span>
                            <footer></footer>
                            <br />
                            <br />
                        </span>
                        :
                        null
                    }
            </div>
                )
            })
                
        }
            
            
        </div>
    )
}