const getTtdKabag = ({ tanggal }) => {
    console.log(tanggal)
    const time_doc = new Date(tanggal)
    
    // if(time_doc >= new Date("2024-04-01")){
    if(time_doc >= new Date("2024-03-01")){
        return <TtdKabag2 />
    }else{
        return <TtdKabag1 />
    }
}



function TtdKabag1(){
    return (
        <div>
             
             <div className="w-[300] text-[13px]">
                a.n. Kepala Bagian Umum, <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sub Koordinator Keuangan,
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Raden Moehammad Noermansyah, M.M.
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NIP. 19771202 201012 1 001

            </div>    
        </div>
    )
}


function TtdKabag2(){
    return (
        <div>
             <br />
             <div className="w-[300] text-[13px]">
                Kepala Bagian Umum, <br />
                
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                Rr. Rizki Amalia Nurhayati, S.H., M.H.
                <br />
                NIP. 19810126 200604 2 001

            </div>    
        </div>
    )
}

export default getTtdKabag