import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true

export const getAllRoles = async () => {
    const reqData = await axios.get( backEndUrl() + "/penerimaan/role", getCred())
                    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getOneRole = async ({ nama_role }) => {
      const attr  = { 
                    nama_role: { required: true, checkv: { isString: true } }
                };
    const values= { 
        nama_role: nama_role
    }
    const valid = checkType({ attr: attr, values: values })
    if (valid.status) {
    
        const reqData = await axios.get( backEndUrl() + "/penerimaan/role/" + nama_role, getCred())
                        .then((res) => {
                            return res.data
                        })
                        .catch((e) => {
                            return {
                                status: 500,
                                message: e.response
                            }
                        }) 
        return reqData

    }else{
        return valid;
    }
}
export const updateRole = async ({ role, menu }) => {
    const attr = {
        role: { required: true, checkv: { isString: true } },
        menu: { required: true, checkv: { isString: true } }
    }
    const values = {
        role: role,
        menu: menu
    }
    const valid = checkType({ attr: attr, values: values })
    if (valid.status) {
        return await axios.put( backEndUrl() + "/penerimaan/user", values, getCred())
                        .then((res) => {
                            return res.data
                        }).catch((err) => {
                            return {
                                status: 500,
                                messages: err.response 
                            }
                        });
    }else{
        return valid;
    }
}