import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getBidang, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';
axios.defaults.withCredentials = true

const expressUrl = "https://k2express.kebtke.id"
const keys       = "819pf6qx2g0Ob03ueDwT"
export const bankVa     =  async () => {
    return await axios.get( backEndUrl() + "/engine/global/bank_va", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    })
}
export const pembayaranBidang     =  async () => {
    return await axios.get( backEndUrl() + "/engine/global/pembayaran_bidang", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    })
}
export const getPendapatanLengkap = async () => {
    return await axios.get( backEndUrl() + "/penerimaan/laporan/pendapatanLengkap", getCred() )
                    .then((res) => res.data)
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    })
}
// type pendapatan | penerimaan | piutang
export const getLaporan  = async ({ type }) => {
     
     // // console.log(role, getBidang())
     const reqData = await axios.get( backEndUrl() + "/penerimaan/laporan/" + type, getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const groupSum = async ({ id_pelanggan }) => {
    
    return await axios.get( backEndUrl() + "/penerimaan/laporan/statistik_pelanggan/" + id_pelanggan, getCred())
        .then(res => {
            return res.data;
        })
        .catch(e => {
            return {
                status: 500,
                message: e.response
            }
        })
}
export const getStatusSendWa = async () => {
    
    return await axios.get(backEndUrl() + "/engine/global/send_whatsapp", getCred() )
    .then((res) => {
                    return res.data
                })
                .catch((e) => {
                    return {
                        status: 500,
                        message: e.response
                    }
                }) 
}
export const getGlobalWhatsapp = async () => {
    
    return await axios.get(backEndUrl() + "/engine/global/whatsapp", getCred() )
    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}
export const getGlobalBiayaBank = async () => {
    
    const reqData = await axios.get( backEndUrl() + "/engine/global/biaya_va", getCred()) 
                .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const getDashboard = async () => {
    
    return await axios.get(backEndUrl() + "/penerimaan/laporan/dashboard1",  getCred())
    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}

export const getDashboardExpress = async ({ tahun }) => {
    return await fetch(`${expressUrl}/dash/data/${tahun}?key=${keys}`)
    .then((res) => res.json())
    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
}

export const getTahun =  () => {
    return fetch(`${expressUrl}/dash/tahun?key=${keys}`)
    .then((res) => res.json())
    
    .then((result) => {
        return result.data    
    }).catch((err) => {
        return {
            status: 500,
            message: err.response
        }
    });

}

export const laporanLengkap = () => {
    return fetch(`${expressUrl}/laporan/lengkap?key=${keys}`)
    .then((res) => res.json())
    .then((result) => {
        return result.data    
    }).catch((err) => {
        return {
            status: 500,
            message: err.response
        }
    });
}

export const createSuratTagihan = async ({ id_transaksi, no_va, no_tagihan }) => {
    const data = { id_transaksi: id_transaksi, no_va: no_va, no_tagihan: no_tagihan }
    let response = {}
    await fetch(`${expressUrl}/tagihan/baru?key=${keys}`,{
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
    })
    .then((res) => {
        response = res.json()
    }).catch((err) => { 
        response = {
            status: 500,
            message: err.response
        }
    });
    return response
}

export const getSuratTagihan = async ({ id_transaksi }) => {
    let response = {}
    await fetch(`${expressUrl}/tagihan/${id_transaksi}?key=${keys}`)
    .then((res) => {
        response = res.json()
    }).catch((err) => { 
        response = {
            status: 500,
            message: err.response
        }
    });
    return response
}

export const updateStatusUpload  = async ({ no_tagihan }) => {
    let response = {  }
    var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("active_role", "admin");

        var raw = JSON.stringify({
            "no_tagihan": no_tagihan
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const a = await fetch(`https://k2express.kebtke.id/tagihan/upload?key=${keys}`, requestOptions)
        // .then(response => {
        //     const res = response.json()
        //     response = { status: 200, message:res }
        // })
        .catch(error => {
            console.log(error)
            response = { status: 500, message: error }
        });

        if(response.status !== 500){
            const json = await a.json()
            response = {status: 200, message: json}
        }
        return response
}

export const getCatatan = ({ id_transaksi }) => {
    return fetch(`${expressUrl}/catatan/${id_transaksi}?key=${keys}`)
    .then((res) => res.json())
    .then((result) => {
        return result.data    
    }).catch((err) => {
        return {
            status: 500,
            message: err.response
        }
    });
}

export const createCatatan = ({ id_transaksi, konten }) => {

    const data = {
        "id_transaksi": id_transaksi,
        "konten": konten
    }
    
    return fetch(`${expressUrl}/catatan?key=${keys}`,{
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
    })
    .then((res) => {
        return res.json()
    }).catch((err) => { 
        return {
            status: 500,
            message: err.response
        }
    });
}


export const updateCatatan = ({ id_transaksi, konten }) => {

    const data = {
        "id_transaksi": id_transaksi,
        "konten": konten
    }
    
    return fetch(`${expressUrl}/catatan?key=${keys}`,{
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
    })
    .then((res) => {
        return res.json()
    }).catch((err) => { 
        return {
            status: 500,
            message: err.response
        }
    });
}