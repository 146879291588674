import { Component, Fragment } from 'react';
import { faEye, faMapSigns, faPlus, faUser, faSort, faPhone, faMailBulk, faAddressCard, faBuilding, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { InputText, TextArea, Radio } from '../../components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllPelanggan, createPelanggan, deleteUser, updatePelanggan } from '../../api/Pelanggan';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { Switch } from '@headlessui/react';
import Spinner    from '../../components/Spinner';
import { checkType, strSpecialRemove } from '../../api/Value';
import { AppType } from '../../config/Config';
export default class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            myForm: {
                user_info: {
                    nik: "",
                    nama: "",
                    alamat: "",
                    telp: "",
                    email: "",
                    npwp: "",
                    instansi: "",
                    nama_tujuan_1: "",
                    alamat_tujuan_1: "",
                    nama_tujuan_2: "",
                    alamat_tujuan_2: "",
                    nama_cp_konsumen: "",
                    hp_cp_konsumen: "",
                    email_cp_konsumen: "",
                    nama_cp_internal: "",
                    hp_cp_internal: "",
                    email_cp_internal: "",
                    profile_picture: ""
                },
                status: "non_aktif"
            },
            formState:{
                    nik: "",
                    nama: "",
                    alamat: "",
                    telp: "",
                    email: "",
                    npwp: "",
                    instansi: "",
                    nama_tujuan_1: "",
                    alamat_tujuan_1: "",
                    nama_tujuan_2: "",
                    alamat_tujuan_2: "",
                    nama_cp_konsumen: "",
                    hp_cp_konsumen: "",
                    email_cp_konsumen: "",
                    nama_cp_internal: "",
                    hp_cp_internal: "",
                    email_cp_internal: ""
            },
            isPerusahaan: false,
            isActive: true,
            idDeletePelanggan: 0,
            submitLoader: false,
            nameFile: "",

            // update 
            id_user: "",

        }
    }
    componentDidMount = () => {
        const dataForm  = this.props.detailForm;
        let data        = {};
        let id_user     = ""
        let isPerusahaan  = false;
        let isActive    = true;
        for(let d in dataForm) {
            if (d == "status") {
                isActive    = (dataForm[d] == "aktif")
            }else if (d == "instansi") {
                isPerusahaan    = (dataForm[d] === "Perusahaan")                
            }else if (d == "id_user") {
                id_user = dataForm[d];
            }else{
                data[d] = dataForm[d] 
            }
        }
        let myForm = this.state.myForm
        myForm['user_info'] = data;
        this.setState({
            id_user: id_user,
            myForm: myForm,
            isActive: isActive,
            isPerusahaan: isPerusahaan
        })
    }

     // simpan pelanggan
    savePelanggan = () => {
        this.setState({
            submitLoader: true
        })
        const required = ["nama", "alamat", "telp", "email"];
        
        // const validasi = this.validasi();
        // console.log(validasi)
        const id_user = this.state.id_user;
        let formState = this.state.formState;
        let isValid   = true;
        const myForm = this.state.myForm;
       
        for(let d of required){
            const val =  myForm["user_info"][d]
            if (myForm["user_info"][d] === "" || myForm['user_info'][d] === undefined) {
                formState[d] = "required"
                isValid = false;
            } 
            if(val.charAt(val.length-1) === "." && d === "email"){
                formState[d] = "Email Invalid"
                isValid = false;
            }
        }
        if (isValid === false) {
            
            this.setState({
                formState: formState,
                submitLoader: false
            });
        }else{
            let newForm  = myForm;
            const self   = this;
            myForm["user_info"]['instansi'] = (this.state.isPerusahaan) ? "Perusahaan" : "Perorangan";


            let userInfo = myForm.user_info;
            for(let u in userInfo){
                if (typeof userInfo[u] === "string") {
                    userInfo[u] = userInfo[u].trim(); 
                }
            }

            // replace special character
            myForm['user_info']['nama']          = strSpecialRemove(myForm['user_info']['nama'])
            
            myForm['user_info']['nama_tujuan_1'] = strSpecialRemove(myForm['user_info']['nama_tujuan_1']) 
            myForm['user_info']['nama_tujuan_2'] = strSpecialRemove(myForm['user_info']['nama_tujuan_2']) 
            if (id_user == "") {
                createPelanggan({ user_info: userInfo, status: (this.state.isActive) ? "aktif" : "nonaktif" }).then((result) => {
                    console.log(result)
                    if(result.status  == 200){
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Pelanggan berhasil ditambah " bg="green"/>
                        }) 
                        self.props.closeModalForm(true);

                    }else{

                        toast.custom((t) => {
                            return <ToastComponent param={t} message={ typeof result.messages?.data === "string" ? result.messages?.data: "Pelanggan gagal ditambah Mohon Coba Lagi"} bg="red"/>
                        }) 
                    }
                    self.setState({
                        submitLoader: false
                    })
                })
            }else if (id_user != "") {
                let user_info = {};
                for(let d in this.state.formState){
                    if (typeof user_info[d] === "string") {
                        user_info[d] = myForm['user_info'][d].trim();
                    }else{
                        user_info[d] = myForm['user_info'][d];

                    }
                }
                updatePelanggan({ id_user: id_user, username: myForm.user_info.username, user_info: JSON.stringify(user_info), status: (this.state.isActive) ? "aktif" : "nonaktif" }).then((result) => {
                //    console.log(result)
                    if(result.status  == 200){
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Pelanggan berhasil diupdate " bg="green"/>
                        }) 
                        self.props.closeModalForm(true);

                    }else{
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={ typeof result.messages.data === "string" ? result.messages.data : "Pelanggan gagal diupdate Mohon Coba Lagi"} bg="red"/>
                        }) 
                    }
                    self.setState({
                        submitLoader: false
                    })
                })


            }  
        }

        
      
        
    }
    
    // bind input form
    bindUserInfo = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        let myForm = this.state.myForm;
        myForm['user_info'][name] = val
        this.setState({
            myForm: myForm
        })
    }
    // toggle personal || instansi
    setPersonal = (isPerusahaan) => {
        this.setState({
            isPerusahaan: isPerusahaan
        })
    }
    // toggle user aktif || non_aktif
    setActive = () =>  {
        this.setState({
            isActive: !this.state.isActive
        })
    }
    // check user click  jenis instansi
    handleInstansi = (e) => {
        this.setPersonal(!this.state.isPerusahaan)
    }
    render(){
        const { myForm, isPerusahaan, isActive, idDeletePelanggan, submitLoader, formState, nameFile, loader } = this.state;
        const { uploadProfile, savePelanggan, bindUserInfo, setPersonal, setActive, handleInstansi } = this;
        const { closeModalForm } = this.props;
        const countClassGrid     = (isPerusahaan) ? "3" : "2"
        
        return (
            <div>
                
        <div className="pt-1 ">
            <div className={"grid md:grid-cols-" + countClassGrid}>
                <div className="p-2">
                    <TextArea other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.nama }} Title={<span>{"Nama " + (isPerusahaan ? "Perusahaan" : "")} <span className="text-red-500">*</span> </span>} Placeholder = {"Nama " + (isPerusahaan ? "Perusahaan" : "")} Name="nama" Status={formState.nama} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                    <TextArea other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.alamat }} Title={<span>Alamat <span className="text-red-500">*</span></span>} Placeholder = "Alamat" Name="alamat" Status={formState.alamat}  TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                    <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.telp }} Type="number" Title={ <span>Telp <span className="text-red-500">*</span> </span>} Placeholder = "Telp" Name="telp" Status={formState.telp} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                    <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.email }} Title={ <span>Email <span className="text-red-500">*</span> </span> } Placeholder = "Email" Name="email" Status={formState.email} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                    <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.nik }} Type="number" Title="NIK" Placeholder="NIK" Name="nik" Status={formState.nik} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                    <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.npwp }} Type="number" Title="NPWP" Placeholder = "NPWP" Name="npwp" Status={formState.npwp} TitleClass=" text-xs" elementClass=" mb-2" InputClass=" " />
                </div>
                <div className="p-3">
                    <div className="">Status aktif</div>
                     <Switch
                        checked={isActive}
                        onChange={setActive}
                        className="ml-2 bg-gray-200 relative inline-flex flex-shrink-0 h-[30px] w-[62px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                        <span className="sr-only">Use setting</span>
                        <span
                        aria-hidden="true"
                        className={`${isActive ? 'translate-x-8 bg-blue-500' : 'translate-x-0 bg-white'}
                            pointer-events-none inline-block h-[26px] w-[26px] rounded-full  shadow-lg transform ring-0 transition ease-in-out duration-200`}
                        />
                    </Switch>
                    <div className=" mt-1 mb-1">Perorangan/Perusahaan</div>

                      <Switch
                        checked={isPerusahaan}
                        onChange={handleInstansi}
                        className="ml-2 bg-gray-200 relative inline-flex flex-shrink-0 h-[30px] w-[62px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                        <span className="sr-only">Use setting</span>
                        <span
                        aria-hidden="true"
                        className={`${isPerusahaan ? 'translate-x-8 bg-blue-500' : 'translate-x-0 bg-white'}
                            pointer-events-none inline-block h-[26px] w-[26px] rounded-full  shadow-lg transform ring-0 transition ease-in-out duration-200`}
                        />
                    </Switch>

                  
                    {
                        (isPerusahaan) ?
                        <div>
                         
                            <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.nama_tujuan_1 }} Title="Nama Tujuan Tagihan 1" Placeholder = "Nama Tujuan Tagihan 1" Name="nama_tujuan_1" Status={formState.nama_tujuan_1} TitleClass=" text-xs"  elementClass=" mb-2" InputClass=" "  />
                           
                            <TextArea other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.alamat_tujuan_1 }} Title="Alamat Tujuan Tagihan 1" Placeholder = "Alamat Tujuan Tagihan 1" Name="alamat_tujuan_1" Status={formState.alamat_tujuan_1} TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" " />
                      
                            <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.nama_tujuan_2 }} Title="Nama Tujuan Tagihan 2" Placeholder = "Nama Tujuan Tagihan 2" Name="nama_tujuan_2" Status={formState.nama_tujuan_2} TitleClass=" text-xs"  elementClass=" mb-2" InputClass=" " />
                           
                            <TextArea other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.alamat_tujuan_2 }} Title="Alamat Tujuan Tagihan 2" Placeholder = "Alamat Tujuan Tagihan 2" Name="alamat_tujuan_2" Status={formState.alamat_tujuan_2} TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" "/>
                         
                        </div>
                        : 
                        null
                    }
                    
                    
                </div>
                {
                        (isPerusahaan) ?
                        <div className="p-3">

                            <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.nama_cp_konsumen }} Title="Nama CP Konsumen" Placeholder = "Nama CP Konsumen" Name="nama_cp_konsumen" Status={formState.nama_cp_konsumen} TitleClass=" text-xs"  elementClass=" mb-2" InputClass=" " />
                    
                            <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.hp_cp_konsumen }} Title="HP CP Konsumen" Placeholder = "HP CP Konsumen" Name="hp_cp_konsumen" Status={formState.hp_cp_konsumen} TitleClass=" text-xs"  elementClass=" mb-2" InputClass=" " />
                           
                            <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.email_cp_konsumen }} Title="Email CP Konsumen" Placeholder = "Email CP Konsumen" Name="email_cp_konsumen" Status={formState.email_cp_konsumen} TitleClass=" text-xs"  elementClass=" mb-2" InputClass=" " />
                           {
                               (AppType() === "EBT") ?
                                <Fragment>
                                    
                            <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.nama_cp_internal }} Title="Nama CP Internal" Placeholder = "Nama CP Internal" Name="nama_cp_internal" Status={formState.nama_cp_internal} TitleClass=" text-xs"  elementClass=" mb-2"  InputClass=" "/>
                            
                            <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.hp_cp_internal }} Title="HP CP Internal" Placeholder = "HP CP Internal" Name="hp_cp_internal" Status={formState.hp_cp_internal} TitleClass=" text-xs"  elementClass=" mb-2" InputClass=" " />
                          
                            <InputText other={{ onChange: bindUserInfo, defaultValue: myForm.user_info.email_cp_internal }} Title="Email CP Internal" Placeholder = "Email CP Internal" Name="email_cp_internal" Status={formState.email_cp_internal} TitleClass=" text-xs"  elementClass=" mb-2" InputClass=" " />
                                </Fragment>
                               :
                                null
                           }
                        </div>
                        : 
                        null 
                }
            </div>
            <ModalFooter closeModal = {closeModalForm} onSubmit={savePelanggan} loader={submitLoader} />
        </div>
            
            </div>
        )
    }
}

const ModalFooter = ({ closeModal, onSubmit, loader }) => {
   
    return(
        <div className="text-right flex justify-end">
            
            
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-600 p-2 shadow-md hover:bg-gray-500 active:bg-gray-700 active:shadow-none rounded-md w-32  text-white" onClick={() => closeModal(false)}>Batal</button>
                        <button onClick={onSubmit} className=" font-bold bg-green-600 ml-2 p-2 shadow-md hover:bg-green-500 active:bg-green-700 active:shadow-none rounded-md w-32 border-green-700 text-white">
                                Simpan
                        </button>
                    </div>
                : 
                <button className="	 cursor-not-allowed flex font-bold bg-green-600 ml-2 p-2 rounded-md w-52 border-green-600 text-white">
                  
                     <div className="w-1/4">
                         <Spinner 
                        color="rgb(255 255 255)"
                        width="30px"
                        height="30px"
                    />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}