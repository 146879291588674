import { Component } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { InputText, TextArea, Select } from '../../components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { Switch } from '@headlessui/react';
import Spinner    from '../../components/Spinner';
import { checkType, numberFormat } from '../../api/Value';
import { backEndUrl } from '../../config/Config';
import { createLayanan, updateLayanan } from '../../api/Layanan';
import ModalFooter from '../../components/ModalFooter';
export default class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            myForm: { 
                kode_layanan: "",
                nama_layanan: "",
                tarif: "",
                target: "",
                is_product: false,
                info_layanan: "{}",
                kategori: "",
                unit_penjual: "",
                tipe_form: ""
            },
            formState:{
                kode_layanan: "",
                nama_layanan: "",
                tarif: "",
                target: "",
                info_layanan: "",
                kategori: "",
                unit_penjual: "",
                tipe_form: ""
            }
        }
    }
    componentDidMount = () => {
        let kode_layanan = this.props.kode_layanan;
   
        const data       = this.props.detailForm
       
        // is update
        if(kode_layanan != "")  {
            let myForm = this.state.myForm;
           
            for(let m in myForm) {
                if (m == "info_layanan") {
                    const info = (data[m] !== "") ? JSON.parse(data[m]) : {} 
                     //console.log("DATAA", info)
                    myForm["tipe_form"]     = info.tipe_form;//(info.tipe_form !== undefined && info.tipe_form !== null) ? info.tipe_form : "";
                }else if(m !== "tipe_form"){
                    myForm[m] = data[m];
                }
            }
        //    console.log(myForm)
            this.setState({
                myForm: myForm
            })
        // is child add
        }else if (data['kode_layanan']) {
            let myForm = this.state.myForm;

            myForm['kode_layanan'] = data['kode_layanan']
            this.setState({
                myForm: myForm
            })
        }
        
        var s = document.querySelector("#cari");
        s.disabled = "disabled"
    }
    componentWillUnmount = () => {
          var s = document.querySelector("#cari").removeAttribute("disabled");
    }
    saveLayanan = () => {
        const attr = ["kode_layanan", "nama_layanan"];
        let myForm   = this.state.myForm;
        let formState = this.state.formState;
        let isValid  = true;
        for(let d  of attr){
            if(myForm[d] == ""){
                formState[d] = "Required";
                isValid =false
            }
        }

        if (myForm.is_product) {
            if (myForm.tipe_form == "") {
                formState['tipe_form'] = "Required"
                isValid = false;
            }
        }
        this.setState({
            formState: formState
        })

        myForm.info_layanan = JSON.stringify({ tipe_form: myForm.tipe_form })

        if (isValid == true) {
            myForm['tarif']     =  (myForm['tarif'] == "" || myForm['tarif'] == undefined  || myForm['tarif'] == null) ? 0 : (typeof myForm['tarif'] == "number") ?  myForm['tarif'] : parseInt(myForm['tarif'].replaceAll(",", ""));
            myForm['target']     = (myForm['target'] == "" || myForm['target'] == undefined || myForm['target'] == null) ? 0 :  (typeof myForm['target'] == "number") ?  myForm['target'] : parseInt(myForm['target'].replaceAll(",", ""));
            this.setState({
                loader: true
            })
            const self = this;

            if (this.props.kode_layanan == "") {
                // harus benar2 unik
                const cariKode = this.props.data.filter((x) => x.kode_layanan == myForm.kode_layanan);

                if(cariKode.length > 0) {
                    toast.custom((t) => {
                            return <ToastComponent param={t} message="Kode Layanan Terpakai" bg="red"/>
                        }) 
                          self.setState({
                                    loader: false
                                })
                }else{
                    
                    createLayanan(myForm).then((res) => {
                        if(res.status == 200){
                            toast.custom((t) => {
                                return <ToastComponent param={t} message="Layanan berhasil ditambah" bg="green"/>
                            }) 
                            self.props.closeModal({ isUpdate: true, kode_layanan: myForm.kode_layanan })        
                        }else{
                            toast.custom((t) => {
                                return <ToastComponent param={t} message={res.messages.data} bg="red"/>
                            }) 
                                self.setState({
                                    loader: false
                                })
                        }
                    })  
                } 
            }else if (this.props.kode_layanan != "") {
                   
                updateLayanan({kode: this.props.kode_layanan,...myForm}).then((res) => {
                    if(res.status == 200){
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Layanan berhasil diubah" bg="green"/>
                        }) 
                        self.props.closeModal({ isUpdate: true, kode_layanan: myForm.kode_layanan })        
                    }else{
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={res.messages.data} bg="red"/>
                        }) 
                            self.setState({
                                loader: false
                            })
                    }
                })
            }
        }else{

            setTimeout(() => {
                for(let a in formState){
                    formState[a] = ""
                }
                this.setState({
                    formState: formState
                })
            }, 2000);
        }

    }
    bindForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const dt = this.props.detailForm;
        let myForm  = this.state.myForm;
        if (dt.type === "plus" && name === "kode_layanan") {
            if (value.indexOf(dt.fixed) === -1) {
                myForm["kode_layanan"] = dt.fixed;
                this.setState({
                    myForm: myForm
                })
            }else{
                myForm["kode_layanan"] = value;
                this.setState({
                    myForm: myForm
                })
            }
        }else{
            myForm[name] = (name == "target" || name == "tarif")  ? numberFormat(`${value}`.replaceAll(",","")) : value;
            console.log(myForm)
            this.setState({
                myForm: myForm
            })
        }
    }
    handleIsProduct = () => {
        let myForm  = this.state.myForm;
        myForm['is_product'] = !myForm['is_product'];
        this.setState({
            myForm: myForm
        })
    }
    handleUnit = (e) => {    
        const value = e.target.value;
      //  console.log(value)
        let myForm = this.state.myForm;
        if (value != "") {
            let val = myForm.unit_penjual.split(",").map((x) => { return x.trim() });
            val.push(value);
            myForm['unit_penjual'] = val.join(",")
            this.setState({
                myForm: myForm
            })
        }

    }
    deleteUnit = (index) => {
        let myForm = this.state.myForm;
        let val     = myForm['unit_penjual'].split(",")
        val.splice(index, 1);
            val     = val.join(",");
            myForm["unit_penjual"] = val;
            this.setState({
                myForm: myForm
            })
    }
    render(){
        const { myForm, formState, loader } = this.state;
        const { saveLayanan, handleIsProduct, bindForm, handleUnit, deleteUnit } = this;
        const { closeModal, categoryList, unit, formGlobal, disabledKode } = this.props;
        return (
            <div className="p-3">
                <div className="grid md:grid-cols-2 gap-4">
                    <div>
                        {
                            disabledKode ?
                            <div>
                                <div>Kode Layanan</div>
                                <div className="text-sm pl-5 p-2">{ myForm.kode_layanan }</div>
                                
                            </div>
                            :
                            <InputText 
                                other={{ onChange: bindForm, value: myForm.kode_layanan, disabled: disabledKode }} 
                                Type="text" 
                                Title="Kode Layanan" 
                                Placeholder="Kode Layanan" 
                                Name="kode_layanan" 
                                Status={formState.kode_layanan} 
                                TitleClass=" text-xs" 
                                elementClass=" mb-2" 
                                />
                        }
                       
                        {
                            disabledKode ? 
                            <span className="italic text-[12px] text-red-500">Parent Tidak Dapat Ganti Kode</span>
                            :
                            null
                        }
                        <InputText 
                                other={{ onChange: bindForm, value: myForm.nama_layanan }} 
                                Type="text" 
                                Title="Nama Layanan" 
                                Placeholder="Nama Layanan" 
                                Name="nama_layanan" 
                                Status={formState.nama_layanan} 
                                TitleClass=" text-xs" 
                                elementClass=" mb-2" 
                                InputClass=" " />
                        
                        <InputText 
                                other={{ onChange: bindForm, value: myForm.tarif }} 
                                Type="text" 
                                Title="Tarif" 
                                Placeholder="Tarif" 
                                Name="tarif" 
                                Status={formState.tarif} 
                                TitleClass=" text-xs" 
                                elementClass=" mb-2" 
                                InputClass=" " />
                        
                        <InputText 
                                other={{ onChange: bindForm, value: myForm.target }} 
                                Type="text" 
                                Title="Target" 
                                Placeholder="Target" 
                                Name="target" 
                                Status={formState.target} 
                                TitleClass=" text-xs" 
                                elementClass=" mb-2" 
                                InputClass=" " />
                            <div>Status Produk</div>

                            <Switch
                                checked={myForm.is_product}
                                onChange={handleIsProduct}
                                className="ml-2 bg-gray-200 relative inline-flex flex-shrink-0 h-[30px] w-[62px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
                            >
                                <span className="sr-only">Use setting</span>
                                <span
                                aria-hidden="true"
                                className={`${myForm.is_product ? 'translate-x-8 bg-blue-500' : 'translate-x-0 bg-white'}
                                    pointer-events-none inline-block h-[26px] w-[26px] rounded-full  shadow-lg transform ring-0 transition ease-in-out duration-200`}
                                />
                            </Switch>
                
                    </div>
                    <div >
                        <Select Other={{ onChange: bindForm, value: myForm.kategori }} Title="Pilih Pelaksana"  Name="kategori" Status={formState.kategori} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                                    <option value="">Pilih Pelaksana</option>
                                    {   
                                        categoryList.map((x) =>  {
                                            return <option key={x} value={x}>{x}</option>   
                                        })
                                    }
                        </Select>
                     <Select Other={{ onChange: handleUnit }} Title="Pilih Unit Penjual"  Name="unit" Status={formState.unit_penjual} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                            <option value="">Pilih Unit</option>
                            {   
                                
                                unit.map((x) =>  {
                                    const find = (myForm.unit_penjual) ? myForm.unit_penjual.indexOf(x) : -1;
                                    //console.log(myForm.unit_penjual)
                                    if (find == -1) {
                                        return <option key={x}  value={x}>{x}</option>   
                                        
                                    }else{
                                        return null
                                    }
                                })
                            }
                        </Select>

                        {
                            (myForm.unit_penjual != undefined) ?
                            myForm.unit_penjual.split(",").map((x, index) => {
                                if (x == "") {
                                    return null
                                }
                                return (
                                     <div className="inline-block bg-blue-500 pb-1 pt-1 mt-2 pl-2 pr-2  rounded-md text-white mr-2 " key={x}>
                                        {x} 
                                        <FontAwesomeIcon className="ml-1" onClick={() => deleteUnit(index)} icon={faTimes} />
                                    </div>
                                )
                            })
                            : null
                        }
                        <br />
                        <br />
                         <Select Other={{ onChange: bindForm, value: myForm.tipe_form }}   Title="Pilih Jenis Form"  Name="tipe_form" Status={formState.tipe_form} TitleClass=" text-xs" elementClass=" mb-3" InputClass=" ">
                            <option value="">Pilih Jenis Form</option>
                            {   
                                (formGlobal !== undefined && formGlobal !== null) ?
                                    formGlobal.map((x) => {
                                        return (
                                            <option key={x}>{x}</option>
                                        )
                                    })
                                :
                                null
                            }
                        </Select>
                    </div>
                </div>
                <ModalFooter closeModal = {closeModal} onSubmit={saveLayanan} loader={loader} />

            </div>
        )
    }
}
