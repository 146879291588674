import { Card } from '../components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faEye, faEyeSlash, faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, Fragment } from 'react'
import { Transition } from '@headlessui/react'
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import FormLogin from '../components/FormLogin';
import Auth from '../api/Auth'

SwiperCore.use([Navigation, Pagination, A11y]);

export default ({ verifyLogin }) => {
    const [Eye, setEye] = useState(false);
    const [isShowing, setShowing] = useState(false);
    const [leftShowing, setLeftShowing] = useState(true);
    const [rightShowing, setRightShowing] = useState(false);
    const lr    = (isShowing) ? "animation-lr" : "animation-rl";
    const lrc   = (isShowing) ? "login-penerimaan" : "login-pengeluaran";
    const bodyMoving = (set, hideText) => {
        if(hideText == "l"){
            setLeftShowing(false)
            setTimeout(() => {
         //       console.log("Right Show")
             setRightShowing(true)

            }, 700);
        }else{
            setRightShowing(false)
            setTimeout(() => {
             setLeftShowing(true)

            }, 700);
        }
        setTimeout(() => {
            var element = document.getElementById("card-moving");
            if(set) {
             element.classList.add("r-login");
            }else{
             element.classList.remove("r-login");
            }
        }, 800);
        setShowing(set)
    }
    useEffect(() => {
        const myRoot = document.getElementById("root");
        myRoot.classList.add("overflow-x-hidden")
        return () => {
             myRoot.classList.remove("overflow-x-hidden")
        }
    }, []);
    return (
        <div className="w-screen h-screen overflow-hidden bg-white relative" >
                    
                
                
                    <div id="card-moving" className={" hidden md:block bg-white absolute md:w-1/3 h-full " + lr} style={{background: `url('${require("../bg.svg").default}')`, backgroundRepeat: "no-repeat", backgroundSize:"cover"}} >
                        <Transition
                            show={leftShowing}
                            enter="transition-opacity"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex p-5 ">
                                <img src={require("../esdm.png")} className="w-14" alt="" />
                                <div className="text-white ml-4 pt-4">
                                    PPSDM MIGAS        
                                </div>
                            </div>
                            <br />
                            <br />
                            <div>
                                <div className="font-bold text-white text-2xl mt-20 text-center">
                                    PENGELUARAN          
                                </div>
                                <div className="text-center mt-2 text-white">
                                    Sistem Informasi Pengeluaran <br/>
                                        Pusat Pengembangan Sumber Daya Manusia <br /> Minyak dan Gas Bumi
                                    <br />

                                    <button onClick={() => { bodyMoving(!isShowing, "l") }}  className="bg-white mt-5 text-black p-2 w-52 h-14 hover:bg-gray-200 rounded-full">Pindah</button>
                                </div>
                            </div>
                        </Transition>
                    </div>
                    <div className="flex p-5 ">
                            <img src={require("../esdm.png")} className="w-14" alt="" />
                            <div className="text-black ml-4 pt-4">
                                PPSDM MIGAS        
                            </div>
                        </div>
                        <div className="md:hidden">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                navigation={{
                                        nextEl: '.next-slide',
                                        prevEl: '.prev-slide',
                                    }}
                                >
                                
                                <SwiperSlide  >
                                <Auth.AuthConsumer>
                                {
                                    ({ verifyLogin }) => {
                                        return(
                                            <LoginPenerimaan verifyLogin= {verifyLogin} />
    
                                        )
                                    }
                                }
                            </Auth.AuthConsumer>
                                
                                </SwiperSlide>
                                <SwiperSlide>
                                <Auth.AuthConsumer>
                           
                                    {
                                        ({ verifyLogin }) => {
                                            return(
                                                <LoginPengeluaran verifyLogin= {verifyLogin} />
        
                                            )
                                        }
                                    }
                                </Auth.AuthConsumer>
                                </SwiperSlide>
                              
                            </Swiper>
                        </div>

                        <div className="hidden md:block">

                            <Transition
                                show={leftShowing}
                                enter="transition-opacity"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity delay-400 "
                                leaveFrom="opacity-100 "
                                leaveTo="opacity-0 "
                            >
                            <Auth.AuthConsumer>
                            {
                                ({ verifyLogin }) => {
                                    return(
                                        <LoginPenerimaan verifyLogin= {verifyLogin} />

                                    )
                                }
                            }
                        </Auth.AuthConsumer>
                               
                            </Transition>
                             <Transition
                                show={rightShowing}
                                enter="transition-opacity"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity delay-400"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                            {
                                ({ verifyLogin }) => {
                                    return(
                                        <LoginPengeluaran verifyLogin= {verifyLogin} />

                                    )
                                }
                            }

                            </Transition>
                    
                        </div>
                        

                  

                <Transition
                    show={rightShowing}
                    enter="transition-opacity"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                <div className="hidden md:block  w-1/3 h-full absolute r-login pt-16 top-0 pl-2">
                
                    <br />
                    <br />
                    <div className="font-bold text-white text-2xl mt-20 text-center">
                            PENERIMAAN           
                        </div>
                        <div className="text-center mt-2 text-white">
                            SIMPELKU PENERIMAAN <br/>
                            <button onClick={() => { bodyMoving(!isShowing, "r") }}  className="bg-white mt-5 text-black p-2 w-52 h-14 hover:bg-gray-200 rounded-full">Pindah</button>
                        </div>
                </div>
                               </Transition>
        </div>
    )
}

const LoginPenerimaan = ({verifyLogin}) => {

    return (
              
                            <div className=" h-screen col-span-2 text-center pt-20 md:ml-1/2 md:pl-[36%]">
                                <div className="text-4xl font-bold">
                               PENERIMAAN
                                </div>
                                <div className="mt-3 text-gray-500">
                                    SIMPELKU PENERIMAAN <br />
                                    
                                </div>
                                <FormLogin submit={verifyLogin} btnClass="hover:opacity-80 active:opacity-50 mt-5 w-full h-10 rounded-md text-white mb-10 bg-gradient-to-r from-green-500 to-teal-500 shadow-md hover:bg-white" />
                                 <button className="text-black mt-5 text-lg md:hidden next-slide">
                                    Pengeluaran <FontAwesomeIcon icon={faAngleDoubleRight} />
                                </button>
                            </div>
 
    )
}
const LoginPengeluaran = ({verifyLogin}) => {

    return (
        
                     <div className=" h-screen text-center pt-20 md:-ml-[36%]" >
                        <div className="text-4xl font-bold">
                       PENGELUARAN

                        </div>
                        <div className="mt-3 text-gray-500">
                            Sistem Informasi Pengeluaran <br />
                            
                        </div>
                        <FormLogin submit={verifyLogin} btnClass="hover:opacity-80 active:opacity-50 mt-5 w-full h-10 rounded-md text-white mb-10 bg-gradient-to-r from-green-500 to-teal-500 shadow-md hover:bg-white" />
                        <div className="text-black mt-5 text-lg md:hidden prev-slide">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} /> Penerimaan 
                        </div>
                    </div>

    )
}