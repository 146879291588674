import { useEffect } from 'react';
import Spinner from '../components/Spinner';

export default () => {

    useEffect(() => {
  
        return () => { };
    }, []);
    return (
        <div>
        
            
          
        </div>
    )
}

const Load = ({ position="l", message, param, bg="cyan"  }) => {

    return(
        <div className="flex  fixed bottom-3 right-3 rounded-md border-l-8 border-blue-500 shadow-xl text-white bg-white p-3">
            <div>
                <Spinner color="#3a82f6" width="30px" height="30px" />
            </div>
            <div className="pl-3 pt-1 text-blue-500 font-bold">
                4 / 5 mengirim Invoice
            </div>
        </div>
    )
}