import { Fragment } from 'react';
import TableRow from './TableRow';
import { AppType } from '../../../config/Config';
export default ({myForm}) =>{
    return (
            <div>
           
                    <table className="text-md" width="100%">
                        <tbody>      
                            <TableRow title="Nama " bg="bg-gray-200" value={myForm.transaksi.info_pelanggan.nama} />  
                            <TableRow title="Alamat " bg="" value={myForm.transaksi.info_pelanggan.alamat} />  
                            <TableRow title="Telp " bg="bg-gray-200" value={myForm.transaksi.info_pelanggan.telp} />  
                            <TableRow title="Email " bg="" value={myForm.transaksi.info_pelanggan.email} />  
                            <TableRow title="NIK " bg="bg-gray-200" value={myForm.transaksi.info_pelanggan.nik} />  
                            <TableRow title="NPWP " bg="" value={myForm.transaksi.info_pelanggan.npwp} />  
                            <TableRow title="Instansi " bg="bg-gray-200" value={myForm.transaksi.info_pelanggan.instansi} />  
                            <TableRow title="Nama CP Konsumen " bg="" value={myForm.transaksi.info_pelanggan.nama_cp_konsumen} />  
                            
                            <TableRow title="HP CP Konsumen " bg="bg-gray-200" value={myForm.transaksi.info_pelanggan.hp_cp_konsumen} />  
                            {
                                (AppType() === "EBT") ?
                                <Fragment>
                                <TableRow title="Nama CP Internal " bg="" value={myForm.transaksi.info_pelanggan.nama_cp_internal} />  
                                <TableRow title="HP CP Internal " bg="bg-gray-200" value={myForm.transaksi.info_pelanggan.hp_cp_internal} />  
                                <TableRow title="Email CP Internal " bg="" value={myForm.transaksi.info_pelanggan.email_cp_internal} />  
                            </Fragment>
                                : 
                                null
                            }
                            
                    </tbody>
                </table>
            </div>
    )
}
