import { useState, useEffect, Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes, useNavigate, useLocation   } from "react-router-dom";
import { Navbar } from './components/Navbar';
import Dashboard  from './menu/Dashboard';
import Pelanggan  from './menu/Pelanggan';
import User from './menu/User';
import { getNavigationMenu, getNavigationTransaksi } from './config/Config'
import Transaksi  from './menu/Transaksi';
import Laporan  from './menu/Laporan';
import Login from './menu/Login';
import LoginM from './menu/LoginM';
import Layanan from './menu/Layanan';
import NotFound from './menu/NotFound';
import BelumDiajukan from './menu/Approval/BelumDiajukan';
import Berjalan from './menu/Approval/Berjalan';
import Selesai from './menu/Approval/Selesai';
import Pendapatan from './menu/Laporan/Pendapatan';
import PendapatanLengkap from './menu/Laporan/PendapatanLengkap';
import Penerimaan from './menu/Laporan/Penerimaan';
import Piutang from './menu/Laporan/Piutang';
import Terhapus from './menu/Approval/Terhapus';
import LaporanPelanggan from './menu/Laporan/Pelanggan';
import DetailPelanggan from './menu/Laporan/DetailPelanggan';
import IndexPrint from './menu/File/IndexPrint';
import Integrasi from './menu/Integrasi';
import CariTransaksi from './menu/CariTransaksi'
import AccessDenied from './menu/AccessDenied';
import Profile from './menu/Profile';
import Auth from './api/Auth'
import { backEndUrl, AppType } from './config/Config';
import axios from 'axios';
import DetailTransaksi from './menu/Approval/DetailTransaksi';
import Invoice from './menu/Invoice';
import Pelaksana from './menu/Pelaksana'
import KuitansiMigas from './menu/File/KuitansiMigas';
import OrderPenyelenggara from './menu/File/OrderPenyelenggara';
import BindInvoice from './menu/BindInvoice';
const mainMenu = getNavigationMenu();
const childMenu = getNavigationTransaksi();

const MyApp = ( {akses} ) => {
   
    const location = useLocation();
    const pathname = location.pathname;
    let id         = "";
    for(let i in  mainMenu){
      if (mainMenu[i]["to"] === pathname) {
        id = mainMenu[i]["id"]
        break;
      }
    }
    if (id === "") {
      for(let l in childMenu){
        for(let r of childMenu[l])
        if (r["to"] === pathname) {
          id = r["id"];
          break;
        }
      }
    }
  
    if (akses === undefined || pathname === "/app/403" || pathname === "/") {
      return null;
    }
 
     const cariIndex = akses.join(",").indexOf(id) !== -1;
     
     if (cariIndex === true) {
       return (pathname.indexOf("/app/printInvoice") !== -1) ? null: <BindInvoice />
     }else if(cariIndex === false){
   // console.warn("AKSES DITOLAK", pathname, id)
      return <Navigate to="/app/403" />;

     }
   
  return pathname
}
class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      loader: true
    }
  }
  componentDidMount = () => {
  // axios.get("http://localhost/api/setcookie.php",{withCredentials: true})
    if (AppType() === "EBT") {
        document.title = "SIKK PENERIMAAN";
    }else{
      document.title = "SIMPELKU PENERIMAAN";
    }
  }
  
  render() {
  
    return (
      <Auth.AuthProvider>
       
        <Auth.AuthConsumer>
        {({state, logout}) => {
          const akses = state.access
          const { isLogin } = state;
       
          return (
            <div>
              
              <MyApp akses={akses} />
              {
                (isLogin) ? 
                  <Fragment>
                    <Navbar logout={logout}/>
                    <Routes>
                      <Route path="/app/printInvoice/:id/:id/:id" element={<IndexPrint isLogin={true} />} />
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/app" element={<Dashboard />} />
                      <Route path="/app/dashboard" element={<Dashboard />} />
                      <Route path="/app/layanan" element={<Layanan />} />
                      <Route path="/app/user" element={<User />} />
                      <Route path="/app/pelanggan" element={<Pelanggan />} />
                      <Route path="/app/transaksi" element={<Transaksi />} />
                      <Route path="/app/transaksi/cariTransaksi" element={<CariTransaksi />} />
                      <Route path="/app/pelaksana" element={<Pelaksana />} />
                      <Route path="/app/kuitansi/:id/:id" element={<KuitansiMigas />} />
                      <Route path="/app/OrderPenyelenggara" element={<OrderPenyelenggara />}/>
                      <Route path="/app/transaksi/berjalan" element={<Berjalan />} />
                      <Route path="/app/transaksi/belumDiajukan" element={<BelumDiajukan />} />
                      <Route path="/app/transaksi/selesai" element={<Selesai />} />
                      <Route path="/app/transaksi/terhapus" element={<Terhapus />} />
                      <Route path="/app/laporan/pendapatan" element={<Pendapatan />} />
                      <Route path="/app/laporan/pendapatan_lengkap" element={<PendapatanLengkap />} />
                      <Route path="/app/laporan/penerimaan" element={<Penerimaan />} />
                      <Route path="/app/laporan/piutang" element={<Piutang />} />
                      <Route path="/app/laporan/pelanggan" element={<LaporanPelanggan />} />
                      <Route path="/app/laporan/pelanggan/:id" element={<DetailPelanggan />} />
                      <Route path="/app/transaksi/:id" element={<Transaksi />} />
                      <Route path="/app/laporan" element={<Laporan />} />
                      <Route path="/app/profile" element={<Profile />} />
                      <Route path="/app/invoice" element={<Invoice />} />
                      <Route path="/app/integrasi" element={<Integrasi />} />
                      <Route path="*" element={<NotFound/>}/>
                      <Route path="/app/403" element={<AccessDenied/>}/>
                      <Route path="/app/detail_transaksi/:id" element={<DetailTransaksi/>}/>
                    </Routes>
                    
                  </Fragment>
                :
                  <Routes>
                 
                      <Route path="*" element={<Login />} />
                      <Route path="/app/printInvoice/:id/:id/:id" element={<IndexPrint isLogin={false} />} />

                  </Routes>
              }
            </div>
          )
        }}
        </Auth.AuthConsumer>
      </Auth.AuthProvider>
    )
  }
}

export default App;
