export default  ({ detail, detailType, closeModalForm }) => {
 
    return (
        <div>
            <br />
                <div >
                    <div>
                        <table width="100%">
                            <tbody>      
                                <TableRow title="Kode Layanan" bg="bg-gray-200" value={detail.kode_layanan} />  
                                <TableRow title="Uraian" bg="" value={detail.uraian_keranjang} />  
                                <TableRow title="Kuantitas" bg="bg-gray-200" value={detail.kuantitas} />  
                                <TableRow title="Tarif" bg="" value={detail.tarif_keranjang} />  
                                <TableRow title="Jumlah" bg="bg-gray-200" value={detail.jumlah_keranjang} />  
                                <TableRow title="Tgl Awal" bg="" value={detail.tgl_awal} />  
                                <TableRow title="Tgl Akhir" bg="bg-gray-200" value={detail.kode_layanan} />  
                                {
                                    (detail.info_keranjang.type_form === "penunjang") ?
                                    <TableRow title="Nomor fasilitas" bg="" value={detail.info_keranjang.nomor_fasilitas} />  
                                    :
                                    null
                                }
                                
                            </tbody>
                        </table>

                        {
                            (detail.info_keranjang !== undefined) ?
                                (detail.info_keranjang.type_form === "pelatihan/sertifikasi") ?
                                <div>
                                    <br />
                                    <div className="text-xl">
                                        List Peserta
                                    
                                    </div>
                                    <br />
                                    <table width="100%">
                                        <thead>
                                            <tr>
                                                <td>Nama</td>
                                                <td>Jumlah</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            detail.info_keranjang.list_peserta.map((x, i) => {
                                                let bg = (i%2 === 0) ? "bg-gray-200" : "";
                                                return (
                                                    <tr key={i}>
                                                       <td className={"p-2 " + bg}>{x.namaPeserta}</td>
                                                        <td className={" p-2 " + bg}> { x.biayaPeserta }</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                            
                                        </tbody>
                                    </table>
                                </div>
                                :
                                null
                            : 
                                null
                        }
                    </div>
               
                </div>
            
            <br />
            <div className="text-right flex justify-end">
                <button className="font-bold bg-gray-600 p-2 shadow-md hover:bg-gray-500 active:bg-gray-700 active:shadow-none rounded-md w-32  text-white" onClick={closeModalForm}>TUTUP</button>
            </div>
        </div>
    )
}

const TableRow = ({ title, value, bg  }) => {
    return (
        <tr>
            <td className={"w-48 p-2 " + bg}>{ title }</td>
            <td className={"p-2 " + bg}>:</td>
            <td className={" p-2 " + bg}> { value }</td>
        </tr>
    )
}