import { Component, Fragment, useState } from 'react';
import { SmallNavbar } from '../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort,faList, faCheck, faTrash, faEdit, faUser,faBuilding, faCoins, faChartLine, faTimes, faFile } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../components/Card';
import Datatable, { IconSort } from '../components/Datatable';
import Modal    from '../components/Modal';
import Spinner    from '../components/Spinner';
import { getAllTransaksiPelaksana } from '../api/Transaksi';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../components/Toast';
import { Navigate  } from "react-router-dom";
import { InputText, Select } from "../components/Input";
import { approveTerima, approveTolak } from '../api/Transaksi';
import { getKeranjangTransaksi } from '../api/Keranjang';
import { ActiveDropdown } from '../config/Config';
import FullLoader from '../components/FullLoader';
import { NavLink }   from 'react-router-dom';
import { numberFormat } from '../api/Value';
import TabApproval from '../components/TabApproval';
export default class Pelaksana extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            dataFilter: [],
            detailModal: false,
            detail: {
                transaksi: {},
                keranjang: []
            },
            ready: false,
            type_data: "semua"
        }
    }
    componentDidMount  = () => {
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/pelaksana", title: "Pelaksana" }));
        const act = document.getElementById("Transaksi_nav");
        // if(act){ 
        //         act.classList.add("border-b-4")
        //         act.classList.add(ActiveDropdown())
        // }
        const self = this;
        getAllTransaksiPelaksana().then((result) => {
            console.log(result)
            if (result.status === 200) {

                self.setState({
                    data: result.data !== null ? result.data : [],
                    dataFilter: result.data !== null ? result.data : [],
                    ready: true
                })
            }else{
                self.setState({
                    ready: true
                })
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Gagal Mendapatkan Transaksi Pelaksana" bg="orange"/>
                })
            }
        //    console.log(result)
        })
    }
    componentWillUnmount = () => {
        const act = document.getElementById("Transaksi_nav");
        if (act !== null) {

            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
    }
    openDeleteForm = () => {

    }
     closeModalDetail  =() => {
        this.setState({
            detail: {
                keranjang: [],
                transaksi: {}
            },
            detailModal: false
        })
    }
     detailTransaksi = (index) => {
        
        let data = this.state.data[index];
        const self = this;
        getKeranjangTransaksi({ id_transaksi: data.id_transaksi }).then((result) => {
            if (result.status === 200) {
                let detail = self.state.detail;
                detail["keranjang"] = result.data.map((x) => {
                    return { ...x,info_keranjang: JSON.parse(x.info_keranjang) }
                    
                });
                detail["transaksi"] = { ...data, info_pelanggan: JSON.parse(data.info_pelanggan), info_transaksi: JSON.parse(data.info_transaksi), user_pembuat: JSON.parse(data.user_pembuat), history: JSON.parse(data.history)  }
               self.setState({
                   detail: detail,
                   detailModal: true
               })
               
            }else{
        //        console.log(result)
            }
        })
    }
    handleType = (nameType) => {
        this.setState({
            type_data: nameType,
            dataFilter: (nameType === "semua") ? this.state.data : this.state.data.filter((r) => {
                            return r.tipe_transaksi === nameType
                        })
        })
    }
    render(){
        const { openDeleteForm, closeModalDetail, detailTransaksi, handleType } = this;
        const { dataFilter, detailModal, detail, ready, type_data }           = this.state;
        if (ready === false) {
            return <FullLoader />
        }
        return (
            <div className="p-5 animate-fadein">
                        <Toaster position="bottom-right"/>
            

                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Pelaksana
                            </div>
                        </Fragment>}
                />
                
                <div className="pt-3">
                    <Card>
                        <TableTransaksi DataRender={dataFilter} detailTransaksi={detailTransaksi} />
                    </Card>
                </div>
               { /*<Modal
                    Title = {<span className="text-black">Detail Transaksi</span>}
                    Body  = {<Detail myForm={ detail } closeModalForm={closeModalDetail} />}
                    Footer = ""
                    closeModal = {closeModalDetail}
                    isOpen = {detailModal}
                    Container = "max-w-4xl min-h-92 bg-white"
                />*/}

            </div>
        )
    }
}

const TableTransaksi = ({  DataRender,detailTransaksi }) => {
 
  const TheadComponent = ({ sortData, sort, order_by }) => {
    return (
      <thead>
        <tr>
        <td onClick={() => sort("id_transaksi")} className=" text-center border-2 border-gray-200 pl-2 pr-2" rowSpan="2">ID {IconSort(order_by, "id_transaksi")}</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Uraian/Bidang/Pembuat/Tipe</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Pelanggan/Jumlah/Progress/Penolakan</td>
        
           
        </tr>
      </thead>
      
    )
  }
  return (
    <div>
        <Datatable 
          Head={TheadComponent}
          Data={DataRender}
          Entries={[]}
          Body={(row, index) => {
            const mod = (index%2 == 0) ? " bg-gray-100 " : "";
            let user_info = {}
            let history = [];
            let user_pembuat = {}
           //\ console.log(history)
            try {
                user_pembuat = JSON.parse(row.user_pembuat)
                user_info    = JSON.parse(row.info_pelanggan)
                history      = JSON.parse(row.history)
            } catch (error) {
             console.log(error, row.history)   
            }
            return (
              <tr key={ index }>
                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod} width="5%"> 
                    { row.id_transaksi } 
                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                    <NavLink to={"/app/detail_transaksi/" + row.id_transaksi}>
                    <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={<span className="cursor-pointer underline text-blue-500">{row.uraian}</span>} />
                    </NavLink>
                        <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={row.bidang} />
                        <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={user_pembuat.nama} />
                        <ChipTable icon={<FontAwesomeIcon icon={faFile} />} text={row.tipe_transaksi} />

                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                        <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={<span>{user_info.nama}</span>} />

                    <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.jumlah_transaksi}`)} />
                    <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={row.progress_transaksi} />
                    <ChipTable icon={<FontAwesomeIcon icon={faTimes} />} text={

                        
                        (row.progress_transaksi.indexOf("ditolak") !== -1 && history.length > 0) ? 
                        <div className="text-red-600">
                            <span className="font-bold">{ 
                                history[history.length-1]["username"] } ({ history[history.length-1]["role"] }) : </span>
                           { history[history.length-1]["aksi"]}
                        
                        </div>

                        : 
                        null
                    } />

                </td>
    
               
              </tr>
            )
          }}
        />
    </div>
  )
}
