import { Pie  } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement, Tooltip, Legend
} from 'chart.js';

ChartJS.register(
ArcElement, Tooltip, Legend
);


const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
  },
};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


export default ({ data }) => {
    return (
        <div className="-mt-10">
            <Pie options={options} data={data} />
        </div>
    )
}