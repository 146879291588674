import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getBidang, VaParse, getCred } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true

export const invoicePublik = async ({ id_transaksi }) => {
    return await axios.get(backEndUrl() + "/invoice_publik/" + id_transaksi + "?token=tn8Wtd5enpXkwhEy", getCred())
    .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
  
}

export const getAllInvoice  = async () => {
     let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
     if (role === "admin"|| role === "user") {
         return {
             status: 500,
             message: "Role unathorized"
         }
     }
     // // console.log(role, getBidang())
     const reqData = await axios.get( backEndUrl() + "/penerimaan/invoice", getCred())
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const uploadInvoiceBermeterai = async ({ id_invoice, file, onUpload }) => {
    let form  = new FormData();
        form.append("file", file[0]);
            const options = {
                onUploadProgress:(progressEvent) =>{
                    const {loaded,total} = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    
                    onUpload(percent)
                },
                ...getCred()
            }
            return await axios.post( backEndUrl() + "/penerimaan/invoice/uploadbermeterai/" + id_invoice, form , options)
               .then((res) => {
                    return res.data
                }).catch((err) => {
                    return {
                        status: 500,
                        messages: err.response 
                    }
                });
}
export const getInvoicesOfTransaksi = async ({ id_transaksi }) => {
   //// console.log(id_transaksi)
    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
     const reqData = await axios.get( backEndUrl() + "/penerimaan/invoice/transaksi/" + id_transaksi, {
        ...getCred(),
        transformResponse: [function (data) {
            // Do whatever you want to transform the data
            return VaParse(data);
        }],
    })
     .then((res) => {
                        return res.data
                    })
                    .catch((e) => {
                        return {
                            status: 500,
                            message: e.response
                        }
                    }) 
    return reqData
}
export const updateInvoiceInfo = async ({ id_invoice, info_invoice }) => {
    let form = {
        id_invoice: id_invoice,
        info_invoice: JSON.stringify(info_invoice)
    }
    console.log(form)
    return await axios.post( backEndUrl() + "/penerimaan/invoice/update", form, getCred())
               .then((res) => {
                    return res.data
                }).catch((err) => {
                    return {
                        status: 500,
                        messages: err.response 
                    }
                });
}