import { Component, Fragment } from 'react';
import { SmallNavbar } from '../../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSort,faList, faPrint,faBuilding, faCoins, faChartLine, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import Datatable from '../../components/Datatable';
import { ActiveDropdown } from '../../config/Config';
import { getLaporan } from '../../api/Laporan';
import { NavLink }   from 'react-router-dom';
import { numberFormat, saveToExcel } from '../../api/Value';
import { Select } from '../../components/Input';
import { getGlobalFilterLaporan } from '../../api/User';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
export default class Penerimaan extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            data_filtered: [],
            bidang: [],
            selected: ""
        }
    }
    componentDidMount  = () => {
        const act = document.getElementById("Laporan_nav");
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/laporan/penerimaan", title: "Laporan Penerimaan" }));
        if(act){ 
                act.classList.add("border-b-4")
                act.classList.add(ActiveDropdown())
        }
        const self = this;
        getLaporan({ type: "penerimaan" }).then(res => {
            if (res.status === 200) {
                self.setState({
                    data:(res.data) ? res.data : [],
                    data_filtered: (res.data) ? res.data : []
                })
            }else if(res.status === 500){
                toast.custom(t => {
                    return (
                        <ToastComponent param={t} message="Laporan Gagal Dimuat" bg="red" />
                    )
                })
            }
        })
        getGlobalFilterLaporan().then(res => {
            if (res.status === 200) {
                console.log(res)
                const nilai = res.data.nilai;
                self.setState({
                    bidang: nilai.split(",")
                })
            }else if (res.status === 500) {
                toast.custom(t => {
                    return (
                        <ToastComponent param={t} message="Filter Laporan Gagal Dimuat" bg="red" />
                    )
                })
            }
        })
    
    }
    componentWillUnmount = () => {
        const act = document.getElementById("Laporan_nav");
        if (act !== null) {

            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
    }
    filterData = (e) => {
        const value = e.target.value;
        if (value === "semua") {
            this.setState({
                data_filtered: this.state.data,
                 selected: value 
            })
        }else{
                
            this.setState({
                data_filtered: this.state.data.filter((x) => {
                    return x.bidang === value
                }),
                selected: value 

            })
        }
    }
    excelPenerimaan = () => {
        let exportFinal = [];
        const data = this.state.data.map((x) => {
            let user_info = {}
            let history = [];
            let user_pembuat = {}
           
            try {
                user_pembuat = JSON.parse(x.user_pembuat)
                user_info    = JSON.parse(x.info_pelanggan)
                history      = JSON.parse(x.history)
            } catch (error) {
            // console.log(error, row.history)   
            }
            exportFinal.push({
                id_transaksi: x.id_transaksi,
                nama_pelanggan: user_info.nama,
                nama_pembuat: user_pembuat.nama,
                bidang: x.bidang,
                jumlah_transaksi: x.jumlah_transaksi,
                progress: x.progress_transaksi
            })
            return x;
        })
        saveToExcel("Penerimaan", exportFinal);
    }
    render(){
     
        return (
            <div className="p-5 animate-fadein">
            <Toaster position="bottom-right"/>

                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Penerimaan
                            </div>
                        </Fragment>}
                />

                <div className="pt-3">
                    <Card>
                      
                        
                        <TableTransaksi 
                            selected ={this.state.selected}
                            
                            DataRender={this.state.data_filtered} 
                            filterData = {this.filterData} 
                            bidang={this.state.bidang} 
                            excelPenerimaan={ this.excelPenerimaan }
                        />
                    </Card>
                </div>
            </div>
        )
    }
}


const TableTransaksi = ({  DataRender, filterData,bidang, selected, excelPenerimaan }) => {
 
 const TheadComponent = ({ sortData }) => {
   return (
     <thead>
       <tr>
           <td className=" border-2  border-gray-300 p-2 w-20 text-center" >ID</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Uraian/Bidang/Pembuat</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Pelanggan/Jumlah/Progress/Penolakan</td>
       
          
       </tr>
     </thead>
     
   )
 }
 return (
   <div>
       <Datatable 
         Head={TheadComponent}
         Data={DataRender}
         Entries={[]}
         Export={() => {
             return (
                <div className="flex">
                    <div className="p-1 w-[200px] -mt-8 ml-2">
                        Filter Bidang
                        <Select Other={{ onChange: filterData, value: selected }}>
                            {
                                bidang.map((x) => {
                                    return (
                                        <option value={x} key={x}>{x}</option>
                                    )
                                })
                            }
                        </Select>            
                    </div>
                    <div>
                        <button onClick={() => excelPenerimaan()} className="hover:bg-teal-400 active:bg-teal-600 active:shadow-none border-teal-600 bg-teal-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Excel</button>                
                    </div>
                </div>
             )
         }}
         Body={(row, index) => {
           const mod = (index%2 == 0) ? " bg-gray-100 " : "";
           let user_info = {}
           let history = [];
           let user_pembuat = {}
          //\ console.log(history)
           try {
               user_pembuat = JSON.parse(row.user_pembuat)
               user_info    = JSON.parse(row.info_pelanggan)
               history      = JSON.parse(row.history)
           } catch (error) {
            console.log(error, row.history)   
           }
           return (
             <tr key={ index }>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod} width="5%"> 
                   { row.id_transaksi } 
               </td>
               <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                   <NavLink to={"/app/detail_transaksi/" + row.id_transaksi}>
                   <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={<span className="cursor-pointer underline text-blue-500">{row.uraian}</span>} />
                   </NavLink>
                       <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={row.bidang} />
                       <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={user_pembuat.nama} />

               </td>
               <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                       <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={<span>{user_info.nama}</span>} />

                   <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.jumlah_transaksi}`)} />
                   <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={row.progress_transaksi} />
                   <ChipTable icon={<FontAwesomeIcon icon={faTimes} />} text={

                       
                       (row.progress_transaksi.indexOf("ditolak") !== -1 && history.length > 0) ? 
                       <div className="text-red-600">
                           <span className="font-bold">{ 
                               history[history.length-1]["username"] } ({ history[history.length-1]["role"] }) : </span>
                          { history[history.length-1]["aksi"]}
                       
                       </div>

                       : 
                       null
                   } />

               </td>
   
              
             </tr>
           )
         }}
       />
   </div>
 )
}
