import axios from 'axios';
import { backEndUrl } from '../config/Config';
import { checkType, getBidang } from '../api/Value';
import { v4 as uuidv4 } from 'uuid';


export const getAllApps = async () => {
    return await axios.get( backEndUrl() + "/penerimaan/external", { withCredentials: true } )
    .then(res => {
        return res.data;
    }).catch(e => {
        return {
            status: 500,
            message:e.response
        }
    })
}

export const  createApp = async ({ nama_app, url_app, secret_token, url_callback, app_status }) => {
    let dataForm = {
        "nama_app": nama_app,
        "url_app": url_app,
        "secret_token": secret_token,
        "url_callback": url_callback,
        "app_status": app_status,
        "uuid": uuidv4()
    }
    console.log(dataForm)
    return await axios.post(backEndUrl() + "/penerimaan/external", dataForm, { withCredentials: true })
    .then(res => {
        return res.data;
    }).catch(e => {
        return {
            status: 500,
            message:e.response
        }
    })
}

export const  updateApp = async ({ id_app, nama_app, url_app, secret_token, url_callback, app_status }) => {
    let dataForm = {
        "id_app": id_app,
        "nama_app": nama_app,
        "url_app": url_app,
        "secret_token": secret_token,
        "url_callback": url_callback,
        "app_status": app_status,
        "uuid": uuidv4()
    }
    return await axios.put(backEndUrl() + "/penerimaan/external", dataForm, { withCredentials: true })
    .then(res => {
        return res.data;
    }).catch(e => {
        return {
            status: 500,
            message:e.response
        }
    })
}

export const  deleteApp = async ({ id_app }) => {
   
    return await axios.delete(backEndUrl() + "/penerimaan/external/" + id_app, { withCredentials: true })
    .then(res => {
        return res.data;
    }).catch(e => {
        return {
            status: 500,
            message:e.response
        }
    })
}