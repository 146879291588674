import { Component, Fragment } from 'react';
import { SmallNavbar } from '../../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSort,faList, faPrint,faBuilding, faCoins, faChartLine, faTimes,
    faPhone, faFileInvoiceDollar,
    faMapSigns,
    faMailBulk,
    faCode,
    faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import Datatable from '../../components/Datatable';
import { ActiveDropdown } from '../../config/Config';
import { getLaporan, laporanLengkap } from '../../api/Laporan';
import { NavLink }   from 'react-router-dom';
import { numberFormat, saveToExcel } from '../../api/Value';
import { Select } from '../../components/Input';
import { getPendapatanLengkap } from '../../api/Laporan';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { toDateTime } from '../../api/Value'
import Moment from 'react-moment';
import moment from 'moment';

export default class PendapatanLengkap extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            data_filtered: [],
            bidang: [],
            selected: "",

            filter_data: { tgl_awal: "", tgl_akhir: "", kualitas: "" }
        }
    }
    componentDidMount  = () => {
        const act = document.getElementById("Laporan_nav");
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/laporan/pendapatan_lengkap", title: "Laporan Pendapatan Lengkap" }));
        if(act){ 
                act.classList.add("border-b-4")
                act.classList.add(ActiveDropdown())
        }
        const self = this;
        laporanLengkap().then((res) => {
            
            if (res.status !== 500 && res) {
                
                const result = res.map((x, i) => {
                    
                    const infoObj = x.info_transaksi ? JSON.parse(x.info_transaksi) : {}
                    
                    x['dt_invoice_created'] = toDateTime(x.dt_invoice_created)
                    let created = x['dt_invoice_created'].split(" ")[0].split("/").reverse().join("-")
                    let kl      = (new Date() - new Date(created)) / (1000 * 60 * 60 * 24)
                    if(x.progress_transaksi !== 'lunas'){

                        if(kl <= 1){
                            x["kualitas"] = "Lancar"                    
                        }else if(kl < 1 && kl <= 2){
                            x["kualitas"] = "Kurang Lancar"                    
                        }else if(kl < 2 && kl <= 3){
                            x["kualitas"] = "Diragukan"                    
                        }else if(kl > 3){
                            x["kualitas"] = "Macet"                    
                        }
                    }else{
                        x["kualitas"] = ""                    
                    }
                    
                    x['dt_pembayaran']      = x.dt_bayar ? toDateTime(x.dt_bayar) : ""
                    
                        x['no_kuitansi'] = { Int64: x["kuitansi"] }
                    x["jumlah_dibayar"]  = { Float64: x["jumlah_dibayar"] }
                    x['info_obj'] = infoObj
                    x['progress_transaksi'] = (x.progress_transaksi == "invoice_bermeterai") ? "belum lunas" : x.progress_transaksi
                    return x 
                })
                
                self.setState({
                    data: result,
                    data_filtered: result,
                    filter_data: { tgl_awal: "", tgl_akhir: "" }
                })
            }else if(res.status === 500){
                toast.custom(t => {
                    return (
                        <ToastComponent param={t} message="Laporan Gagal Dimuat" bg="red" />
                    )
                })
            }
            
        })
        /*
        getPendapatanLengkap().then(res => {
            
            if (res.status === 200 && res.data) {
                const result = res.data.map((x) => {

                    const infoObj = x.info_transaksi ? JSON.parse(x.info_transaksi) : {}
                    x['dt_invoice_created'] = toDateTime(x.dt_invoice_created)
                    x['dt_pembayaran']      = x.dt_pembayaran.Valid ? toDateTime(x.dt_pembayaran.Time) : ""
                    if(x['no_kuitansi']['Valid'] == false){
                        x['no_kuitansi']['Int64'] =  ""
                    }
                    x['info_obj'] = infoObj
                    x['progress_transaksi'] = (x.progress_transaksi == "invoice_bermeterai") ? "belum lunas" : x.progress_transaksi
                    return x 
                })

                self.setState({
                    data: result,
                    data_filtered: result,
                    filter_data: { tgl_awal: "", tgl_akhir: "" }
                })
            }else if(res.status === 500){
                toast.custom(t => {
                    return (
                        <ToastComponent param={t} message="Laporan Gagal Dimuat" bg="red" />
                    )
                })
            }
        })
        */
    }
    componentWillUnmount = () => {
        const act = document.getElementById("Laporan_nav");
        if (act !== null) {

            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
    }
    filterData = (e) => {
        const value = e.target.value;
        const name  = e.target.name;
        let filter_data = this.state.filter_data

            filter_data[name] = value 
    
        this.setState({
            filter_data: filter_data
        }, () => {
            let data =  this.state.data
            const tgl_awal = (filter_data.tgl_awal != "") ? new Date(filter_data.tgl_awal) : false 
            const tgl_akhir= (filter_data.tgl_akhir != "") ? new Date(filter_data.tgl_akhir) : false
            const kualitas = (filter_data.kualitas != "") ? filter_data["kualitas"] : false
          
            data = data.filter((x) => {
                let valid = true
                const date = new Date(x.dt_invoice_created.split(" ")[0].split("/").reverse().join("-"))

                if(kualitas){
                    valid = kualitas === x.kualitas
                    if(valid === false){
                        return false
                    }
                }

                if(tgl_awal && tgl_akhir){
                    return date >= tgl_awal && date <= tgl_akhir
                }

                if(tgl_awal){
                    valid = date >= tgl_awal
                }

                if(tgl_akhir){
                    valid = date <= tgl_akhir
                }


                return valid
            })
                  
            this.setState({
                data_filtered: data
            })
        })
    }
    excelPendapatan = () => {
        let exportFinal = [];
        const data = this.state.data_filtered.map((row) => {
            
            exportFinal.push({
                id: row.id_transaksi,
                nama: row.info_obj.nama_tagihan,
                alamat: row.info_obj.alamat_tagihan,
                email: row.info_obj.email_tagihan,
                no_telp: row.info_obj.no_telp_tagihan,
                id_invoice: row.id_invoice ,
                jumlah: row.jumlah_transaksi ,
                invoice_dibuat: row.dt_invoice_created ,
                va: "'" + row.no_va,
                bidang: row.bidang,
                kode_layanan: row.kode_layanan,
                nama_layanan: row.nama_layanan,
                progress: row.progress_transaksi ,
                jumlah_dibayar: row.jumlah_dibayar.Float64,
                datetime_bayar: row.dt_pembayaran ,
                no_kuitansi: row.no_kuitansi.Int64 ,
                kualitas: row.kualitas
                
            })
            return row;
        })
        saveToExcel("Pendapatan", exportFinal);
    }
    render(){
     
        return (
            <div className="p-5 animate-fadein">
            <Toaster position="bottom-right"/>

                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Pendapatan Lengkap
                            </div>
                        </Fragment>}
                />

                <div className="pt-3">
                    <Card>
                      
                        
                        <TableTransaksi 
                        selected ={this.state.selected}
                        excelPendapatan={ this.excelPendapatan }
                        DataRender={this.state.data_filtered} 
                        filterData = {this.filterData} 
                        tglFilter = { this.state.filter_data }
                        bidang={this.state.bidang} />
                    </Card>
                </div>
            </div>
        )
    }
}


const TableTransaksi = ({  DataRender, filterData,bidang, selected,excelPendapatan, tglFilter }) => {
 
 const TheadComponent = ({ sortData }) => {
   return (
     <thead>
        <tr>
            <td className=" border-2  border-gray-300 p-2 w-20 text-center" rowSpan="2">
                <div className="w-[60px]">
                    ID            
                </div>
            </td>
            <td className=" border-2  border-gray-300 p-2 w-20 text-center" colSpan="4">Tujuan Tagihan</td>
            <td className=" border-2  border-gray-300 p-2 w-20 text-center" colSpan="7">Invoice</td>
            <td className=" border-2  border-gray-300 p-2 w-20 text-center" colSpan="5">Status</td>
        </tr>
        <tr>
           <td className=" border-2 border-gray-300 p-2 text-center">
                Nama
           </td>
           <td className=" border-2 border-gray-300 p-2 text-center">
               <div className=" w-[210px] ">
                    Alamat
                </div>
                
           </td>
           <td className=" border-2 border-gray-300 p-2 text-center">
                Email
           </td>
           <td className=" border-2 border-gray-300 p-2 text-center">
                Telp
           </td>

           <td className=" border-2 border-gray-300 p-2 text-center">
                <div className=" w-[100px] ">
                    No Invoice
                </div>
           </td>
           <td className=" border-2 border-gray-300 p-2 text-center">
                Jumlah
           </td>
           <td className=" border-2 border-gray-300 p-2 text-center">
               <div className=" w-[180px] text-center">
                    Waktu Terbit
                </div>
            </td>
            <td className=" border-2 border-gray-300 p-2 text-center">
                No VA
            </td>
            <td className=" border-2 border-gray-300 p-2 text-center">
                Bidang
            </td>
            <td className=" border-2 border-gray-300 p-2 text-center">
                <div className=" w-[150px] ">
                    Kode Layanan
                </div>
            </td>
            <td className=" border-2 border-gray-300 p-2 text-center">
                <div className=" w-[150px] ">
                    Nama Layanan
                </div>
            </td>

            <td className=" border-2 border-gray-300 p-2 text-center">
                <div className=" w-[150px] ">
                    Progress
                </div>
            </td>
            <td className=" border-2 border-gray-300 p-2 text-center">
                <div className=" w-[150px] ">
                    Jumlah Dibayar
                </div>
            </td>
            <td className=" border-2 border-gray-300 p-2 text-center">
                <div className=" w-[180px] ">
                    Waktu Pembayaran
                </div>
            </td>
            <td className=" border-2 border-gray-300 p-2 text-center">
                <div className=" w-[100px] ">
                    No Kuitansi
                </div>
                
            </td>
            <td className=" border-2 border-gray-300 p-2 text-center">
                <div className=" w-[100px] ">
                    Kualitas
                </div>
                
            </td>
       </tr>
     </thead>
     
   )
 }
 return (
   <div>
       <Datatable 
         Head={TheadComponent}
         Data={DataRender}
         Entries={[]}
         Export={({ exportFile }) => {
             return (
                <div className="flex">
                    <div>
                        <div className="-mt-[32px] pl-2">
                            <h5 className="mb-2">Tanggal Awal</h5>
                            <input type="date" value={  tglFilter.tgl_awal } name="tgl_awal" onChange={ filterData } className="text-[14px] mb-1 border-2 p-2 pl-5 w-full rounded-md bg-gray-100 "  />
                        </div>
                    </div>
                    <div>
                        <div className="-mt-[32px] pl-2">
                            <h5 className="mb-2">Tanggal Akhir</h5>
                            <input type="date" value={  tglFilter.tgl_akhir } name="tgl_akhir" onChange={ filterData } className="text-[14px] mb-1 border-2 p-2 pl-5 w-full rounded-md bg-gray-100 "  />
                        </div>
                    </div>
                    <div>
                        <button onClick={() => excelPendapatan()} className="hover:bg-teal-400 active:bg-teal-600 active:shadow-none border-teal-600 bg-teal-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Excel</button>                
                    </div>
                    <div className="pl-2 -mt-8">
                        <div className="mb-2">Filter Kualitas</div>
                        <select
                            value = {tglFilter.kualitas }
                            onChange={ filterData }
                            name = "kualitas"
                            className="text-[14px] border-2 p-2 pl-5 w-full rounded-md bg-gray-100"
                        >
                            <option value="">Semua</option>
                            {
                                ["Lancar", "Kurang Lancar", "Diragukan", "Macet"].map((x) => {
                                    return <option value={ x } key={ x }> { x }</option>
                                })
                            }
                        </select>
                    </div>
                </div>
             )
         }}
         Body={(row, index) => {
           const mod = (index%2 == 0) ? " bg-gray-100 " : "";
      
           return (
             <tr key={ index }>
                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}> 
                   { row.id_transaksi } 
                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} >
                    {row.info_obj.nama_tagihan}
                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} >
                   {row.info_obj.alamat_tagihan}
                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} >
                    {row.info_obj.email_tagihan}
                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} >
                    {row.info_obj.no_telp_tagihan}
                </td>

                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}> 
                    { row.id_invoice } 
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}> 
                    { numberFormat(`${row.jumlah_transaksi}`) } 
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top text-center " + mod}> 
                    { row.dt_invoice_created }
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top " + mod}> 
                    {row.no_va}
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top text-center " + mod}> 
                    {row.bidang.toUpperCase()}
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top " + mod}> 
                    {row.kode_layanan}
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top " + mod}> 
                    {row.nama_layanan}
                </td>

                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}> 
                    { row.progress_transaksi } 
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top text-right" + mod}> 
                    { numberFormat(`${row.jumlah_dibayar.Float64}`) } 
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top text-center  " + mod}> 
                    { row.dt_pembayaran }
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}> 
                    { row.no_kuitansi.Int64 } 
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}> 
                    { row.kualitas } 
                </td>
                
             </tr>
           )
         }}
       />
   </div>
 )
}
