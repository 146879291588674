export default ({ type_data, handleType }) => {
    return (
        <div className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl w-1/2 shadow-md">
            <button onClick={() => handleType("semua")} className={`${type_data === "semua" ? "bg-white shadow text-blue-500" : "hover:bg-white/[0.12] text-gray-600 "  } w-full py-2.5 text-sm leading-5 font-medium  rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60`}>
                Semua
            </button>
            <button onClick={() => handleType("kerjasama")} className={`${type_data === "kerjasama" ? "bg-white shadow text-blue-500" : "hover:bg-white/[0.12] text-gray-600 "  } w-full py-2.5 text-sm leading-5 font-medium  rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60`}>
                Kerjasama
            </button>
            <button onClick={() => handleType("perorangan")} className={`${type_data === "perorangan" ? "bg-white shadow text-blue-500" : "hover:bg-white/[0.12] text-gray-600 "  } w-full py-2.5 text-sm leading-5 font-medium rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60`}>
                Perorangan
            </button>
            <button onClick={() => handleType("kedinasan")} className={`${type_data === "kedinasan" ? "bg-white shadow text-blue-500" : "hover:bg-white/[0.12] text-gray-600 "  } w-full py-2.5 text-sm leading-5 font-medium rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60`}>
                Kedinasan
            </button>
        </div>
    )
}