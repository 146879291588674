

export default ({ color="#e15b64", width="100px", height="100px" }) => {
    const  style = { margin: "auto", display: "block", shapeRendering: "auto" }     
    return (
  
        <svg xmlns="http://www.w3.org/2000/svg" style={ style } width={ width } height={ height } viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke={ color } strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
        </svg>

      
    )
}