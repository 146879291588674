import '../../kuitansi.css';
import { NoDokumenPembayaran, getInaDate, numberFormat, ex_terbilang, ucWords } from '../../api/Value';
import { Component } from 'react'
import { getOneTransaksi } from '../../api/Transaksi';
import { getAllPembayaranTrx } from '../../api/Pembayaran';
import { isJson } from '../../api/Value';
import SuratPenagihan from './Penagihan_pembayaran'
import InvoicePrint from './Invoice';
import Lampiran from './Lampiran';
import Pajak from './Pajak';
import Pajak2Hal from './PajakHal2';
import Pajak3Hal from './PajakHal3';
import InvoiceMigas from './InvoiceMigas';
import { AppType } from '../../config/Config';

export default class IndexPrint extends Component{
    state = {
        status: false,
        myForm: {transaksi: {}, pembayaran: {}},
        segment: 0
    }
    componentDidMount = () => {
        const myNavbar = document.getElementById("myNavbar");
        if (myNavbar) {
            myNavbar.remove();  
        }
        const isLogin = this.props.isLogin;
        document.body.style.backgroundColor = "white";

        const param = new URL(window.location.href);
        const pathname = param.pathname;
        // const segment = param.pathname.split("/");
        
        // this.getData(segment[3], segment[4])
        //window.print();
    }
    getData = async (id_transaksi, id_pembayaran) => {
        let transaksi = {};
        let pembayarantmp= {};
        let isValid = true;
        let status  = "";
        await getOneTransaksi({ id_transaksi: id_transaksi }).then(res => {
            if (res.status === 200) {
                const data = res.data;
                if (data) {
                 //   console.log(data)
                    transaksi = data;
                }else{
                    isValid = false;
                    status = "Transaksi Tidak Ditemukan !";
                }
            }else{
                isValid     = false;
                status      = "Gagal Mendapatkan Transaksi !";
            }
        })
        if (isValid === true) {      
            await getAllPembayaranTrx({ id_transaksi: id_transaksi }).then(res => {
                if (res.status === 200) {
                    const data = res.data;
                   
                    if (data) {
                        const pembayaran = data.filter((x) => {
                            return x.id_pembayaran === parseInt(id_pembayaran)
                        })
                      
                        if (pembayaran.length === 0) {
                            isValid = false;
                            status  = "Pembayaran tidak ditemukan!"
                        }else{
                            pembayarantmp = pembayaran[0];
                        }
                    }else{
                        isValid = false;
                        status  = "Gagal Mendapatkan Pembayaran";
                    }
                }else{
                    isValid     = false; 
                    status      = "Gagal Mendapatkan Pembayaran!";
                }
            })   
        }
        
        if (isValid === true) {
            transaksi['info_pelanggan'] = JSON.parse(transaksi['info_pelanggan']);
            //console.log(pembayarantmp)
            this.setState({
                myForm: { transaksi: transaksi, pembayaran: pembayarantmp },
                status: false
            },() => {
                   this.cetak()
            })
        }else if (isValid === false) {
            this.setState({
                status: status
            }, () => {
                window.print();

            })
        }
    }
    cetak= (id_invoice="Gagal") => {
        const isLogin = this.props.isLogin;
        const interval = setInterval(() => {
            let finish = true;
            for(let i of document.images){
                if (i.complete) {
                    
                }else{
                    finish = false;
                    break;
                }
            }    
            if (finish) {
                clearInterval(interval)
                const dc = document.title
               
                // if (isLogin === true) {
                    window.print();
                    
                
                document.title = dc;
            }
        }, 500);
        
    }
    render(){
        const { status, myForm, segment }  = this.state;
        
        if (status) {
            return(
                <div>
                    <br />
                    <br />
                    <br />
                    <center>
                        <h2>    
                        {
                           status
                        }
                        </h2>
                    </center>
                </div>
            )
        }
        return(
            <div className="bg-white calibri w-[210mm] h-[297mm]">
                <Order myForm={ myForm } />
            </div>
        )
    }
}
const Order = ({ myForm }) => {
    return (
        <div className="p-4">
            <table className="w-full">
                <tbody>
                    <tr>
                        <td className="border border-black text-center w-[15%]"  rowSpan="5">
                            <center>
                                <img src={require("../../esdm.png")} width="60" />
                                <div className="font-bold">PPSDM MIGAS</div>
                            </center>
                        </td>
                        <td className="border border-black text-center font-bold">
                            SISTEM MANAJEMEN INTEGRASI
                        </td>
                        <td className="border border-black pl-2">No. Dokumen</td>
                        <td className="border border-black pl-2">FR-BDMPK-0304</td>
                    </tr>
                    <tr>
                        <td className="text-center font-bold border border-black" rowSpan="4">
                            ORDER PENYELENGGARAAN <br /> KEGIATAN (INDUSTRI)
                        </td>
                    </tr>  
                    <tr>
                        <td className="border border-black pl-2">No. Revisi</td>
                        <td className="border border-black pl-2">0</td>
                    </tr> 
                    <tr>
                        <td className="border border-black pl-2">Tgl Berlaku</td>
                        <td className="border border-black pl-2">01 September 2011</td>
                    </tr>
                    <tr>
                        <td className="border border-black pl-2">Halaman</td>
                        <td className="border border-black pl-2">1 dari 1</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}