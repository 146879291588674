import { useState, Component, Fragment, createRef } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faPencilAlt, faClipboardList } from  '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';
import { SmallNavbar } from '../../../components/Navbar';
import { Card } from '../../../components/Card'//'../../components/Card';
import { getCatatan, createCatatan, updateCatatan } from '../../../api/Laporan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Moment from 'react-moment';

import 'moment-timezone';
library.add(fab)

 class Catatan extends Component{
    constructor(props){
        super(props);
        this.inputTextarea = createRef(null)
        this.state = {
            data: [],
           konten: "",
           statusEdit: false
        }
    }
    componentDidMount = () => {
        this.all()
    } 
    all = () => {
        const self = this
        getCatatan({ 
            id_transaksi: this.props.id_transaksi
         }).then((res) => {
            let keterangan = ""
            res.forEach((f) => {
                keterangan = f.konten
            })
            self.setState({
                data: res,
                konten: keterangan
            })

        })
    }

    add = () => {
        const self = this
        
        if(this.state.konten === ""){
            toast.error("Konten Required.")
          
        }else if(this.state.data.length === 0){
            toast.promise(createCatatan({
                id_transaksi: this.props.id_transaksi,
                konten: this.state.konten
            }), {
                loading: "Mohon Tunggu",
                success: "",
                error:"Terjadi Kesalahan"
            })
            .then((res) => {
                self.setState({
                    statusEdit: false
                })
                self.all()
              
            })
        }else{
            toast.promise(updateCatatan({
                id_transaksi: this.props.id_transaksi,
                konten: this.state.konten
            }), {
                loading: "Mohon Tunggu",
                success: "",
                error:"Terjadi Kesalahan"
            })
            .then((res) => {
                self.setState({
                    statusEdit: false
                })
                self.all()
            })
        }
       
    }
    render(){
        const active = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE")
       
         return (
            <div>
               
                <Toaster position="bottom-right"/>
                <div className=' w-[600px] relative '>
                <SmallNavbar

                    left={<Fragment>
                                                
                        <div className="flex-initial ml-1  mb-2" >
                            Catatan
                        </div>
                    </Fragment>}
                    />
                    <div>
                        {
                            this.state.statusEdit ?
                                <Card className=' min-h-[350px]'>
                                    <textarea 
                                        ref={ this.inputTextarea }
                                        defaultValue={ this.state.konten }
                                        onChange={ (e) => this.setState({ konten: e.target.value }) } 
                                        onBlur={ () => this.add() } 
                                        rows={ 15 } 
                                        className=" text-[14px] border-2 p-2 pl-5 w-full rounded-md bg-gray-100 "></textarea>
                                </Card>
                            :
                                <div>
                                    <Card className=' min-h-[350px]'>
                                        <div className=' text-gray-600 text-sm text-right mb-1'>
                                            {
                                                this.state.data.map((x) => {
                                                    return <Moment format="DD/MM/YYYY HH:mm:ss" tz="Asia/Jakarta">{x.dt_updated}</Moment>
                                                })
                                            }    
                                        </div>

                                        
                                        {
                                            this.state.data.length === 0 ?
                                                <div className='text-gray-600 text-sm italic'>
                                                    Tidak Ada Catatan.
                                                </div>
                                            :
                                            this.state.data.map((x) => {
                                                return x.konten
                                            })
                                        }
                                        {
                                            active === "adminkeu" ?
                                                <button onClick={ () => this.setState({ statusEdit: true }, () => this.inputTextarea.current.focus())  } className='border-2 border-blue-400 rounded-full w-8 h-8  absolute right-2 bottom-2'>
                                                    <FontAwesomeIcon icon={ faPencilAlt } className='text-blue-500'/>
                                                </button>
                                            : 
                                            null
                                        }
                                               
                                             
                                    </Card>
                                </div>
                                
                        }
                    </div>
                </div>
            </div>
         )
     }
 }

 export default Catatan