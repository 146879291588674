import * as React from 'react'
import { handleResponse } from './Response';
import { backEndUrl } from '../config/Config';
import axios from 'axios';
import FullLoader from '../components/FullLoader';
import { useNavigate } from 'react-router-dom'
import { randomColor } from './Value'
const AuthContext = React.createContext();

const AuthReducer = (state, action) => {
    switch (action.type) {
        case "login":
            return { isLogin: true, roles: action.roles, access: action.access, profile: action.profile }
            break;
        case "logout":
            return { isLogin: false, roles: [] }
            break;
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
            break;
    }
} 
const AuthProvider = ({ children }) => {
    const [ state, dispatch ] = React.useReducer(AuthReducer, { isLogin: false, });
    const [ loading, setLoading ] = React.useState(true)
    const navigate = useNavigate();
    const verifyLogin = async ({ username, password }) => {
        let form = new FormData();
            form.set("username", username);
            form.set("password", password);
        const options = {
          method: "POST",
          body: form,
           credentials: 'omit'
        }
      //  setLoading(true);
        const myUrl   =  backEndUrl() + "/auth";
        
        var reqLogin = await axios.post(myUrl, form, {withCredentials: true})
          .then((res) => {
            // console.log(res.data);
           localStorage.setItem("PENERIMAAN_ID_USER", res.data.id_user)
           localStorage.setItem("PROFIL_COLOR", randomColor())
            // get first role is not user OR pelanggan
            const roles = res.data.roles;
          
            let check   = roles.split(",").filter((x) => (x != "user" && x != "pelanggan" && x != ""));

            if (check.length == 0) {
              return {
                status: 500,
                message: "Role Tidak Diketahui Role Anda : " + roles
              }
            }else{
    
              localStorage.setItem("PENERIMAAN_ACTIVE_ROLE", check[0]);
            }

            return {
              status:200,
              message: "Login approved",
              idUser: res.data.id_user
            }
          })
          .catch(e => {
            // console.log(e)
            return {
              status: 500,
              message: e.response.data.messages
            }
          })
        if(reqLogin.status == 200){
          const idUser = reqLogin.idUser
          return await  getOneUser(idUser);
        }
      return reqLogin;
    }
    const logout = async () => {
        const reqLogout = await axios.get(backEndUrl() + "/logout",{withCredentials: true})
              .then((res) => {
                // console.log(res.data);
                  localStorage.removeItem("PENERIMAAN_ID_USER")
                  
                  dispatch({ type: 'logout' })
        navigate('/', { replace: true })
                  
                  return {
                    status: 200,
                    message: "Logout Berhasil"
                  }
              })
              .catch((e) => {
                // console.log(e)
                return {
                  status: 500,
                  message: e.response
                }
              })
                          
       return reqLogout;
    }
    const getOneUser  = (idUser, isPindahRole = false) => {
      if(isPindahRole === true){
        setLoading(true);
      }

          const headersUser = {active_role:  localStorage.getItem("PENERIMAAN_ACTIVE_ROLE")}

          const penerimaUser =    axios.get(backEndUrl() + "/engine/user/" + idUser, {withCredentials: true, headers:headersUser })
            .then((res) => {
              const result = res.data;
              console.log(result)
              let roles    = result.data.roles.split(",").map((x) => { return x.trim() });
              const saveToSession = sessionStorage.setItem("ROLE_PENERIMAAN", JSON.stringify(result.data));
              let myData        = JSON.parse(result.data.detail.Menu)
            //  myData.push("pelaksana")
              dispatch({ type: 'login', roles: roles, access: myData, profile: result.data.user_info })
              setLoading( false )
              // Navigate({to:"/app/dashboard"})
              return {
                status: 200,
                message: "Login Berhasil"
              }
            }).catch((e) => {
              // console.log(e)
              dispatch({ type: 'logout' })
              setLoading(false)
              return {
                status: 500,
                message: "Gagal Mendapatkan Data Pengguna"
              }
            });
            return penerimaUser
    }
    React.useEffect(() => {
        const idUser  = localStorage.getItem("PENERIMAAN_ID_USER");
        // jika di local storage ada id nya (bukan fresh login)
        if (idUser) {
       
          // check ke server apakah login masih ada
          getOneUser(idUser);
        }else{
          // belum pernah login
          setLoading(false)
        }
      return () => {
        
      };

    }, []);
    
    if (loading) {
      return <FullLoader />
    }
    
    const value = {state, dispatch, verifyLogin, logout, getOneUser};

    return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>
}
const useAuth = () =>  {
    const context = React.useContext(AuthReducer)
    if(context === undefined) {
        throw new Error('useAuth must be used within a CountProvider')
    }
    return context;
}
const  AuthConsumer = ({children}) => {
  return (
    <AuthContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('CountConsumer must be used within a CountProvider')
        }
        return children(context)
      }}
    </AuthContext.Consumer>
  )
}

const getCookie = (cname) => {
 
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";

}

const myCookieIsExist = () => {
  return getCookie("Auth_token") != ""
}
const getCsrfToken = () => {
  return getCookie("_csrf")
}


export default { AuthConsumer: AuthConsumer, AuthProvider: AuthProvider, useAuth: useAuth, myCookieIsExist: myCookieIsExist, getCsrfToken: getCsrfToken }