import '../../pajak.css';

export default () => {
    return (
        <div style={{fontSize: "13px"}}>
    <div className="arial bg-white file justify p-[1.5cm] pl-[2.8cm] pr-[2.8cm] pt-[0.5cm]">
   
        <ol className="list-decimal	ml-[0.4cm]" start="5">
        
            <li>Berdasarkan administrasi kami, Pusat Pengembangan Sumber Daya Manusia Ketenagalistrikan, Energi Baru, Terbarukan dan Konservasi Energi Pada Kementerian
                Energi dan Sumber Daya Mineral NPWP 00.003.225.0-009.000 terdaftar dengan KLU Bendahara Pemerintah Pusat merupakan bukan Pengusaha Kena Pajak.

            </li>
        </ol>
        <br />
        <div className="pl-[1cm]">
            Atas perhatian dan kerja samanya kami ucapkan terima kasih. 
        </div>
        
        <br/>
        <br/> 
        <div style={{marginLeft: "374px"}}>
            Kepala Kantor Pelayanan Pajak 
            <br/>
            Pratama Jakarta Pasar Rebo 
            <img src={require("../../ttd_pajak.JPG")} className="mt-2" width="70" alt="" />
            <div className="text-gray-500">Ditandatangani secara elektronik</div>
            Widi Widodo
        </div>
        </div>
       
        </div>
    
    )
}