import Datatable from '../../components/Datatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faEye, faSortUp, faSortDown, faPlus,  faEdit, faTrash, faCaretRight, faCaretDown, faList, faDollarSign, faBullseye, faCoins, faChartLine, faChalkboard} from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import { numberFormat } from '../../api/Value';
const patternColor   = ["100", "200", "300", "400", "500", "600", "700", "800", "900"]
const patternBg      = ["#8e24aa",  "#3949ab", "#1e88e5", "#00acc1", "#00897b","#43a047"]
export default ({ exportAll,openModalDetail, openModalForm, openModal, Data_Layanan, openDetailUser, openEditModal, handleExpand, handleChecked }) => {

  const theadUser = ({ sort, order_by }) => {
    //console.log(order_by)
    return (
      <thead>
        
        <tr>
            <td className=" border-2 border-gray-200 p-2 w-20 text-center bg-gray-100" >#</td>
            <td className=" border-2 border-gray-200 p-2 text-center bg-gray-100">Kode</td>
            <td className=" border-2 border-gray-200 p-2 text-center bg-gray-100">Nama</td>
            <td className=" border-2 border-gray-200 p-2 text-center bg-gray-100">Pelaksana/Form/Unit/Produk</td>
            
            <td className=" border-2 border-gray-200 p-2 text-center bg-gray-100">Tarif/Target/Realisasi</td>
            <td className=" border-2 border-gray-200 p-2 text-center bg-gray-100">Aksi</td>
        </tr>
       
      </thead>
      
    )
  }

  const Export  = ({ exportFile }) => {
      return (
          <div>
            <button onClick={() => exportFile("print", "User")} className="hover:bg-gray-400 active:bg-gray-600 active:shadow-none bg-gray-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Print</button>
            <button onClick={exportAll} className="hover:bg-teal-400 active:bg-teal-600 active:shadow-none border-teal-600 bg-teal-500 ml-2 p-2 mt-0.5 rounded-md text-white shadow-md">Excel</button>
            
          </div>
      )
  }
  return (
    <div>
       <div className="bg-[#8e24aa]"></div>
       <div className="bg-[#3949ab]"></div>
       <div className="bg-[#1e88e5]"></div>
       <div className="bg-[#00acc1]"></div>
       <div className="bg-[#00897b]"></div>
       <div className="bg-[#43a047]"></div>
    

       <div className="bg-gray-100"></div>
       <div className="bg-gray-200"></div>
       <div className="bg-gray-300"></div>
       <div className="bg-gray-400"></div>
       <div className="bg-gray-500"></div>
       <div className="bg-gray-600"></div>
       <div className="bg-gray-700"></div>
       <div className="bg-gray-800"></div>
       <div className="bg-gray-900"></div>

        <Datatable 
          Export={ Export }
          Head={theadUser}
          Data={Data_Layanan}
          Entries={[]}
          hidePagination= {true}
          Body={(row, index) => {
           
            const viewCode = row.kode_layanan.split(".")
            const ml  = viewCode.length;
            const isProduct = (row.is_product) ? "green" : "red";
            let mod = (viewCode.length%2 === 0) ? " bg-gray-200 " : "" 
   
            let bg  = patternBg[parseInt(row.padding_rem)-1];
            const padExpand = (viewCode.length === 1) ? viewCode.length : viewCode.length*2;
            const statusForm = (row.info_layanan !== "" && row.info_layanan !== undefined && row.info_layanan !== null ) ? JSON.parse(row.info_layanan)['tipe_form']: null
            const statusUnit = (row.is_product && (row.unit_penjual === "" || row.unit_penjual === ",")) ? "bg-yellow-400" : "";
          //  console.log(bg)
            return (
              <tr key={ index }>
                <td className={"border-2 border-gray-300 p-2 align-top text-center " + mod}>
                  
                      <input type="checkbox" checked={row.checked} className="border-5 w-[22px] h-[22px]" onChange={(e) => handleChecked(row.index, e.target.checked)} />
                    
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top  " + mod} style={{paddingLeft: padExpand + "rem"}} width="25%">
                   <div onClick={() => handleExpand(row.index)}>
                      {
                        (row.is_product === false)?
                          <FontAwesomeIcon icon={(row.expand) ? faCaretDown : faCaretRight} className="mr-2 text-gray-500" width="25%" />
                        :
                          null
                      }
                      <div className={`bg-[${bg}] hover:opacity-80 min-w-[60px] text-center  cursor-pointer inline-block pl-1 pr-1 text-white rounded-md`}>
                        { viewCode[viewCode.length-1] }
                      </div>
                    
                   </div>
                    
                </td>
                
                <td className={"border-2 border-gray-300 p-2  align-top " + mod} width="20%" valign="top">
                   { row.nama_layanan }
                       
                </td>
                <td className={"border-2 border-gray-300 p-2  align-top " + mod} width="20%" valign="top">
                    <ChipTable customCard={(row.is_product && row.kategori === "") ? "bg-yellow-400" : ""} icon={<FontAwesomeIcon icon={faList} />} text={row.kategori} />
                       
                    <ChipTable customCard={((statusForm === null || statusForm === "") && row.is_product) ? "bg-yellow-400" : ""} icon={<FontAwesomeIcon icon={faChalkboard} />} text={ statusForm } />
                  
                    <div className={`border-b-2 mb-2 min-h-[20px] w-full ${statusUnit}`}>

                    {
                      row.unit_penjual.split(",").map((x) => {
                        if (x == "") {
                          return ""
                        }
                        return (
                            <div className="inline-block mb-2 bg-blue-500 pb-1 pt-1 mt-2 pl-2 pr-2  rounded-md text-white mr-2 " key={x}>
                                {x} 
                          </div>
                        )
                      })
                    }
                    
                    </div>
                    <div className={`w-[20px] h-[20px] bg-${isProduct}-500 ml-1 rounded-full`}></div>
                 
                </td>
                  <td className={"border-2 border-gray-300 p-2  align-top " + mod} width="20%">
                    <ChipTable customCard={(row.is_product && row.tarif === 0) ? "bg-yellow-400" : ""} icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.tarif}`)} />
                    <ChipTable icon={<FontAwesomeIcon icon={faBullseye} />} text={numberFormat(`${row.target}`)} />
                    <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={0} />
                   
                       
                </td>
                <td className={"border-2 border-gray-300 p-2 align-top text-center " + mod}>
                    <div className="w-[200px]">
                       <button  onClick={() => openModalDetail({ index: row.index })}  className="active:shadow-none bg-white ml-2  active:bg-gray-300 hover:bg-gray-200 border-gray-400 border-2 shadow-md text-gray-400 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faEye} />
                        </button> 
                          <button onClick={() => openModalForm({ index: row.index, type: "plus" })} className="active:shadow-none bg-white ml-2  active:bg-blue-300 hover:bg-blue-200 border-blue-400 border-2 shadow-md text-blue-400 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faPlus} />
                        </button> 
                     
                        <button onClick={() => openEditModal({ index: row.index })} className="active:shadow-none bg-white ml-2  active:bg-gray-300 hover:bg-gray-200 border-orange-400 border-2 shadow-md text-orange-400 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faEdit} />
                        </button> 
                    
                        <button onClick={() => openModal({ index: row.index })} className="active:shadow-none bg-white ml-2 active:bg-gray-300 hover:bg-gray-200 border-red-500 border-2 shadow-md text-red-500 p-1 rounded-full w-10">
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    
                </td>
              </tr>
            )
          }}
        />
    </div>
  )
}