import Spinner    from './Spinner';

export default ({ closeModal, onSubmit, loader, textSubmit=false, position="justify-end", isDelete=false }) => {
   
    return(
        <div className={" flex " + position}>
            {
                (!loader) ?
                    <div>
                        <button className={`font-bold ${isDelete ? "bg-white text-black" : "bg-gray-600 text-white"} p-2 shadow-md hover:bg-gray-500 active:bg-gray-700 active:shadow-none rounded-md w-32  `} onClick={() => closeModal(false)}>Batal</button>
                        <button onClick={onSubmit} className={` whitespace-nowrap	 font-bold ${isDelete ? "bg-red-800 hover:bg-red-500 active:bg-red-900 " : "bg-green-600 hover:bg-green-500 active:bg-green-700"} ml-2 p-2 shadow-md  active:shadow-none rounded-md min-w-[150px] border-green-700 text-white`}>
                             {(textSubmit) ? textSubmit : (isDelete) ? "Yakin" :  "Simpan"}
                        </button>
                    </div>
                : 
                <button className={` cursor-not-allowed flex font-bold ${isDelete ? "bg-red-800 border-red-600" : "bg-green-600 border-green-600" } ml-2 p-2 rounded-md w-52  text-white`}>
                  
                     <div className="w-1/4">
                         <Spinner 
                        color="rgb(255 255 255)"
                        width="30px"
                        height="30px"
                    />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}

export const Delete = ({ loader, closeModal, onSubmit }) => {


     return(
        <div className="text-right flex justify-end">
            
            
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={onSubmit} className=" font-bold bg-red-700 ml-2 p-2 shadow-md hover:bg-red-800 active:bg-red-900 active:shadow-none rounded-md w-32 text-white">
                                Yakin
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-red-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
            

        </div>
    )
}